const modules = {
    PURCHASEINVOICES                     : 1,
    GO2UBL                               : 2,
    PAYMENTORDERS                        : 3,
    TENDERS                              : 4,
    SALESINVOICES                        : 5,
    PROJECTS                             : 6,
    HOURS                                : 7,
    MANDAYS                              : 8,
    PERIODICINVOICES                     : 9,
    PLANNING                             : 10,
    RESELLER                             : 11,
    SALESORDERS                          : 15,
    WORKORDERS                           : 16,
    MOLLIE_PAYMENTS                      : 20,
    MOLLIE_INCASSO                       : 22,
}

export default modules;