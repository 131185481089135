<template>
    <Dialog :dialog="dlg">
        <template v-slot:toolbar-left>
            
            <ToolbarField label="Status">
                <OrderStatusChip :model="model"></OrderStatusChip>
            </ToolbarField>

            <ToolbarField class="ml-8" label="Relatie">
                <span class="text-primary">{{model.rel_name}}</span>
            </ToolbarField>

            <ToolbarField class="ml-8" label="Totaal excl. BTW">
                <AmountText v-model='model.ord_amount'/>    
            </ToolbarField>

            <ToolbarField class="ml-8" label="Openstaand excl. BTW">
                <AmountText v-model='model.open_amount' :class="{'text-success':!model.open_amount&&model.ord_amount}"/>    
            </ToolbarField>

        </template>
        <template v-slot:toolbar-right>
            <ActionButton :model="model" v-if="!model.isArchived" ml action="salesorder.archive" icon="archive" @click="onArchive">Status Gereed</ActionButton>
            <ActionButton :model="model" v-else ml action="salesorder.unarchive" icon="unarchive" @click="onUnArchive">Maak actueel</ActionButton>
            
            <ActionButton :model="model" icon="salesinvoice" ml main @click='()=>onStartCreateInvoice()'>Maak factuur</ActionButton>
        </template>

        <template v-slot:toolbar-menu>
                
        </template>        

        <template v-slot:tabs-left>
            <v-tabs dense v-model="dlg.tab">        
                <v-tab>Gegevens</v-tab>                
                <v-tab :disabled="model.isLoading">Regels</v-tab>
            </v-tabs>
        </template>        

        <v-tabs-items class="salesorder-tabs mt-0" v-model="dlg.tab">                
            <v-tab-item class="salesorder-header" transition="none" reverse-transition="none">                        
                <v-form ref="formHeader" lazy-validation>
                    <TabHeader :model="model"></TabHeader>
                </v-form>
            </v-tab-item>
            <v-tab-item class="salesorder-lines" transition="none" reverse-transition="none">                        
                <v-form ref="formLines" lazy-validation>
                    <TabLines :invTriggerCnt="invTriggerCnt" :model="model" @save="onSave" @onCreateInvoice="onCreateInvoice"></TabLines>
                </v-form>
            </v-tab-item>
        </v-tabs-items>

    </Dialog>
</template>



<script setup>
    import useModel from '@/models/salesorder/salesorder'    

    import Dialog from '@shared/ui/dialogs/ActionDialogModal'
    import { ref, watch } from 'vue'
    import clsDialog from '@cls/clsDialog'
    import ToolbarField from '@shared/ui/dialogs/ToolbarField'
    
    import TabHeader from './TabHeader'
    import TabLines from './TabLines'
    import OrderStatusChip from '@/ui/controls/OrderStatusChip'
    import AmountText from '@controls/text/Amount.vue'
    import ActionButton from '@controls/buttons/ActionButton'
    import appDlg from '@app/dlg'
    import action from '@app/action'
    import noty from '@shared/lib/noty'
    import eventbus from '@app/eventbus'

    const formHeader = ref(null); 
    const formLines = ref(null); 

    const invTriggerCnt = ref(0);

    let model = useModel();
    let dlg = ref(
        new clsDialog("salesorder", "Verkooporder", model, [formHeader, formLines], 
        {
            setKeyOnOpen: true,
            noteType: "salesorder_note",
            flagType: "salesorder_flag",
            attachmentType: "salesorder_attachment",

            keepOpen: true,
        }
    )).value; 

    async function onSave(promResolve, promReject) {
        if (!await dlg.onSave()) {
            promReject({canceled: true});
        } else {
            promResolve();
        }
    }     

    async function onCreateInvoice(lines) {     
        if (!await dlg.value.onSave()) {
            return false;
        }        
        var id_invoice = model.createInvoice(lines);

        try {
            var result = await appDlg.promised.open("salesinvoice", {id: id_invoice});
            if (result.count) { // Files were uploaded.
                dt.search();
            }
        } catch (e) {} // the upload dialog was canceled.

     
    }

    function onStartCreateInvoice() {
        dlg.tab = 1; // Lines must be selected in the lines tab.
        setTimeout( ()=> invTriggerCnt.value++, 100)        
    }

    async function onArchive() {
        if (!await noty.tryConfirm("Weet u zeker dat u deze order in status 'Gereed' wilt zetten?<br><br>U kunt de order hierna terugvinden in het menu 'Gereed'.<br>Orders in status 'Gereed' kunt u altijd weer actueel maken.")) {
            return;
        }
        if (!action.can('salesorder.archive')) {
            return;
        }
        if (model.disabled) { // Also, when generally disabled, do not save.
            return; 
        }
        if (!await dlg.onSave()) {
            return false;
        }
        try {
            await model.archive();
            eventbus.model.stats.refresh("salesorder");   
        } catch (e) { if (!e.canceled) { throw e;} }

    }
    async function onUnArchive() {
        if (!await noty.tryConfirm("Weet u zeker dat u deze order weer actueel wilt maken?<br><br>U kunt de order hierna terugvinden in het menu 'Open'.")) {
            return;
        }
        if (!action.can('salesorder.unarchive')) {
            return;
        }
        if (model.disabled) { // Also, when generally disabled, do not save.
            return; 
        }
        if (!await dlg.onSave()) {
            return false;
        }
        try {
            await model.unArchive();
            eventbus.model.stats.refresh("salesorder");   
        } catch (e) { if (!e.canceled) { throw e;} }

    }


    // 
    window.sodlg   = dlg;
</script>

