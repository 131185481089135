import { clsModel, fnCreate } from '@cls/clsModel'
import { jobtitles as api } from '@/app/api'

var modelName = "jobtitle";
const fields = ['id', 'name'];

class clsJobtitle extends clsModel {

    name = null;
    
    get modelRep() {
        return this.name;
    }
    
    
    constructor() {
        super({
          api: api,   
          modelName: modelName, 
          mandatoryFields: ['name'],

          fillable: fields
        })
    } 
        
 }
 export default fnCreate(clsJobtitle , 'clsJobtitle');
