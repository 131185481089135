import user from '@app/user'
import { clsModel, fnCreate } from '@cls/clsModel'
import noty from '@shared/lib/noty'

var modelName = "changepersonname";
const fields = ['password', 'name'];

class clsChangePersonName extends clsModel {

    password= '';
    name= '';

    async save() {
        await user.updateUserPersonName(this.name, this.password);
        noty.snack.info("De naam is aangepast")        
    }
    async load() {
        this.password= '';
        this.name= user.name;
    }    
    constructor() {
        super({          
          modelName: modelName, 
          fillable: fields
        })
    } 
}

export default fnCreate(clsChangePersonName , 'clsChangePersonName');
