<template>
    <Dialog :dialog="dlg">
        <div>
            <p>
                Met een support-verzoek deelt u tijdelijk uw Optimit omgeving met een Optimit medewerker. 
            </p>
            <p>
                Een support medewerker: 
                <UlCustom>
                    <LiCustom check>Heeft <strong>geen</strong> toegang tot uw financiele dashboard</LiCustom>    
                    <LiCustom check>Heeft toegang tot alle overige functies binnen uw Optimit omgeving</LiCustom>    
                </UlCustom>

            </p>
            <p>
                Een support verzoek eindigt:
                <UlCustom>
                    <LiCustom check>Automatisch 60 minuten na het oppakken van het verzoek</LiCustom>    
                    <LiCustom check>Op het moment dat u eerder het verzoek intrekt</LiCustom>    
                </UlCustom>
            </p>                
        </div>

        <v-divider v-if="!model.isDataLoading" class="mt-2 mb-4"></v-divider>

        <div v-if="model.isDataLoading">            
        </div>
        <AlertArea v-else-if="!model.hasRequest" error icon-info>
            U heeft geen uitstaande support aanvraag.
        </AlertArea>
        <div v-else>
            <AlertArea error icon-info>
                U heeft een uitstaande support-aanvraag.
            </AlertArea>
            <v-row dense>
                <v-col>Aangemaakt: </v-col>
                <v-col><DateTimeField isUtc time v-model="model.created_at"></DateTimeField></v-col>
            </v-row>
            <v-row dense>
                <v-col>Verloopt:   </v-col><v-col><DateTimeField isUtc time v-model="model.expires_at"></DateTimeField></v-col>
            </v-row>
            <v-row dense>
                <v-col>Opgepakt:   </v-col>
                    <v-col>
                        <DateTimeField isUtc time v-if="model.pickedup_at" v-model="model.pickedup_at"></DateTimeField>
                        <span v-else>Nog niet opgepakt</span>
                    </v-col>
            </v-row>
            <v-row dense v-if="model.pickedup_by">
                <v-col></v-col>
                <v-col>{{model.pickedup_by}}</v-col>
            </v-row>

        </div>

        <template v-slot:footer-save>
            <span v-if="model.isDataLoading"></span>
            <ActionButton :disabled="model.isLoading" scmodel="model" ml v-else-if="!model.hasRequest" main primary @click='onCreateRequest'>Maak verzoek</ActionButton>
            <ActionButton :disabled="model.isLoading" scmodel="model" ml v-else main error @click='onRemoveRequest'>Verwijder verzoek</ActionButton>
        </template>

    </Dialog>
</template>

<script setup>
    import useModel from '@/models/supportrequest'
    import Dialog from '@shared/ui/dialogs/ActionDialog'
    import { ref } from 'vue'
    import clsDialog from '@cls/clsDialog'
    import ActionButton from '@controls/buttons/ActionButton'
    import DateTimeField from '@controls/text/DateTime'
    import UlCustom from "@controls/list/UlCustom"
    import LiCustom from "@controls/list/LiCustom"
    import AlertArea from '@shared/ui/markup/AlertArea'
    const form = ref(null); 


    let model = useModel();
    let dlg = ref(
        new clsDialog("support_request", "Support verzoek", model, [form], {
            icon: 'support',
            // save:false,
            // cancel: 'Sluit',
            keepOpen: true,
            noSubTitle: true
        } )).value;

    function onCreateRequest() {
        dlg.onSave();
    }
    function onRemoveRequest() {
        model.removeRequest();
    }

    

    window.supportDialog = dlg;

</script>
