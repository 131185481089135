import clsApi from '@cls/clsApi'

class clsRelationApi extends clsApi {


    merge(id_relation_from, id_relation_to) { 
        return this.post(`merge`, {id_relation_from:id_relation_from, id_relation_to:id_relation_to}); 
    }

    /**
     * Save / insert a bank account
     */
    saveBankAccount(data) {
        return this.post(`bankaccount/save`, data);
    }
    /**
     * Save / insert a bank account
     */
    loadBankAccount(id) {
        return this.get(`bankaccount/load/${id}`);
    }

    /**
     * Remove a bankaccount
     */
    removeBankAccount(id) {
        return this.post(`bankaccount/remove`, {id: id});
    }
    /**
     * Execute checks before saving a bank account
     */
    getSaveBankAccountWarnings(data) {
        return this.post(`bankaccount/save/getwarnings`, data);
    }
}

export default clsRelationApi;
