<template>

    <ActionCombo :noClearable="noClearable" :placeholder="placeholder" :skeleton="compSkeleton" :disabled="compDisabled" :rules="rules" v-model="dvalue" :items="items.list"></ActionCombo>

</template>

<script setup>

    // import CountryCombo from '@controls/combo/Country'
    // <CountryCombo :disabled="model.disabled" :rules="model.rules.country_code" v-model="model.country_code"></CountryCombo>

    const props = defineProps({
        disabled: {
            type: [Boolean]
        },
        rules: {
            type: [Array]
        },
        noClearable: {
            type: [Boolean],
            default: false
        },
        skeleton: {
            type: [Boolean]
        },
        value: {
            type: [String, Number]
        },
        placeholder: {
            type: [String],
            default: 'Land'
        },
        model: {
            type: [Object]
        }

    })
    
    import ActionCombo from '@controls/combo/ActionCombo'
    import {country as items} from '@app/list';
    import {computed} from 'vue'

    const emit = defineEmits(['input'])

    const dvalue = computed({
        get() {
            return props.value
        },
        set: function(v) {
            emit('input', v)
        }	
    })
    const compSkeleton = computed({
        get() {
            if (props.skeleton) {
                return true;
            }
            if (!props.model) {
                return false;
            }
            return props.model.isDataLoading;
        }
    });
    const compDisabled = computed({
        get() {
            if (props.disabled) {
                return true;
            }
            if (!props.model) {
                return false;
            }
            return props.model.disabled;
        }
    });

</script>
