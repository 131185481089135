<template>
        <v-card class="bg-card">
            <v-card-text>
                <v-row dense>
                    <v-col>
                    <ToggleButtonGroup dusk='vatlines.type' @input="()=>model.applyVatLineAmounts()" :disabled="model.isStatusAtLeastAccepted" col-auto :model="model" v-model="model.vatlines.type" :options="vattypes"/>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-text class="pa-0 pb-0">                 
                <LocalTable :model="model.vatlines" modelLinesProp='lines' :disabled="model.isStatusAtLeastAccepted" no-hover dense :dt="tblVatLines" editable>
                    <template v-slot:item.id_vat="{ item }">
                        <span class="pl-3 text-disabled" v-if="model.vatlines.type=='shifted'">BTW Verlegd</span>
                        <span class="pl-3 text-disabled" v-else-if="model.vatlines.type=='foreign'">BTW Buitenland</span>
                        <VatCombo dusk='id_vat' :disabled="model.isStatusAtLeastAccepted"  @input="()=>onVatChange(item)" no-clearable standard-types v-else no-border :model="model" :rules="model.rules.id_vat" v-model="item.id_vat"></VatCombo>
                    </template>
                    <template v-slot:item.id_ledger="{ item }">
                        <LedgerCombo dusk='id_ledger' :disabled="model.isStatusAtLeastAccepted"  no-border :model="model" :rules="model.rules.id_ledger" v-model="item.id_ledger"></LedgerCombo>
                    </template>
                    <template v-slot:item.amount_vat="{ item }">
                        <NumberField dusk='amount_vat' :disabled="model.isStatusAtLeastAccepted"  no-border :model="model" amount v-model="item.amount_vat" :currency="model.crnc_code">
                        </NumberField>
                    </template>
                    <template v-slot:item.amount_excl="{ item }">
                        <NumberField dusk='amount_excl' :disabled="model.isStatusAtLeastAccepted"  no-border :model="model" amount v-model="item.amount_excl" :currency="model.crnc_code">
                            <template v-slot:prepend-inner>
                                <span class="cursor-pointer xslot-show-on-hover" v-if="(!model.disabled)&&model.missing_line_amount" @click="()=>model.calcVatLine(item)"><Icon small type="calculator"></Icon></span>
                            </template>
                        </NumberField>
                    </template>
                    <template v-slot:item.cmd-remove="{ item }">
                        
                        <ActionButton dusk='btn-remove' v-if="!model.isStatusAtLeastAccepted" :disabled="model.isStatusAtLeastAccepted" :model="model" ml icon-button xcolor="white" icon="remove" 
                            action="purchaseinvoice.modify" 
                            @click="() => model.removeVatLine(item)">
                        </ActionButton>

                    </template>

                    <template v-slot:tfoot>
                        <tfoot >
                        <tr>
                            <td class="pt-4 pb-4" colspan="2">
                                <ActionButton sub action="purchaseinvoice.modify" dusk='btn-addvatline'  v-if="!model.isStatusAtLeastAccepted" :disabled="model.isStatusAtLeastAccepted" ml :model="model" icon="add" @click='()=>model.addVatLine()'>Nieuwe regel</ActionButton>
                            </td>
                            <td class="pt-4  pb-4 text-right text-error text-small pr-7" colspan="3">                                
                                <span v-if="model.missing_line_amount">
                                    Nog te specificeren: <Amount dusk="missing_line_amount" v-model="model.missing_line_amount" :currency="model.crnc_code"></Amount>                                
                                </span>
                            </td>
                        </tr>
                        </tfoot>
                    </template>

                </LocalTable>
            </v-card-text>
        </v-card>
</template>


<script setup>
    
    import Form from '@controls/forms/Form'
    import FormColumn from '@controls/forms/FormColumn'
    import FormRow from '@controls/forms/FormRow'
    import TextField from '@controls/input/TextField'
    import Checkbox from "@controls/check/Checkbox.vue";
    import ToggleButtonGroup from '@controls/buttons/ToggleButtonGroup'
    import ActionButton from '@shared/ui/controls/buttons/ActionButton'
    import LocalTable from '@datatable/LocalTable'  
    import clsLocalTable from '@cls/clsLocalTable'
    import {purchaseinvoice as settings} from '@app/settings'
    import NumberField from '@controls/input/NumberField'
    import VatCombo from '@controls/combo/Vat'
    import LedgerCombo from '@controls/combo/Ledger'
    import Amount from "@controls/text/Amount.vue";
    import ActionMenuItem from "@controls/menu/ActionMenuItem.vue";
    import Icon from '@controls/icons/Icon'
    import {computed, ref} from 'vue'

    let vattypes = [{id: 'standard', name: 'BTW Standaard'}, {id: 'shifted', name: 'BTW Verlegd'}, {id: 'foreign', name: 'BTW Buitenland'}];

    const props = defineProps({
        model: {
            type: [Object]
        },
    });

    let headers = [
        { "text": "BTW", "value": "id_vat", class:"pl-3", styleHeader:{width:"200px"}}, 
        { "text": "Grootboek", "value": "id_ledger", class:"pl-3", visible: settings.use_ledgers}, 
        { "text": "Totaal Excl. BTW", "value": "amount_excl", class:"text-right pr-3"}, 
        { "text": "BTW Bedrag", "value": "amount_vat", class:"text-right pr-3"}, 
        { "text": "", value: "cmd-remove", class: 'cmd'}, 
    ];

    let tblVatLines = new clsLocalTable("purchaseinvoice_vatlines", headers, {
        noDataText: "Er zijn nog geen BTW regels toegevoegd",
        model: props.model.vatlines, 
        modelLinesProp: 'lines'
        
    });

    /**
     * When the vat type changes trigger the parent model to calculate again.
     * The line itself can not do it as the calculation depends on line sibling amounts.
     */
    function onVatChange(line) {
        
        props.model.calcVatLine(line);
    }

</script>
