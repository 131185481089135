<template>
    <span>            
        <StatusChip type="tender" :status="chipStatus(item)">{{statusRep(item)}}
          <template v-slot:prefix><span v-if="item.archived_at" class="success" title="Offerte is gereed">
              <Icon small type='check'></Icon>
          </span></template>
        </StatusChip>
    </span>
</template>

<script setup>
    import StatusChip from "@controls/widgets/StatusChip.vue";
    import Icon from '@controls/icons/Icon'
    import {tenderstatus} from '@app/list';
    import Constants from '@app/consts'
    import {date} from '@lib/date'
  
    const props = defineProps({
      item: {          
          type: [Object]
      },
    });

      /**
   *  The status to be used by the chips.
   */
   function chipStatus(item) {
      switch (item.id_status) {
          case Constants.tender.status.STATUS_CONCEPT    : return 'concept';   // 
          case Constants.tender.status.STATUS_ACCEPTED   : return 'accepted';  // 
          case Constants.tender.status.STATUS_REJECTED   : return 'rejected';  // vervallen
          case Constants.tender.status.STATUS_FINISHED   : return 'processed'; // finished 
          case Constants.tender.status.STATUS_OPEN       : 
            if (0 === item.accepted) {
                return "rejected";
            } else if(1 === item.accepted) {
                return "accepted";
            }
            if (date.isInPast(item.tend_exp_date)) {
                return "expired";
            }
            return "open";
          default: break;
      }
      return "";
  }

function statusRep(item) {
    if (item.id_status != Constants.tender.status.STATUS_OPEN) {
        return tenderstatus.oneProp(item.id_status, 'name', '-');
    }
    if (0 === item.accepted) {
        return "Afgewezen";
    } else if(1 === item.accepted) {
        return "Ondertekend";
    }
    if (date.isInPast(item.tend_exp_date)) {
        return "Verlopen";
    }

    return "Uitstaand";        
}

  

</script>