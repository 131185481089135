<template>
    <Dialog :dialog="dlg">
        <template v-slot:toolbar-left>
            
            <ToolbarField label="Status">
                <StatusChip type='po' :status='model.status'>{{model.statusRep}}</StatusChip>
            </ToolbarField>

            <ToolbarField class="ml-8" label="Totaal">
                <Amount v-if="model.hasDiscount" class="" :value="model.total_minus_discount" currency="EUR"></Amount>
                <Amount v-else class="" :value="model.total" currency="EUR"></Amount>
                
                <Amount v-if="model.hasDiscount" class="ml-2 text-small text-line-through" :value="model.total" currency="EUR"></Amount>
                
            </ToolbarField>
            <ToolbarField class="ml-8" v-if="model.hasDiscount" label="Betalingskorting">
                <Amount v-if="model.hasDiscount" class="" :value="model.total_discount" currency="EUR"></Amount>
            </ToolbarField>

        </template>
        <template v-slot:toolbar-right>

            <ActionButton ml sub dropdown v-if="model.split_g" >
                Download
                <template v-slot:dropdown>
                    <ActionMenuItem action="paymentorder.download" @click="()=>onDownload()">Betaalopdracht</ActionMenuItem> 
                    <ActionMenuItem action="paymentorder.download" @click="()=>onDownloadG()">G-Betaalopdracht</ActionMenuItem> 
                </template>
            </ActionButton>

            <ActionButton v-else ml action="paymentorder.download" :disabled="model.isDataLoading" icon="download" @click='()=>onDownload()'>Download</ActionButton>            
            <ActionButton v-if="!model.isOpen" :disabled="model.isDataLoading" ml action="paymentorder.backtoconcept" icon="rotate-left" @click='()=>onConcept()'></ActionButton>
            <ActionButton v-if="model.isPending" ml action="paymentorder.setpaid"  icon="payment" @click='()=>onSetPaid()'></ActionButton>

        </template>

        <template v-slot:toolbar-menu>
            <ActionMenuItem no-execute action="paymentorder.open" type="export" @click="()=>dt.toExcel()">Exporteer regels naar Excel</ActionMenuItem>                             
        </template>

        <template v-slot:header-menu>
            
        </template>        

        <v-form ref="form" lazy-validation>
            <v-card class="bg-card">
                <v-card-text>
                        <v-row dense>
                            <v-col sm="6" xl="4" class="pr-4">
                                <FormRow col-label="3" label="Omschrijving">            
                                    <TextField max-length="100" :model="model" :rules="model.rules.description" placeholder="Omschrijving" v-model="model.description"></TextField>
                                </FormRow>

                                <FormRow col-label="3" label="Uitvoerdatum">
                                    <DatePicker :model="model" v-model="model.execution" :rules="model.rules.execution"></DatePicker>
                                </FormRow>

                                <FormRow col-label="3" label="Van rekening">
                                    <CompanyIBAN :model="model" :rules="model.rules.iban" v-model="model.iban"></CompanyIBAN>
                                </FormRow>
                            </v-col>
                            <v-col sm="6" xl="4" class="pl-4">

                                <FormRow col-label="3" label="G-bedrag"  v-if="model.total_g>0">            
                                    <Checkbox :model="model" v-model="model.split_g" label="Betaal g-bedragen van aparte rekening"></Checkbox>
                                </FormRow>
                                <FormRow v-if="model.total_g>0 && model.split_g" col-label="3" label="Van G-Rekening">     
                                    <CompanyIBAN g :model="model" :rules="model.rules.iban" v-model="model.ibang"></CompanyIBAN>
                                </FormRow>
                            </v-col>
                        </v-row>
                </v-card-text>
            </v-card>    

            <v-card class="bg-card mt-4">
                <v-card-title class="text-larger py-2">
                    Inkoopfacturen
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="pa-0 pb-4">
                    <div class="paymentorder-lines">
                        <LocalTable :model="model" modelLinesProp='lines' no-hover no-dense :dt="dt" class="full-height paymentorderlines">

                            <template v-slot:menu ="{ item }">
                                <ActionMenuItem v-if="item.isPurchaseInvoiceLine" action="purchaseinvoice.open" :payload="item" no-execute @click="onOpenInvoice(item)">Toon factuur</ActionMenuItem>
                                <v-divider v-if="item.isPurchaseInvoiceLine" ></v-divider>
                                <ActionMenuItem :model="model" v-if="item.hasChildren" action="paymentorder.modify" icon="back" @click="onSplit(item)">Split in losse facturen</ActionMenuItem>
                                <ActionMenuItem :model="model" action="paymentorder.modify" icon="combine_amount" @click="dt.onStartCheckBox(item, 'combine')">Combineer regels</ActionMenuItem>
                                <v-divider></v-divider>
                                <ActionMenuItem :model="model" action="paymentorder.modify" icon="remove" @click="dt.onStartCheckBox(item, 'remove')">Verwijder uit betaalbestand</ActionMenuItem>
                            </template>

                            <template v-slot:item.total_payable ="{ item }">                                
                                <span v-if="item.hasDiscount">
                                    <Amount class="" :value="item.total_minus_discount" currency="EUR"></Amount>
                                    <Amount class="ml-2 text-small text-line-through" :value="item.total_payable" currency="EUR"></Amount>
                                </span>
                                <span v-else>
                                    <Amount class="" :value="item.total_payable" currency="EUR"></Amount>
                                </span>
                            </template>
                            
                            <template v-slot:item.discount ="{ item }">
                                <span v-if="!Number(item.discount)">-</span>
                                <span v-else>
                                    <span class="pl-4" v-if="item.hasChildren"></span>
                                    <span v-else style="top:-2px;position:relative;" class="mr-4 mb-0 cursor-pointer" @click="onToggleDiscount(item)">
                                        <span v-if="item.apply_discount">
                                            <i aria-hidden="true" :class="{disabled:model.disabled}" class="v-icon notranslate v-icon--dense fas fa-check-square theme--light primary--text"></i>
                                        </span>
                                        <span v-else>
                                            <i aria-hidden="true" :class="{disabled:model.disabled}" class="v-icon notranslate v-icon--dense far fa-square theme--light"></i>
                                        </span>                                            
                                    </span>                                    
                                    <span>
                                        <Amount class="" :value="item.discount" currency="EUR"></Amount>
                                    </span>                                    
                                    <span v-if="!model.isPaid && item.isDiscountLate && !item.hasChildren" class="ml-1 text-error">
                                        Laat
                                    </span>
                                </span>
                            </template>

                            <template v-slot:item.rel_name ="{ item }">
                                <span class="text-primary cursor-pointer" v-if="item.hasChildren" @click="() => onToggleLine(item)">
                                    <Icon style="top:-2px;" class="text-primary mr-2" small v-if="item.expanded" type='collapse'></Icon>
                                    <Icon style="top:-2px;" class="text-primary mr-2" small v-else type='expand'></Icon>
                                    <span class="mr-2">({{item.cntChildren}})</span>{{item.rel_name}}
                                </span>
                                <span v-else-if="item.isChild">
                                    
                                </span>
                                <span v-else>
                                {{ item.rel_name }}
                                </span>
                            </template>
                            
                            <template v-slot:item.invoicenr ="{ item }">
                                <span v-if="!item.isPurchaseInvoiceLine"> 
                                    {{item.invoicenr}}
                                </span>
                                <span v-else class="text-primary cursor-pointer" @click="() => onOpenInvoice(item)">
                                    {{item.invoicenr}}
                                </span>
                            </template>

                            <template v-slot:commands="{dt,bulkAction, selectedCount}">
                                <ActionButton main primary icon="remove" v-if="bulkAction=='remove'"  @click="()=>onRemoveLines(dt)" :disabled="!selectedCount" >Verwijder</ActionButton>
                                <ActionButton main primary icon="combine_amount" v-if="bulkAction=='combine'"  @click="()=>onCombineLines(dt)" :disabled="!selectedCount" >Combineer</ActionButton>
                            </template>

                            <template v-slot:footer.total_payable ="{ dt }">
                                <Amount class="text-bold" v-if="model.hasDiscount" :value="model.total_minus_discount" currency="EUR"></Amount>
                                <Amount class="text-bold" v-else :value="model.total" currency="EUR"></Amount>                                
                                <Amount v-if="model.hasDiscount" class="ml-2 text-small text-line-through" :value="model.total" currency="EUR"></Amount>
                            </template>
                            <template v-slot:footer.discount ="{ dt }">
                                <Amount class="text-bold ml-4" :value="model.total_discount"></Amount>
                            </template>
                            <template v-slot:footer.g_amount ="{ dt }">
                                <Amount class="text-bold" :value="model.total_g"></Amount>
                            </template>
                            <template v-slot:footer.total_vat ="{ dt }">
                                <Amount class="text-bold" :value="model.total_vat"></Amount>
                            </template>
                            <template v-slot:footer.total_excl ="{ dt }">
                                <Amount class="text-bold" :value="model.total_excl"></Amount>
                            </template>

                            <template v-slot:tfoot="{/*dt, hasCmd, */columnHeaders}">
                                <tfoot>
                                <tr><td :colspan="columnHeaders.length+1">
                                    <ActionButton :model="model" action="paymentorder.modify" class="mt-4"  icon="create" sub @click='()=>onAddLine()'>Voeg facturen toe</ActionButton>
                                </td></tr>
                                </tfoot>
                            </template>

                        </LocalTable>
                        
                    </div>
                </v-card-text>
            </v-card>    
        </v-form>

    </Dialog>
</template>



<script setup>
    import useModel from '@/models/paymentorder'
    import Form from '@controls/forms/Form'
    import FormColumn from '@controls/forms/FormColumn'
    import FormRow from '@controls/forms/FormRow'
    import TextField from '@controls/input/TextField'
    import Dialog from '@shared/ui/dialogs/ActionDialogModal'
    import ActionButton from '@shared/ui/controls/buttons/ActionButton'
    import ActionMenuItem from "@controls/menu/ActionMenuItem.vue";
    import { ref, computed } from 'vue'
    import clsDialog from '@cls/clsDialog'
    import noty from '@lib/noty'

    import ToolbarField from '@shared/ui/dialogs/ToolbarField'
    import Amount from "@controls/text/Amount.vue";
    import DatePicker from '@controls/input/DatePicker'
    import Checkbox from "@controls/check/Checkbox.vue";
    import CompanyIBAN from '@/ui/controls/CompanyIBAN'

    import Icon from '@controls/icons/Icon'
    import LocalTable from '@datatable/LocalTable'  
    import clsLocalTable from '@cls/clsLocalTable'
    import eventbus from '@app/eventbus'
    import StatusChip from "@controls/widgets/StatusChip.vue";

    import IconMenu from "@controls/menu/IconMenu.vue";
    // export excel
    import string from '@lib/string'
    import XLSX from 'xlsx'
    import appDlg from '@app/dlg'

    const form = ref(null); 


    let model = useModel();
    let dlg = ref(
        new clsDialog("paymentorder", "Betaalopdracht", model, [form], 
        {
            noteType: 'paymentorder_note',
            flagType: 'paymentorder_flag',
            keepOpen: true,
            sm:12,rlg:10,rxl:8
        }
    )).value;  //TODO

    let headers = [
        { "type": "cmd" }, 

        { canhide: false, "text": "Relatie", "value": "rel_name"}, 
        { canhide: false, "text": "Factuurnummer", "value": "invoicenr" }, 
        { canhide: false, "text": "Bedrag incl BTW", "value": "total_payable", excelType: 'amount'}, 
        { "text": "Betalingskorting", "value": "discount", excelType: 'amount'}, 
        { excelOnly: true, "text": "Pas korting toe", "value": "apply_discount", excelFmt: (value, item) => value ? "Ja" : "Nee"}, 
        { canhide: false, "text": "IBAN", "value": "iban" }, 
        { canhide: false, "text": "Factuurdatum", "value": "invoicedate" , type: 'date'}, 
        { canhide: false, "text": "Vervaldatum", "value": "invoiceduedate" , type: 'date' }, 
        { "text": "Bedrag excl BTW", "value": "total_excl", visible: false, type: 'amount' }, 
        { "text": "BTW", "value": "total_vat", visible: false, type: 'amount' }, 
        { "text": "Naar G-rekening", "value": "g_amount", visible: false, type: 'amount'}, 
        { "text": "G-rekening", "value": "ibang", visible: false},         
    ];


    class clsMyTable extends clsLocalTable {
        getRowDataForExcel() {
            return model.getLinesForExcel();
        }
    }


    let dt = ref(new clsMyTable("dt_paymentorder_invoices", headers, {
        noDataText: "Er zijn nog geen inkoopfacturen toegevoegd", 
        model: model, 
        showFooter: true,
        modelLinesProp: "lines"    
    })).value;

    // -- add a purchaseinvoice to the list of suppliers.
    async function onAddLine() {

        if (!await dlg.onSave(true)) {
            return; // validation error
        }
        // No need to specify excluded invoices. When already in a payment order they will not be returned.
        
        let selected = await appDlg.promised.open('select_purchaseinvoice', {multiselect: true, filter: {selectfor: 'paymentorder'}})
        // let selected = await eventbus.dialog.open.promise('select_purchaseinvoice', {multiselect: true, filter: {selectfor: 'paymentorder'}})

        if (!selected || !selected.length) {
            return;
        }
        model.addLines(selected);
    }
    async function onRemoveLines(dt) {
        let ids = dt.selectedIDs();
        await model.removeLines(ids);
        dt.onStopCheckbox();
    }
    async function onCombineLines(dt) {
        let ids = dt.selectedIDs();
        await model.combineLines(ids);
        dt.onStopCheckbox();
    }
    async function onSplit(item) {
        let id = item.id;
        await model.splitLine(id);
        dt.onStopCheckbox();
    }    
    async function onToggleDiscount(item, e) {

        model.toggleDiscount(item);
    }

    async function onToggleLine(item) {
        model.toggleLine(item);
    }

    async function onOpenInvoice(line) {
        eventbus.dialog.pdfviewer(async () => line.downloadAsPdf(), `Inkoopfactuur ${line.invoicenr} - ${line.rel_name}`);
    }
    
    async function onDownloadG() {
        if (model.isOpen) {
            await noty.confirm("Wanneer u het betaalbestand download kunt u hierna geen wijzigingen meer aanbrengen in de betaalopdracht.");
        }

        if (!model.disabled) {
            await dlg.onSave(true /* keep dialog open */)
        }
        await model.downloadWarnings();
        model.downloadG();        
    }
    async function onDownload() {
        if (model.isOpen) {
            await noty.confirm("Wanneer u het betaalbestand download kunt u hierna geen wijzigingen meer aanbrengen in de betaalopdracht.");
        }
        if (!model.disabled) {
            await dlg.onSave(true /* keep dialog open */)
        }
        await model.downloadWarnings();
        model.download();        
    }
    async function onSetPaid() {
        model.setPaid();        
    }
    async function onConcept() {
        await noty.confirm("Wanneer u de betaalopdracht terug naar concept zet kunt u de betaalopdracht wijzigen, inkoopfacturen toevoegen en verwjderen. ");

        model.setConcept();        
    }

    // 
    window.pmodlg   = dlg;
    window.pmod     = model;
    window.pmodt = dt;
</script>
