import clsSettings from '@cls/clsSettings'

var modelName="purchaseinvoice"
class clsPurchaseSettings extends clsSettings {

    use_ledgers  = false
    use_bookdate  = false

    constructor() {
        super({modelName: modelName});
    }


    /**
     * clone the current setting.
     * load fresh data from the server.
     * autoLoad: load the data automagically
     * noDestroy: prevent object being cleaned up on unmount.
     *            Actually, can not think for a use case.
     */
    clone(autoLoad, noDestroy) {
        var obj = new clsPurchaseSettings();

        if (!noDestroy) {
            obj.registerDestroyOnUnmount();            
        }
        if (autoLoad) {
            obj.load();
        }
    
        return obj;
    }

    fill(data) {
        this.use_ledgers  = data.use_ledgers;
        this.use_bookdate = data.use_bookdate;
    }

    toJSON() {
        return {
            use_ledgers  : this.use_ledgers,
            use_bookdate : this.use_bookdate,
        };
    }

}

export default clsPurchaseSettings;
