<template>
    <Dialog :dialog="dlg">

        <div class="mb-4">
            Voor de veiligheid moet u uw huidige wachtwoord invoeren.
        </div>
        <v-divider class="mb-4" ></v-divider>

        <v-form ref="form" @submit.prevent="()=>false" lazy-validation>

            <Form>
                <FormRow label="Uw naam">

                    <TextField placeholder="Uw naam" 
                        :rules="model.rules.mandatory" 
                        v-model="model.name">
                    </TextField>

                </FormRow>
                <FormRow label="Uw wachtwoord">

                    <PasswordField inner-icon placeholder="Huidige wachtwoord" scdense="!true"
                        :rules="model.rules.mandatory" 
                        v-model="model.password">
                    </PasswordField>

                </FormRow>
            </Form>

        </v-form>


    </Dialog>

</template>

<script setup>

import PasswordField from '@controls/input/PasswordField'
import TextField from '@controls/input/TextField'
import Dialog from '@shared/ui/dialogs/ActionDialog'
import clsDialog from '@cls/clsDialog'
import Form from '@controls/forms/Form'
import FormColumn from '@controls/forms/FormColumn'
import FormRow from '@controls/forms/FormRow'
import {ref} from 'vue'

import useModel from '@shared/models/clsChangePersonName'; 
const form = ref(null); 

let model = useModel();
let dlg = ref(
    new clsDialog("changepersonname", "Verander uw naam", model, [form], {
        noSubTitle: true,
        save:'Wijzig',
        icon: 'security',
    } )).value;

</script>

