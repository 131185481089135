import clsEventbus from '@/app/clsEventbus'
import fnExpose from '@shared/lib/expose'

// Note that the class implements a couple of method which manipulate the core eventbus object.
// We export the core object to the application.
let obj = new clsEventbus();

fnExpose(obj, 'eventbus')

export default obj.eventbus;

