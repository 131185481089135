import clsApi from '@cls/clsApi'

class clsTender extends clsApi {

    listChapters(id_tender) {
        return this.get(`listchapters/${id_tender}`); 
    }
    async importChapters(id_tender_destination, id_tender, chapters, actualize) {
        return this.post(`loadchapters`, {id_tender_destination: id_tender_destination, id_tender: id_tender, chapters: chapters||[], actualize: actualize}); 
    }
    loadStats(id_group) {
        if (!id_group) {
            return this.get(`stats`); 
        }
        return this.get(`stats/${id_group}`); 
    }
    loadSendData(id) { 
        return this.post(`loadsenddata`, {id: id}); 
    }
    loadNew(model) { 
        return this.post(`loadnew`, model); 
    }
    emailSend(model) { 
        return this.post(`sendviaemail`, model); 
    }
    async downloadSend(id) {         
        return await this.downloadPostRaw(`sendviadownload`, {id: id});  
    }
    async accept(id) { 
        return this.post(`accept`, {id: id}); 
    }
    async reject(id) { 
        return this.post(`reject`, {id: id}); 
    }
    async createInvoice(config) { 
        return this.post(`createinvoice`, config); 
    }
    loadConfirmationSendData(id) { 
        return this.post(`loadconfirmationsenddata`, {id: id}); 
    }
    emailConfirmationSend(model) { 
        return this.post(`sendconfirmationviaemail`, model); 
    }
    async downloadConfirmationSend(id) {         
        return await this.downloadPostRaw(`sendconfirmationviadownload`, {id: id});  
    }

    getPdf(id) {
        return this.getRaw(`downloadpdf/{id}`, {id: id}, {hideErrors: true});  
    }
    getConfirmationPdf(id) {
        return this.getRaw(`downloadconfirmationpdf/{id}`, {id: id}, {hideErrors: true});  
    }
        
    async backToConcept(id) {
        return this.post(`backtoconcept`, {id: id}); 
    }

    async changeTenderGroup(ids, id_group) {
        return this.post('changetendergroup', {ids: ids, id_group, id_group})
    }
    /**
     * Copy the tender and return the tender id. 
     */
    async copy(model) { 
        return this.post(`copy`, model); 
    }

    /**
     * Save part of the tender.
     * @param {} model 
     * @returns 
     */
    async savePartial(model) {
        return this.post(`savepartial`, model);     
    }
}

export default clsTender;
