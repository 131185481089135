import clsApi from '@cls/clsApi'

class clsSubscription extends clsApi {

    async load() {
        return this.get(`load`); 
    }

    async activateModule(id_module) {
        return this.post("module/activate", {id_module: id_module});
    }
    async deActivateModule(id_module) {
        return this.post("module/deactivate", {id_module: id_module});
    }

    async getFirstPaymentCheckoutUrl() {
        return this.get("paymentmethod/select");
    }
    async cancelSubscription() {
        return this.post("cancel");
    }
    async reactivateSubscription() {
        return this.post("reactivate");
    }
    async saveBillingDetails(model) {
        return this.post("savebillingdetails", model);
    }

}

export default clsSubscription;
