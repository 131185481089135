<template>
    <Dialog :dialog="dlg">
        <v-form ref="form" lazy-validation>
            <Form>
                <FormRow label="Voorvoegsel">            
                    <TextField :model="model" max-length="255" placeholder="Dhr, Mevr, ..."  v-model="model.prs_prefix"></TextField>
                </FormRow>
                <FormRow label="Voornaam">
                    <TextField :model="model" max-length="255" placeholder="Voornaam" :rules="model.rules.mandatory" v-model="model.prs_firstname"></TextField>
                </FormRow>
                <FormRow label="Tussenvoegsel">
                    <TextField :model="model" max-length="255" placeholder="van, van der, ..." :rules="model.rules.prs_infix" v-model="model.prs_infix"></TextField>
                </FormRow>
                <FormRow label="Achternaam">
                    <TextField :model="model" max-length="255" placeholder="Achternaam" :rules="model.rules.mandatory" v-model="model.prs_lastname"></TextField>
                </FormRow>
                <FormRow label="Functie">
                    <TextField :model="model" max-length="255" placeholder="Directeur, uitvoerder, ..." :rules="model.rules.prs_title" v-model="model.prs_title"></TextField>
                </FormRow>
                <FormRow label="E-mail">
                    <TextField :model="model" max-length="128" placeholder="E-mail" :rules="model.rules.prs_email" v-model="model.prs_email"></TextField>
                </FormRow>
                <FormRow label="Telefoonnummer">
                    <TextField :model="model" max-length="255" placeholder="Telefoonnummer" :rules="model.rules.prs_phone" v-model="model.prs_phone"></TextField>
                </FormRow>
                <FormRow label="Mobiel nummer">
                    <TextField :model="model" max-length="255" placeholder="Mobiel nummer" :rules="model.rules.prs_mobile" v-model="model.prs_mobile"></TextField>
                </FormRow>
            </Form>
        </v-form>

    </Dialog>
</template>



<script setup>
    import useModel from '@/models/relationcontact'
    import Form from '@controls/forms/Form'
    import FormColumn from '@controls/forms/FormColumn'
    import FormRow from '@controls/forms/FormRow'
    import TextField from '@controls/input/TextField'
    import Dialog from '@shared/ui/dialogs/ActionDialog'

    import { ref } from 'vue'
    import clsDialog from '@cls/clsDialog'

    const form = ref(null); 

    let model = useModel();
    let dlg = ref(
        new clsDialog("relation_contact", "Contactpersoon", model, [form], {
            icon: 'person'
        } )).value;

    window.dtc = dlg;
</script>
