//
// Usage: 
//  import constants from '@app/consts'
//

import alerts from '@lib/consts/alerts';
import system from '@lib/consts/system';

class clsConst {
    alerts = alerts
    system = system
}

export default clsConst;