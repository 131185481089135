<template>
    <Dialog :dialog="dlg">
        <v-form ref="form" lazy-validation>
            
                <Form>
                    <FormRow label="Actualiseer prijzen" col-label="8" col-value-right subLabel="Neem meest actuele prijzen over van artikelen">                    
                        <Checkbox :model="model" class="ml-4" switcher v-model="model.actualize"></Checkbox>
                    </FormRow>
                    <FormRow label="Laat origineel vervallen" col-label="8" col-value-right subLabel="De originele offerte wordt vervangen door de kopie">                    
                        <Checkbox :model="model" class="ml-4" switcher v-model="model.replace_original"></Checkbox>
                    </FormRow>
                </Form>
        </v-form>

    </Dialog>
</template>



<script setup>
    import useModel from '@/models/tender/copytender'
    import Form from '@controls/forms/Form'
    import FormRow from '@controls/forms/FormRow'
    import Dialog from '@shared/ui/dialogs/ActionDialog'
    import Checkbox from '@controls/check/Checkbox.vue'
    
    import { ref } from 'vue'
    import clsDialog from '@cls/clsDialog'

    const form = ref(null); 
    let model = useModel();

    let dlg = ref(
        new clsDialog("tender_copy", "Kopieer offerte", model, [form], {
            icon: 'copy',
            save: 'Kopieer'
        } )).value;

        window.como = model;
</script>
