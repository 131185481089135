import clsApi from '@cls/clsApi'

class clsProjectPhase extends clsApi {

    up(id) {
        return this.post(`up/${id}`); 
    }

    down(id) {
        return this.post(`down/${id}`); 
    }
}

export default clsProjectPhase;
