var alerts = {
    general: {
        no_rights_action: {
            title: "Geen rechten",
            body:  "U heeft geen rechten om deze actie uit te voeren.",
        },
        no_rights_access: {
            title: "Geen rechten",
            body:  "U heeft geen rechten om deze gegevens in te zien.",
        },
        no_rights_change: {
            title: "Geen rechten",
            body:  "U heeft geen rechten om deze gegevens aan te passen.",
        },
        no_rights_remove: {
            title: "Geen rechten",
            body:  "U heeft geen rechten om deze gegevens te verwijderen.",
        },
        no_rights_create: {
            title: "Geen rechten",
            body:  "U heeft geen rechten om nieuwe gegevens aan te maken.",
        },
        no_lines_selected: {
            title: "Niets geselecteerd",
            body:  "U heeft geen regels geselecteerd.",
        },
        not_yet_implemented: {
            title: "Nog even geduld",
            body:  "Deze functie is nog niet beschikbaar.",
        },
        save_first: {
            title: "Sla de gegevens op",
            body:  "U moet de gegevens eerst opslaan voordat u deze actie kunt uitvoeren.",
        },
    },
    
    attachment: {
        no_rights_remove: {
            title: "Geen rechten",
            body:  "U heeft geen rechten bijlagen te verwijderen.",
        },
        no_rights_download: {
            title: "Geen rechten",
            body:  "U heeft geen rechten bijlagen te downloaden.",
        },
        no_rights_add: {
            title: "Geen rechten",
            body:  "U heeft geen rechten bijlagen toe te voegen.",
        },
    },
}

export default alerts;
