import { clsModel, fnCreate } from '@cls/clsModel'
import { projectgroups as api } from '@/app/api'

var modelName = "projectgroup";
const fields = [
    'id', 
    'name',
    'id_employee_manager',
    'id_employee_planner',
    'id_employee_executor',
];

class clsProjectGroup extends clsModel {

    name = null;
    id_employee_manager = null;
    id_employee_planner = null;
    id_employee_executor = null;

    get modelRep() {
        return this.name;
    }    
    
    constructor() {
        super({
          api: api,   
          modelName: modelName, 
          mandatoryFields: ['name'],

          fillable: fields
        })
    } 
        
 }
 export default fnCreate(clsProjectGroup , 'clsProjectGroup');
