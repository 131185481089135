<template>
    <Dialog :dialog="dlg" :dt="dt">

        <template v-slot:filter>

            <TextField block max-length="255" placeholder="Zoek..." v-model="dt.filter.q"></TextField>

        </template>
    </Dialog>
</template>



<script setup>

    import useDataTable from '@app/useDataTable'
    import string from '@lib/string'

    import Form from '@controls/forms/Form'
    import FormColumn from '@controls/forms/FormColumn'
    import FormRow from '@controls/forms/FormRow'
    import TextField from '@controls/input/TextField'
    import Dialog from '@shared/ui/dialogs/SelectListDialog'
    import { relations as api } from '@app/api'

    import { ref } from 'vue'
    import clsDialog from '@cls/clsSelectListDialog'
    import Constants from '@app/consts'

    const form = ref(null); 
    const id_optimit_type = Constants.optimit_types.relation;

    var headers = [
        { type: 'cmd'},
        { type: "note" },
        { text: "Naam"                              ,value: "rel_name",    type:"open", action:"relation.open" },
        { text: "Nummer"                            ,value: "rel_nr"                                           },
        { text: "Plaats"                            ,value: "adr_city"                                         },
        { text: "Straat"                            ,value: "adr_street"        , fmt: (a,b,item) => string.concat(" ", a, item.adr_street_number) },
        { text: "Nummer"                            ,value: "adr_street_number" },
        { text: "Postcode"                          ,value: "adr_zipcode"                                      },
        { text: "Land"                              ,value: "country"                                          },
        { visible: false, text: "Landcode"         ,value: "country_code"                                          },
        { text: "E-mail"                            ,value: "rel_email"                               ,type: 'email'         },
        { text: "Factuur e-mail"                    ,value: "rel_email_invoice"                       ,type: 'email'         },
        { text: "Aanmanings e-mail"                 ,value: "rel_email_reminder"                      ,type: 'email'         },
        { text: "Telefoon"                          ,value: "rel_phone"                               ,type: 'phone'},
        { text: "Mobiel"                            ,value: "rel_mobile"                              ,type: 'phone'},
        { text: "Website"                           ,value: "rel_website"                             ,type: 'url'},
        { text: "KVK ummer"                         ,value: "rel_coc_number"                                   },
        { text: "BTW nummer"                        ,value: "rel_vat_number"                                   },
//        { text: "IBAN"                              ,value: "rel_iban1"                                       },
//        { text: "G-rekening"                        ,value: "rel_ibang"                                       },
        { visible: false, text: "Betaaltermijn inkoopfacturen"      ,value: "rel_payment_terms_days", fmt: ( v ) => v ? `${v} dagen` : ""},
        { visible: false, text: "Betaaltermijn verkoopfacturen"     ,value: "rel_credit_payment_terms_days", fmt: ( v ) => v ? `${v} dagen` : ""},
        { visible: false, text: "Betalingskorting"                  ,value: "credit_restriction"                            },
        { visible: false, text: "Boekhoudnummer"                    ,value: "booknumber"                                       },
        { visible: false, text: "Aangemaakt"                        ,value: "created_at"                    , type: 'datetime' },
        { visible: false, text: "Laatst gewijzigd"                  ,value: "updated_at"                    , type: 'datetime' },
    ]

    var dt = useDataTable("dt_vendorselect", "relation", api, headers, {
        actionDblClick:null,
        itemName: {
            prefix: 'de',
            single: 'leverancier',
            plural: 'leveranciers',
        }, 
        useCommandPane: false,
        canArchive: false,
        filter: {
            type: 'supplier'
        }
    })

    let dlg = ref(
        new clsDialog("selectvendor", "Selecteer leverancier", dt, {
            multiselect: false
        })).value;



</script>
