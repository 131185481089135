import clsApi from '@cls/clsApi'

class clsPurchaseInvoiceApi extends clsApi {

    loadStats() {
        return this.get(`stats`); 
    }
    accept(id) { 
        return this.post(`accept`, {id: id}); 
    }
    reject(id, reason) { 
        return this.post(`reject`, {id: id, reason: reason}); 
    }
    upload(model) { 
        return this.post(`upload`, model); 
    }
    setPaid(ids) { 
        return this.post(`setpaid`, {ids: ids}); 
    }
    setUnPaid(ids) { 
        return this.post(`setunpaid`, {ids: ids}); 
    }
    setStatusNew(id) {
        return this.post(`setstatusnew`, {id: id}); 
    }    

}

export default clsPurchaseInvoiceApi;
