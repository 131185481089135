<template>
    <div>
        <Datatable :dt="dt" class="full-height">
            <template v-slot:menu ="{ item }">
                <ActionMenuItem action="relation_contact.open" @click="dlg.open('relation_contact', item)"></ActionMenuItem>
                <v-divider></v-divider>
                <ActionMenuItem action="relation_contact.remove" v-if="!item.archived_at" @click="dt.onStartRemove(item)"></ActionMenuItem>
                <ActionMenuItem action="relation_contact.modify" v-else icon="unarchive" @click="dt.onStartUnArchive(item)"></ActionMenuItem>
            </template>

        </Datatable>

    </div>
</template>



<script setup>
    import useDataTable from '@app/useDataTable'
    import Datatable from '@datatable/Datatable'
    import ActionMenuItem from "@controls/menu/ActionMenuItem.vue";
    import { relation_contacts as api } from '@app/api'
    import string from '@lib/string'
    import action from '@app/action'
    import dlg from '@app/dlg'
    
    const props = defineProps({
        model: {
            type: [Object]
        },
    });

    var fmtName = function(a,b,item) {
        return string.concat(" ", item.prs_firstname, item.prs_infix, item.prs_lastname);  
    }

    var headers = [
        { type: 'cmd' },
        { text: "Naam", value: "prs_firstname" , fmt: fmtName, type: 'open'},
        { text: "Functie",     value: "prs_title"     },
        { text: "E-mail",     value: "prs_email"    ,type: 'email' },
        { text: "Telefoon",     value: "prs_phone"  ,type: 'phone'   },
        { text: "Mobiel",    value: "prs_mobile"    ,type: 'phone' },
    ]

    var dt = useDataTable("dt_relation_contacts", "relation_contact", api, headers, {
        itemName: {
            prefix: 'De',
            single: 'contactpersoon',
            plural: 'contactpersonen',
        }, parent: {
            id_relation: props.model.id
        },
        canArchive: false
    })

</script>