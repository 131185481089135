<template>

        <div class="browser">
            <span class="text-small d-sm-none d-lg-inline-block">
                <span style="display:none;">{{brInfo}}</span>
                <span style="min-width:40px;display:inline-block;text-align:right;"> {{ix}}</span> 
                    van 
                <span xstyle="min-width:30px;display:inline-block;">{{total}}</span>
            </span>
            <ActionButton :disabled="disabled" ml dusk="btn-dialog-menu-toolbar-prev" icon-button-outlined icon="chevronleft"  @click="previous"></ActionButton>
            <ActionButton :disabled="disabled" ml dusk="btn-dialog-menu-toolbar-next" icon-button-outlined icon="chevronright" @click="next"></ActionButton>
        </div>

</template>

<script setup>

import Icon from '@controls/icons/Icon'
import ActionButton from '@controls/buttons/ActionButton'
import {computed, ref} from 'vue'
import eventbus from '@app/eventbus'

/**
 */
const props = defineProps({
    dialog: {
        type: [Object]
    },
})

const id = computed({
    get() {
        return props.dialog?.model?.id;
    }
})

const tableName = computed({
    get() {
        return props.dialog?.tableName;
    }
})

const ix = ref(0);
const total = ref(0);
const skipBrowseInfo = ref(false);
// We need to get the ix and total items from the datatable. 
// Retrieval of this information is triggered by change of the model id. 
// In the browsing sequence, the id of the model is cleared when loading. 
// If we would pickup the empty id, the browse info (2 van 12) would change to 
// (0 van 0) any time a browse next or previous is in progress.
// For this reason, we use the model id as a dependency (by returning it here) 
// and in the process, only when the model id is set, retrieve the browsing info from the table.
// Note that in order to let this work, the brInfo value must be in the reactive display component.
// That is why the brInfo is hidden in the template above.
const brInfo = computed({
     get() {
        if (!id.value) {
            return id.value;
        }
        if (skipBrowseInfo.value) {
            skipBrowseInfo.value = false;
            return;
        }
        var data = {id: id.value, tableName: tableName.value, ix: 0, totalItems: 0}
        eventbus.datatable.browse.info(data);
        ix.value = data.totalItems ? data.ix + 1 : 0
        total.value = data.totalItems;
        return id.value;
     }    
})

function next() {
    var data = {tableName: tableName.value, id: id.value, isBrowsing: false}
    eventbus.datatable.browse.next(data);
    if (data.isBrowsing) {
        skipBrowseInfo.value = true; // Skip one update until the data is loaded.
    }
}
function previous() {
    var data = {tableName: tableName.value, id: id.value, isBrowsing: false}
    eventbus.datatable.browse.previous(data);
    if (data.isBrowsing) {
        skipBrowseInfo.value = true; // Skip one update until the data is loaded.
    }
}

const disabled = computed({
    get() {
        var model = props.dialog?.model;
        if (!model) {
            return false;
        }
        if (model.isDataLoading) {
            return true;
        }
        if (model.isLoading) {
            return true;
        }
        return false;
    }    
})


</script>
