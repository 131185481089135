<template>
    <Dialog :dialog="dlg" xno-sub-title no-content-padding>

        <Datatable :dt="dt" class="full-height">

            <template v-slot:item.id_link ="{ item }">
                <span v-if="item.id_type == Constants.purchaseinvoice.logtypes.ID_LOGTYPE_IN_PAYMENTORDER" class="text-primary" @click="()=>dlgLib.open('paymentorder', {id: item.id_link})">            
                    Betaalopdracht
                </span>
                <span v-else-if="item.id_type == Constants.purchaseinvoice.logtypes.ID_LOGTYPE_DISPUTE" class="cursor-pointer text-primary">            
                    {{dispute.oneProp(item.id_link, 'name')}}
                </span>
                <span v-else>-</span>
            </template>

        </Datatable>

    </Dialog>
</template>



<script setup>
    import useDataTable from '@app/useDataTable'
    import Datatable from '@datatable/Datatable'
    import { purchaseinvoice_logs as api } from '@app/api'
    import { ref, onMounted, onUnmounted } from 'vue'
    import clsDialog from '@cls/clsDialog'
    import Dialog from '@shared/ui/dialogs/ActionDialog'
    import Constants from '@app/consts'
    import dlgLib from '@app/dlg'
    import {dispute} from '@app/list'

    var headers = [
        { text: "Type",                     value: "type"},
        { text: "Gebruiker",                value: "username", },
		{ isUtc: true, text: "Tijdstip",                 value: "created_at",    type: 'datetime',   visible: true},
		{ text: "Referentie",               value: "id_link",    rfmt: (v,V,item) => v||"-"},
    ]

    var dt = useDataTable("dt_purchase_invoice_log", "purchaseinvoice_history", api, headers, {
        itemName: {
            prefix: 'de',
            single: 'logregel',
            plural: 'logregels',
        }, parent: {
            id_invoice: null // model.id
        },
        noAutoSearch: true, // Search not until the parent id is set.
        canArchive: false
    })


    class clsPModel {
        id = null;
        invoicenr  = null;

        get subTitle() {
            if (this.isLoading || this.isDataLoading) {
                return 'Laden...';
            }
            return `Inkoopfactuur ${this.invoicenr||''}`;
        }
        async load(id, params) {
            params = params ||{}
            this.invoicenr  = params.invoicenr;            
            this.id = id;
            dt.setParent({id_purchase_invoice: id}, true); // true: search
        }
    }

    let model = ref (new clsPModel());

    let dlg = ref(
        new clsDialog("purchaseinvoice_history", "Factuurgeschiedenis", model, [], {            
            setKeyOnOpen: true,
            icon: 'log',
            maxWidth:'1200px', 
            showNote: false,
            showAttachment: false,
            save:false,
            cancel:'Sluit'
        } )).value;  //TODO

    window.pdtl = dt;
</script>