<template>
    <div>
        <v-card class="mb-2">
            <v-card-text>
                <v-row dense>
                    <v-col sm="6" xl="4" class="pr-8">
                                <FormRow v-if="feature.canProject" col-label="4" label="Project">    
                                    <ProjectPicker no-chapter-select :disabled="model.isOpenOrLater" :filter="{'selectfor':'tender'}" :fieldMap="projectFieldMap" :model='model' :valueModel="model" :rules='model.rules.id_project' v-model='model.id_project'></ProjectPicker>
                                </FormRow>
                                <FormRow col-label="4" label="Titel">    
                                    <TextField :disabled="model.isOpenOrLater" max-length="255" placeholder="Offertetitel" :model="model" :rules="model.rules.tend_name" v-model="model.tend_name"></TextField>
                                </FormRow>
                                <FormRow col-label="4" label="Referentie">    
                                    <TextField :disabled="model.isOpenOrLater" max-length="255" placeholder="Optioneel" :model="model"  v-model="model.tend_reference"></TextField>
                                </FormRow>
                                <FormRow label='Offerte / Vervaldatum'>
                                    <v-row dense>
                                        <v-col><DatePicker :disabled="model.isOpenOrLater" :model="model" v-model='model.tend_date'></DatePicker> </v-col>
                                        <v-col><DatePicker :offsetSource="model.tend_date" :disabled="model.isOpenOrLater" :model="model" v-model='model.tend_exp_date'></DatePicker> </v-col>
                                    </v-row>
                                </FormRow>
                                <FormRow col-label="4" label="Verkoper">    
                                    <EmployeeCombo :disabled="model.isOpenOrLater" placeholder="Verkoper" :model='model' :rules='model.rules.id_employee_contact' v-model='model.id_employee_contact'></EmployeeCombo>

                                </FormRow>
                                <FormRow label="Gebruik teken-portaal" >            
                                    <Checkbox :disabled="model.isOpenOrLater" label="Klant kan offerte online accepteren of afwijzen" switcher dusk="use_sign_portal" :model="model" v-model="model.use_sign_portal"></Checkbox>
                                </FormRow>


                    </v-col>
                    <v-col sm="6" xl="4">
                            <FormRow col-label="4" label="Relatie">    
                                <RelationCombo :disabled="model.isOpenOrLater" sales :model="model" :rules="model.rules.id_relation" v-model="model.id_relation"></RelationCombo>
                            </FormRow>
                            <FormRow col-label="4" label="Adres">    
                                <TextField disabled :model="model" v-model="model.rel_address">
                                    <template v-slot:append-outer>        
                                    <ActionMenu>
                                        <ActionButton comboContextButton icon='menu-vertical'></ActionButton>
                                        <template v-slot:dropdown>
                                            <slot name="contextMenu">
                                                <ActionMenuItem :disabled="!model.id_relation" action='relation.open' @click="()=>dlg.open('relation', {id: model.id_relation})"></ActionMenuItem>
                                            </slot>
                                        </template>
                                    </ActionMenu>
                                    </template>
                                </TextField>
                            </FormRow>
                            <FormRow col-label="4" label="Contactpersoon">    
                                <RelationContactCombo :disabled="model.isOpenOrLater||!model.id_relation" placeholder="Optioneel"  :model="model" :rules="model.rules.id_address" :id_parent="model.id_relation" v-model="model.id_person"></RelationContactCombo>
                            </FormRow>

                            <FormRow col-label="4" label="BTW Verlegd">            
                                <Checkbox switcher :disabled="model.isOpenOrLater" :model="model" :rules='model.rules.tend_vat_shifted' v-model="model.tend_vat_shifted"></Checkbox>
                                <span v-if="model.tend_vat_shifted" style="position:relative; top:-5px;" :class="{'text-error':model.tend_vat_shifted}" class="text-italic">BTW Wordt verlegd naar klant</span>
                            </FormRow>
                            <FormRow v-if="feature.canConsumerPrice" col-label="4" label="Regelprijs">            
                                <Checkbox :disabled="model.isOpenOrLater||model.hasLines" @click="onClickUseConsumerPrice" switcher :model="model" v-model="model.useAmountsInclVat">
                                </Checkbox>
                                <span style="position:relative; top:-5px;" :class="{'text-error':model.useAmountsInclVat}" class="text-italic">Voer regelprijs inclusief BTW in</span>
                            </FormRow>

                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <v-row dense v-if="model.portalSignLink&&model.use_sign_portal"> 
            <v-card class="col-6 mb-2">
                <v-card-title>
                    Klant-ondertekening
                    <v-spacer></v-spacer>
                    <StatusChip v-if="!model.hasUserResponse" type="tender" status="">Geen</StatusChip>
                    <StatusChip v-else-if="model.isUserResponseAccepted" type="tender" status="accepted">Geaccepteerd</StatusChip>
                    <StatusChip v-else type="tender" status="rejected">Afgwezen</StatusChip>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <div v-if="!model.hasUserResponse">
                        <p>De klant heeft de offerte nog niet online ondertekend of afgewezen. </p>
                        <p v-if="model.portalSignLink">
                            <a :href="model.portalSignLink" target="_blank">Open offerte in tekenportaal</a>
                        </p>
                    </div>
                    <div v-else>
                        <FormRow col-label="4" label="Getekend">    
                            <v-row dense>
                                <v-col>
                                    <TextField disabled v-model="model.userresponse.signed_by"></TextField>                                    
                                </v-col>
                                <v-col>
                                    <TextField disabled v-model="model.fmtSigned_at"></TextField>
                                </v-col>
                            </v-row>
                        </FormRow>
                        <FormRow col-label="4" label="IP Adres">    
                            <TextField disabled v-model="model.userresponse.signed_ip"></TextField>
                        </FormRow>
                        <FormRow col-label="4" label="Opmerking">    
                            <TextArea rows="3" disabled v-model='model.userresponse.remarks'></TextArea>
                        </FormRow>
                        <FormRow col-label="4" label="Handtekening">    
                            <div class="bordered bg-disabled">
                                <img style="max-width:100%;" :src='model.userresponse.signature'/>
                            </div>
                        </FormRow>
                    </div>
                </v-card-text>
            </v-card>
        </v-row>
    </div>
</template>



<script setup>
    
    import FormRow from '@controls/forms/FormRow'
    import TextField from '@controls/input/TextField'
    import RelationCombo from '@controls/combo/Relation'
    import DatePicker from '@controls/input/DatePicker'
    import ProjectPicker from '@controls/picker/Project'
    import ToggleButtonGroup from '@controls/buttons/ToggleButtonGroup'
    import EmployeeCombo from '@controls/combo/Employee'
    import RelationContactCombo from '@controls/combo/RelationContact'
    import ActionMenu from '@controls/menu/ActionMenu.vue'
    import ActionMenuItem from '@controls/menu/ActionMenuItem.vue'
    import ActionButton from '@controls/buttons/ActionButton'
    import dlg from '@app/dlg'
    import StatusChip from "@controls/widgets/StatusChip.vue";
    import TextArea from '@controls/input/TextArea'
    import Checkbox from "@controls/check/Checkbox";
    import feature from "@app/features";

    const props = defineProps({
        model: {
            type: [Object]
        },
    });

    // Our fields which come from projects. We want them overridden when a project is selected.
    let projectFieldMap = {pro_reference: 'tend_reference', pro_name: 'tend_name', pro_number: "pro_number", id_relation: "id_relation"};

    function onClickUseConsumerPrice(a,b,c) {
        if (!(props.model.isOpenOrLater||props.model.disabled) && props.model.hasLines) {
            noty.alert(`Er staan al regels in de offerte.<br>Alvorens de regelinvoer type te wijzigen dient u eerst alle regels te verwijderen.`);
        }      
    }

</script>
