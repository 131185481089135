<template>
    <Dialog :dialog="dlg">

        <AlertArea error>
            Hiermee laat u deze offerte vervallen.<br>
            Wilt u een nieuwe versie van deze offerte maken, gebruik dan de functie 'Maak nieuwe versie'.
        </AlertArea>

    </Dialog>
</template>



<script setup>
    import useModel from '@/models/tender/accepttender'
    import Dialog from '@shared/ui/dialogs/ActionDialog'
    import AlertArea from '@shared/ui/markup/AlertArea'

    import { ref } from 'vue'
    import clsDialog from '@cls/clsDialog'

    const form = ref(null); 
    let model = useModel();

    let dlg = ref(
        new clsDialog("tender_reject", "Laat offerte vervallen", model, [form], {
            icon: 'remove',
            save: 'Ga door'
        } )).value;

    
</script>
