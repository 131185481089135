import clsApi from '@cls/clsApi'

class clsFlagApi extends clsApi {

    async save(value, id_optimit_type, id_entity) {
        return await this.post(`save`, {value: value, id_optimit_type: id_optimit_type, id_entity: id_entity}); 
    }

}

export default clsFlagApi;
