<template>        
    <span :class="{'cursor-pointer': id&&rep, 'text-primary': id&&rep}" @click="onClick">          
        <slot>
            {{ (id&&rep)?rep:empty }}
        </slot>  
    </span>
</template>

<script setup>

//
// Drill down into an entity. 
// For example, show a text which is clickable and on click, open an invoice: 
// 
// import DrillDown from '@controls/buttons/DrillDown'
//
//   <DrillDown open="salesinvoice" :id="model.id_invoice" :rep="model.inv_number" empty="-"></DrillDown> 
//

import dlg from '@app/dlg'

const props = defineProps({
    open: {
        type: [String]
    },

    rep: {
        type: [String, Number, Date, Boolean],
        default: ""
    },

    id: {
        type: [String, Number]
    },

    empty: {
        type: [String, Number],
        default: "-"
    },

})

/**
 * Hanldle 
 */
function onClick() {
    if (props.id && props.open) {
        dlg.open(props.open, {id: props.id});
    }
}

</script>
