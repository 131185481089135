<template>
    <Dialog :dialog="dlg" no-content-padding>
            <template v-slot:tabs-left>
                <v-tabs dusk="tabs-recipe" dense v-model="dlg.tab">        
                    <v-tab dusk="tab-recipe-header">Recept</v-tab>
                    <v-tab dusk="tab-recipe-products" :disabled="model.isLoading">Artikelen</v-tab>
                </v-tabs>
            </template>

            <v-form xref="form" lazy-validation>
                <v-tabs-items class="product-tabs xmt-2" v-model="dlg.tab">                
                    <v-tab-item class="restore-content-padding" transition="fade-transition" reverse-transition="fade-transition">                        
                        <v-form ref="formHeader">
                            <v-row>
                                <v-col sm="12" lg="8 " xl="6">
                                <FormRow label="Code">            
                                    <TextField disabled dusk="pd_code" v-model="model.pd_code"></TextField>
                                </FormRow>
                                <FormRow label="Naam">            
                                    <TextField dusk="pd_name" :model="model" max-length="255" placeholder="Naam" :rules="model.rules.mandatory" v-model="model.pd_name"></TextField>
                                </FormRow>
                                <FormRow label="Omschrijving" label-v-top>            
                                    <TextArea dusk="pd_description" rows=2 :model="model" max-length="2048" placeholder="" :rules="model.rules.pd_description" v-model="model.pd_description"></TextArea>                                    
                                </FormRow>
                                <v-divider class="my-4"></v-divider>

                                <FormRow label="Artikelgroep">            
                                    <ProductGroupCombo dusk="id_productgroup" :model="model" :rules="model.rules.id_productgroup" v-model="model.id_productgroup"></ProductGroupCombo>
                                </FormRow>

                                <v-divider class="my-4"></v-divider>
                                <FormRow label="Eenheid">            
                                    <UnitCombo dusk="id_unity" :model="model" :rules="model.rules.mandatory" v-model="model.id_unity"></UnitCombo>
                                </FormRow>

                                <FormRow label="Inkoopprijs Excl.">
                                    <NumberField disabled dusk="pd_purchase_price_calculated" amount v-model="model.calc_purchase_price"></NumberField>
                                </FormRow>
                                <FormRow label="Vaste prijs">
                                    <Checkbox class="d-inline-block" dusk="use_recipe_price" :model='model' v-model='model.use_recipe_price'></Checkbox>
                                    <span class="d-inline-block">Gebruik vaste prijs per eenheid </span>

                                </FormRow>
                                <FormRow label="Verkoopprijs excl. BTW">
                                    <NumberField v-if="model.use_recipe_price"  dusk="price_excl_vat" amount :model="model" :rules="model.rules.price_excl_vat"  v-model="model.price_excl_vat"></NumberField>
                                    <NumberField v-else dusk="price_excl_vat" amount disabled :model="model" :rules="model.rules.calc_price_excl_vat"  v-model="model.calc_price_excl_vat"></NumberField>
                                </FormRow>

                                <v-divider v-if="feature.canConsumerPrice" class="my-4"></v-divider>

                                <FormRow v-if="feature.canConsumerPrice" label="Consumentenprijs incl. BTW">
                                    <NumberField v-if="model.use_recipe_price" dusk="price_incl_vat" amount :model="model" :rules="model.rules.price_incl_vat"  v-model="model.price_incl_vat"></NumberField>
                                    <NumberField v-else dusk="price_incl_vat" amount disabled v-model="model.calc_price_incl_vat"></NumberField>
                                </FormRow>
                            </v-col>
                            </v-row>
                        </v-form>
                    </v-tab-item>
                    <v-tab-item>
                        <v-form ref="formLines">
                        <LocalTable :model="model" modelLinesProp='lines' :dt="tblLocal">
                            <template v-slot:menu="{ item }">
                                <ActionMenuItem action="recipe.modify" no-execute icon="remove" @click="model.removeLine(item)">Verwijder artikel</ActionMenuItem>
                            </template>
                            <template v-slot:item.amount="{ item }">
                                <NumberField dusk="amount" dense decimal :model="model" :rules="model.rules.mandatory"  v-model="item.amount"></NumberField>
                            </template>
                            <template v-slot:item.pd_purchase_price_calc="{ item }">
                                <AmountText :value="(item.pd_purchase_price||0)*(item.amount||0)" :currency='model.currency'/>    
                            </template>
                            <template v-slot:item.pd_sales_price_excl_vat_calc="{ item }">
                                <AmountText :value="(item.pd_sales_price_excl_vat||0)*(item.amount||0)" :currency='model.currency'/>    
                            </template>
                            <template v-slot:item.archived_at="{ item }">
                                <span v-if="!item.archived_at">Actief</span>
                                <span v-else class="text-error">Gearchiveerd</span>
                            </template>

                            <template v-slot:footer.pd_sales_price_excl_vat_calc ="{ dt }">
                                <AmountText class="text-bold" :value="model.calc_price_excl_vat"></AmountText>                                
                            </template>
                            <template v-slot:footer.pd_purchase_price_calc ="{ dt }">
                                <AmountText class="text-bold" :value="model.calc_price_incl_vat"></AmountText>                                
                            </template>

                        </LocalTable>
                        </v-form>

                    </v-tab-item>
                </v-tabs-items>
            </v-form>

            <template v-slot:footer-left>
                <ActionButton main sub v-if="dlg.tab>=1" action="recipe.modify" dusk="btn-add-product" :model="model" ml icon="product" @click='()=>addProduct()'>Voeg artikel toe</ActionButton>
            </template>
    </Dialog>
</template>



<script setup>
    import useModel from '@/models/product/recipe'
    import Form from '@controls/forms/Form'
    import FormRow from '@controls/forms/FormRow'
    import TextField from '@controls/input/TextField'
    import TextArea from '@controls/input/TextArea'
    import Dialog from '@shared/ui/dialogs/ActionDialog'
    import VatCombo from '@controls/combo/Vat'
    import UnitCombo from '@controls/combo/Unit'
    import NumberField from '@controls/input/NumberField'
    import ProductGroupCombo from '@controls/combo/ProductGroup'
    import ActionButton from '@controls/buttons/ActionButton'
    import ActionMenuItem from "@controls/menu/ActionMenuItem.vue";
    import Checkbox from '@controls/check/Checkbox.vue'
    import { computed, ref } from 'vue'
    import clsDialog from '@cls/clsDialog'
    import LocalTable from '@datatable/LocalTable'  
    import clsLocalTable from '@cls/clsLocalTable'
    import appDlg from '@app/dlg'
    import AmountText from '@controls/text/Amount.vue'
    import feature from '@app/features';

    const formHeader = ref(null); 
    const formLines = ref(null); 

    let model = useModel();
    let dlg = ref(
        new clsDialog("recipe", "Recept", model, [formHeader, formLines], {
            maxWidth: "1200px",
            noteType: "product_note",
        } )).value;

    // -- add a supplier to the list of suppliers.
    async function addProduct() {

        try {
            var fnMultiSelectCallback = function(selected) {
                if (!selected || !selected.length) {
                    return;
                }
                model.addProduct(selected[0]);                
            }

            let selected = await appDlg.promised.open("select_product", {multiselect:false, fnMultiSelectCallback: fnMultiSelectCallback})
            if (!selected || !selected.length) {
                return;
            }
            model.addProduct(selected[0]);
        } catch (e) {} // canceled

    }
    
    var headers = [
        { type: 'cmd'},
        { text: "Aantal",                   value: "amount", class:'w-min-3 w-3 text-right pr-3' },
        { text: "Eenheid",                  value: "un_name" },
        { text: "Code",                     value: "pd_code"},
        { text: "Naam",                     value: "pd_name"},
        { visible: false, text: "Stuk-inkoopprijs",  value: "pd_purchase_price", type: 'amount'},
        { text: "Stuk-verkoopprijs excl. BTW",       value: "pd_sales_price_excl_vat" , type: 'amount'},
        { visible: false, text: "Inkoopprijs",       value: "pd_purchase_price_calc"},
        { text: "Verkoopprijs excl. BTW",            value: "pd_sales_price_excl_vat_calc" },
    ]

    let tblLocal = new clsLocalTable("recipelines", headers, {
        noDataText: "Er zijn geen artikelen toegevoegd",
        model: model, 
        modelLinesProp: "lines",
        showFooter: true,

    });

    window.dlgp = dlg;
</script>
