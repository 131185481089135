<template>
    <Dialog :dialog="dlg">

        <v-form ref="form" @submit.prevent="()=>false" lazy-validation>

            <v-row>
                <v-col sm="1"><h1>1)</h1></v-col>
                <v-col xsm="5">Scan de QR code met Google Authenticator, Duo, of een soortgelijke app.

                    <div class="mt-4 text-note">Let op. Wanneer u eerder de QR code heeft gescand dient u deze opnieuw te scannen.</div> 

                </v-col>
                <v-col>
                    <div v-html="model.qrCode" />
                </v-col>
            </v-row>
            <v-row>
                <v-col sm="1"><h1>2)</h1></v-col>
                <v-col sxm="5">Voer de code van uw authenticator in.</v-col>
                <v-col>
                    <TextField placeholder="Authenticator code" :rule="[(v)=>!!v]" required v-model="model.authcode"></TextField>
                </v-col>
            </v-row>
            <v-row>
                <v-col sm="1"><h1>3)</h1></v-col>
                <v-col>Na het opslaan moet bij de volgende keer inloggen de 2-factor code uit de gebruikte app worden ingevoerd.</v-col>
            </v-row>
            
        </v-form>


    </Dialog>

</template>

<script setup>

import eventbus from '@app/eventbus'
import PasswordField from '@controls/input/PasswordField'
import TextField from '@controls/input/TextField'
import Form from '@controls/forms/Form'
import FormColumn from '@controls/forms/FormColumn'
import FormRow from '@controls/forms/FormRow'

import useModel from '@shared/models/clsSetup2FA'
import Dialog from '@shared/ui/dialogs/ActionDialog'
import clsDialog from '@cls/clsDialog'
import {ref} from 'vue'

const form = ref(null);
let model = useModel();
let dlg = ref(
    new clsDialog("setup2fa", "Instellen 2-factor authenticatie", model, [form], {
        noSubTitle: true,
        icon: 'security',
        save: 'Doorgaan'
    } )).value;

window.pwddlg = dlg;


</script>

