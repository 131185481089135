//////////////////////////////////////////////////////////////////////////////////////////
//
// Unauthorized routes
//
//////////////////////////////////////////////////////////////////////////////////////////

// The routerview is an empty 
const routerView = import("@/views/routerview");
//const routerView = {
//    template: '<router-view></router-view>'
//}

const unAuthorizedRoutes = [
    {
        name        : 'accept.invitation', 
        path        : '/uitnodiging/accepteren/:token',  
        component   :  () => import("@/views/auth/page/AcceptInvitation.vue"),        
        meta        : { 
            menu    : {text: '', icon: ''},
                        requiresAuth: false,
            }        
    },
    {
        name        : 'accept.accountant.invitation', 
        path        : '/accountant/uitnodiging/accepteren/:accToken',  
        component   :  () => import("@/views/auth/page/AcceptInvitation.vue"),        
        meta        : { 
            menu    : {text: '', icon: ''},
                        requiresAuth: false,
            }        
    },
    {
        name        : 'no.administrations',
        path        : '/geen/administratie',  
        component   :  () => import("@/views/auth/page/NoAdministration.vue"),        
        meta        : {
            menu    : {text: '', icon: ''},
            requiresAuth: false,
        }        
    },    
    {
        name        : 'create.administration', 
        path        : '/administratie/aanmaken',  
        component   :  () => import("@/views/auth/page/NYI.vue"),        
        meta        : {
            menu    : {text: '', icon: ''},
            requiresAuth: false,
        }        
    },
    { 
        name        : 'auth.login',
        path        : '/login',  
        component   :  () => import("@/views/auth/page/Login.vue"),        
        meta        : {
            menu    : {text: 'Login', icon: 'login'},
            requiresAuth: false, 
        }
    },
    { 
        name        : 'auth.support.login',
        path        : '/475bd827-388c-486c-9ac7-a5f76b61488b/:token/:target',  
        component   :  () => import("@/views/auth/page/SupportLogin.vue"),        
        meta        : {
            menu    : {text: 'Support', icon: 'support'},
            requiresAuth: false, 
        }
    },
    // forgot password is unauthorized.
    { 
            name        : 'auth.forgotpwd',
            path        : '/forgot-password',  
            component   :  () => import("@/views/auth/page/ForgotPassword.vue"),        
            meta        : {
                menu    : {text: 'Wachtwoord vergeten', icon: 'login'},
                requiresAuth: false, 
            }
    },
    // two-factor is unauthorized as it is the last step before being authorized.
    { 
        name        : 'auth.twofactor',
        path        : '/two-factor',  
        component   :  () => import("@/views/auth/page/TwoFactor.vue"),        
        meta        : {
            menu    : {text: '2-factor', icon: 'security'},
            requiresAuth: false, 
        }
    },
    // reset password is unauthorized.
    { 
        name        : 'auth.resetpwd',
        path        : '/reset-password',  
        component   :  () => import("@/views/auth/page/ResetPassword.vue"),        
        meta        : {
            menu    : {text: 'Reset wachtwoord', icon: 'login'},
            requiresAuth: false, 
        }
    },

];
    
//////////////////////////////////////////////////////////////////////////////////////////
//
// Authorized routes
//
//////////////////////////////////////////////////////////////////////////////////////////
const authorizedRoute = {
    path: '/c/:id_administration',
//    component   :  () => import("@/App.vue"),
    component : () => routerView,
    children    : [
        { 
            name        : 'mobile.dashboard',
            path        : 'm/dashboard',  
            component   :  () => import("@/views/mobile/Dashboard.vue"),        
            meta        : {
                menu    : {text: 'Dashboard', icon: 'dashboard'},
                requiresAuth: false, 
            }
        },
    

        //////////////////////////////////////////////////////////////////////////////////////////
        //
        // Misc routes
        //
        //////////////////////////////////////////////////////////////////////////////////////////
        {
            // Release Notes.
            name        : 'release.notes',
            path        : 'releasenotes',  
            component   :  () => import("@/views/releasenotes.vue"),        
            meta        : { menu    : {text: 'Releasenotes', icon: 'log'}}
        },    
        {
            name        : 'subscription.canceled',
            path        : 'abonnement/beeindigd',  
            component   :  () => import("@/views/system/SubscriptionCanceled.vue"),        
            meta        : { menu    : {text: 'Abonnement beeindigd', icon: 'remove'}}
        },    
        {
            name        : 'subscription.suspended',
            path        : 'abonnement/opgeschort',  
            component   :  () => import("@/views/system/SubscriptionSuspended.vue"),        
            meta        : { menu    : {text: 'Abonnement opgeschort', icon: 'warning'}}
        },    
        {
            name        : 'subscription.trialperiodexpired',
            path        : 'abonnement/proefperiode/afgelopen',  
            component   :  () => import("@/views/system/TrialPeriodExpired.vue"),        
            meta        : { menu    : {text: 'Proefperiode afgelopen', icon: 'warning'}}
        },    
        // Select administration has no administration yet. Therefore, path is on root level.
        {
            name        : 'select-administration', 
            path        : '/select-administration',  
            component   :  () => import("@/views/auth/page/SelectAdministration.vue"),
            meta        : { menu    : {text: 'Icons', icon: 'dashboard'}, }
        },
        //////////////////////////////////////////////////////////////////////////////////////////
        //
        // User auth settings
        //
        //////////////////////////////////////////////////////////////////////////////////////////

        //////////////////////////////////////////////////////////////////////////////////////////
        //
        // Dashboard
        //
        //////////////////////////////////////////////////////////////////////////////////////////
        {
            name        : 'dashboard', 
            path        : 'dashboard',  
            component   :  () => import("@/views/dashboard/dashboard.vue"),        
            meta        : { menu    : {text: 'Dashboard', icon: 'dashboard'}, }
        },
        //////////////////////////////////////////////////////////////////////////////////////////
        //
        // Relations
        //
        //////////////////////////////////////////////////////////////////////////////////////////
        {
            path: 'relaties', name: 'relations', redirect:'relaties/overzicht', meta: {action: 'relation.open', menu: { text: "Relaties", icon: 'relations' }},
            component : () => routerView,
            children: [
                {
                    name        : 'relations.overview',
                    path        : 'overzicht',  
                    component   :  () => import("@/views/relation/relations.vue"),
                    meta        : { action: 'relation.open', menu : {text: 'Relaties', icon: 'relations'}},
                },
                {
                    name        : 'relations.archive', 
                    path        : 'archief',  
                    component   :  () => import("@/views/relation/archive.vue"),
                    meta        : { action: 'relation.open', menu: {text: 'Archief', icon: 'archive'}},
                },
            ]
        },
        //////////////////////////////////////////////////////////////////////////////////////////
        //
        // Masterdata
        //
        //////////////////////////////////////////////////////////////////////////////////////////
        {
            path: 'stamgegevens', meta: {menu: { text: "Stamgegevens", icon: 'settings' }},
            component : () => routerView,
            children: [
                { 
                    name        : 'masterdata.units', 
                    path        : 'eenheden',  
                    component   : () => import("@/views/masterdata/units.vue"),
                    meta        : { action: 'unit.open', menu: { text: 'Eenheden', icon: 'units'} },
                },
                { 
                    name        : 'masterdata.departments', 
                    path        : 'afdelingen',  
                    component   : () => import("@/views/masterdata/departments.vue"),
                    meta        : { action: 'department.open', menu: {text: 'Afdelingen', icon: 'department'} },
                },
                { 
                    name        : 'masterdata.jobtitles', 
                    path        : 'functiegroepen',  
                    component   : () => import("@/views/masterdata/jobtitles.vue"),
                    meta        : { action: 'jobtitle.open', menu: {text: 'Functiegroepen', icon: 'jobtitle'} },
                },
                { 
                    name        : 'masterdata.costtypes', 
                    path        : 'kostentypes',  
                    component   : () => import("@/views/masterdata/costtypes.vue"),
                    meta        : { action: 'costtype.open', menu: {text: 'Kostentypes', icon: 'costtype'} },
                },
                { 
                    name        : 'masterdata.hourtypes', 
                    path        : 'urentyperingen',  
                    component   : () => import("@/views/masterdata/hourtypes.vue"),
                    meta        : { action: 'hourtype.open', menu: {text: 'Urentyperingen', icon: 'hours'} },
                },
                { 
                    name        : 'masterdata.disputes', 
                    path        : 'disputen',  
                    component   : () => import("@/views/masterdata/disputes.vue"),
                    meta        : { action: 'dispute.open', menu: {text: 'Disputen', icon: 'dispute'} },
                },
                { 
                    name        : 'masterdata.creditrestrictions', 
                    path        : 'betalingskorting',  
                    component   : () => import("@/views/masterdata/creditrestrictions.vue"),
                    meta        : { action: 'creditrestriction.open', menu: {text: 'Betalingskorting', icon: 'discount'} },
                },
                { 
                    name        : 'masterdata.tariffs', 
                    path        : 'tarieven',  
                    component   : () => import("@/views/masterdata/tariffs.vue"),
                    meta        : { action: 'tariff.open', menu: {text: 'Tarieven', icon: 'tariff'} },
                },
                { 
                    name        : 'masterdata.product.groups', 
                    path        : 'artikelen/groepen',  
                    component   : () => import("@/views/masterdata/groups.vue"),
                    meta        : { action: 'productgroup.open', menu: {shorttext: 'groepen', text: 'Artikelgroepen', icon: 'productgroup'} },
                },
                { 
                    name        : 'masterdata.product.suppliers', 
                    path        : 'artikelen/fabrikanten',  
                    component   : () => import("@/views/masterdata/suppliers.vue"),
                    meta        : { action: 'supplier.open', menu: {shorttext: 'Fabrikanten', text: 'Fabrikanten', icon: 'warehouse'} },
                },
                { 
                    name        : 'masterdata.standardtexts', 
                    path        : 'standaardtekst',  
                    component   : () => import("@/views/masterdata/standardtexts.vue"),
                    meta        : { action: 'standardtext.open', menu: {text: 'Standaard tekst', icon: 'standardtext'} },
                },
                { 
                    name        : 'masterdata.dayparts', 
                    path        : 'dagdelen',  
                    component   : () => import("@/views/masterdata/dayparts.vue"),
                    meta        : { action: 'daypart.open', menu: {text: 'Dagdelen', icon: 'calendar'} },
                },
                { 
                    name        : 'masterdata.absencetypes', 
                    path        : 'verloftypes',  
                    component   : () => import("@/views/masterdata/absencetypes.vue"),
                    meta        : { action: 'absencetype.open', menu: {text: 'Verloftypes', icon: 'sun'} },
                },
                { 
                    name        : 'masterdata.workordertypes', 
                    path        : 'Werkbontypes',  
                    component   : () => import("@/views/masterdata/workordertypes.vue"),
                    meta        : { action: 'workordertype.open', menu: {text: 'Werkbon Types', icon: 'workorder'} },
                },
            ]
        },
        //////////////////////////////////////////////////////////////////////////////////////////
        //
        // Mijn...
        //
        //////////////////////////////////////////////////////////////////////////////////////////
        {
            path: 'mijn', meta:  {menu: { text: "Mijn Optimit", icon: 'person' }},
            component : () => routerView,
            children: [
                {
                    name        : 'my.subscription', 
                    path        : 'abonnement',  
                    component   :  () => import("@/views/my/Subscription.vue"),
                    meta        : { action: 'subscription.open', menu    : {text: 'Abonnement', icon: 'subscription'}},
                },
                {
                    name        : 'my.shop', 
                    path        : 'abonnement/shop',  
                    component   :  () => import("@/views/my/Subscription.vue"),
                    meta        : { action: 'subscription.open', menu    : {text: 'Shop', icon: 'product'}},
                },
                { 
                    name        : 'my.profile',
                    path        : 'profiel',  
                    component   :  () => import("@/views/my/Profile.vue"),        
                    meta        : { menu    : {text: 'Profiel', icon: 'user'} }
                },
                { 
                    name        : 'my.security',
                    path        : 'beveiliging',  
                    component   :  () => import("@/views/my/Security.vue"),        
                    meta        : {
                        menu    : {text: 'Beveiliging', icon: 'security'}
                    }
                },                
        
            ]
        },

        //////////////////////////////////////////////////////////////////////////////////////////
        //
        // Settings
        //
        //////////////////////////////////////////////////////////////////////////////////////////
        {
            path: 'instellingen', meta:  {menu: { text: "Instellingen", icon: 'settings' }},
            component : () => routerView,
            children: [
                {
                    name        : 'settings.company', 
                    path        : 'mijn-bedrijf',  
                    component   :  () => import("@/views/settings/company.vue"),
                    meta        : { action: 'company.open', menu    : {text: 'Bedrijfsgegevens', icon: 'company'}},
                },
                {
                    name        : 'settings.manday', 
                    path        : 'mandagen',  
                    component   :  () => import("@/views/settings/manday.vue"),
                    meta        : { action: 'settings_manday.open', menu    : {text: 'Mandagstaten', icon: 'manday'}},
                },
                {
                    name        : 'settings.invoice', 
                    path        : 'facturen',  
                    component   :  () => import("@/views/settings/invoice.vue"),
                    meta        : { action: 'settings_salesinvoice.open', menu: {text: 'Verkoop', icon: 'invoice'}},
                },
                {
                    name        : 'settings.tendergroups', 
                    path        : 'offertes',  
                    component   :  () => import("@/views/settings/tendergroups.vue"),
                    meta        : { action: 'tendergroup.open', menu: {text: 'Offerte', icon: 'tender'}},
                },
                {
                    path: 'project', name: 'settings.project', redirect: 'project/fases', meta:  {menu: { text: "Project", icon: 'project' }},
                    component : () => routerView,
                    children: [    
                        {
                            name        : 'settings.projectgroups', 
                            path        : 'types',  
                            component   :  () => import("@/views/settings/projectgroups.vue"),
                            meta        : { action: 'projectgroup.open', menu: {text: 'Projecttype', icon: 'project'}},
                        },
                        {
                            name        : 'settings.projectphases', 
                            path        : 'fases',  
                            component   :  () => import("@/views/settings/projectphases.vue"),
                            meta        : { action: 'projectphase.open', menu: {text: 'Projectfase', icon: 'project'}},
                        },
                    ]
                },
                {
                    name        : 'settings.salesorders', 
                    path        : 'verkooporders',  
                    component   :  () => import("@/views/settings/salesorders.vue"),
                    meta        : { action: 'settings_salesorder.open', menu: {text: 'Verkooporder', icon: 'order'}},
                },
                {
                    name        : 'settings.purchase', 
                    path        : 'inkoopfacturen',  
                    component   :  () => import("@/views/settings/purchaseinvoice.vue"),
                    meta        : { action: 'settings_purchaseinvoice.open', menu: {text: 'Inkoop', icon: 'purchase'}},
                },
                {

                    name        : 'settings.email', 
                    path        : 'email',  
                    component   :  () => import("@/views/settings/email.vue"),
                    meta        : { action: 'emailtemplate.open', menu: {text: 'E-mail opmaak', icon: 'email'}},
                },
                {
                    name        : 'settings.document', 
                    path        : 'document',  
                    component   :  () => import("@/views/settings/document.vue"),
                    meta        : { action: 'documentlayout.open', menu:  {text: 'Document opmaak', icon: 'docs'}},
                },
                {
                    name        : 'settings.accountancy', 
                    path        : 'boekhoudkoppeling',  
                    component   :  () => import("@/views/settings/accountancy.vue"),
                    meta        : { action: 'accountancy.open', menu: {text: 'Boekhoudkoppeling', icon: 'accountancy'}},
                },
                {
                    name        : 'settings.linkemail', 
                    path        : 'emailkoppeling',  
                    component   :  () => import("@/views/settings/linkemail.vue"),
                    meta        : { action: 'link_email.open', menu: {text: 'E-mail koppeling', icon: 'email'}},
                },
                {
                    name        : 'settings.employees', 
                    path        : 'medewerkers',  
                    component   :  () => import("@/views/settings/employees.vue"),
                    meta        : { action: 'employee.open', menu:  {text: 'Medewerkers', icon: 'relations'}},
                },
                {
                    name        : 'settings.accountant', 
                    path        : 'accountant',  
                    component   :  () => import("@/views/settings/accountant.vue"),
                    meta        : { action: 'accountant.open', menu:  {text: 'Accountant', icon: 'accountant'}},
                },
                {
                    name        : 'settings.users', 
                    path        : 'gebruikers',  
                    component   :  () => import("@/views/settings/users.vue"),
                    meta        : { action: 'companyuser.open', menu: {text: 'Gebruikers', icon: 'user'}},
                },
                {
                    name        : 'settings.rightsgroups', 
                    path        : 'rechtengroepen',  
                    component   :  () => import("@/views/settings/rightsgroups.vue"),
                    meta        : { action: 'rightsgroup.open', menu:  {text: 'Rechtengroepen', icon: 'security'}},
                },
                {
                    name        : 'settings.mollie', 
                    path        : 'mollie',  
                    component   :  () => import("@/views/settings/Mollie.vue"),
                    meta        : { action: 'settings_mollie.open', menu: {text: 'Mollie koppeling', icon: 'mollie-incasso'}},
                },

            ]
        }, 
        //////////////////////////////////////////////////////////////////////////////////////////
        //
        // Mandays
        //
        //////////////////////////////////////////////////////////////////////////////////////////
        {
            path: 'mandagen', name: 'mandays', redirect: 'mandagen/open', meta: {action: 'manday.open', menu: { text: "Mandagen", icon: 'manday' }},
            component : () => routerView,
            children: [
                {
                    name: 'mandays.open',  
                    path        : 'open',  
                    component   :  () => import("@/views/manday/mandays.vue"),
                    meta        : {  action: 'manday.open', menu : {short: 'Mandagen', text: 'Mandagstaten', icon: 'manday'}},
                },
                {
                    name: 'mandays.archive',  
                    path        : 'archief',  
                    component   :  () => import("@/views/manday/archive.vue"),
                    meta        : {  action: 'manday.open', menu : {text: 'Archief', icon: 'archive'}},
                },
                {
                    name: 'manday.settings',  
                    path        : 'instellingen',  
                    component   :  () => import("@/views/manday/settings.vue"),
                    meta        : {  action: 'manday.open', menu : {text: 'Instellingen', icon: 'settings'}},
                },
            ]
        },
        //////////////////////////////////////////////////////////////////////////////////////////
        //
        // Products
        //
        //////////////////////////////////////////////////////////////////////////////////////////
        {
            path: 'artikelen', name:'products', redirect: 'artikelen/overzicht', meta: {action: 'product.open', menu: { text: "Artikelen", icon: 'product' }},
            component : () => routerView,
            children: [
                {
                    name        : 'products.overview',
                    path        : 'overzicht',  
                    component   :  () => import("@/views/product/products.vue"),
                    meta        : { action: 'product.open',  menu    : {text: 'Artikelen', icon: 'product'}},
                },
                {
                    name        : 'recipes.overview',
                    path        : 'recepten',  
                    component   :  () => import("@/views/product/recipes.vue"),
                    meta        : { action: 'recipe.open',  menu    : {text: 'Recepten', icon: 'recipe'}},
                },
                {
                    name        : 'products.archive',
                    path        : 'archief',  
                    component   :  () => import("@/views/product/archive.vue"),
                    meta        : { action: 'product.open',  menu    : {text: 'Archief', icon: 'archive'}},
                },
                {
                    name        : 'products.settings',
                    path        : 'instellingen',  
                    component   :  () => import("@/views/product/NYI.vue"),
                    meta        : { action: 'product.open',  menu    : {text: 'Instellingen', icon: 'settings'}},
                },
            ]
        },
        //////////////////////////////////////////////////////////////////////////////////////////
        //
        // Sales Invoice
        //
        //////////////////////////////////////////////////////////////////////////////////////////
        {
            path: 'verkoopfacturen', name: 'salesinvoices', redirect: 'verkoopfacturen/concept', meta: {action: 'salesinvoice.open', menu: { text: "Verkoop", icon: 'invoice' }},
            component : () => routerView,
            children: [        
                {
                    name        : 'salesinvoices.concept',  
                    path        : 'concept',  
                    component   :  () => import("@/views/salesinvoice/concept.vue"),
                    meta        : {action: 'salesinvoice.open', menu    : {short: 'Verkoop', text: 'Concept', icon: 'invoice'}},
                },
                {
                    name        : 'salesinvoices.open',  
                    path        : 'open',  
                    component   :  () => import("@/views/salesinvoice/open.vue"),
                    meta        : {action: 'salesinvoice.open', menu    : {text: 'Openstaand', icon: 'invoice'}},
                },
                {
                    name        : 'salesinvoices.expired',  
                    path        : 'vervallen',  
                    component   :  () => import("@/views/salesinvoice/expired.vue"),
                    meta        : {action: 'salesinvoice.open', menu    : {text: 'Vervallen', icon: 'invoice'}},
                },
                {
                    name        : 'salesinvoices.paid',  
                    path        : 'betaald',  
                    component   :  () => import("@/views/salesinvoice/paid.vue"),
                    meta        : {action: 'salesinvoice.open', menu    : {text: 'Betaald', icon: 'invoice'}},
                },
                {
                    name        : 'salesinvoices.search',  
                    path        : 'zoeken',  
                    component   :  () => import("@/views/salesinvoice/search.vue"),
                    meta        : {action: 'salesinvoice.search', menu    : {text: 'Zoeken', icon: 'search'}},
                },
                {
                    name        : 'salesinvoices.reminders',  
                    path        : 'aanmaningen',  
                    component   :  () => import("@/views/salesinvoice/reminders.vue"),
                    meta        : {action: 'salesinvoice.reminders', menu    : {text: 'Aanmaningen', icon: 'defaultnotice'}},
                },
            ]
        },
        //////////////////////////////////////////////////////////////////////////////////////////
        //
        // Werkbonnen
        //
        //////////////////////////////////////////////////////////////////////////////////////////
        {
            path: 'werkbonnen', name: 'workorder', redirect:'werkbonnen/concept', meta: {action: 'workorder.open', menu: { text: "Werkbon", icon: 'workorder' }},
            component : () => routerView,
            children: [        
                {
                    name        : 'workorder.concept',  
                    path        : 'concept',  
                    component   :  () => import("@/views/workorder/concept.vue"),
                    meta        : { action: 'workorder.open', menu    : {short: 'Werkbon', text: 'Niet ingepland', icon: 'workorder'}},
                },
                {
                    name        : 'workorder.planned',  
                    path        : 'ingepland',  
                    component   :  () => import("@/views/workorder/planned.vue"),
                    meta        : { action: 'workorder.open', menu    : {short: 'Werkbon', text: 'Ingepland', icon: 'workorder'}},
                },
                {
                    name        : 'workorder.invoicable',  
                    path        : 'te-factureren',  
                    component   :  () => import("@/views/workorder/invoicable.vue"),
                    meta        : { action: 'workorder.open', menu    : {short: 'Werkbon', text: 'Te factureren', icon: 'workorder'}},
                },
                {
                    name        : 'workorder.done',  
                    path        : 'afgerond',  
                    component   :  () => import("@/views/workorder/done.vue"),
                    meta        : { action: 'workorder.open', menu    : {short: 'Werkbon', text: 'Afgerond', icon: 'workorder'}},
                },
                {
                    name        : 'workorder.planboard',  
                    path        : 'plannen',  
                    component   :  () => import("@/views/workorder/planboard/page.vue"),
                    meta        : { action: 'workorder_planboard.open', menu    : {text: 'Planbord', icon: 'workorder'}},
                },
                {
                    name        : 'workorder.search',  
                    path        : 'zoeken',  
                    component   :  () => import("@/views/workorder/search.vue"),
                    meta        : { action: 'workorder.search', menu    : {text: 'Zoeken', icon: 'search'}},
                },
            ]
        },
        //////////////////////////////////////////////////////////////////////////////////////////
        //
        // Planning
        //
        //////////////////////////////////////////////////////////////////////////////////////////
        {
            path: 'planning', meta: {menu: { text: "Planning", icon: 'planning' }},
            component : () => routerView,
            children: [        
                {
                    name        : 'planning.board',  
                    path        : 'bord',  
                    component   :  () => import("@/views/planning/board.vue"),
                    meta        : { action: 'workorder_planboard.open', menu    : {text: 'Planbord', icon: 'planning'}},
                },
            ]
        },

        //////////////////////////////////////////////////////////////////////////////////////////
        //
        // Projecten
        //
        //////////////////////////////////////////////////////////////////////////////////////////
        {
            path: 'projecten', name:'project', redirect:'projecten/overzicht/actual', meta: {action: 'project.open', menu: { text: "Projecten", icon: 'project' }},
            component : () => routerView,
            children: [        
                {
                    name        : 'project.phase',
                    path        : 'overzicht/:phase?',  
                    component   :  () => import("@/views/project/page.vue"),
                    meta        : { action: 'project.open',  menu: {text: 'projecten', icon: 'project'}},
                },
                {
                    name        : 'project.search',
                    path        : 'zoeken',  
                    component   :  () => import("@/views/project/search.vue"),
                    meta        : { action: 'project.search', menu: {text: 'Zoeken', icon: 'search'}},
                },
            ]
        },
        //////////////////////////////////////////////////////////////////////////////////////////
        //
        // Sales Orders
        //
        //////////////////////////////////////////////////////////////////////////////////////////
        {
            path: 'verkooporders', name:'salesorder', redirect:'verkooporders/overzicht/open', meta: {action: 'salesorder.open', menu: { short: 'Orders', text: "Verkooporders", icon: 'orders' }},
            component : () => routerView,
            children: [        
                {
                    name        : 'salesorder.status',
                    path        : 'overzicht/:status?',  
                    component   :  () => import("@/views/salesorder/page.vue"),
                    meta        : { action: 'salesorder.open',  menu: { short: 'Orders', text: 'Verkooporders', icon: 'orders'}},
                },
                {
                    name        : 'salesorder.search',
                    path        : 'zoeken',  
                    component   :  () => import("@/views/salesorder/search.vue"),
                    meta        : { action: 'salesorder.search', menu: {text: 'Zoeken', icon: 'search'}},
                },
            ]
        },
        //////////////////////////////////////////////////////////////////////////////////////////
        //
        // Tenders
        //
        //////////////////////////////////////////////////////////////////////////////////////////
        {
            name: 'tender', redirect:"offertes/overzicht", path: 'offertes', meta: {action: 'tender.open', menu: { text: "Offertes", icon: 'tender' }},
            component : () => routerView,
            children: [        
                { 
                    name        : 'tender.overview',
                    path        : 'overzicht/:status?',  
                    component   :  () => import("@/views/tender/overview.vue"),
                    meta        : { action: 'tender.open', menu: {short:"Offerte", text: 'Overzicht', icon: 'tender'}},
                },
                { 
                    name        : 'tender.search',
                    path        : 'zoeken',  
                    component   :  () => import("@/views/tender/search.vue"),
                    meta        : { action: 'tender.search', menu: {short:"Offerte", text: 'Zoeken', icon: 'search'}},
                },
            ]
        },
        //////////////////////////////////////////////////////////////////////////////////////////
        //
        // Purchase Invoice
        //
        //////////////////////////////////////////////////////////////////////////////////////////
        {
            path: 'inkoopfacturen', name:'purchaseinvoices', redirect:"inkoopfacturen/goedkeuren", meta: {action: 'purchaseinvoice.open', menu: { text: "Inkoop", icon: 'purchase' }},
            component : () => routerView,
            children: [        
                {
                    name        : 'purchaseinvoices.accept',
                    path        : 'goedkeuren',  
                    component   :  () => import("@/views/purchase/invoice/accept.vue"),
                    meta        : { action: 'purchaseinvoice.open', menu    : {short: 'Inkoop', text: 'Goedkeuren', icon: 'purchase'}},
                },
                {
                    name        : 'purchaseinvoices.dispute',
                    path        : 'dispuut',  
                    component   :  () => import("@/views/purchase/invoice/dispute.vue"),
                    meta        : { action: 'purchaseinvoice.open', menu    : {text: 'Dispuut', icon: 'purchase'}},
                },
                {
                    name        : 'purchaseinvoices.expiredunpaid',
                    path        : 'nognietbetaald/vervallen',  
                    component   :  () => import("@/views/purchase/invoice/expiredunpaid.vue"),
                    meta        : { action: 'purchaseinvoice.open', menu    : {text: 'Vervallen, niet betaald', icon: 'purchase'}},
                },
                {
                    name        : 'purchaseinvoices.unpaid',
                    path        : 'nognietbetaald',  
                    component   :  () => import("@/views/purchase/invoice/unpaid.vue"),
                    meta        : { action: 'purchaseinvoice.open', menu    : {text: 'Nog niet betaald', icon: 'purchase'}},
                },
                {
                    name        : 'purchaseinvoices.search',
                    path        : 'zoeken',  
                    component   :  () => import("@/views/purchase/invoice/search.vue"),
                    meta        : { action: 'purchaseinvoice.search', menu    : {text: 'Zoeken', icon: 'search'}},
                },
            ]
        },    
        //////////////////////////////////////////////////////////////////////////////////////////
        //
        // Payment Orders
        //
        //////////////////////////////////////////////////////////////////////////////////////////
        {
            path: 'betaalopdrachten',name: 'paymentorders', redirect: 'betaalopdrachten/open', meta: {action: 'paymentorder.open', menu: { text: "Betalen", icon: 'payment' }},
            component : () => routerView,
            children: [        
                {
                    name        : 'paymentorders.open',
                    path        : 'open',  
                    component   :  () => import("@/views/purchase/paymentorder/open.vue"),
                    meta        : { action: 'paymentorder.open', menu    : {short: 'Betalen', text: 'Openstaand', icon: 'payment'}},
                },
                {
                    name        : 'paymentorders.paid',
                    path        : 'afgehandeld',  
                    component   :  () => import("@/views/purchase/paymentorder/paid.vue"),
                    meta        : { action: 'paymentorder.open', menu    : {text: 'Betaald', icon: 'payment'}},
                },
            ]
        },
        //////////////////////////////////////////////////////////////////////////////////////////
        //
        // Test
        //
        //////////////////////////////////////////////////////////////////////////////////////////
        {
            path: 'test', meta: {menu: { text: "Test", icon: 'dashboard' }},
            component : () => routerView,
            children: [        
                {
                    name        : 'optitest',
                    path        : '',  
                    component   :  () => import("@shared/views/optitest/Icon.vue"),        
                    meta        : { menu    : {text: 'Icons', icon: 'dashboard'}, }
                },
                {
                    name        : 'optitest.icons.opticon',
                    path        : 'icons/opticon',  
                    component   :  () => import("@shared/views/optitest/Icon.vue"),        
                    meta        : { menu    : {text: 'Icons', icon: 'dashboard'}, }
                },
                {
                    name        : 'optitest.icons.types',
                    path        : 'icons/types',  
                    component   :  () => import("@shared/views/optitest/IconsByType.vue"),        
                    meta        : { menu    : {text: 'Icons', icon: 'dashboard'}, }
                },
                {
                    name        : 'optitest.design',
                    path        : 'design',  
                    component   :  () => import("@/views/optitest/design.vue"),        
                    meta        : { menu    : {text: 'Design', icon: 'widgets'}, }
                },
                {
                    name        : 'optitest.test',
                    path        : 'test',  
                    component   :  () => import("@/views/optitest/test/test.vue"),        
                    meta        : { menu    : {text: 'Test', icon: 'example'}, }
                },
                {
                    name        : 'optitest.test.pdf',
                    path        : 'test/pdf',  
                    component   :  () => import("@/views/optitest/test/pdf.vue"),        
                    meta        : { menu    : {text: 'Test', icon: 'example'}, }
                },
                {
                    name        : 'optitest.test.cyclicobjectreference',
                    path        : 'test/cyclicobjectreference',  
                    component   :  () => import("@/views/optitest/test/cyclicobjectreference.vue"),        
                    meta        : { menu    : {text: 'Test', icon: 'example'}, }
                },
                {
                    name        : 'optitest.test.cyclicobjectreference.solution',
                    path        : 'test/cyclicobjectreference/solution',  
                    component   :  () => import("@/views/optitest/test/cyclicobjectreference.solution.vue"),        
                    meta        : { menu    : {text: 'Test', icon: 'example'}, }
                },
            ]
        },
    ]
}

const routes = [authorizedRoute, ...unAuthorizedRoutes]

export default routes;