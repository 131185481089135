import { clsModel, fnCreate } from '@cls/clsModel'
import { products as api } from '@/app/api'
import vat from '@lib/vat'
import mutex from '@lib/mutex'
import Constants from '@app/consts'


var modelName = "product";
const id_optimit_type = Constants.optimit_types.product;

const fields = ['pd_name', 'pd_code', 'ean', 'pd_description', 'pd_description_short', 'pd_purchase_price', 'pd_sales_price_excl_vat', 'pd_sales_price_incl_vat', 'pd_supplier_code', 'pd_supplier_name', 'id_vat', 'id_ledger', 'id_unity', 'id_supplier', 'id_productgroup', 'vendors'];

class clsProduct extends clsModel {

    pd_name = null;
    pd_code = null;
    ean = null;
    pd_description = null;
    pd_description_short = null;
    pd_purchase_price = null;
    pd_sales_price_excl_vat = null;
    pd_sales_price_incl_vat = null;
    pd_supplier_code = null;
    pd_supplier_name = null;
    id_vat = null;
    id_ledger = null;
    id_unity = null;
    id_supplier = null;
    id_productgroup = null;

    vendors = [];

    addVendor(item) {
        let found = (this.vendors||[]).find( (x) => x.id_relation == item.id_relation);
        if (found) {
            return; // already there.
        }
        this.vendors.push({id_relation: item.id, archived_at: null, rel_name: item.rel_name, purchase_price_excl_vat: 0});
    }
    removeVendor(item) {
        this.vendors = (this.vendors||[]).filter( (x) => x.id_relation != item.id_relation);
    }

    /**
     * Make sure that the vendors array is always present, frontend probably depends on array type. 
     * @param {} data 
     * @returns 
     */
    fill(data) {
        data = data ||{};
        if (!data.vendors) {
            data.vendors = [];
        }
        data.pd_purchase_price       = data.pd_purchase_price || 0;  
        data.pd_sales_price_excl_vat = data.pd_sales_price_excl_vat ||0;  
        data.pd_sales_price_incl_vat = data.pd_sales_price_incl_vat ||0; 

        // clear the disabled flag.
        this.disabled = false; // the 'force disable' flag
        return super.fill(data);        
    }

    /**
     * override the implementation when required
     * A product can be opened readonly. E.g. when opened from within an invoice. 
     */
    async onAfterLoad(data, params, extraData) {
        extraData = extraData ||{};        
        this.disabled = !!extraData.readonly; // the 'force disable' flag        
    }
    
    /**
     * Get the ids of the available vendors.
     */
    get vendorIds() {
        return (this.vendors||[]).map( (x) => x.id_relation);
    }

    get modelRep() {
        return this.pd_code;
    }

    // Getters and setters for the prices. Originally with the purpose of automatic calculation when changing either of those. 
    // However, with the introduction of consumer prices, no more coupling between price excl and price incl.
    get id_pd_vat() {
        return this.id_vat;
    }
    get price_excl_vat() {
        return this.pd_sales_price_excl_vat;
    }
    get price_incl_vat() {
        return this.pd_sales_price_incl_vat;
    }
    set price_incl_vat(value) {
        this.pd_sales_price_incl_vat = value;
        if (!this.isFilling) {
            this.incl2excl();        
        }
    }
    set price_excl_vat(value) {
        this.pd_sales_price_excl_vat = value;
        if (!this.isFilling) {
            this.excl2incl();        
        }
    }
    set id_pd_vat(value) {
        this.id_vat = value;
    }

    // Calculate the excl price from the incl.
    incl2excl() {
        this.pd_sales_price_excl_vat = vat.incl2excl(this.id_vat, this.price_incl_vat);
    }
    // Calculate the incl price from the excl.
    excl2incl() {
        this.pd_sales_price_incl_vat = vat.excl2incl(this.id_vat, this.price_excl_vat);
    }

    
    /**
     * Override to create specific implementations. 
     */
    async doCreate(defaults) {
        defaults = defaults || {};
        var result = await api.loadNew();
        return await super.doCreate(result.data);
    }


    constructor() {
        super({
          api: api,   
          modelName: modelName, 
          id_optimit_type: id_optimit_type, 

          fillable: fields
        })
    } 


 }
 export default fnCreate(clsProduct , 'clsProduct');
