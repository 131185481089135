import eventbus from '@app/eventbus'
import {settings as api} from '@app/api'
import {onUnmounted} from 'vue'

/**
 * Use this class as a base class for settings.
 * 
 * Important, if you clone this model, make sure to destroy on onMount. 
 * This is important as we must unregister the events, so that the new 
 * model can be cleaned up by memory mgt.
 * 
 */

class clsSettings {
    
    isDataLoading = false;
    initialState = null;    // initial state is used to check if it is dirty
    isDirty = false;
    rules = {};

    // We store the registered events so that we can unregister on destroy.
    eventRegistrations = [];

    modelName = null;
    constructor(config, options) {
        config = config ||{};
        this.modelName = config.modelName || ""; 

        options = options ||{};
        (options.mandatoryFields||[]).forEach( (f) => {
            this.rules[f] = [ (v) => !!this.isDataLoading || !!v || "Veld is verplicht" ];
        })

        this.registerEvents();
    }

    /**
     * Register destroy in unmount. 
     */
    registerDestroyOnUnmount() {
        var self = this;
        onUnmounted( async () => {
            self.destroy();
        });            
    }
    /**
     * Destroy. Call this in onUnmounted when this model is created in a component. 
     * It unregisterers events, which releases the object for memory cleanup.
     */
    destroy() {
        this.eventRegistrations.forEach( (fnUnregister) => {
            fnUnregister()
        });
    }

    /**
     * Get the current state, which is basicly just a stringification fo the json representation
     */
    _getState() {
        var json = this.toJSON();
        return JSON.stringify(json).replaceAll('false', '0').replaceAll('true', '1');
    }

    /**
     * Fill the data. Overwrite when appropriate.
     * 
     * @param {} data 
     */
    fill(data) {
        data = data || {}
        for (var key in data) {
            if (undefined !== this[key]) {
                this[key] = data[key];
            }
        }
    }

    toJSON() {
        console.error('implement toJSON in specialized class.');
    }

    /**
     * HELPER TO Add one or more properties to a json structure.
     * Example: 
     *  var json = this.propsToJSON(["id","name","rights"])
     * @param {*} arrProps 
     * @returns 
     */
    propsToJSON(arrProps) {
        let result = {};
        var self = this;
        (arrProps || []).forEach(  ( item ) => result[item] = self[item] ) 
       
        return result;
    }

    /*
     * Load data from the server. As a side effect, we refresh our own settings by distributing the loaded event. 
     */
    async load() {
        this.isDataLoading = true;
        this.isDirty = false;

        try {
            let result = await api.load(this.modelName);
            // call the loaded event so that we refresh our own and also other instance data.
            eventbus.appdata.loaded({settings: result.data}); 
        }
        finally {
            this.isDataLoading = false;
        }
    }

    /*
     * save settings data to the server. As a side effect, we refresh our own settings. 
     */
    async save(data) {
        this.isDataLoading = true;
        var json = this.toJSON();
        try {
            let result = await api.save(this.modelName, json);        
            eventbus.appdata.loaded({settings: result.data}); // Will call the fill method via register events. 
        }
        finally {
            this.isDataLoading = false;
        }
    }

    /**
     * Is any of the settings modified?
     * @returns 
     */
    get isModified() {
        var state = this._getState();
        return state != this.initialState;
    }

    // Usage (workaround vue 2.7):  
    //      const inst = getCurrentInstance();
    //      inst.proxy.constructor.options.beforeRouteLeave = [(a,b,c) => model.onRouteLeaveDirtyCheck(a,b,c)] 
    // 
    async onRouteLeaveDirtyCheck (to, from, next) {
    
        try {
            if (!this.isModified) {
                next()
                return;
            } else {
                await eventbus.dialog.confirm.promise({title: 'U heeft wijzigingen gemaakt', body: 'Wilt u doorgaan?<br>De wijzigingen worden dan niet opgeslagen.'})
                next()
            }
        } catch (e) {
            next(false)
        }
    }

    /**
     * Register for events.
     */
    registerEvents() {
        var self = this;
        var modelName = self.modelName;
        if (!modelName) {
            return;
        }
        this.eventRegistrations.push( 
            eventbus.appdata.loaded.on(  (data) => {
                if (data && data.settings && data.settings[modelName]) {
                    self.fill(data.settings[modelName]);    
                    self.initialState = this._getState();        
                }
            })
        )        
    }
}

export default clsSettings