<template>
    <div>
        <Datatable :dt="dt" class="full-height">
            <template v-slot:menu ="{ item }">
                <ActionMenuItem action="salesinvoice.open" @click="dlg.open('salesinvoice', item)"></ActionMenuItem>
            </template>

            <template v-slot:item.inv_status ="{ item }">

                <span class="cursor-pointer" @click="dlg.open('salesinvoice', item)">            
                    <StatusChip type="salesinvoice" :status="item.inv_status" :substatus="subStatus(item)">{{statusRep(item)}}</StatusChip>
                </span>
            </template>
            <template v-slot:footer.prepend>
                <v-row dense>
                    <v-col>
                        <v-card flat>
                            <v-card-text>
                                <ActionButton action="salesinvoice.create" sub dusk="btn-add-invoice" :model="model" icon="add" xmain xprimary @click='createInvoice'>Nieuwe factuur</ActionButton>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </template>

        </Datatable>
    </div>
</template>

<script setup>
    import useDataTable from '@app/useDataTable'
    import Datatable from '@datatable/Datatable'
    import ActionMenuItem from "@controls/menu/ActionMenuItem.vue";
    import { salesinvoices as api } from '@app/api'
    import string from '@lib/string'
    import dlg from '@app/dlg'
    import StatusChip from "@controls/widgets/StatusChip.vue";
    import {date} from '@lib/date'
    import {computed, watch, ref} from 'vue'
    import ActionButton from '@controls/buttons/ActionButton'

    const props = defineProps({
        model: {
            type: [Object]
        },
        activeTab: {
            type: [Boolean]
        },
    });

    var fmtName = function(a,b,item) {
        return string.concat(" ", item.prs_firstname, item.prs_infix, item.prs_lastname);  
    }

    var headers = [
    { type: 'cmd'},
        { type: 'flag', flagType: 'salesinvoice_flag'},
        { type: 'note', noteType: 'salesinvoice_note'},
        { text: "Status",                   value: "inv_status", },
        { text: "Factuurnummer",            value: "inv_number", type: 'open', fmt: (v, item) => ((v)? v : '-')} ,
        { visible: false, text: "Klant",                    value: "rel_name"} ,
        {  text: "Factuur titel",            value: "inv_name",       cellClass: "max-col-width" },
        { visible: false, text: "Referentie",               value: "inv_reference", fmt: (v, item) => ((v)? v : '-')} ,
        { text: "Factuurdatum",             value: "inv_date",    type: 'date', sort: 'desc'   },
        { visible: false, text: "Vervaldatum", value: "inv_exp_date", type: 'date'},
        { text: "Excl BTW",                 value: "inv_sub_total",     type: 'amount'     },
        { text: "BTW",                      value: "inv_vat_total",      type: 'amount'     },
        { text: "Incl BTW",                 value: "inv_total",  type: 'amount'     },
        { visible: false,  text: "Betaald",                  value: "inv_total_paid",     type: 'amount'     },
        { visible: false,  text: "Openstaand",               value: "inv_amount_open",     type: 'amount'     },
        { visible: false,  text: "1e herinnering",           value: "reminder1_date",     type: 'date'     , emptyText: '-'},
        { visible: false,  text: "2e herinnering",           value: "reminder2_date",     type: 'date'     , emptyText: '-'},
        { visible: false,  text: "In gebrekestelling",       value: "defaultnotice_date",     type: 'date' , emptyText: '-'},

        { isUtc: true, visible: false, text: "Gewijzigd",                value: "updated_at",    type: 'datetime',   },
		{ visible: false, text: "Gewijzigd door",           value: "updated_by",                        },
		{ isUtc: true, visible: false, text: "Aangemaakt",               value: "created_at",    type: 'datetime',   },
		{ visible: false, text: "Aangemaakt door",          value: "created_by",                        },
		{ visible: false, text: "Boekhoudnummer",           value: "accountancy_number",                },
    ]


    const compIdChapter = computed({
        get() {
            return props.model.id_pr_chapter;
        }
    })
    const lastChapterSearched = ref(null);

    var dt = useDataTable("dt_project_salesinvoices", "salesinvoice", api, headers, {
        modelNames: ["salesinvoice_send"], // Also refresh when an invoice is sent.
        itemName: {
            prefix: 'De',
            single: 'verkoopfactuur',
            plural: 'verkoopfacturen',
        }, parent: {
            id_project: props.model.id,
        }, filter: {
            id_project_chapter: ()=>compIdChapter.value
        },
        onAfterSearch: () => lastChapterSearched.value = compIdChapter.value,
        preventRemoveHandler: ()=>!props.model.isDialogOpen, // No refresh when dialog is not open
        preventSaveHandler:   ()=>!props.model.isDialogOpen, // No refresh when dialog is not open

        canArchive: false
    })

    // When a chapter is selected and we are the active tab, search.
    watch(compIdChapter, (newValue, oldValue) => {
        if (props.activeTab) {
            dt.search();
        }
    })
    // The currently active tab. Must be reactive in order to be watched
    const compActiveTab = computed({
        get() {
            return props.activeTab
        }
    })
    // When this tab is activated and the last chapter searched is not the actual one, search.
    watch(compActiveTab, (newValue, oldValue) => {
        if (newValue) { // tab is activated
           if (lastChapterSearched.value != compIdChapter.value) { 
                dt.search();
            }
        }
    })

    // Show the pdf
    function onPdf(item) {
        dlg.pdf( async () => api.getPdf(item.id));
    }

    function statusRep(item) {
        let status = item?.inv_status;
        switch (string.lower(status)) {
            case "new"     : return "Nieuw";
            case "concept" : return "Concept";
            case "open"    : 
                if (item.defaultnotice_date) {
                    return "Ingebreke";
                }
                if (item.reminder2_date) {
                    return "Herinnering 2";
                }
                if (item.reminder1_date) {
                    return "Herinnering 1";
                }
                if (item.inv_total_paid) {
                    return "Deelbetaling";
                }
                if (date.isInPast(item.inv_exp_date)) {
                    return "Vervallen";
                }
                return "Open";
            case "paid"    : return "Betaald";
        }
        return "-";
    }
    /**
     * When the status is open and a reminder is sent, reflect the correct substatus.  
     * 
     */
    function subStatus(item) {
        let status = item?.inv_status;
        if (string.lower(status) != "open") {
            return null;
        }
        let strSubStatus = null;
        if (date.isInPast(item.inv_exp_date)) {
            strSubStatus = "expired";
        }
        if (item.reminder1_date) {
            strSubStatus = "reminder1";
        }
        if (item.reminder2_date) {
            strSubStatus = "reminder2";
        }
        if (item.defaultnotice_date) {
            strSubStatus = "defaultnotice";
        }
        if (item.inv_total_paid) {
            return "wrongly-paid";
        }

        return strSubStatus;
    }

    function createInvoice() {
        var pro_data = {id_relation: props.model.id_invoicable_relation, id_project: props.model.id, pro_number: props.model.pro_number, pro_name: props.model.pro_name};

        var id_chapter = props.model.id_pr_chapter;
        var pr_chapter_seq = props.model.pr_chapter_seq;
        if (id_chapter) {
            pro_data.id_project_chapter = id_chapter;
        }
        if (pr_chapter_seq) {
            pro_data.pro_number = `${pro_data.pro_number}.H${pr_chapter_seq}`;
        }
        
        dlg.create('salesinvoice', pro_data)
    }

</script>