<template>
    <tr>
        <td class="col-cmd">                 
            <Checkbox v-if="!item.noCheckbox && dt.checkboxed" v-model="item.checked"></Checkbox>
            <span v-else-if="!item.noMenu && !dt.noMenu">
                <slot name="menu" :item="item" :dt="dt"></slot>
            </span>
            <slot v-else name="menucmd" :item="item" :dt="dt"></slot>
        </td>
        <td v-for="(header, index) in columnHeaders" :key="index" :class="header.cellClass" class="pr-0 overflow-y">
            <span v-if="header.type=='drag'">
                <Icon class="cursor-move-y" type="drag"></Icon>
            </span>
            <span v-else-if="header.value=='tphp_amount'">
                <NumberField max="1000000" decimal dusk="amount" :disabled="model.isOpenOrLater" no-border :model='model' :rules='model.rules.tphp_amount' v-model='item.tphp_amount' ></NumberField>
            </span>
            <span v-else-if="header.value=='id_unity'">
                <UnitCombo dusk="unit" :disabled="model.isOpenOrLater" no-border :model='model' :rules='model.rules.id_unity' v-model='item.id_unity'></UnitCombo>
            </span>
            <span v-else-if="header.value=='tphp_pd_name'">
                <RichTextLine dusk="name" :disabled="model.isOpenOrLater" no-border ref="rfocus" :model="model" placeholder="Omschrijving" v-model="item.tphp_pd_name"></RichTextLine>
            </span>
            <span v-else-if="header.value=='id_product'">
                <ProductPicker @expand="()=>onExpand(item)"  dusk="product" :disabled="model.isOpenOrLater" :field-map="productSelectFieldMap" :filter="{'type': 'rp', 'selectfor':'salesinvoice'}" no-border :model='model' :rules='model.rules.id_product' :valueModel="item" v-model='item.id_product'></ProductPicker>
            </span>
            <span class="txt" v-else-if="header.value=='pd_supplier_code'">
                {{item[header.value]}}
            </span>
            <span class="txt" v-else-if="header.value=='pd_supplier_name'">
                {{item[header.value]}}            
            </span>
            <span class="txt" v-else-if="header.value=='ean'">
                {{item[header.value]}}
            </span>
            <span class="txt" v-else-if="header.value=='sup_name'">
                {{item[header.value]}}
            </span>
            <span class="txt" v-else-if="header.value=='marge'">
                <span v-if="model.isOpenOrLater||model.disabled">{{ marge(item) }}</span>
                <v-menu v-else
                :value="menu_marge"
                :close-on-content-click="false"
                :nudge-width="100"
                offset-y
                >
                <template v-slot:activator="{ on, attrs }">
                    <span class="cursor-pointer text-underline py-1" no-v-on="on" @click="()=>onToggleMarge()"">
                        {{marge(item)}}
                    </span>
                </template>
                <v-card>
                        <v-card-title>
                            Wijzig marge
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text>
                            <div v-if="!item.tphp_purchase_price">
                                Voer aub eerst een inkoopprijs in.
                                <div class="mt-2" v-if="!columnHeaders.find( (x)=>x.value=='tphp_purchase_price')">
                                    U kunt in de inkoopprijs-kolom aanzetten in de instellingen links bovenin deze tabel. 
                                </div>
                            </div>
                            <div v-else class="min-w-3 w-3">
                                <NumberField max="10000" min="-1000" percentage dusk="marge" :disabled="model.isOpenOrLater" :model='model' 
                                    nov-model='item.marge' 
                                    :value="calcMarge"
                                    @input="(v) => setMarge(v)"
                                    @blur="menu_marge=false"
                                >
                                </NumberField>
                        </div>
                    </v-card-text>
                </v-card>
            </v-menu>

            </span>
            <span v-else-if="header.value=='tphp_sales_price'">
                <NumberField max="20000000" dusk="price" :disabled="model.isOpenOrLater" no-border amount :model='model' :rules='model.rules.tphp_sales_price' v-model='item.tphp_sales_price' :currency='item.crnc_code'></NumberField>
            </span>
            <span v-else-if="header.value=='tphp_purchase_price'">
                <NumberField dusk="price" :disabled="model.isOpenOrLater" no-border amount :model='model' :rules='model.rules.tphp_purchase_price' v-model='item.tphp_purchase_price' :currency='item.crnc_code'></NumberField>
            </span>
            <span v-else-if="header.value=='id_vat'">
                <span class="ml-2 txt" v-if="model.tend_vat_shifted">Verlegd</span>
                <VatCombo dusk="vat" :disabled="model.isOpenOrLater" no-clearable standard-types no-border v-else :model='model' :rules='model.rules.id_vat' v-model='item.id_vat'></VatCombo>
            </span>
            <span v-else-if="header.value=='amount_excl'" class="txt">
                <AmountText dusk="totalprice" no-border v-model='item.amount_excl' :currency='model.currency'/>    
            </span>

        </td>
    </tr>
</template>


<script setup>
    import NumberField from '@controls/input/NumberField'
    import VatCombo from '@controls/combo/Vat'
    import IconMenu from "@controls/menu/IconMenu.vue";
    import Icon from '@controls/icons/Icon'
    import UnitCombo from '@controls/combo/Unit'    
    import ProductPicker from '@controls/picker/Product'
    import AmountText from '@controls/text/Amount.vue' 
    import {numeric} from '@lib/numeric'
    import RichTextLine from '@controls/input/RichTextLine'
    import noty from '@shared/lib/noty'
    import ActionButton from '@controls/buttons/ActionButton'
    import {computed, ref} from 'vue'

    // Take over the following fields from the corresponding properties in the selected item: 
    const productSelectFieldMap = {
    //  selectfield:              invoiceline field
        pd_name:                  'tphp_pd_name',
        pd_supplier_code:         'pd_supplier_code',
        pd_supplier_name:         'pd_supplier_name',
        pd_sales_price_excl_vat:  'tphp_sales_price', 
        pd_purchase_price_excl_vat:  'tphp_purchase_price', 
        id_vat:                   'id_vat',
        id_unity:                 'id_unity',
        sup_name:                 'sup_name',
    };

//    if (!window.menu_margel) {
//        window.menu_margel = menu_marge;
//    }
    const props = defineProps({
        model: {
            type: [Object]
        },
        item: {
            type: [Object]
        }, 
        dt: {
            type: [Object]
        },
        columnHeaders: {
            type: [Object, Array]
        },        
    });
    const menu_marge = ref(false);
    const calcMarge = ref(0);
    function onToggleMarge() {
        if (!menu_marge.value) {
            calcMarge.value = props.item.marge;
        }
        menu_marge.value = !menu_marge.value;
    }
    function setMarge(v) {
        props.item.marge = v;
    }
    // The visual representation of the marge.
    function marge(item) {
        var m = item.marge; 
        if (null == m) 
        if (!Number(item.tphp_purchase_price)) { // Can not be calculated when no purchase price
            return "-- %";
        }
        if (!Number(item.tphp_sales_price)) { // Can not be calculated when no sales price
            return "-- %";
        }
        var r = 100 * (item.tphp_sales_price - item.tphp_purchase_price) / item.tphp_purchase_price;
        r = numeric.fmt( r, r == Math.round(r) ? 0 : 2);
        return `${r}%`;
    }
    const emit = defineEmits(['expand'])
    function onExpand(line) {
        emit('expand', line)
    }

//     async function onExpand(line) {
//         if (! await noty.tryConfirm('Wanneer u doorgaat wordt deze regel vervangen door regels met alle artikelen waaruit het recept is opgebouwd.')) {
//             return;
//         }
//         props.model.expandRecipe(line)
//     }

</script>
