import clsEventbus from '@cls/clsEventbus'

class clsEventbusExt extends clsEventbus {

    /**
     * Overwrite this method to implement your own events.
     */
     registerEvents() {

    }

} 

export default clsEventbusExt;
