import clsApi from '@cls/clsApi'

class clsPaymentOrderApi extends clsApi {

    addLines(id_payment_order, arrLineIds) {
        return this.post(`addlines`, {id_payment_order: id_payment_order, purchaseinvoices: arrLineIds});
    }
    removeLines(id_payment_order, arrLineIds) {
        return this.post(`removelines`, {id_payment_order: id_payment_order, lines: arrLineIds});
    }

    combineLines(id_payment_order, arrLineIds) {
        return this.post(`combinelines`, {id_payment_order: id_payment_order, lines: arrLineIds});
    }
    splitLine(id_payment_order, id_payment) {
        return this.post(`splitline`, {id_payment_order: id_payment_order, id_payment: id_payment});
    }
    applyDiscount(id_payment_order, id_purchase_invoice) {
        return this.post(`applydiscount`, {id_payment_order: id_payment_order, id_purchase_invoice: id_purchase_invoice});
    }
    removeDiscount(id_payment_order, id_purchase_invoice) {
        return this.post(`removediscount`, {id_payment_order: id_payment_order, id_purchase_invoice: id_purchase_invoice});
    }
    setPaid(id_payment_order) {
        return this.post(`setpaid`, {id_payment_order: id_payment_order});
    }
    setOpen(id_payment_order) {
        return this.post(`setopen`, {id_payment_order: id_payment_order});
    }
    // For the download sepa methods, we are actually just loading a json result. Therefore, we can use the 'normal' post.
    download(id_payment_order) {
        return this.post('download', {id_payment_order: id_payment_order});
    }
    downloadG(id_payment_order) {
        return this.post('downloadg', {id_payment_order: id_payment_order});
    }
    downloadWarnings(id_payment_order) {
        return this.post('download/getwarnings', {id_payment_order: id_payment_order});
    }

}

export default clsPaymentOrderApi;
