<template>
    <Dialog :dialog="dlg">
        <v-form ref="form" lazy-validation>
                        
            <ActionCombo :model="model" placeholder="Type" no-clearable v-model="model.type" :items="model.types"></ActionCombo>                    
        
            <v-divider class="my-4" ></v-divider>
    
            <ToggleButtonGroup :model="model" class="" col-auto v-model="model.digital" :options="[{id: true, name: 'Via e-mail'}, {id: false, name: 'Download'}]"/>
            
            <v-divider class="my-4" ></v-divider>
            <div v-if="model.digital">
                <Form>
                    <FormRow label="Verstuur naar" col-label="2">                    
                        <TextField :model="model" :rules="model.rules.to" placeholder="Verstuur naar" v-model="model.to" 
                        :suggestions="model.recipients" @suggested="handleSuggestionTo" handle-suggestion>
                        
                        </TextField>
                    </FormRow>
                    <FormRow label="CC" col-label="2">                    
                        <TextField :model="model" :rules="model.rules.cc" placeholder="CC" v-model="model.cc"
                        :suggestions="model.recipients" @suggested="handleSuggestionCC" handle-suggestion>

                        </TextField>
                    </FormRow>
                    <FormRow label="Onderwerp" col-label="2">                    
                        <TextField :model="model" :rules="model.rules.subject" placeholder="Onderwerp" v-model="model.subject"></TextField>
                    </FormRow>
                    <v-row dense>
                        <v-col>
                            <RichTextArea md :model='model' placeholder='E-mail body' v-model='model.body'>
                                <template v-slot:toolbar-left>
                                    <label class="ml-2 mr-8">Bericht</label>
                                </template>
                            </RichTextArea>
                        </v-col>
                    </v-row>
                </Form>

                <AttachmentSelector class="my-2"
                    :prepend-fixed="[{name:model.typeName}]"
                    hide-empty-dropdown
                    :model="model"
                    :selected="model.attachments"
                    :selectables="model.allattachments"
                    @attach="(attachment) =>model.attach(attachment)"
                    @unattach="(attachment) =>model.unattach(attachment)">
                    <template v-slot:right>
                        <div class="text-right">
                        </div>
                    </template>
                </AttachmentSelector>
            </div>

            <div v-else>
                <h1>Download en print de herinnering</h1>
                <div>
                    Verstuur de herinnering vervolgens naar: 
                </div>
                <div class="mt-4 ml-4 mb-4"> 
                    <div v-for="(line, ix) in model.address" :key="ix">
                        {{line}}
                    </div>

                </div>
                

            </div>
        </v-form>


        <template v-slot:footer-save-text>
            {{model.digital ? "Verstuur" : "Download" }}
        </template>

    </Dialog>
</template>



<script setup>
    import useModel from '@/models/salesinvoice/reminder'
    import Form from '@controls/forms/Form'
    import FormColumn from '@controls/forms/FormColumn'
    import FormRow from '@controls/forms/FormRow'
    import TextField from '@controls/input/TextField'
    import Dialog from '@shared/ui/dialogs/ActionDialog'
    import RichTextArea from '@controls/input/RichTextArea'
    import ActionButton from '@controls/buttons/ActionButton'
    import ToggleButtonGroup from '@controls/buttons/ToggleButtonGroup'
    import AttachmentSelector from '@/ui/controls/AttachmentSelector'
    import Checkbox from '@controls/check/Checkbox.vue'
    import string from '@lib/string'
    import ActionCombo from '@controls/combo/ActionCombo'

    import { ref } from 'vue'
    import clsDialog from '@cls/clsDialog'

    const form = ref(null); 
    let model = useModel();

    let dlg = ref(
        new clsDialog("salesinvoice_reminder", "Verstuur herinnering", model, [form], {
            icon: 'reminder',
            noSubTitle: true
        } )).value;


    function handleSuggestionTo(v) {
        if ( (model.to||"").indexOf(v) >=0) {
            return;
        }
        model.to = string.concat("; ", model.to, v);
    }
    function handleSuggestionCC(v) {
        if ( (model.cc||"").indexOf(v) >=0) {
            return;
        }
        model.cc = string.concat("; ", model.cc, v);
    }

    window.sirdlg = dlg;
</script>
