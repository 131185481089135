//
// Usage: 
//  import string from '@shared/lib/string'
// or: 
//  import string from '@lib/string'
//

var string = {};
string.isEmpty = (str) => {
    if (str == null) {
        return true;
    }
    return (""+str) == "";
};
/**
 * is the provided strint not empty?
 * When multiple parametersare provided: are all provided strings not empty?
 * @param  {...any} theArgs 
 * @returns 
 */
string.isNotEmpty = (...theArgs) => {    
    for (var n = 0; n < theArgs.length; n++) {
        if (string.isEmpty(theArgs[n])) {
            return false;
        }
    }
    return true;
};

// 
// when str is empty, return the default value, otherwise, return str
// 
string.ifEmpty = (str, defaultValue) => {
    defaultValue = defaultValue || "";
    return string.isEmpty(str) ? defaultValue : str; 
};

/**
 * Does string str start with startStr (ignore case) ? 
 * returns: true|false
 */
string.startsWith = (str, startStr) => {
    return (str||"").toUpperCase().startsWith( (startStr||"").toUpperCase());
}
/**
 * Does string str end with endStr (ignore case) ? 
 * returns: true|false
 */
string.endsWith = (str, endStr) => {
    return (str||"").toUpperCase().endsWith( (endStr||"").toUpperCase());
}
/**
 * Decode a base 64 string to its original.
 * @param {*} strBase64Encoded 
 * @returns 
 */
string.base64Decode = (strBase64Encoded) => {
    return atob(strBase64Encoded);
} 
/**
 * Encode a byte stream to a base 64 encoded string.
 * Note, For unicode strings, the character code points can exceed 0xff, which causes 
 * 'characters out of range' exceptions. As there is no usecase for us, we ignore it. 
 * However, whenever this is the case, find a solution on: https://developer.mozilla.org/en-US/docs/Glossary/Base64
 * @param {*} bytes 
 * @returns 
 */
string.base64Encode = (bytes) => {
    const binString = String.fromCodePoint(...bytes);
    return btoa(binString);
} 

/**
 * In:  "abcde", "olifant"
 * Out: false
 * 
 * In:  "abcde", "c"
 * Out: true
 * 
 * In:  "abcde", "CD"
 * Out: true
 * 
 * In:  "abcde", "CD", false
 * Out: false
 * 
 * Note: right can be an array of strings as well, which makes the method return true when left contains at least 
 *       one of the strings.
 * 
 */
string.contains = (left, right, ignorecase) => {
    if (string.isEmpty(left) || !right || !right.length) {
        return false;
    }
    ignorecase = ignorecase || (undefined === ignorecase);

    var arrRight = right.forEach ? right : [right];
    for (var n = 0; n < arrRight.length; n++) {
        var right = arrRight[n]
        if (string.isNotEmpty(left) && string.isNotEmpty(right)) {        
            if (ignorecase) {
                left = (''+left).toLowerCase();
                right = (''+right).toLowerCase();
            }        
            if (left.indexOf(right) >=0) {
                return true;
            }    
        }
    }
    return false;
};

/**
 * Trim a strim
 */
string.trim = (str) => {
    if (!str) {
        return "";
    }
    return `${str}`.trim();
};
/**
 * Return the first not empty string in the list. 
 * Note that when no 'not empty' string is found, the last one is returned. 
 * This alows for: string.coalesce(value1, value2, '');.
 */
string.coalesce = (...theArgs) => {    
    for (var n = 0; n < theArgs.length; n++) {
        if (string.isNotEmpty(theArgs[n])) {
            return theArgs[n];
        }
    }
    if (theArgs.length) {
        return theArgs[theArgs.length-1]; // last one.
    }
    return null;
};
/**
 * Concate all not empty parameters by the given separator
 * In: string.concat(", ", "abc",null, "def")
 * Out: "abc, def"
 */
string.concat = (sep, ...theArgs) => {    
    if (!sep) {
        sep = "";
    }
    var args = [];
    for (var n = 0; n < theArgs.length; n++) {
        var arg = theArgs[n];
        if (typeof arg == 'array') {
            args = args.concat(arg);
        } else {
            args.push(arg);
        }
    }

    let result = "";
    for (var n = 0; n < theArgs.length; n++) {
        var arg = theArgs[n];
        if (string.isNotEmpty(arg)) {
            if (string.isNotEmpty(result)) {
                result = result + sep;
            }
            result = result + arg;
        }
    }
    return result;
};

/**
 * Split a string by the given separator (by default: " ").
 * Rurns array. 
 * Exammple: 
 *  IN: 
 *      string.split("A very good day")
 *  Out: 
 *      ["A", "very", "good", "day"]
 *
 * Exammple: 
 *  IN: 
 *      string.split("a, b, c, d, e", ",")
 *  Out: 
 *      ["a", "b", "c", "d", "e"]
 */
string.split = (str, sep) => {
    if (!str) {
        return [];
    }
    str = str.trim();
    sep = sep || " ";
    return ((""+str).split(sep)||[]).map( (x) => x.trim())
};

/**
 * Return the right n characters (default 1) of the given string.
 * Returns "" when the string is empty.
 * Exammple: 
 *  IN: 
 *      string.right("A very good day")
 *  Out: 
 *      "y"
 */
string.right = (str, n) => {
    if (string.isEmpty(str)) {
        return "";
    }
    if (!Number(n) || n == 0) {
        n = 1;
    }
    if (n < 0) {
        n = -1 * n;
    }
    return str.slice( -1*n );    
};

/**
 * Return the left n characters (default 1) of the given string.
 * Returns "" when the string is empty.
 * Exammple: 
 *  IN: 
 *      string.left("A very good day")
 *  Out: 
 *      "A"
 */
string.left = (str, n) => {
    if (!str) {
        return "";
    }
    if (!Number(n) || n == 0) {
        n = 1;
    }
    if (n < 0) {
        return string.right(str, n);
    }
    return str.slice(0,n);
};

/**
 * Is the given value in the range of the provided arguments. 
 * If so, the value is returned. 
 * Otherwise, the default value is returned.
 * 
 * @param {*} value 
 * @param {*} defaultValue 
 * @param {*} ignoreCase 
 * @param  {...any} theArgs 
 */
var in_range_or = function(_value, defaultValue, ignoreCase, arrAccepted) {
    let value = _value;
    arrAccepted = arrAccepted || [];
    if (string.isEmpty(value)) {
        return defaultValue;
    }
    if (ignoreCase) {
        value = (""+value).toLowerCase();
    }
    for (var n = 0; n < arrAccepted.length; n++) {
        let str = arrAccepted[n];
        if (ignoreCase) {
            str = (""+str).toLowerCase();
        }
        if (str == value) {
            return _value;
        }
    }
    return defaultValue;
};

// in: "P", dontcare, dontcare     out: null
// otherwise, see numeric.range
string.inRangeOrNull = (value, arrAccepted) => {
    return in_range_or(value, null, true, arrAccepted);
}
// in: "P", "DEFAULTVALUE", ["A", "B", "C"]     out: "DEFAULTVALUE"
// in: "P", "DEFAULTVALUE", ["A", "P", "C"]     out: "P"
string.inRangeOrDefault = (value, defaultValue, arrAccepted) => {
    return in_range_or(value, defaultValue, true, arrAccepted);
}

// isInRange(value, ...)
// in: "P", ["a", "b"]          out: false
// in: "P", "a", "b"            out: false
// in: "P", ["a", "p", "c"]     out: true
// in: "P", "a", "p", "c"       out: true
string.isInRange = (...theArgs) => {
    if (theArgs.length <2) {
        return false;
    }
    var value = theArgs.shift(1)    
    var arr = (theArgs[0] && theArgs[0].filter) ? theArgs[0] : theArgs;
    
    return null != string.inRangeOrNull(value, arr);
}

// in: "p", "P"                 out: true
// in: "a", "b"                 out: false
string.compare = (left, right) => {
    return string.inRangeOrNull(left, [right]) !== null;
}

// For a given first and last name, construct a paraph. 
// For example: 
//          'Herman', 'Kortekaas': 'HK'
//          'Herman', null: 'HE'
//          null, 'Kortekaas': 'KO'
// 
string.paraph = (firstName, lastName) => {
    var para = "";
    if (string.isNotEmpty(firstName) && string.isNotEmpty(lastName)) {
        para = string.left(firstName) + string.left(lastName);
    } else if (string.isNotEmpty(lastName)) {
        para = string.left(lastName,2);
    } else {
        para = string.left(firstName,2);
    }
    return (""+para).toUpperCase();
}

/**
 * Strip html tags from a string.
 * This is not for complying to any owasp threat. 
 * It is just for removing tags from input where it is not appropriate.
 * 
 * @param {} str 
 * @returns 
 */
string.stripTags = (str) => {
    if (string.isEmpty(str)) {
        return str;
    }
    return (""+str).replace(/(<([^>]+)>)/gi, "");
}

var uid = 0;

/**
 * Very simple incremental uid. 
 * Usage: 
 *      var id = string.uid()  ==> 'uid_1
 *      var id2 = string.uid()  ==> 'uid_2
 * 
 * @param {*} type 
 */
string.uid =() => {
    uid = uid + 1;
    return `uid_${uid}`;
}

/**
 * Convert string to uppercase
 * Usage: 
 *  string.upper("the old man") ==> "THE OLD MAN"
 * @param {*} s 
 * @returns 
 */
string.upper = (s) => {
    if (typeof s !== 'string') {
        return s
    }
    return (s||"").toUpperCase();
}

/**
 * Convert string to lower
 * Usage: 
 *  string.upper("the YOUNG man") ==> "the young man"
 * @param {*} s 
 * @returns 
 */
string.lower = (s) => {
    if (typeof s !== 'string') {
        return s
    }
    return (s||"").toLowerCase();
}

/**
 * Capitalize the string
 * Usage: 
 *  string.capitalize("the old man") ==> "The old man"
 * @param {*} s 
 * @returns 
 */
string.capitalize = (s) => {
    if (typeof s !== 'string') {
        return ''
    }
    return s.charAt(0).toUpperCase() + s.slice(1)
}
/**
 * Get a random string of a specified length
 * @param {} length 
 * @returns 
 */
string.random = (length) => {
    if (!length) {
        length = 16;
    }

    let str = [ ...Array(length) ].map(() => (~~(Math.random() * 36)).toString(36)).join('');
    return str;
}

/**
 * Get a random string of a specified length
 * @target      - the search to execute the replace on
 * @searchValue - the value to search and relpace
 * @replacment  - the replacement value
 * @bOnce       - when true, only the first occurence is replaced. By default all occurrances are replaced.
 *
 * @returns the string where the searchValue is replaced by the replacement.
 */
string.replace = (target, searchValue, replacement, bOnce) => {
    if (!target || !target.length || !target.replace || !target.replaceAll) {
        return false;
    }
    if (bOnce) {
        return target.replace(searchValue, replacement);
    }
    return target.replaceAll(searchValue, replacement);
}

export default string;