import { clsModel, fnCreate } from '@cls/clsModel'
import { project_costlimit as api } from '@/app/api'
import {relation} from '@app/list'

var modelName = "project_limit";
const fields = [
    'id', 
    'id_project',
    'id_relation',
    'pl_amount',
];

class clsProjectCostLimit extends clsModel {

    id_project = null;
    id_relation = null;
    pl_amount = null;

    get modelRep() {
        return relation.oneProp(this.id_relation, 'rel_name', '-')
    }    
    fill(data, ignoreInitialState) {
        data.pl_amount = data.pl_amount ||0;
        return super.fill(data, ignoreInitialState);        
    }

    constructor() {
        super({
          api: api,   
          modelName: modelName, 
          fillable: fields
        })
    } 
        
 }
 export default fnCreate(clsProjectCostLimit , 'clsProjectCostLimit');
