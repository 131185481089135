<template>

    <ActionCombo :noClearable="noClearable" :placeholder="placeholder" :skeleton="compSkeleton" :disabled="compDisabled" :rules="rules" v-model="dvalue" :items="items"></ActionCombo>

</template>

<script setup>

    // import WorkorderStatus from '@controls/combo/WorkorderStatus'
    // <WorkorderStatus v-model="model.status" :model="model" :rules="model.rules.mandatory"></WorkorderStatus>

    const props = defineProps({
        disabled: {
            type: [Boolean]
        },
        rules: {
            type: [Array]
        },
        noClearable: {
            type: [Boolean],
            default: false
        },
        skeleton: {
            type: [Boolean]
        },
        value: {
            type: [String, Number]
        },
        placeholder: {
            type: [String],
            default: 'Artikelgroep'
        },
        model: {
            type: [Object]
        }

    })
    
    import ActionCombo from '@controls/combo/ActionCombo'
    import {computed} from 'vue'

    const emit = defineEmits(['input'])

    const dvalue = computed({
        get() {
            return props.value
        },
        set: function(v) {
            emit('input', v)
        }	
    })

    const compSkeleton = computed({
        get() {
            if (props.skeleton) {
                return true;
            }
            if (!props.model) {
                return false;
            }
            return props.model.isDataLoading;
        }
    });
    const compDisabled = computed({
        get() {
            if (props.disabled) {
                return true;
            }
            if (!props.model) {
                return false;
            }
            return props.model.disabled;
        }
    });

    const items = computed({
        get() {
            return [
                {id: 'concept', name: 'Concept'}, 
                {id: 'planned', name: 'Ingepland'},
                {id: 'done', name: 'Afgerond'}
            ]
        },
    })

</script>
