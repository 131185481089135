import { clsModel, fnCreate } from '@cls/clsModel'
import { suppliers as api } from '@/app/api'
import Constants from '@app/consts'


var modelName = "supplier";
const id_optimit_type = Constants.optimit_types.supplier;

class clsSupplier extends clsModel {

    sup_name     = "";

    constructor() {
        super({
          api: api,   
          modelName: modelName, 
          id_optimit_type: id_optimit_type, 

          mandatoryFields: ['sup_name']
        })
    } 

    /**
     * Fill the data 
     * 
     * @param {*} data 
     */
    fill(data) {
        data = data || {};
        super.fill(data);

        this.sup_name     = data.sup_name || ""
    }

    /**
     * Get the JSON representation for saving the data
     * 
     * @param {*} data 
     */
    toJSON() {
        return this.propsToJSON(["id", "sup_name"]);
    }
        
 }
 export default fnCreate(clsSupplier , 'clsSupplier');
