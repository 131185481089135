import clsApi from '@cls/clsApi'

class clsAccountancyApi extends clsApi {

    load() {
        return this.get(`load`); 
    }

    loadAdministrations() {
        return this.get(`administrations/load`); 
    }

    loadConfiguration() {
        return this.get(`configuration/load`); 
    }

    saveCashCredentials(model) {
        return this.post(`cash/credentials/save`, model); 
    }

    saveAdministration(model) {
        return this.post(`administrations/save`, model); 
    }

    saveConfiguration(model) {
        return this.post(`configuration/save`, model); 
    }

    refreshLedgers() {
        return this.post(`ledgers/refresh`); 
    }
    importRelations(model) {
        return this.post(`relations/import`, model); 
    }
    disconnect() {
        return this.post(`disconnect`); 
    }

    async startExact() {
        return this.post('exact/start')
    }
    async startUnit4() {
        return this.post('unit4/start')
    }
    async startAccountview() {
        return this.post('accountview/start')
    }
    async startTwinfield() {
        return this.post('twinfield/start')
    }

}

export default clsAccountancyApi;
