<template>    

      <v-menu :close-on-content-click="true" offset-y :disabled="compDisabled">

        <template v-slot:activator="{ on }">
            <span v-on="on" :class="`flag-${activeId}`">
                <v-btn elevation="0" class="header-action-button px-0">
                    <Icon small :type="(Number(value)>0)?'flag':'flag-empty'"></Icon>                                    
                </v-btn>
            </span>
        </template>
        <v-card>
        <v-list>            
            <v-list-item @click="setValue(flag.id)" dense v-for="(flag) in flags" :key="flag.id">
                <v-list-item-content class="cursor-pointer">
                    <v-list-item-title >
                        <span :class="`flag-${flag.id}`">
                            <Icon small  :type="(Number(flag.id)>0)?'flag':'flag-empty'" sccolor="flag.color"></Icon>
                        </span>
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
        </v-card>

      </v-menu>       

</template>

<script setup>
import ActionButton from '@controls/buttons/ActionButton'
import Icon from '@controls/icons/Icon'
import ActionCombo from '@controls/combo/ActionCombo'
import {computed} from 'vue'
import action from '@app/action'

// Note: it seems a lot of overkill to have a flag menu specific for a dialog header.
//       However, after having a lot of decision statements in a generic flag menu component, 
//       I have decided to split it to keep it as simple as possible.
//       This component just renders a basic button and defers setting the value to the parent.
//  
//
// Usage: 
/// import FlagMenu from "@controls/menu/DialogHeaderFlagMenu.vue";        
//  <FlagMenu :model="model" v-model="model.flag" @input="(v)=>setValue(v)"></FlagMenu>

    const props = defineProps({
        disabled: {
            type: [Boolean]
        },
        value: {
            type: [String, Number]
        },
        action: {
            type: [String]
        },
        model: {
            type: [Object]
        },

    })

    const emit = defineEmits(['input'])    
    const dvalue = computed({
        get() {
            return props.value
        },
        set: function(v) {
            emit('input', v)
        }	
    })
    function setValue (value) {
        dvalue.value = value;
    }

    /**
     * A flag for a model is not disabled based on the model but by authorization 
     * on the action to modify the flag. 
     * That is because the data is independent of the model. It is just a visual indicator.
     * Therefore, whether the model is disabled or not, it is not of interest for us.
     */
    const compDisabled = computed({
        get() {
            if (props.disabled) {
                return true;
            }
            if (props.action) {
                return !action.can(props.action);
            }
            return false;
        }
    });
    let flags = [
        {id: 0, name: 'empty'   , color:"default"},
        {id: 1, name: '1'       , color:"error"},
        {id: 2, name: '2'       , color:"primary"},
        {id: 3, name: '3'       , color:"success"},
        {id: 4, name: '4'       , color:"warning"},
    ];

    const activeId = computed({
        get() {
            let flag = flags.find ( (f) => Number(f.id) == Number(dvalue.value));
            if (!flag) {
                flag = flags[0];
            }
            return flag.id;   
        }
    });


</script>
