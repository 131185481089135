import clsApi from '@cls/clsApi'

class clsDataApi extends clsApi {

    async loadSetting(modelName) {
        var url = `loadsetting/${modelName}`;
        return this.get(url);
    }

    async saveSetting(modelName, data) {
        var url = `savesetting/${modelName}`;
        let payload = {model: data};
        return this.post(url, payload);
    }

    async loadList(modelName) {
        var url = `list/${modelName}`;
        return this.get(url);
    }
    async loadSettings(modelName) {
        var url = `settings/${modelName}`;
        return this.get(url);
    }

}

export default clsDataApi;
