import { clsModel, fnCreate } from '@cls/clsModel'
import { tenders as api } from '@/app/api'
import Constants from '@app/consts'

// We operate on a sales invoice, no special model name for sending an invoice. 
var modelName = "tender_send";
const fields = ['id', 'subject', 'body', 'to', 'cc', 'recipients', 'address', 'attachments', 'allattachments', 'mergeattachments', 'tend_number', 'id_optimit_type'];

class clsTenderSend extends clsModel {

    
    email_type = null; // Constants.email.types.[ID_EMAIL_TYPE_TENDER|ID_EMAIL_TYPE_TENDER_CONFIRMATION]

    subject = null;
    body = null;
    to = null;
    cc = null;
    recipients = [];    
    attachments = [];
    allattachments = [];
    tend_number = null;
    digital = true;
    address = [];
    id_optimit_type = null;
 
    mergeattachments = false;

    attach(attachment) {
        this.attachments.push(attachment);
    }
    unattach(attachment) {
        this.attachments = this.attachments.filter( (item) => item != attachment);
    }
    async refreshAttachments() {
        this.isDataLoading = true;
        try {
            var currentAttachments = this.allattachments.map( (x) => x.id);
            
            var result = await this.api.loadSendData(this.id);
            this.allattachments = result.data.allattachments ||[];
            this.allattachments.forEach( (att) => {
                if (!currentAttachments.find ( (x) => x == att.id)) {
                    // new attachment
                    this.attachments.push(att);
                }
            })
        }
        finally {
            this.isDataLoading = false;
        }
    }
     
    apiLoad(id, params) {
        if (this.email_type == Constants.email.types.ID_EMAIL_TYPE_TENDER) {
            return this.api.loadSendData(id, params);

        } else if (this.email_type == Constants.email.types.ID_EMAIL_TYPE_TENDER_CONFIRMATION)  {
            return this.api.loadConfirmationSendData(id, params);

        }
        
        throw "Unknown tender send type.";
    }
    async apiSave(model) {
        if (this.email_type == Constants.email.types.ID_EMAIL_TYPE_TENDER) {
            if (!this.digital) {
                await this.api.downloadSend(this.id);        
                return { id: this.id} // The data is downloaded to the client. 
                                 // We don't want the downloaded data to be sent as followup event parameters.
            }
            return this.api.emailSend(model);
        } else if (this.email_type == Constants.email.types.ID_EMAIL_TYPE_TENDER_CONFIRMATION)  {
            if (!this.digital) {
                await this.api.downloadConfirmationSend(this.id);        
                return { id: this.id} // The data is downloaded to the client. 
                                 // We don't want the downloaded data to be sent as followup event parameters.
            }
            return this.api.emailConfirmationSend(model);

        }
        throw "Unknown tender send type.";
    }

    /**
     * Get the main title. Depends on whether we send a tender or a tender confirmation.
     */
    get title() {
        if (this.email_type == Constants.email.types.ID_EMAIL_TYPE_TENDER) {
            return "Verstuur offerte";
        }
        return "Verstuur opdrachtbevestiging";
    }
    
    async onBeforeOpen(params, extraData) {
        params = params ||{}
        this.digital = true;
        this.email_type = params.email_type || Constants.email.types.ID_EMAIL_TYPE_TENDER;

        if (params && params.id) {
            await api.getWarnings({id: params.id}, "getsendwarnings");             
        }    
    }

    /**
     * Override this method to handle default notification or to not notify
     * @param {*} resultData 
     */
    onSaveNotification(resultData, msg) {
        msg = this.digital ? "De offerte is verzonden" : "De offerte is gedownload";
        super.onSaveNotification(resultData, msg);
    }

    /**
     * When the invoice is sent for the first time, statistics need to be refreshed.
     */
    onAfterSave(resultData) {
        if (!this.tend_number) {
            this.sendStatsRefreshEvent();   
        }
    }

    fill(data) {
        data = data || {};
        data.digital = true;
        data.attachments = data.attachments ||[];
        data.allattachments = data.allattachments ||[];
        data.address = data.address || [];

        this.digital = true;        
        this.mergeattachments = false;

        return super.fill(data);
    }
    
    toJSON() {        

        var result = this.propsToJSON(['id', 'subject', 'body', 'to', 'cc', 'attachments', 'mergeattachments']);    
        result.attachments = this.attachments.map( (x) => x.id);
        return result;
    }

    constructor() {
        super({
          api: api,   
          modelName: modelName, 
          mandatoryFields: ['subject', 'body'],

          fillable: fields
        })
    } 
        
 }
 export default fnCreate(clsTenderSend, 'clsTenderSend');
