import { clsModel, fnCreate } from '@cls/clsModel'
import { relations as api } from '@/app/api'
import Constants from '@app/consts'
import eventbus from '@app/eventbus'
import bool from '@lib/bool'
import action from '@app/action'
import {salesinvoice as salesSettings, tender as tenderSettings} from '@app/settings';

var modelName = "relation";

// Required when working with notes / attachments
const id_optimit_type = Constants.optimit_types.relation;

var id_language_default = Constants.defaults.id_language;

const fields = [
    "id", 
    "rel_name", 
    "rel_name_short", 
    "rel_code", 
    "rel_coc_number", 
    "rel_vat_number", 
    "rel_credit_payment_terms_days", 
    "rel_credit_incasso", 
    "rel_nr", 
    "rel_email", 
    "rel_phone", 
    "rel_mobile", 
    "rel_website", 
    "rel_tender_terms_days", 
    "rel_payment_terms_days", 
    "id_language", 
    "crnc_code", 
    "id_credit_restriction", 
    "rel_use_g_account", 
    "rel_g_account_perc_calculate_wage", 
    "rel_g_account_perc", 
    "rel_comment", 
    "rel_email_invoice", 
    "rel_email_reminder", 
    "rel_include_ubl", 
    "rel_oin", 
    "adr_street", 
    "adr_street_number", 
    "adr_zipcode", 
    "adr_city", 
    "country_code",     
    "rel_vat_shifted",
    "use_in_purchase",
    "use_in_sales",
    "use_invoice_payment_link",

    "use_tender_company_terms_days",       // This one is deferred from the rel_tender_terms_days being filled or not.
    "use_sales_company_payment_terms_days" // This one is deferred from the rel_payment_terms_days being filled or not.
    // do not auto-sync bankaccounts, therefore, bankaccounts is handled in code.
];

class clsRelation extends clsModel {

    id                                = null;
    rel_name                          = null;
    rel_name_short                    = null;
    rel_code                          = null;
    rel_coc_number                    = null;
    rel_vat_number                    = null;
    rel_credit_payment_terms_days     = null;
    rel_credit_incasso                = null;
    rel_nr                            = null;
    rel_email                         = null;
    rel_phone                         = null;
    rel_mobile                        = null;
    rel_website                       = null;
    rel_tender_terms_days             = null;
    rel_payment_terms_days            = null;
    id_language                       = null;
    crnc_code                         = null;
    id_credit_restriction             = null;
    rel_use_g_account                 = null;
    rel_g_account_perc_calculate_wage = null;
    rel_g_account_perc                = null;
    rel_comment                       = null;
    rel_email_invoice                 = null;
    rel_email_reminder                = null;
    rel_include_ubl                   = null;
    rel_oin                           = null;
    adr_street                        = null;
    adr_street_number                 = null;
    adr_zipcode                       = null;
    adr_city                          = null;
    country_code                      = null;
    rel_vat_shifted                   = null;
    use_in_purchase                   = true;
    use_in_sales                      = true;
    use_invoice_payment_link          = true;
    bankaccounts                      = [];
    
    _use_sales_company_payment_terms_days = false;
    _use_tender_company_terms_days = false;
    /**
     * The default payment ter from the company settings.
     */
    get defaultPaymentTerm() {
        return salesSettings.payment_duedays || 30;
    }
    get use_sales_company_payment_terms_days() {
        return this._use_sales_company_payment_terms_days;
    }
    set use_sales_company_payment_terms_days(v) {
        this._use_sales_company_payment_terms_days = v;
        if (!this.isFilling) {
            this.rel_payment_terms_days = (!!v) ? null : 30;
        }     
    }
    get use_tender_company_terms_days() {
        return this._use_tender_company_terms_days;
    }
    set use_tender_company_terms_days(v) {
        this._use_tender_company_terms_days = v;
        if (!this.isFilling) {
            this.rel_tender_terms_days = (!!v) ? null : 30;
        }     
    }

    constructor() {
        super({
          api: api,   
          modelName: modelName, 
          id_optimit_type: id_optimit_type, 
          mandatoryFields: ['rel_name'], // TODO

          fillable: fields

        })
    } 

    get modelRep() {
        return this.rel_name;
    }

    // Can the current user modify settings?
    get canModifySettings() {
        return action.can('relation_settings.modify');
    }
    /**
     * Remove the bank account with the given ID.
     * @param {} id 
     */
    async removeBankAccount(id) {
        this.isDataLoading = true;
        try {
            let {data} = await api.removeBankAccount(id);
            this.bankaccounts = data.bankaccounts;
            this.sendSavedEvent();    
        } finally {
            this.isDataLoading = false;
        }
    }

    /**
     * For new relation, 
     * @param {*} defaults 
     * @returns 
     */
    async doCreate(defaults) {
        defaults = defaults ||{};
        defaults.use_in_purchase                   = true;
        defaults.use_in_sales                      = true;
        defaults.use_invoice_payment_link          = true;
        
        defaults.use_sales_company_payment_terms_days = true;

        return super.doCreate(defaults);
    }

    /**
     * Import KVK data in our relation 
     * @param {*} data 
     * @returns 
     */
    fromKvk(data) {
        if (!data) {
            return null;
        }
        this.rel_name          = data.name
        this.rel_name_short    = data.name
        this.rel_coc_number    = data.kvk
        this.rel_website       = data.website
        this.adr_street        = data.street
        this.adr_street_number = data.houseNumber
        this.adr_zipcode       = data.zipcode
        this.adr_city          = data.city
        // Dutch KVK only returns NL
        // ... as per 2024-07-29, also foreign addresses are returned. 
        // Unfortunately, no country code is provided. 
        // Therefore, the country code can be empty, in which case the user must fill it.
        this.country_code      = data.cntrCode;    
    }
  
    /**
     * Fill the data 
     * 
     * @param {*} data 
     */
    fill(data) {
        data = data || {};
        data.rel_credit_payment_terms_days     = data.rel_credit_payment_terms_days || 30;
        data.rel_tender_terms_days             = data.rel_tender_terms_days || 0;
        data.id_language                       = data.id_language || id_language_default;
        data.crnc_code                         = data.crnc_code || "EUR";
        data.rel_g_account_perc_calculate_wage = data.rel_g_account_perc_calculate_wage || 0;
        data.rel_g_account_perc                = data.rel_g_account_perc || 0;
        data.country_code                      = data.country_code || 'NL';
        data.rel_vat_shifted                   = !!data.rel_vat_shifted;
                
        data.use_in_purchase                   = bool.isTrue(data.use_in_purchase);
        data.use_in_sales                      = bool.isTrue(data.use_in_sales);
        data.use_invoice_payment_link          = bool.isTrue(data.use_invoice_payment_link);

        // No payment terms days filled --> use defaults from sales settings.
        data.use_sales_company_payment_terms_days = !data.rel_payment_terms_days;
        data.rel_payment_terms_days               = data.rel_payment_terms_days || null;

        // No tender terms days filled --> use defaults from tender settings.
        data.use_tender_company_terms_days = !data.rel_tender_terms_days;
        data.rel_tender_terms_days         = data.rel_tender_terms_days || null;

        super.fill(data);

        this.bankaccounts = data.bankaccounts ||[];
    }        

    /**
     * Get the data for saving. Note that when we re-use the company sales settings, we reset the payment days. 
     * @returns 
     */
    toJSON() {
        var data = super.toJSON();
        if (this.use_sales_company_payment_terms_days) {
            data.rel_payment_terms_days = null;
        }
        if (this.use_tender_company_terms_days) {
            data.rel_tender_terms_days = null;
        }
        return data;
    }

    registerEvents() {
        super.registerEvents();
        eventbus.model.saved.on( (type, para) => {
            if (type != 'relation_bankaccount') {
                return;
            }
            if (!para) {
                return;
            } 
            if (para.id_relation != this.id) {
                return;
            }
            if (undefined === para.bankaccounts) {
                return;
            }
            this.bankaccounts = para.bankaccounts;
            // Do not send the relation saved event. There is no use in this.
            // The lists will update already when a bank account saved event is received.            
            // Actually, 
//            this.sendSavedEvent(); // Pas further to my subscribers.
        });

    }

 }
 export default fnCreate(clsRelation , 'clsRelation');
