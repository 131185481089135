<template>    
    <v-chip small xlabel outlined class="status" :class="cls">
        <span class="prefix" v-if="$slots.prefix"><slot name="prefix"></slot></span>
        <slot>a</slot>
    </v-chip>  
</template>

<script setup>

// A statuschip is used to show a particular status for a component. 
// A couple of statuses are predefined qua visual representation.
// Predefined: 
//        status="success"
//        status="error"
//        status="warning"
// 
// Classes are added depending on the type and status added.
//
// Usage: 
// import StatusChip from "@controls/widgets/StatusChip.vue";
//   <StatusChip type="invoice"></StatusChip>
//   ==> Adds the classes: "type-invoice"
//
//   <StatusChip status="new"></StatusChip>
//   ==> Adds the classes: "status-new"
//
//   <StatusChip type="pi" status="new"></StatusChip>
//   ==> Adds the classes: "type-pi status-new status-pi-new"
//
// Substatus
//   Some entities have substatuses. 
//   For example, an expired invoice. 
//   The 'real' status is is 'Open'. 
//   However, depending on if the invoice is reminded, and what reminder is sent, the substatus can be: 
//   - expired
//   - reminder1
//   - reminder2
//   - defaultnotice
//
//  This translates in classes, for example: 
//  class="salesinvoice status-open reminder1"
// 
    import {computed, ref} from 'vue'
    import string from '@lib/string'

    const props = defineProps({
        status: {
            type: [String, Number]
        },
        substatus: {
            type: [String]
        },
        type: {
            type: [String]
        },
        autoWidth: {
            type: [Boolean]
        }
    })


    const cls = computed({
        get() {
            let clsType = "";
            let clsStatus = "";
            let clsSubStatus = "";
            let clsAutowidth = "";
            if (!!props.autoWidth) {
                clsAutowidth = "auto-width"; 
            }
            
            if (string.isNotEmpty(props.type)) {
                clsType = `type-${props.type}`; 
            }
            if (string.isNotEmpty(props.status)) {
                clsStatus = `status-${props.status}`; 
            }
            if (string.isNotEmpty(props.substatus)) {
                clsSubStatus = `${props.substatus}`; 
            }
            let str = `${clsType} ${clsStatus} ${clsSubStatus} ${clsAutowidth}`;
            return string.lower(str);
        },
    })

</script>