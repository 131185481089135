import clsApi from '@cls/clsApi'

class clsDashboardApi extends clsApi {

    /**
     * 
     * @param {*} name 
     * @returns 
     */
    async loadWidgetData() {
        return this.get(`loadwidgetdata`);         
    }

}

export default clsDashboardApi;
