<template>

    <div>
        <v-row dense>
            <v-col sm="12" lg="6">

                <Card page-section class="" v-if="model.use_in_sales" icon="invoice" title="Verkoopfacturen">
                    <FormRow class-label="v-top" col-label="6"  label="Betaaltermijn">                                    
                        <Radiogroup :model="model" v-model="model.use_sales_company_payment_terms_days">
                            <v-row dense>
                                <v-col>
                                    <Radio :disabled="!model.canModifySettings" :model="model" :value="true" :label="`Standaard (${model.defaultPaymentTerm} dagen)`"/>
                                </v-col>
                            </v-row>
                            <v-row dense>
                                <v-col>
                                    <v-row dense>
                                        <v-col sm="auto">
                                            <Radio :disabled="!model.canModifySettings"  :model="model" :value="false" />
                                        </v-col>
                                        <v-col>
                                            <NumberField :disabled="(model.use_sales_company_payment_terms_days)||!model.canModifySettings" min="0" max="100" 
                                            :suffix="model.use_sales_company_payment_terms_days?'':'dagen'" integer :model="model" :rules="model.rules.rel_payment_terms_days"  
                                            v-model="model.rel_payment_terms_days"></NumberField>                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>                            
                        </Radiogroup>
                    </FormRow>

                    <FormRow col-label="6"  label="Factuur taal">            
                        <LanguageCombo :disabled="!model.canModifySettings" :model="model" :rules="model.rules.id_language" v-model="model.id_language"></LanguageCombo>
                    </FormRow>
                    <FormRow col-label="6" v-if="feature.canMolliePayment" label="Stuur betaal-link mee">            
                        <Checkbox switcher :disabled="model.isOpenOrLater" :model="model" v-model="model.use_invoice_payment_link"></Checkbox>
                        <span style="position:relative; top:-5px;" class="text-italic">op pdf en in factuur e-mail</span>
                    </FormRow>
                    <FormRow col-label="6" label="Standaard BTW Verlegd">            
                        <Checkbox :model="model" :disabled="!model.canModifySettings" class="" switcher v-model="model.rel_vat_shifted"></Checkbox>
                    </FormRow>
                    <v-divider class="my-4"></v-divider>
                    <FormRow col-label="6" label="Gebruik G-rekening" >            
                        <v-row dense>
                            <v-col>
                                <Checkbox :model="model" :disabled="!model.canModifySettings" switcher v-model="model.rel_use_g_account"></Checkbox>
                            </v-col>
                            <v-col sm="auto" class="text-right">
                                <v-menu offset-y bottom>
                                    <template v-slot:activator="{ on }">
                                        <span class="text-primary cursor-pointer" v-on="on">Voorbeeld</span>
                                    </template>
                                    <v-card>
                                        <v-card-text>
                                            <div class="text-bold mb-1">Specifieer standaard een G-bedrag voor deze relatie</div>
                                            De instellingen worden volgens onderstaand rekenvoorbeeld<br>
                                            overgenomen in een verkoopfactuur waar u deze relatie gebuikt.<br>
                                            U kunt de gegevens in de factuur indien gewenst verder aanpassen.
                                            <v-divider class="my-2"></v-divider>
                                            <table dense>
                                                <thead><tr>
                                                    <th class="text-left">Relatie instellingen</th><th><th class="w-1"></th><th class="text-left">Factuur</th><th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td></td><td></td>
                                                        <td></td>
                                                        <td class="text-bold">Factuur bedrag</td><td class="pl-2">€ 1000,00</td>
                                                    </tr>
                                                    <tr><td colspan="5">&nbsp;</td></tr>
                                                    <tr>
                                                        <td>Loondeel percentage</td><td class="pl-2">50%</td>
                                                        <td></td>
                                                        <td class="text-italic text-small">Loondeel: 50% x 1000 = € 500,00</td><td></td>
                                                    </tr>
                                                    <tr>
                                                        <td>G-percentage</td><td class="pl-2">25%</td>
                                                        <td></td>
                                                        <td colspan="2" class="text-italic text-small">G-bedrag: 25% x 500 = € 125,00</td>
                                                    </tr>
                                                    <tr><td colspan="5">&nbsp;</td></tr>
                                                    <tr>
                                                        <td></td><td></td>
                                                        <td></td>
                                                        <td class="text-bold">Naar G-rekening</td><td class="pl-2">€ 125,00</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </v-card-text>
                                    </v-card>
                                </v-menu>
                            </v-col>
                        </v-row>
                    </FormRow>
                    <FormRow col-label="6"  label="Loondeel percentage">            
                        <NumberField :disabled="!model.canModifySettings" percentage :model="model" :rules="model.rules.rel_g_account_perc_calculate_wage"  v-model="model.rel_g_account_perc_calculate_wage"></NumberField>
                    </FormRow>
                    <FormRow col-label="6"  label="G-percentage" >            
                        <NumberField :disabled="!model.canModifySettings" percentage  :model="model" :rules="model.rules.rel_g_account_perc"  v-model="model.rel_g_account_perc"></NumberField>
                    </FormRow>
                    <v-divider class="my-4"></v-divider>
                    <FormRow col-label="6" label="Stuur E-Factuur mee (UBL)">            
                        <Checkbox :model="model" :disabled="!model.canModifySettings" switcher v-model="model.rel_include_ubl"></Checkbox>
                    </FormRow>
                    <FormRow col-label="6" label="OIN (e-factuur)">            
                        <TextField :model="model" :disabled="!model.canModifySettings" max-length="30" :rules="model.rules.rel_oin"  placeholder="Organisatie ID nummer" v-model="model.rel_oin"></TextField>
                    </FormRow>
                </Card>

            </v-col>

            <v-col sm="12" lg="6" class="pl-2">

                <Card page-section class="" v-if="model.use_in_purchase" icon="purchase" title="Inkoopfacturen">
                    <FormRow col-label="6"  label="Betaaltermijn">            
                        <NumberField :disabled="!model.canModifySettings" min="0" max="100" suffix="dagen" integer :model="model" :rules="model.rules.rel_credit_payment_terms_days"  v-model="model.rel_credit_payment_terms_days"></NumberField>
                    </FormRow>

                    <FormRow col-label="6"  label="Betaalconditie" >            
                        <CreditrestrictionCombo :disabled="!model.canModifySettings" :model="model" :rules="model.rules.id_credit_restriction"  v-model="model.id_credit_restriction"></CreditrestrictionCombo>
                    </FormRow>

                    <FormRow col-label="9" :col-value-right="!false"  label="Standaard automatische incasso">            
                        <Checkbox :model="model" :disabled="!model.canModifySettings" class="d-inline-block width-auto" switcher v-model="model.rel_credit_incasso"></Checkbox>
                    </FormRow>
                </Card>

                <Card page-section class="mt-4" v-if="model.use_in_sales" icon="tender" title="Offertes">

                    <FormRow class-label="v-top" col-label="6"  label="Offertetermijn">                                    
                        <Radiogroup :model="model" v-model="model.use_tender_company_terms_days">
                            <v-row dense>
                                <v-col>
                                    <Radio :disabled="!model.canModifySettings" :model="model" :value="true" label="Gebruik termijn van offertegroep"/>
                                </v-col>
                            </v-row>
                            <v-row dense>
                                <v-col>
                                    <v-row dense>
                                        <v-col sm="auto">
                                            <Radio :disabled="!model.canModifySettings" :model="model" :value="false" />
                                        </v-col>
                                        <v-col>
                                            <NumberField :disabled="(model.use_tender_company_terms_days)||!model.canModifySettings" min="0" max="100" 
                                            :suffix="model.use_tender_company_terms_days?'':'dagen'"                                            
                                            integer :model="model" :rules="model.rules.rel_payment_terms_days"  
                                            v-model="model.rel_tender_terms_days"></NumberField>                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>                            
                        </Radiogroup>
                    </FormRow>
                </Card>

            </v-col>

        </v-row>

    </div>
</template>


<script setup>
    import Icon from '@controls/icons/Icon'
    import Form from '@controls/forms/Form'
    import FormColumn from '@controls/forms/FormColumn'
    import FormRow from '@controls/forms/FormRow'
    import TextField from '@controls/input/TextField'
    import LanguageCombo from '@controls/combo/Language'
    import CreditrestrictionCombo from '@controls/combo/Creditrestriction'
    import NumberField from '@controls/input/NumberField'
    import Checkbox from "@controls/check/Checkbox.vue";
    import CardTitle from '@controls/card/CardTitle'
    import Card from '@controls/card/Card'
    import {computed} from "vue";
    import feature from "@app/features";
    import Radiogroup from "@controls/check/Radiogroup.vue";
    import Radio from "@controls/check/Radio.vue";

    /*
        this.rel_tender_terms_days             = data.rel_tender_terms_days;
        this.crnc_code                         = data.crnc_code;
        this.id_credit_restriction             = data.id_credit_restriction;
*/

    const props = defineProps({
        model: {
            type: [Object]
        },
    });


    const cb = computed({
        get() {
            return props.model.rel_vat_shifted
        },
    })

</script>
