import clsApi from '@cls/clsApi'

class clsSessionApi extends clsApi {

    /**
     * 
     * @param {*} name 
     * @returns 
     */
    async setUserPersonName(name, password) {
//        return this.put(`user/profile-information`, {name: name}); 
        return this.post(`api/user/updatepersonname`, {name: name, password: password});         
    }

}

export default clsSessionApi;
