import routes from '@/app/routes.2'

/**
 * This component handles the routes
 * The purpose of this class is to manage the routes, and, when applicable to the application, 
 * handle route specific authorisation. 
 * 
 * To implement user specific authorisation decisions, override method isUserAuthorizedFor.  
 *
 * Note that the routes are defined in the application @/app/routes.2.js file. 
 * Note also that the actual VUE router calls the isAuthorized method.
 * 
 */
 class clsRouting {
 
    routes = {};
    vueRouter = null;

    /**
      * Set the isAuthorized flag in all available meta data 
      */
    constructor() {        
        this.routes = routes;
    }

    /**
     * Set the core instance of the router. 
     * 
     * @param {*} router 
     */
    setVueRouter(router) {
        this.vueRouter = router;
    }

    /**
     * Get the route via the route or via the name.
     * Note that the route configuration makes use of child objects. 
     * As the vue router already processed the route tree and makes it 1 dimensionally available for us, 
     * we re-use the vue routers functionallity to find the named route. 
     * 
     * @param {} route 
     */
    _route(route) {
        if (typeof(route) == "string") {
            if (!this.vueRouter) {
                throw "The vueRouter is not set in the routing object";
            }
            var resolvedRoute = this.vueRouter.getRoutes().find( (x) => x.name == route)
            if (!resolvedRoute) {
                throw `Route ${route} is not defined`;
            }
            route = resolvedRoute;
        }
        return route;
    }

    /**
     * Navigate to the given route
     * @param {*} route 
     */
    navigateTo(route, params) {
        if (!this.vueRouter) {
            throw "The vueRouter is not set in the routing object";
        }
        route = this.to(route, params);
        this.vueRouter.push(route);    
    }
    
    /**
     * Get the current route. Again, use the router for this.
     * As such, the route as returned by the vue router is returned. 
     * ON PITFALL: it does not include the route name!
     * @returns 
     */
    getCurrentRoute() {
        if (!this.vueRouter) {
            throw "The vueRouter is not set in the routing object";
        }        
        return this.vueRouter.currentRoute;
    }






    /**
     * Override to implement other criteria which define whether a user should be logged in for the given route 
     * 
     * 
     * @param {*} route 
     * @returns 
     */
    shouldUserBeLoggedInFor(route) {
        // By default, unless requiresAuth is explicitly set to false, the user should be logged in.
        if (route && route.meta && (route.meta.requiresAuth === false)) {
            return false;
        }
        return true;
    }
    
    /**
     * Override to define the rule for whether a user is authorized for the given route.
     * 
     * @param {*} route. Can be a route object or a route name (string) 
     * @returns 
     */
    isUserAuthorizedFor(route) {
        return this.hasRequiredModule(route) && this.hasRequiredRight(route);
    }

    /**
     * Override when access to routes must be restricted by modules. This is only for optimit, not for admin. 
     * @param {*} route 
     * @returns 
     */
    hasRequiredModule(route) { 
        return true; 
    } 
    
    /**
     * Override when access to routes must be restricted by modules. This is only for optimit, not for admin. 
     * @param {*} route 
     * @returns 
     */
    hasRequiredRight(route) {
        return true;
    }




    /**
     * When the route is coupled to an action, return the action.
     * @param {*} route 
     * @returns 
     */
    actionFor(route) {
        // Only when the vueRouter is already set, use it to get the action.
        if (this.vueRouter) {
            route = this._route(route);
        }
        return route?.meta?.action;
    }

    /**
     * Return the 'to' clause which can be used in routing components. 
     * For example: 
     *  <v-list-item :to="compTo" exact :class="{'opacity-50': compDisabled}" :title="compTitle" @click="onClick">  
     * 
     * @param {*} name 
     */
    to(name, params) {
        let to = {
            name: name
        };
        if (params) {
            to.params = params;
        }
        return to;
    }

    /**
     * Get an icon to use for this route
     * @param {*} route - either a route object or a name. 
     */
    iconFor(route) {
        route = route || this.getCurrentRoute();
        route = this._route(route);

        return route && route.meta && route.meta.menu && route.meta.menu.icon;
    }

    /**
     * Get an text to use for this route
     * @param {*} route - either a route object or a name. 
     */
    textFor(route, preferShortText) {
        route = route || this.getCurrentRoute();
        route = this._route(route);
        
        let menu = route && route.meta && route.meta.menu;
        if (preferShortText) {
            return menu.short || menu.text;
        }
        return menu.text;
    }
    /**
     * Get an path for the named route or the current route
     * @param {*} route - either a route object or a name. 
     */
    pathFor(route) {
        route = route || this.getCurrentRoute();
        route = this._route(route);
        return route.path;
    }
    /**
     * Get the name for the named route or the current route
     * @param {*} route - either a route object or a name. 
     */
    nameFor(route) {
        route = route || this.getCurrentRoute();
        route = this._route(route);
        return route.name;
    }

    /**
     * For the current route, return the text of the parent. 
     * Use case: 
     *    parent: route 'purchase' with meta.menu.text == 'Inkoopfacturen'
     *    children: [Goedkeuren, archief, etcetera]
     * In the submenu header, the parent text is to be displayed.
     * 
     * Note that when no parent is available, the text of the current route is returned.
     * 
     * @param {*} route - either a route object or a name. 
     */
    currentParentText() {
        let route = this.getCurrentRoute();
        let text = route.meta.menu.text;

        if (route.matched && route.matched.length > 1) {            // [0] - authorized items
            var parent = route.matched[route.matched.length-2];     // [1] - inkoopfacturen,    [2] - goedkeuren
            if (parent && parent.meta && parent.meta.menu && parent.meta.menu.text) {
                text = parent.meta.menu.text || text; 
            }
        } 
        return text;
    }

    /**
     * Get the routes
     * @returns 
     */
    getRoutes() {
        return this.routes;
    }

 }
 
 export default clsRouting;