import user from '@app/user'
import { clsModel, fnCreate } from '@cls/clsModel'
import noty from '@shared/lib/noty'

var modelName = "changeusername";
const fields = ['password', 'email'];

class clsChangeUsername extends clsModel {

    password= '';
    email= '';

    async save() {
        var result = await user.updateUsername(this.email, this.password);
        if (!result.success) {
            noty.snack.error(result.message);
        } else {
            noty.snack.info(result.message);
        }
        return result.success;
    }
    async load() {
        this.password= '';
        this.email= user.email;
    }    
    constructor() {
        super({          
          modelName: modelName, 
          fillable: fields
        })
    } 
}

export default fnCreate(clsChangeUsername , 'clsChangeUsername');
