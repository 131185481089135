import {numeric, percentage} from '@lib/numeric'

// Extra work statuses. A fixed list.
const ST_SUBMITTED = 1
const ST_ACCEPTED = 2
const ST_REJECTED = 3

// For a finished line, calculate: 
// - the percentage
// - the expected amount
function fnFinishLine(line) {
    line.pct = percentage.from((line.budget||0), (line.invoiced||0)+(line.pipeline||0));
    line.expected = (line.budget||0) - (line.invoiced||0) - (line.pipeline||0);
    line.total_invoiced = (line.invoiced||0) + (line.pipeline||0);
    return line;
}

/**
 * Group by subtype. 
 * In: 'c', 'Inkoopkosten', 
 *    [
 *       {type: 'c', subtype: '1', id_chapter: 0, name: 'Tegelwerk', budget: 100, invoiced: 0, pipeline: 0},
 *       {type: 'c', subtype: '1', id_chapter: 1, name: 'Tegelwerk', budget: 100, invoiced: 50, pipeline: 50},
 *       {type: 'c', subtype: '2', id_chapter: 1, name: 'Voegwerk',  budget: 300, invoiced: 200, pipeline: 0},
 *    ] 
 * Out:  (assuming all chapters are selected)
 *    [
 *       {type: 'c', name: 'Inkoop', budget: 500, invoiced: 250, pipeline: 50},
 *       {type: 'c', subtype: '1', name: 'Tegelwerk', budget: 200, invoiced: 50, pipeline: 50},
 *       {type: 'c', subtype: '2', name: 'Voegwerk',  budget: 300, invoiced: 200, pipeline: 0},
 *    ]
 */
function groupBySubType(type, groupName, lines, totalOnly, expandByModelProperty, excludeSubTypesInTotal) {
    var map = {};
    var totalLine = { expandByModelProperty:expandByModelProperty, type: type, name: groupName, budget: 0, invoiced: 0, pipeline: 0 };
    (lines||[]).forEach( (line) => {
        var key = `${line.name}${line.subtype}`;
        if (!map[key]) {
            map[key] = { expandByModelProperty: expandByModelProperty, type: line.type, subtype: line.subtype, name: line.name, budget: 0, invoiced: 0, pipeline: 0 };
        } 
        if (! (excludeSubTypesInTotal||[]).find( (x) => x === line.subtype)) {
            totalLine.budget   += (line.budget||0); 
            totalLine.invoiced += (line.invoiced||0); 
            totalLine.pipeline += (line.pipeline||0);     
        }

        map[key].budget   += (line.budget||0); 
        map[key].invoiced += (line.invoiced||0); 
        map[key].pipeline += (line.pipeline||0); 
    });
    var result = [];
    result.push(fnFinishLine(totalLine));
    if (!totalOnly) {
        for(var key in map) {
            result.push(fnFinishLine(map[key]));
        }    
    }
    return result;
}
function getPurchaseLines(idActiveChapter, result, project) {
    var type = 'c';
    var lines = result.filter( (line) => (line.type == type && ('a' == idActiveChapter || line.id_chapter == idActiveChapter) )); 
    return groupBySubType(type, 'Inkoopkosten', lines, false, 'resultPurchaseExpanded');     
}
function getHourLines(idActiveChapter, result, project) {
    var type = 'h';
    var lines = result.filter( (line) => (line.type == type && ('a' == idActiveChapter || line.id_chapter == idActiveChapter) ));    
    return groupBySubType(type, 'Uren', lines, false, 'resultHoursExpanded');     
}
function getOtherCostLines(idActiveChapter, result, project) {
    var type = 'o';
    var lines = result.filter( (line) => (line.type == type && ('a' == idActiveChapter || line.id_chapter == idActiveChapter) ));    
    return groupBySubType(type, 'Overige kosten', lines, true /* No need for grouping on subtype */);     
}
function getRevenue(idActiveChapter, result, project) {
    var type = 'r';
    var lines = result.filter( (line) => (line.type == type && ('a' == idActiveChapter || line.id_chapter == idActiveChapter) ));    
    return groupBySubType(type, 'Aanneemsom', lines, true);     
}
function getExtraWork(idActiveChapter, result, project) {
    var type = 'e';
    var lines = result.filter( (line) => (line.type == type && ('a' == idActiveChapter || line.id_chapter == idActiveChapter) ));    
    var excludeSubtypes = [];
    if (!project.resultIncludeExtraWorkSubmitted) excludeSubtypes.push(ST_SUBMITTED);
    if (!project.resultIncludeExtraWorkAccepted) excludeSubtypes.push(ST_ACCEPTED);
    if (!project.resultIncludeExtraWorkRejected) excludeSubtypes.push(ST_REJECTED);    
    return groupBySubType(type, 'Meerwerk', lines, false, 'resultExtraWorkExpanded' ,excludeSubtypes );     
}
function getLessWork(idActiveChapter, result, project) {
    var type = 'l';
    var lines = result.filter( (line) => (line.type == type && ('a' == idActiveChapter || line.id_chapter == idActiveChapter) ));    
    var excludeSubtypes = [];
    if (!project.resultIncludeLessWorkSubmitted) excludeSubtypes.push(ST_SUBMITTED);
    if (!project.resultIncludeLessWorkAccepted) excludeSubtypes.push(ST_ACCEPTED);
    if (!project.resultIncludeLessWorkRejected) excludeSubtypes.push(ST_REJECTED);    
    return groupBySubType(type, 'Minderwerk', lines, false, 'resultLessWorkExpanded',excludeSubtypes);     
}

    // In: result: 
    //    "result": [
    //        { "type": "r", "grp": "Opbrengst",  "name": "Facturen",        "budget": 11000,  "invoiced": 10000    , outstanding: 1000},
    //        { "type": "r", "grp": "Opbrengst",  "name": "Meerwerk",        "budget": 3500,   "invoiced": 2300     , outstanding: 1200},
    //        { "type": "r", "grp": "Opbrengst",  "name": "Minderwerk",      "budget": 1299,  "invoiced": 0        , outstanding: -1299},
    //        { "type": "c", "grp": "Kosten",     "name": "Inkoop algemeen", "budget": 0,      "invoiced": 5890.85 , outstanding: 0},
    //        { "type": "c", "grp": "Kosten",     "name": "Omkoopkosten",    "budget": 0,      "invoiced": 50      , outstanding: 0},
    //        { "type": "c", "grp": "Kosten",     "name": "Zichtwerk",       "budget": 2122,  "invoiced": 50      , outstanding: 2072},
    //        { "type": "c", "grp": "Uren",       "name": "Werk",            "budget": 5400,  "invoiced": 6270    , outstanding: 0},
    //        { "type": "c", "grp": "Uren",       "name": "Scholing",        "budget": 2100,  "invoiced": 0        , outstanding: 2100},
    //    ]
function fnFormatResult(project) {         
    var idActiveChapter = project.idActiveDossierChapter;

    // For cost types, inverse the amounts so that we can create a result.
    var result = (project.result ||[]).map( (line) => {
        if ('rel'.indexOf(line.type) >=0) {
            return line;
        }
        var l = {...line};
        l.budget = -line.budget;
        l.invoiced = -line.invoiced;
        l.pipeline = -line.pipeline;
        return l;
    });

    // Costs
    var purchase  = getPurchaseLines(idActiveChapter, result, project);
    var hours     = getHourLines(idActiveChapter, result, project);
    var othercost = getOtherCostLines(idActiveChapter, result, project);
    var totalCostLines = [...purchase,...hours, ...othercost].filter( (line) => line.subtype === undefined);
    var totalCosts = fnFinishLine(totalCostLines.reduce( (accumulator, line) => { return {
        name: accumulator.name, budget: accumulator.budget+line.budget, invoiced: accumulator.invoiced+line.invoiced, pipeline: accumulator.pipeline+line.pipeline}
    }, { name: 'Totaal Kosten', budget: 0, invoiced: 0, pipeline: 0 }));
    var costs = {
        total: totalCosts,
        lines: [...purchase, ...hours, ...othercost]
    }

    // Revenue
    var revenueLines   = getRevenue(idActiveChapter, result, project);
    var extraworkLines = getExtraWork(idActiveChapter, result, project);
    var lessworkLines  = getLessWork(idActiveChapter, result, project);
    var totalRevenueLines = [...revenueLines,...extraworkLines, ...lessworkLines].filter( (line) => line.subtype === undefined);
    var totalRevenue = fnFinishLine(totalRevenueLines.reduce( (accumulator, line) => { return {
        name: accumulator.name, budget: accumulator.budget+line.budget, invoiced: accumulator.invoiced+line.invoiced, pipeline: accumulator.pipeline+line.pipeline}
    }, { name: 'Totaal Omzet', budget: 0, invoiced: 0, pipeline: 0 }));
    var revenue = {
        total: totalRevenue,
        lines: [...revenueLines,...extraworkLines, ...lessworkLines]
    }

    // Result
    var result = fnFinishLine([costs.total, revenue.total].reduce( (accumulator, line) => { return {
        name: accumulator.name, budget: accumulator.budget+line.budget, invoiced: accumulator.invoiced+line.invoiced, pipeline: accumulator.pipeline+line.pipeline}
    }, { name: 'Resultaat', budget: 0, invoiced: 0, pipeline: 0 }));

    return {
        revenue: revenue,
        costs: costs,
        result: result,
    }
};
export default fnFormatResult;
