import { clsModel, fnCreate } from '@cls/clsModel'
import { hourtypes as api } from '@/app/api'

var modelName = "hourtype";
const fields = ['id', 'ht_name', 'ht_project'];

class clsHourType extends clsModel {

    ht_name = null;
    ht_project = null;
    
    get modelRep() {
        return this.ht_name;
    }

    fill(data) {
        data = data ||{};
        data.ht_project = !!data.ht_project;
        return super.fill(data);
    }
    
    constructor() {
        super({
          api: api,   
          modelName: modelName, 

          fillable: fields
        })
    } 
        
 }
 export default fnCreate(clsHourType , 'clsHourType');
