<template>
    <div>
        <Datatable :dt="dt" class="full-height">
            <template v-slot:menu ="{ item }">
                <ActionMenuItem action="tender.open" @click="dlg.open('tender', item)"></ActionMenuItem>
            </template>

            <template v-slot:item.inv_number="{ item }">
                <span v-if="!item.id_invoice">-</span>
                <ActionButton no-icon link-button v-else action="salesinvoice.open" @click="()=>dlg.open('salesinvoice', {id:item.id_invoice})">{{ item.inv_number || 'Concept' }}</ActionButton>
            </template>

            <template v-slot:item.status="{ item }">
                <span class="cursor-pointer" @click="()=>dlg.open('tender', item)">            
                    <TenderStatusChip :item="item"></TenderStatusChip>
                </span>
            </template>

            <template v-slot:footer.prepend>
                <v-row dense>
                    <v-col>
                        <v-card flat>
                            <v-card-text>
                                <span v-if="feature.canTenderGroup" class="inline-block">
                                    <ActionButton sub dropdown>
                                       <template v-slot:dropdown>
                                            <ActionMenuItem no-icon v-for='(item, index) in tgList.list' :key='index' action="tender.create" @click="()=>createTender(item.id)">{{ item.name }}</ActionMenuItem> 
                                       </template>
                                       Nieuwe offerte
                                    </ActionButton>
                                </span>

                                <ActionButton v-else action="tender.create" sub dusk="btn-add-tender" :model="model" icon="add" xmain xprimary @click='()=>createTender()'>Nieuwe offerte</ActionButton>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </template>

        </Datatable>
    </div>
</template>

<script setup>
    import useDataTable from '@app/useDataTable'
    import Datatable from '@datatable/Datatable'
    import ActionMenuItem from "@controls/menu/ActionMenuItem.vue";
    import ActionButton from '@controls/buttons/ActionButton'
    import { tenders as api } from '@app/api'
    import dlg from '@app/dlg'
    import TenderStatusChip from "@controls/widgets/TenderStatusChip.vue";
    import feature from "@app/features";
    import {tendergroup as tgList} from '@app/list';

    const props = defineProps({
        model: {
            type: [Object]
        },
    });

    var headers = [
      { type: 'cmd'},
      { type: 'flag', flagType: 'tender_flag'},
      { type: 'note', noteType: 'tender_note'},
      { visible: false, text: "Groep",                    value: "group",      },
      { text: "Status",                   value: "status",      },
      { text: "Offertenummer",            value: "tend_number", type: 'open', fmt: (v, item) => ((v)? v : 'Concept')} ,
      { visible: false, text: "Factuur",  value: "inv_number"} ,
      { visible: false, text: "Verkoper",                 value: "empl_contact"} ,
      { text: "Klant",                    value: "rel_name"} ,
      { visible: false, text: "Telefoon",                 value: "rel_phone", type: 'phone' },
      { text: "Offertedatum",             value: "tend_date",    type: 'date'   },
      { visible: false, text: "Vervaldatum", value: "tend_exp_date", type: 'date'},
      { text: "Referentie",               value: "tend_reference", emptyText: '-'} ,

      { text: "Incl BTW",                 value: "tend_total",     type: 'amount'     },
      { text: "Excl BTW",                 value: "tend_sub_total",     type: 'amount'     },
      { text: "BTW",                      value: "tend_vat_total",      type: 'amount'     },

      { isUtc: true, text: "Gewijzigd",                value: "updated_at",    type: 'datetime',   visible: false},
      { text: "Gewijzigd door",           value: "updated_by",                        visible: false},
      { isUtc: true, text: "Aangemaakt",               value: "created_at",    type: 'datetime',   visible: false},
      { text: "Aangemaakt door",          value: "created_by",                        visible: false},
  ]

    var dt = useDataTable("dt_project_tender", "tender", api, headers, {
        modelNames: ["tender_send"], // Also refresh when an invoice is sent.
        itemName: {
            prefix: 'De',
            single: 'offerte',
            plural: 'offerte',
        }, parent: {
            id_project: props.model.id
        },
        preventRemoveHandler: ()=>!props.model.isDialogOpen, // No refresh when dialog is not open
        preventSaveHandler:   ()=>!props.model.isDialogOpen, // No refresh when dialog is not open
        canArchive: false
    })

    // Show the pdf
    function onPdf(item) {
        dlg.pdf( async () => api.getPdf(item.id));
    }

    /**
     * Not yet implemented as we 
     */
    function createTender(id_group) {
        var pro_data = {
            id_relation: props.model.id_relation, 
            id_project: props.model.id, 
            pro_number: props.model.pro_number, 
            pro_name: props.model.pro_name, 
            pro_reference: props.model.pro_reference
        };        
        if (id_group) {
            pro_data.id_group = id_group;
        }
        dlg.create('tender', pro_data)
    }


</script>