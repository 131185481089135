<template>
    <Dialog :dialog="dlg" >
        <v-form ref="form" lazy-validation>
            <Form>
                <FormRow label="Totaalbedrag">            
                    <NumberField amount :rules="model.rules.mandatory" :model='model' v-model='model.amount' ></NumberField>
                </FormRow>
                <FormRow label="Termijnverdeling">            
                    <SalesOrderTermCombo :model="model" :rules="model.rules.mandatory" v-model="model.id_order_term"></SalesOrderTermCombo>
                </FormRow>
                <v-divider class="my-4"></v-divider>
                <div>
                    De datums van de termijnen worden standaard op vandaag gezet.<br>U kunt de datums naar wens aanpassen in de regels.
                </div>

             </Form>
        </v-form>    
    </Dialog>
</template>



<script setup>
    import useModel from '@/models/salesorder/salesordercreateterm'
    import Form from '@controls/forms/Form'
    import FormRow from '@controls/forms/FormRow'
    import Dialog from '@shared/ui/dialogs/ActionDialog'
    import { ref, computed } from 'vue'
    import clsDialog from '@cls/clsDialog'
    import NumberField from '@controls/input/NumberField'
    import DatePicker from '@controls/input/DatePicker'
    import SalesOrderTermCombo from '@controls/combo/SalesOrderTerm'

    const form = ref(null);

    let model = useModel();
    let dlg = ref(
        new clsDialog("salesorder_createterm", "Automatische termijnverdeling", model, [form], {
            showNote: false,
            showAttachment: false,
            icon: 'moneybills',
            noSubTitle: true,
            save: 'voeg regels toe'
        } )).value;

    window.dcot = dlg;


</script>
