<template>
    <StatusChip type="manday" :status="statusValue" >{{statusRep}}</StatusChip>
</template>

<script setup>
    import string from '@lib/string'
    import {computed} from 'vue'
    import StatusChip from "@controls/widgets/StatusChip.vue";

    const props = defineProps({
        model: {
            type: [Object]
        },
    });

    const statusValue = computed({
        get() {
            if (string.isEmpty(props.model.id_invoice)) {
                return 'new'
            }
            else if (string.isEmpty(props.model.inv_number)) {
                return 'invoiced-concept'
            }
            return 'invoiced' 
        },
    })
    const statusRep = computed({
        get() {
            switch (string.lower(statusValue?.value||"")) {
                case "new"      : return "Nieuw";
                case "invoiced-concept"  : return "In factuur";
                case "invoiced" : return "Gefactureerd";
            }
            return "-";

        }
    })


    const emit = defineEmits(['click'])
    function onClick() {
        emit('click')
    }
</script>