
//
//
// Very simple helper for executing one piece of code at a time. 
//
//
// Usage: 
//  import mutex from '@lib/mutex'
//  var m = mutex.create();
//  
//  set price_incl_vat(value) {
//      this.pd_sales_price_incl_vat = value;
//      pm.runWhen(!this.isDataLoading,  () => this.pd_sales_price_incl_vat = vat.incl2excl(this.id_vat, value) )
//  }
//  set price_excl_vat(value) {
//      this.pd_sales_price_excl_vat = value;
//      pm.runWhen(!this.isDataLoading, () => this.pd_sales_price_incl_vat = vat.excl2incl(this.id_vat, value) )
//  }
//
class clsMutex {
	active = false
  
    run( fn ) {
        if (this.active) {
            return;
        }
        this.active = true;
        try {
            return fn();
        } finally {
            this.active = false;
        }
    }

    runWhen( condition, fn ) {
        if (!condition) {
            return;
        }
        return this.run(fn);
    }

}

export default {create: () => new clsMutex()};