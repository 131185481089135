import Constants from '@app/consts'
const r = Constants.rights;
const m = Constants.modules;

const actions = {
    obsolete: {
//        relations: {
//            archive: { rights: [r.relation_archive], icon: 'archive', text: "Archiveer relatie" },
//            modifybankaccount: { rights: [r.relation_modify_bank_account], },
//            import: { rights: [r.relation_import], icon: 'import', text: "Importeer relaties" },
//            search: { rights: [r.relation_search]},         
//        },
//      { name: 'manday.archive',                      rights: [],      icon: 'archive',  text: 'Mandagenstaat is gereed'   , },
//      paymentorder_search                 : 9020,  // --- 
//      paymentorder_settings_access        : 9025,  // --- 
//      paymentorder_settings_modify        : 9030,  // --- 
//      paymentorder_paraph_access          : 9044,  // --- 
//      paymentorder_paraph_modify          : 9045,  // --- 
//      paymentorder_paraph_modify_others   : 9050,  // --- 
//      purchaseinvoice_paraph_access       : 5055,  // --- 
//      purchaseinvoice_paraph_modify       : 5060,  // --- 
//      purchaseinvoice_paraph_modify_others: 5065,  // --- 
//      salesinvoice_paraph_access          : 6045,  // --- 
//      salesinvoice_paraph_modify          : 6050,  // --- 
//      salesinvoice_paraph_modify_others   : 6055,  // --- 
//      salesinvoice_problems               : 6130,  // --- 
//       tender_paraph_access                : 8045,  // --- 
//       tender_paraph_modify                : 8050,  // --- 
//       tender_paraph_modify_others         : 8055,  // --- 
//      project_settings_access             : 12030, // --- 
//      project_settings_modify             : 12035, // --- 
// project_paraph_access               : 12045, // --- 
// project_paraph_modify               : 12050, // --- 
// project_paraph_modify_others        : 12055, // --- 


    },

    'confirmpassword' : {  open:   { rights: [], icon: 'security', text: "Bevestig wachtwoord"     },  modify: { rights: []} },
    'changepassword'  : {  open:   { rights: [], icon: 'security', text: "Verander wachtwoord"     },  modify: { rights: []} },
    'changeusername'  : {  open:   { rights: [], icon: 'security', text: "Verander gebruikersnaam" },  modify: { rights: []} },
    'changepersonname': {  open:   { rights: [], icon: 'security', text: "Verander uw naam"        },  modify: { rights: []} },
    'setup2fa'        : {  open:   { rights: [], icon: 'security', text: "2factor authenticatie"   },  modify: { rights: []} },

    // The default note. It has no rights. 
    // Override by a specific note to apply specific rights. For example, see: relation_note.
    "note": {
        open:   { rights: [], icon: 'open',   text: "Open notitie"}, 
        create: { rights: [], icon: 'open' }, 
        modify: { rights: [], },
    },

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    //
    // Select dialogs
    //
    // Select dialogs are called from various places and generally do not need frontend authorisation.
    // 
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    "select_purchaseinvoice": { open:   { rights: [], icon: 'select',   text: "Selecteer inkoopfactuur"}, },
    "select_product":         { open:   { rights: [], icon: 'select',   text: "Selecteer artikel"}, },
    "select_standardtext":    { open:   { rights: [], icon: 'select',   text: "Selecteer standaard tekst"}, },
    "select_kvk":             { open:   { rights: [], icon: 'select',   text: "Selecteer uit KVK bestand"}, },
    "select_manday":          { open:   { rights: [], icon: 'select',   text: "Selecteer mandagenstaat"}, },
    "select_project":         { open:   { rights: [], icon: 'select',   text: "Selecteer project"}, },
    "select_tender":          { open:   { rights: [], icon: 'select',   text: "Selecteer offerte"}, },

    // Requesting support is for now not restricted by a right 
    "support_request":        { open:   { rights: [], icon: 'support-user',   text: "Support"}, },

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    //
    // Masterdata
    //
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    "unit": {
        open:   { modules: [m.TENDERS, m.SALESINVOICES, m.PROJECTS], rights: [r.unit_access], icon: 'open',   text: "Open eenheid"}, 
        modify: { modules: [m.TENDERS, m.SALESINVOICES, m.PROJECTS], rights: [r.unit_modify], },
        create: { modules: [m.TENDERS, m.SALESINVOICES, m.PROJECTS], rights: [r.unit_create], icon: 'create', text: "Nieuwe eenheid"},
        remove: { modules: [m.TENDERS, m.SALESINVOICES, m.PROJECTS], rights: [r.unit_remove], icon: 'remove', text: "Verwijder eenheid" },
        excel:  { modules: [m.TENDERS, m.SALESINVOICES, m.PROJECTS], rights: [r.unit_access], icon: 'excel', text: "Exporteer naar Excel" },
    },
    "department": {
        open:   { rights: [r.department_access], icon: 'open',   text: "Open afdeling"}, 
        modify: { rights: [r.department_modify], },
        create: { rights: [r.department_create], icon: 'create', text: "Nieuwe afdeling"},
        remove: { rights: [r.department_remove], icon: 'remove', text: "Verwijder afdeling" },
        excel:  { rights: [r.department_access], icon: 'excel', text: "Exporteer naar Excel" },
    },
    "jobtitle": {
        open:   { rights: [r.jobtitle_access], icon: 'open',   text: "Open functiegroep"}, 
        modify: { rights: [r.jobtitle_modify], },
        create: { rights: [r.jobtitle_create], icon: 'create', text: "Nieuwe functiegroep"},
        remove: { rights: [r.jobtitle_remove], icon: 'remove', text: "Verwijder functiegroep" },
        excel:  { rights: [r.jobtitle_access], icon: 'excel', text: "Exporteer naar Excel" },
    },
    "daypart": {
        open:   { modules: [m.WORKORDERS], rights: [r.daypart_access], icon: 'open',   text: "Open dagdeel"}, 
        modify: { modules: [m.WORKORDERS], rights: [r.daypart_modify], },
        create: { modules: [m.WORKORDERS], rights: [r.daypart_create], icon: 'create', text: "Nieuwe dagdeel"},
        remove: { modules: [m.WORKORDERS], rights: [r.daypart_remove], icon: 'remove', text: "Verwijder dagdeel" },
        excel:  { modules: [m.WORKORDERS], rights: [r.daypart_access], icon: 'excel', text: "Exporteer naar Excel" },
    },
    "absencetype": {
        open:   { modules: [m.WORKORDERS], rights: [r.absence_type_access], icon: 'open',   text: "Open verloftype"}, 
        modify: { modules: [m.WORKORDERS], rights: [r.absence_type_modify], },
        create: { modules: [m.WORKORDERS], rights: [r.absence_type_create], icon: 'create', text: "Nieuwe verloftype"},
        remove: { modules: [m.WORKORDERS], rights: [r.absence_type_remove], icon: 'remove', text: "Verwijder verloftype" },
        excel:  { modules: [m.WORKORDERS], rights: [r.absence_type_access], icon: 'excel', text: "Exporteer naar Excel" },
    },
    "workordertype": {
        open:   { modules: [m.WORKORDERS], rights: [r.workordertype_access], icon: 'open',   text: "Open werkbontype"}, 
        modify: { modules: [m.WORKORDERS], rights: [r.workordertype_modify], },
        create: { modules: [m.WORKORDERS], rights: [r.workordertype_create], icon: 'create', text: "Nieuwe werkbontype"},
        remove: { modules: [m.WORKORDERS], rights: [r.workordertype_remove], icon: 'remove', text: "Verwijder werkbontype" },
        excel:  { modules: [m.WORKORDERS], rights: [r.workordertype_access], icon: 'excel', text: "Exporteer naar Excel" },
    },
    "costtype": {
        open:   { modules: [m.PURCHASEINVOICES], rights: [r.costtype_access], icon: 'open',   text: "Open kostentype"}, 
        modify: { modules: [m.PURCHASEINVOICES], rights: [r.costtype_modify], },
        create: { modules: [m.PURCHASEINVOICES], rights: [r.costtype_create], icon: 'create', text: "Nieuwe kostentype"},
        remove: { modules: [m.PURCHASEINVOICES], rights: [r.costtype_remove], icon: 'remove', text: "Verwijder kostentype" },
        excel:  { modules: [m.PURCHASEINVOICES], rights: [r.costtype_access], icon: 'excel', text: "Exporteer naar Excel" },
    },
    "hourtype": {
        open:   { modules: [m.HOURS], rights: [r.hourtype_access], icon: 'open',   text: "Open urentype"}, 
        modify: { modules: [m.HOURS], rights: [r.hourtype_modify], },
        create: { modules: [m.HOURS], rights: [r.hourtype_create], icon: 'create', text: "Nieuwe urentype"},
        remove: { modules: [m.HOURS], rights: [r.hourtype_remove], icon: 'remove', text: "Verwijder urentype" },
        excel:  { modules: [m.HOURS], rights: [r.hourtype_access], icon: 'excel', text: "Exporteer naar Excel" },
    },
    "dispute": {
        open:   { modules: [m.PURCHASEINVOICES], rights: [r.dispute_access], icon: 'open',   text: "Open dispuut"}, 
        modify: { modules: [m.PURCHASEINVOICES], rights: [r.dispute_modify], },
        create: { modules: [m.PURCHASEINVOICES], rights: [r.dispute_create], icon: 'create', text: "Nieuw dispuut"},
        remove: { modules: [m.PURCHASEINVOICES], rights: [r.dispute_remove], icon: 'remove', text: "Verwijder dispuut" },
        excel:  { modules: [m.PURCHASEINVOICES], rights: [r.dispute_access], icon: 'excel', text: "Exporteer naar Excel" },
    },
    "creditrestriction": {
        open:   { modules: [m.PURCHASEINVOICES], rights: [r.credit_restriction_access], icon: 'open',   text: "Open betalingskorting"}, 
        modify: { modules: [m.PURCHASEINVOICES], rights: [r.credit_restriction_modify], },
        create: { modules: [m.PURCHASEINVOICES], rights: [r.credit_restriction_create], icon: 'create', text: "Nieuwe betalingskorting"},
        remove: { modules: [m.PURCHASEINVOICES], rights: [r.credit_restriction_remove], icon: 'remove', text: "Verwijder betalingskorting" },
        excel:  { modules: [m.PURCHASEINVOICES], rights: [r.credit_restriction_access], icon: 'excel', text: "Exporteer naar Excel" },
    },
    "standardtext": {
        open:   { modules: [m.TENDERS, m.SALESINVOICES], rights: [r.texts_access], icon: 'open',   text: "Open standaard tekst"}, 
        modify: { modules: [m.TENDERS, m.SALESINVOICES], rights: [r.texts_modify], },
        create: { modules: [m.TENDERS, m.SALESINVOICES], rights: [r.texts_create], icon: 'create', text: "Nieuwe standaard tekst"},
        remove: { modules: [m.TENDERS, m.SALESINVOICES], rights: [r.texts_remove], icon: 'remove', text: "Verwijder standaard tekst" },
        excel:  { modules: [m.TENDERS, m.SALESINVOICES], rights: [r.texts_access], icon: 'excel', text: "Exporteer naar Excel" },
    },
    "tariff": {
        open:   { modules: [m.WORKORDERS, m.HOURS], rights: [r.tariff_access], icon: 'open',   text: "Open tarief"}, 
        modify: { modules: [m.WORKORDERS, m.HOURS], rights: [r.tariff_modify], },
        create: { modules: [m.WORKORDERS, m.HOURS], rights: [r.tariff_create], icon: 'create', text: "Nieuwe tarief"},
        remove: { modules: [m.WORKORDERS, m.HOURS], rights: [r.tariff_remove], icon: 'remove', text: "Verwijder tarief" },
        up:     { modules: [m.WORKORDERS, m.HOURS], rights: [r.tariff_modify], icon: 'up', text: "Prioriteit omhoog" },
        down:   { modules: [m.WORKORDERS, m.HOURS], rights: [r.tariff_modify], icon: 'down', text: "Prioriteit omlaag" },
        excel:  { modules: [m.WORKORDERS, m.HOURS], rights: [r.tariff_access], icon: 'excel', text: "Exporteer naar Excel" },
    },
    "productgroup": {
        open:   { modules: [m.TENDERS, m.SALESINVOICES], rights: [r.productgroup_access], icon: 'open',   text: "Open artikelgroep"}, 
        modify: { modules: [m.TENDERS, m.SALESINVOICES], rights: [r.productgroup_modify], },
        create: { modules: [m.TENDERS, m.SALESINVOICES], rights: [r.productgroup_create], icon: 'create', text: "Nieuwe artikelgroep"},
        remove: { modules: [m.TENDERS, m.SALESINVOICES], rights: [r.productgroup_remove], icon: 'remove', text: "Verwijder artikelgroep" },
        excel:  { modules: [m.TENDERS, m.SALESINVOICES], rights: [r.productgroup_access], icon: 'excel', text: "Exporteer naar Excel" },
    },
    "supplier": {
        open:   { modules: [m.TENDERS, m.SALESINVOICES], rights: [r.supplier_access], icon: 'open',   text: "Open fabrikant"}, 
        modify: { modules: [m.TENDERS, m.SALESINVOICES], rights: [r.supplier_modify], },
        create: { modules: [m.TENDERS, m.SALESINVOICES], rights: [r.supplier_create], icon: 'create', text: "Nieuwe fabrikant"},
        remove: { modules: [m.TENDERS, m.SALESINVOICES], rights: [r.supplier_remove], icon: 'remove', text: "Verwijder fabrikant" },
        excel:  { modules: [m.TENDERS, m.SALESINVOICES], rights: [r.supplier_access], icon: 'excel', text: "Exporteer naar Excel" },
    },

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    //
    // Settings
    //
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    "rightsgroup": {
        open:   { rights: [r.rightgroup_access], icon: 'open',   text: "Open rechtengroep"}, 
        modify: { rights: [r.rightgroup_modify], },
        create: { rights: [r.rightgroup_create], icon: 'create', text: "Nieuwe rechtengroep"},
        remove: { rights: [r.rightgroup_remove], icon: 'remove', text: "Verwijder rechtengroep" },
    },
    "accountant": {
        open:   { rights: [r.user_access], icon: 'open',   text: "Open accountant"}, 
        modify: { rights: [r.user_modify], },
        create: { rights: [r.user_create], icon: 'create', text: "Koppel accountant"},
        remove: { rights: [r.user_remove], icon: 'remove', text: "Verwijder accountant" },
    },
    "employee": {
        open:   { rights: [r.employee_access], icon: 'open',   text: "Open medewerker"}, 
        modify: { rights: [r.employee_modify], },
        create: { rights: [r.employee_create], icon: 'create', text: "Nieuwe medewerker"},
        remove: { rights: [r.employee_remove], icon: 'remove', text: "Verwijder medewerker" },
    },
    "employee_note": {
        open:   { rights: [r.employee_note_access], icon: 'open',   text: "Open notitie"}, 
        modify: { rights: [r.employee_note_modify], },
        create: { rights: [r.employee_note_modify], icon: 'create', text: "Nieuwe notitie"},
    },
    "employee_attachment": {
        open:   { rights: [r.employee_attachments_access], icon: 'open',   text: "Open bijlage"}, 
        create: { rights: [r.employee_attachments_create], icon: 'add',   text: "Nieuwe bijlage"}, 
        remove: { rights: [r.employee_attachments_remove], icon: 'remove', text: "Verwijder bijlage"},
    },
    "employee_tariff": {
        open:   { rights: [r.employee_access], icon: 'open',   text: "Open medewerker"}, 
        modify: { rights: [r.employee_modify], },
    },
    "companyuser": {
        open:   { rights: [r.user_access], icon: 'open',   text: "Open gebruiker"}, 
        modify: { rights: [r.user_modify], },
        create: { rights: [r.user_create], icon: 'create', text: "Nieuwe gebruiker"},
        remove: { rights: [r.user_remove], icon: 'remove', text: "Verwijder gebruiker" },
    },
    "companyuser_invitation": {
        open:   { rights: [r.user_access], icon: 'open',   text: "Open uitnodiging"}, 
        modify: { rights: [r.user_modify], },
        create: { rights: [r.user_create], icon: 'create', text: "Nieuwe gebruiker"},
        remove: { rights: [r.user_remove], icon: 'remove', text: "Verwijder uitnodiging" },
    },
    "emailtemplate": {
        open:   { modules: [m.TENDERS, m.SALESINVOICES, m.PLANNING, m.WORKORDERS,], rights: [r.email_settings_access], icon: 'open',   text: "Open email-template"}, 
        modify: { modules: [m.TENDERS, m.SALESINVOICES, m.PLANNING, m.WORKORDERS,], rights: [r.email_settings_modify], },
//        create: { rights: [r.email_settings_create], icon: 'create', text: "Nieuwe email-template"},
        remove: { modules: [m.TENDERS, m.SALESINVOICES, m.PLANNING, m.WORKORDERS,], rights: [r.email_settings_remove], icon: 'remove', text: "Verwijder email-template" },
    },
    "emaillogo": {
        // No use in opening the dialog without modify rights
        open: { rights: [r.email_settings_modify], },
        modify: { rights: [r.email_settings_modify], },
    },
    "company": {
        open:   { rights: [r.organisation_access], icon: 'open',   text: "Open bedrijfsgegevens"}, 
        modify: { rights: [r.organisation_modify], },
    },
    "subscription": {
        open:   { rights: [r.organisation_subscription_access], icon: 'open',   text: "Open abonnement"}, 
        modify: { rights: [r.organisation_subscription_modify], },
    },
    "tendergroup": {
        open:   { modules: [m.TENDERS], rights: [r.tender_settings_access], icon: 'open',   text: "Open offertegroep"}, 
        modify: { modules: [m.TENDERS], rights: [r.tender_settings_modify], },
        create: { modules: [m.TENDERS], rights: [r.tender_settings_modify], icon: 'create', text: "Nieuwe offertegroep"},
        remove: { modules: [m.TENDERS], rights: [r.tender_settings_modify], icon: 'remove', text: "Verwijder offertegroep" },
        excel:  { modules: [m.TENDERS], rights: [r.tender_settings_access], }, 
    },
    "projectgroup": {
        open:   { modules: [m.PROJECTS], rights: [r.project_group_access], icon: 'open',   text: "Open projecttype"}, 
        modify: { modules: [m.PROJECTS], rights: [r.project_group_modify], },
        create: { modules: [m.PROJECTS], rights: [r.project_group_create], icon: 'create', text: "Nieuw projecttype"},
        remove: { modules: [m.PROJECTS], rights: [r.project_group_remove], icon: 'remove', text: "Verwijder projecttype" },
        excel:  { modules: [m.PROJECTS], rights: [r.project_group_access], }, 
    },
    "projectphase": {
        open:   { modules: [m.PROJECTS], rights: [r.project_phase_access], icon: 'open',   text: "Open projectfase"}, 
        modify: { modules: [m.PROJECTS], rights: [r.project_phase_modify], },
        create: { modules: [m.PROJECTS], rights: [r.project_phase_create], icon: 'create', text: "Nieuw projectfase"},
        remove: { modules: [m.PROJECTS], rights: [r.project_phase_remove], icon: 'remove', text: "Verwijder projectfase" },
        excel:  { modules: [m.PROJECTS], rights: [r.project_phase_access], }, 
    },
    "salesorderterm": {
        open:   { modules: [m.SALESORDERS], rights: [r.salesorder_settings_access], icon: 'open',   text: "Open termijnverdeling"}, 
        modify: { modules: [m.SALESORDERS], rights: [r.salesorder_settings_modify], },
        create: { modules: [m.SALESORDERS], rights: [r.salesorder_settings_modify], icon: 'create', text: "Nieuwe termijnverdeling"},
        remove: { modules: [m.SALESORDERS], rights: [r.salesorder_settings_modify], icon: 'remove', text: "Verwijder termijnverdeling" },
        excel:  { modules: [m.SALESORDERS], rights: [r.salesorder_settings_access], }, 
    },
    // ------
    "settings_salesorder": {
        open:   { modules: [m.SALESORDERS], rights: [r.salesorder_settings_access], icon: 'open',   text: "Open verkooporders instellingen"}, 
        modify: { modules: [m.SALESORDERS], rights: [r.salesorder_settings_modify], },
    }, 

    "settings_manday": {
        open:   { modules: [m.MANDAYS], rights: [r.manday_settings_access], icon: 'open',   text: "Open mandagstaten instellingen"}, 
        modify: { modules: [m.MANDAYS], rights: [r.manday_settings_modify], },
    }, 
    "settings_purchaseinvoice": {
        open:   { modules: [m.PURCHASEINVOICES], rights: [r.purchaseinvoice_settings_access], icon: 'open',   text: "Open inkoop instellingen"}, 
        modify: { modules: [m.PURCHASEINVOICES], rights: [r.purchaseinvoice_settings_modify], },
    }, 
    "settings_salesinvoice": {
        open:   { modules: [m.SALESINVOICES], rights: [r.salesinvoice_settings_access], icon: 'open',   text: "Open verkoop instellingen"}, 
        modify: { modules: [m.SALESINVOICES], rights: [r.salesinvoice_settings_modify], },
    }, 
    "documentlayout": {
        open:   { modules: [m.TENDERS, m.SALESINVOICES, m.PLANNING, m.WORKORDERS,], rights: [r.organisation_letter_access], icon: 'open',   text: "Open document opmaak"}, 
        modify: { modules: [m.TENDERS, m.SALESINVOICES, m.PLANNING, m.WORKORDERS,], rights: [r.organisation_letter_modify], },
    }, 
    "settings_mollie": {
        open:   { rights: [r.organisation_mollie_access], icon: 'open',   text: "Open Mollie instellingen"}, 
        modify: { rights: [r.organisation_mollie_modify], },
    }, 

    //////////////////////////////////////////////////////////////////////////////////////////
    //
    // Accountancy
    //
    //////////////////////////////////////////////////////////////////////////////////////////
    "link_email": {
        open:   { rights: [r.link_email_access], icon: 'open',   text: "Open e-mailkoppeling"}, 
        modify: { rights: [r.link_email_modify], },
    },
    "accountancy": {
        open:   { rights: [r.accountancy_link_access], icon: 'open',   text: "Open boekhoudkoppeling"}, 
        create: { rights: [r.accountancy_link_create], icon: 'create', text: "Nieuwe boekhoudkoppeling"},
        modify: { rights: [r.accountancy_link_modify], },
        remove: { rights: [r.accountancy_link_remove], icon: 'remove', text: "Verwijder boekhoudkoppeling" },

        // As for now, the following is in admin only.

        // { name: 'accountancy.purchase.log' ,            rights: [],     icon: 'log',      text: 'Toon log'                         },
        // { name: 'accountancy.purchase.pdf' ,            rights: [],     icon: 'pdf',      text: 'Toon factuur'                     },
        // { name: 'accountancy.purchase.download',        rights: [],     icon: 'download', text: 'Toon info uit boekhouding'        },
        // { name: 'accountancy.purchase.sync',            rights: [],     icon: 'link',     text: 'Stuur naar boekhouding'           },
        // { name: 'accountancy.purchase.debug',           rights: [],     icon: 'info',     text: 'Toon debug info'                  },

        // { name: 'accountancy.sales.log' ,               rights: [],     icon: 'log',      text: 'Toon log'                         },
        // { name: 'accountancy.sales.pdf' ,               rights: [],     icon: 'pdf',      text: 'Toon factuur'                     },
        // { name: 'accountancy.sales.download',           rights: [],     icon: 'download', text: 'Toon info uit boekhouding'        },
        // { name: 'accountancy.sales.sync',               rights: [],     icon: 'link',     text: 'Stuur naar boekhouding'           },
        // { name: 'accountancy.sales.debug',              rights: [],     icon: 'info',     text: 'Toon debug info'                  },
        // { name: 'accountancy.relation.log' ,            rights: [],     icon: 'log',      text: 'Toon log'                         },
        // { name: 'accountancy.relation.show',            rights: [],     icon: 'pdf',      text: 'Toon relatie'                     },
        // { name: 'accountancy.relation.download',        rights: [],     icon: 'download', text: 'Toon info uit boekhouding'        },
        // { name: 'accountancy.relation.sync',            rights: [],     icon: 'link',     text: 'Stuur naar boekhouding'           },
        // { name: 'accountancy.relation.debug',           rights: [],     icon: 'info',     text: 'Toon debug info'                  },    
    },
    // Only the cash link as a dialog as it is the only link which does not authorize itself
    // in the accountancy portal
    "accountancylinkcash": {
        open:   { rights: [r.accountancy_link_access], icon: 'open',   text: "Open boekhoudkoppeling"}, 
        create: { rights: [r.accountancy_link_create], icon: 'create', text: "Nieuwe boekhoudkoppeling"},
    },
    "accountancyconfigure": {
        open:   { rights: [r.accountancy_link_access], icon: 'open',   text: "Open boekhoudkoppeling"}, 
        modify: { rights: [r.accountancy_link_modify] },
    },
    "accountancyrefreshledgers": {
        open:   { rights: [r.accountancy_link_access], icon: 'open',   text: "Open boekhoudkoppeling"}, 
        modify: { rights: [r.accountancy_link_modify] },
    },
    "accountancyimportrelations": {
        open:   { rights: [r.accountancy_link_access], icon: 'open',   text: "Open boekhoudkoppeling"}, 
        modify: { rights: [r.accountancy_link_modify] },
    },

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    //
    // Relations
    //
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    "relation": {
        open:   { rights: [r.relation_access], icon: 'open',   text: "Open relatie"}, 
        modify: { rights: [r.relation_modify], },
        create: { rights: [r.relation_create], icon: 'create', text: "Nieuwe relatie"},
        remove: { rights: [r.relation_remove], icon: 'remove', text: "Verwijder relatie" },
        unarchive: { rights: [r.relation_unarchive], icon: 'unarchive', text: "Maak relatie actueel" },
        
        excel: { rights: [r.relation_export], icon: 'excel', text: "Exporteer naar Excel" },
    },
    "relation_merge": {
        open:   { rights: [r.relation_merge], icon: 'merge',   text: "Voeg relaties samen"}, 
        modify: { rights: [r.relation_merge] },
    },
    "relation_settings": {
        open:   { rights: [r.relation_settings_access], icon: 'insellingen',   text: "relatie instellingen"}, 
        modify: { rights: [r.relation_settings_modify] },
    },
    "relation_bankaccount": {
        open:   { rights: [r.relation_bankaccount_access], icon: 'open',   text: "Open bankrekening"}, 
        modify: { rights: [r.relation_bankaccount_modify] },
        create: { rights: [r.relation_bankaccount_create], icon: 'create', text: "Nieuwe bankrekening"},
        remove: { rights: [r.relation_bankaccount_remove], icon: 'remove', text: "Verwijder bankrekening" },
    },
    "relation_address": {
        open:   { rights: [r.relation_addresses_access], icon: 'open',   text: "Open adres"}, 
        modify: { rights: [r.relation_addresses_modify], },
        create: { rights: [r.relation_addresses_create], icon: 'create', text: "Nieuw adres"},
        remove: { rights: [r.relation_addresses_remove], icon: 'remove', text: "Verwijder adres" },
    },
    "relation_contact": {
        open:   { rights: [r.relation_contacts_access], icon: 'open',   text: "Open contactpersoon"}, 
        modify: { rights: [r.relation_contacts_modify], },
        create: { rights: [r.relation_contacts_create], icon: 'create', text: "Nieuwe contactpersoon"},
        remove: { rights: [r.relation_contacts_remove], icon: 'remove', text: "Verwijder contactpersoon" },
    },
    "relation_note": {
        open:   { rights: [r.relation_note_access], icon: 'open',   text: "Open notitie"}, 
        create: { rights: [r.relation_note_modify]},
        modify: { rights: [r.relation_note_modify], },
    },
    "relation_attachment": {
        open:   { rights: [r.relation_attachments_access], icon: 'open',   text: "Open bijlage"}, 
        create: { rights: [r.relation_attachments_create], icon: 'create', text: "Nieuwe bijlage"},
        remove: { rights: [r.relation_attachments_remove], icon: 'remove', text: "Verwijder bijlage" },
    },

    //////////////////////////////////////////////////////////////////////////////////////////
    //
    // Manday
    // 
    //////////////////////////////////////////////////////////////////////////////////////////
    "manday": {
        open:   { modules: [m.MANDAYS], rights: [r.manday_access], icon: 'open',   text: "Open mandagstaat"    }, 
        modify: { modules: [m.MANDAYS], rights: [r.manday_modify], },
        create: { modules: [m.MANDAYS], rights: [r.manday_create], icon: 'create', text: "Nieuwe mandagstaat"   },
        copy:   { modules: [m.MANDAYS], rights: [r.manday_create], icon: 'copy',   text: "Kopieer mandagstaat"  },
        remove: { modules: [m.MANDAYS], rights: [r.manday_remove], icon: 'remove', text: "Verwijder mandagstaat" },        
        unarchive: { modules: [m.MANDAYS], rights: [r.relation_unarchive], icon: 'unarchive', text: "Maak mandagstaat actueel" },
        
        excel: { modules: [m.MANDAYS], rights: [r.manday_export], icon: 'excel', text: "Exporteer naar Excel"  },

        send:    { modules: [m.MANDAYS], rights: [r.manday_send], icon: 'send', text: "Verstuur mandagstaat"   },
        invoice: { modules: [m.SALESINVOICES], rights: [r.salesinvoice_create], icon: 'send', text: "Maak factuur" },
    },
    "manday_note": {
        open:   { modules: [m.MANDAYS], rights: [r.manday_note_access], icon: 'open',   text: "Open notitie"}, 
        create: { modules: [m.MANDAYS], rights: [r.manday_note_modify], }, 
        modify: { modules: [m.MANDAYS], rights: [r.manday_note_modify], },
    },
    "manday_attachment": {
        open:   { modules: [m.MANDAYS], rights: [r.manday_attachments_access], icon: 'open',   text: "Open bijlage"}, 
        create: { modules: [m.MANDAYS], rights: [r.manday_attachments_create], icon: 'create', text: "Nieuwe bijlage"},
        remove: { modules: [m.MANDAYS], rights: [r.manday_attachments_remove], icon: 'remove', text: "Verwijder bijlage" },
    },
    "manday_flag": {
        modify: { modules: [m.MANDAYS], rights: [r.manday_flags]}, 
    },

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    //
    // Products
    //
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    "product": {
        open:   { modules: [m.TENDERS, m.SALESINVOICES, m.PROJECTS], rights: [r.products_access], icon: 'open',   text: "Open artikel"    }, 
        modify: { modules: [m.TENDERS, m.SALESINVOICES, m.PROJECTS], rights: [r.products_modify], },
        create: { modules: [m.TENDERS, m.SALESINVOICES, m.PROJECTS], rights: [r.products_create], icon: 'create', text: "Nieuwe artikel"   },
        copy:   { modules: [m.TENDERS, m.SALESINVOICES, m.PROJECTS], rights: [r.products_create], icon: 'copy',   text: "Kopieer artikel"  },
        remove: { modules: [m.TENDERS, m.SALESINVOICES, m.PROJECTS], rights: [r.products_remove], icon: 'remove', text: "Verwijder artikel" },                
        excel:  { modules: [m.TENDERS, m.SALESINVOICES, m.PROJECTS], rights: [r.products_export], icon: 'excel', text: "Exporteer naar Excel"  },
    },
    "product_note": {
        open:   { modules: [m.TENDERS, m.SALESINVOICES, m.PROJECTS], rights: [r.products_access], }, 
        create: { modules: [m.TENDERS, m.SALESINVOICES, m.PROJECTS], rights: [r.products_modify], },
        modify: { modules: [m.TENDERS, m.SALESINVOICES, m.PROJECTS], rights: [r.products_modify], },
    },
    "recipe": {
        open:   { modules: [m.TENDERS, m.SALESINVOICES, m.PROJECTS], rights: [r.products_access], icon: 'open',   text: "Open recept"    }, 
        modify: { modules: [m.TENDERS, m.SALESINVOICES, m.PROJECTS], rights: [r.products_modify], },
        create: { modules: [m.TENDERS, m.SALESINVOICES, m.PROJECTS], rights: [r.products_create], icon: 'create', text: "Nieuw recept"   },
        copy:   { modules: [m.TENDERS, m.SALESINVOICES, m.PROJECTS], rights: [r.products_create], icon: 'copy',   text: "Kopieer recept"  },
        remove: { modules: [m.TENDERS, m.SALESINVOICES, m.PROJECTS], rights: [r.products_remove], icon: 'remove', text: "Verwijder recept" },                
        excel:  { modules: [m.TENDERS, m.SALESINVOICES, m.PROJECTS], rights: [r.products_export], icon: 'excel', text: "Exporteer naar Excel"  },
    },
    "recipe_note": {
        open:   { modules: [m.TENDERS, m.SALESINVOICES, m.PROJECTS], rights: [r.products_access], }, 
        create: { modules: [m.TENDERS, m.SALESINVOICES, m.PROJECTS], rights: [r.products_modify], },
        modify: { modules: [m.TENDERS, m.SALESINVOICES, m.PROJECTS], rights: [r.products_modify], },
    },

    //////////////////////////////////////////////////////////////////////////////////////////
    //
    // Payment Orders
    //
    //////////////////////////////////////////////////////////////////////////////////////////
    "paymentorder": {
        open:           { modules: [m.PAYMENTORDERS], rights: [r.paymentorder_access],   icon: 'open',     text: "Open betaalopdracht"    }, 
        modify:         { modules: [m.PAYMENTORDERS], rights: [r.paymentorder_modify], },
        create:         { modules: [m.PAYMENTORDERS], rights: [r.paymentorder_create],   icon: 'create',   text: "Nieuwe betaalopdracht"   },
        remove:         { modules: [m.PAYMENTORDERS], rights: [r.paymentorder_remove],   icon: 'remove',   text: "Verwijder betaalopdracht" },                
        excel:          { modules: [m.PAYMENTORDERS], rights: [r.paymentorder_export],   icon: 'excel',    text: "Exporteer naar Excel"  },
        download:       { modules: [m.PAYMENTORDERS], rights: [r.paymentorder_download], icon: 'download', text: "Download de betaalopdracht"  },
        backtoconcept:  { modules: [m.PAYMENTORDERS], rights: [r.paymentorder_backtoconcept],         icon: 'back',    text: "Terug naar concept"  },
        setpaid:        { modules: [m.PAYMENTORDERS], rights: [r.paymentorder_setpaid],  icon: 'money',    text: "Markeer als betaald"  },
    },
    "paymentorder_note": {
        open:   { modules: [m.PAYMENTORDERS], rights: [r.paymentorder_note_access], }, 
        create: { modules: [m.PAYMENTORDERS], rights: [r.paymentorder_note_modify], },
        modify: { modules: [m.PAYMENTORDERS], rights: [r.paymentorder_note_modify], },
    },
    "paymentorder_flag": {
        modify: { modules: [m.PAYMENTORDERS], rights: [r.paymentorder_flags], },
    },

    //////////////////////////////////////////////////////////////////////////////////////////
    //
    // Purchase Invoices
    //
    //////////////////////////////////////////////////////////////////////////////////////////
    "purchaseinvoice": {
        open:           { modules: [m.PURCHASEINVOICES], rights: [r.purchaseinvoice_access],   icon: 'open',     text: "Open inkoopfactuur"    }, 
        modify:         { modules: [m.PURCHASEINVOICES], rights: [r.purchaseinvoice_modify], },
        create:         { modules: [m.PURCHASEINVOICES], rights: [r.purchaseinvoice_create],   icon: 'create',   text: "Nieuwe inkoopfactuur"   },
        remove:         { modules: [m.PURCHASEINVOICES], rights: [r.purchaseinvoice_remove],   icon: 'remove',   text: "Verwijder inkoopfactuur" },                
        excel:          { modules: [m.PURCHASEINVOICES], rights: [r.purchaseinvoice_export],   icon: 'excel',    text: "Exporteer naar Excel"  },
        search:         { modules: [m.PURCHASEINVOICES], rights: [r.purchaseinvoice_search],   icon: 'excel',    text: "Zoeken"  },
        accept:         { modules: [m.PURCHASEINVOICES], rights: [r.purchaseinvoice_accept],   icon: 'check',   text: "Goedkeuren"  },
        reject:         { modules: [m.PURCHASEINVOICES], rights: [r.purchaseinvoice_reject],   icon: 'reject',   text: "Afkeuren"  },

        // Back to new has same frontend rights as accept. Is that ok?
        backtonew:      { modules: [m.PURCHASEINVOICES], rights: [r.purchaseinvoice_accept],   icon: 'back',   text: "Terug naar status 'nieuw'"  },
    
        paymentstatus:  { modules: [m.PURCHASEINVOICES], rights: [r.purchaseinvoice_paymentstatus],   },
    },
    // reject has a dedicated dialog. Hence, a dedicated base action.
    "purchaseinvoice_reject": {
        open:           { modules: [m.PURCHASEINVOICES], rights: [r.purchaseinvoice_reject],   icon: 'reject',     text: "Keur inkoopfactuur af"    }, 
    },
    // upload has a dedicated dialog. Hence, a dedicated base action.
    "purchaseinvoice_upload": {
        open:           { modules: [m.PURCHASEINVOICES], rights: [r.purchaseinvoice_create],   icon: 'purchase',     text: "Upload inkoopfactuur"    }, 
        create:         { modules: [m.PURCHASEINVOICES], rights: [r.purchaseinvoice_create],   icon: 'purchase',     text: "Upload inkoopfactuur"    }, 
    },
    "purchaseinvoice_note": {
        open:   { modules: [m.PURCHASEINVOICES], rights: [r.purchaseinvoice_note_access], }, 
        create: { modules: [m.PURCHASEINVOICES], rights: [r.purchaseinvoice_note_modify], },
        modify: { modules: [m.PURCHASEINVOICES], rights: [r.purchaseinvoice_note_modify], },
    },
    "purchaseinvoice_attachment": {
        open:   { modules: [m.PURCHASEINVOICES], rights: [r.purchaseinvoice_attachment_access], icon: 'open',   text: "Open bijlage"}, 
        create: { modules: [m.PURCHASEINVOICES], rights: [r.purchaseinvoice_attachment_create], icon: 'add',   text: "Nieuwe bijlage"}, 
        remove: { modules: [m.PURCHASEINVOICES], rights: [r.purchaseinvoice_attachment_remove], icon: 'remove', text: "Verwijder bijlage"},
    },
    "purchaseinvoice_flag": {
        modify: { modules: [m.PURCHASEINVOICES], rights: [r.purchaseinvoice_flags], },
    },
    "purchaseinvoice_history": {
        open:           { modules: [m.PURCHASEINVOICES], rights: [r.purchaseinvoice_access],   icon: 'history',    text: "Geschiedenis"  },
    },

    //////////////////////////////////////////////////////////////////////////////////////////
    //
    // Sales Invoices
    //
    //////////////////////////////////////////////////////////////////////////////////////////
    "salesinvoice": {
        open:           { modules: [m.SALESINVOICES], rights: [r.salesinvoice_access],   icon: 'open',     text: "Open verkoopfactuur"    }, 
        modify:         { modules: [m.SALESINVOICES], rights: [r.salesinvoice_modify], },
        create:         { modules: [m.SALESINVOICES], rights: [r.salesinvoice_create],   icon: 'create',   text: "Nieuwe verkoopfactuur"   },
        remove:         { modules: [m.SALESINVOICES], rights: [r.salesinvoice_remove],   icon: 'remove',   text: "Verwijder verkoopfactuur" },                
        copy:           { modules: [m.SALESINVOICES], rights: [r.salesinvoice_create],   icon: 'copy',     text: "Kopieer verkoopfactuur"   },
        credit:         { modules: [m.SALESINVOICES], rights: [r.salesinvoice_create],   icon: 'back',     text: "Maak creditfactuur"   },
        pdf:            { modules: [m.SALESINVOICES], rights: [r.salesinvoice_access],   icon: 'pdf',      text: "Voorbeeld"    }, 
        example:        { modules: [m.SALESINVOICES], rights: [r.salesinvoice_access],   icon: 'pdf',      text: "Voorbeeld"    }, 
        backtoconcept:  { modules: [m.SALESINVOICES], rights: [r.salesinvoice_modify],   icon: 'back',     text: "Terug naar concept"    }, 
        excel:          { modules: [m.SALESINVOICES], rights: [r.salesinvoice_export],   icon: 'excel',    text: "Exporteer naar Excel"  },
        search:         { modules: [m.SALESINVOICES], rights: [r.salesinvoice_search],   icon: 'search',   text: "Zoeken"  },
        reminders:      { modules: [m.SALESINVOICES], rights: [r.salesinvoice_reminder_access],   icon: 'defaultnotice',   text: "Aanmaningen"  },
    },

    "salesinvoice_send": {
        open:           { modules: [m.SALESINVOICES], rights: [r.salesinvoice_send],        icon: 'send',   text: "Factuur versturen"  },
        modify:         { modules: [m.SALESINVOICES], rights: [r.salesinvoice_send],        icon: 'send',   text: "Factuur versturen"  },
    },
    "salesinvoice_history": {
        open:           { modules: [m.SALESINVOICES], rights: [r.salesinvoice_history],   icon: 'history',    text: "Factuurgeschiedenis"  },
    },

    "salesinvoice_reminder": {
        open:           { modules: [m.SALESINVOICES], rights: [r.salesinvoice_reminder_access], icon: 'reminder',   text: "Herinnering versturen"  },
        modify:         { modules: [m.SALESINVOICES], rights: [r.salesinvoice_reminder_send],   icon: 'reminder',   text: "Herinnering versturen"  },
    },
    "salesinvoice_bulkreminder": {
        open:           { modules: [m.SALESINVOICES], rights: [r.salesinvoice_reminder_access], icon: 'reminder',   text: "Aanmaning versturen"  },
        modify:         { modules: [m.SALESINVOICES], rights: [r.salesinvoice_reminder_send],   icon: 'reminder',   text: "Aanmaning versturen"  },
    },

    "salesinvoice_payments": {
        open:           { modules: [m.SALESINVOICES], rights: [r.salesinvoice_payment_access],   icon: 'payment',     text: "Betalingen"    },         
    },
    "salesinvoice_payment": {
        open:           { modules: [m.SALESINVOICES], rights: [r.salesinvoice_payment_access],   icon: 'open',     text: "Open betaling"    }, 
        modify:         { modules: [m.SALESINVOICES], rights: [r.salesinvoice_payment_modify], },
        create:         { modules: [m.SALESINVOICES], rights: [r.salesinvoice_payment_create],   icon: 'create',   text: "Registreer betaling"   },
        remove:         { modules: [m.SALESINVOICES], rights: [r.salesinvoice_payment_remove],   icon: 'remove',   text: "Verwijder betaling" },                
    },

    "salesinvoice_note": {
        open:   { modules: [m.SALESINVOICES], rights: [r.salesinvoice_note_access], }, 
        create: { modules: [m.SALESINVOICES], rights: [r.salesinvoice_note_modify], },
        modify: { modules: [m.SALESINVOICES], rights: [r.salesinvoice_note_modify], },
    },
    "salesinvoice_attachment": {
        open:   { modules: [m.SALESINVOICES], rights: [r.salesinvoice_attachments_access], icon: 'open',   text: "Open bijlage"}, 
        create: { modules: [m.SALESINVOICES], rights: [r.salesinvoice_attachments_create], icon: 'add',   text: "Nieuwe bijlage"}, 
        remove: { modules: [m.SALESINVOICES], rights: [r.salesinvoice_attachments_remove], icon: 'remove', text: "Verwijder bijlage"},
    },
    "salesinvoice_flag": {
        modify: { modules: [m.SALESINVOICES], rights: [r.salesinvoice_flags], },
    },

    //////////////////////////////////////////////////////////////////////////////////////////
    //
    // Tender
    //
    //////////////////////////////////////////////////////////////////////////////////////////
    "tender": {
        open:           { modules: [m.TENDERS], rights: [r.tender_access],         icon: 'open',     text: "Open offerte"    }, 
        modify:         { modules: [m.TENDERS], rights: [r.tender_modify], }, 
        create:         { modules: [m.TENDERS], rights: [r.tender_create],         icon: 'create',   text: "Nieuwe offerte"   },
        remove:         { modules: [m.TENDERS], rights: [r.tender_remove],         icon: 'remove',   text: "Verwijder offerte" },                

        copy:           { modules: [m.TENDERS], rights: [r.tender_create],         icon: 'copy',     text: "Kopieer offerte"   },
        pdf:            { modules: [m.TENDERS], rights: [r.tender_access],         icon: 'pdf',      text: "Voorbeeld"    }, 
        example:        { modules: [m.TENDERS], rights: [r.tender_access],         icon: 'pdf',      text: "Voorbeeld"    }, 
        excel:          { modules: [m.TENDERS], rights: [r.tender_export],         icon: 'excel',    text: "Exporteer naar Excel"  },
        search:         { modules: [m.TENDERS], rights: [r.tender_search],         icon: 'search',   text: "Zoeken"  },
    },
    
    "tender_import": {
        open:           { modules: [m.TENDERS], rights: [r.tender_modify],        icon: 'import',    text: "Importeer Offerte"  },
    },
    "tender_copy": {
        open:           { modules: [m.TENDERS], rights: [r.tender_modify],        icon: 'copy',     text: "Kopieer offerte"  },
        modify:         { modules: [m.TENDERS], rights: [r.tender_modify],        icon: 'copy',     text: "Kopieer offerte"  },
    },
    "tender_replace": {
        open:           { modules: [m.TENDERS], rights: [r.tender_modify],        icon: 'pdf-plus', text: "Maak nieuwe versie van Offerte"  },
        modify:           { modules: [m.TENDERS], rights: [r.tender_modify],        icon: 'pdf-plus', text: "Maak nieuwe versie van Offerte"  },
    },
    "tender_accept": {
        open:           { modules: [m.TENDERS], rights: [r.tender_modify],        icon: 'pdf-plus', text: "Verander status in opdracht"  },
        modify:         { modules: [m.TENDERS], rights: [r.tender_modify],        icon: 'pdf-plus', text: "Verander status in opdracht"  },
    },
    "tender_reject": {
        open:           { modules: [m.TENDERS], rights: [r.tender_modify],        icon: 'remove', text: "Laat offerte vervallen"  },
    },
    "tender_finish": {
        open:           { modules: [m.TENDERS], rights: [r.tender_modify],        icon: 'archive', text: "Offerte is gereed"  },
        modify:         { modules: [m.TENDERS], rights: [r.tender_modify],        icon: 'archive', text: "Offerte is gereed"  },
    },
    "tender_send": {
        open:           { modules: [m.TENDERS], rights: [r.tender_send],          icon: 'send',      text: "Offerte versturen"  },
        modify:         { modules: [m.TENDERS], rights: [r.tender_send],          icon: 'send',      text: "Offerte versturen"  },
    },
    "tender_history": {
        open:           { modules: [m.TENDERS], rights: [r.tender_history],       icon: 'history',    text: "Offertegeschiedenis"  },
    },
    "tender_version": {
        open:           { modules: [m.TENDERS], rights: [r.tender_history],       icon: 'files',    text: "Offerteversies"  },
    },

    "tender_createinvoice": {
        open:           { modules: [m.TENDERS], rights: [r.tender_create_invoice], icon: 'invoice',   text: "Maak factuur"  },
        modify:         { modules: [m.TENDERS], rights: [r.tender_create_invoice], icon: 'invoice',   text: "Maak factuur"  },
    },

    "tender_note": {
        open:   { modules: [m.TENDERS], rights: [r.tender_note_access], }, 
        create: { modules: [m.TENDERS], rights: [r.tender_note_modify], },
        modify: { modules: [m.TENDERS], rights: [r.tender_note_modify], },
    },
    "tender_attachment": {
        open:   { modules: [m.TENDERS], rights: [r.tender_attachments_access], icon: 'open',   text: "Open bijlage"}, 
        create: { modules: [m.TENDERS], rights: [r.tender_attachments_create], icon: 'add',   text: "Nieuwe bijlage"}, 
        remove: { modules: [m.TENDERS], rights: [r.tender_attachments_remove], icon: 'remove', text: "Verwijder bijlage"},
    },
    "tender_flag": {
        modify: { modules: [m.TENDERS], rights: [r.tender_flags], },
    },
    

    //////////////////////////////////////////////////////////////////////////////////////////
    //
    // Project
    //
    //////////////////////////////////////////////////////////////////////////////////////////
    "project": {
        open:           { modules: [m.PROJECTS], rights: [r.project_access],         icon: 'open',     text: "Open project"    }, 
        modify:         { modules: [m.PROJECTS], rights: [r.project_modify], }, 
        create:         { modules: [m.PROJECTS], rights: [r.project_create],         icon: 'create',   text: "Nieuw project"   },
        remove:         { modules: [m.PROJECTS], rights: [r.project_remove],         icon: 'remove',   text: "Verwijder project" },                
        copy:           { modules: [m.PROJECTS], rights: [r.project_create],         icon: 'copy',     text: "Kopieer project"   },
        excel:          { modules: [m.PROJECTS], rights: [r.project_export],          icon: 'excel',    text: "Exporteer naar Excel"  },
        search:         { modules: [m.PROJECTS], rights: [r.project_search],          icon: 'search',   text: "Zoeken"  },
        status:         { modules: [m.PROJECTS], rights: [r.project_status_modify] },

        financials:         { modules: [m.PROJECTS], rights: [r.project_financials] },
                          
    },
    "project_merge": {
        open:           { modules: [m.PROJECTS], rights: [r.project_merge],   icon: 'combine_amount',     text: "Voeg projecten samen"    }, 
        modify:         { modules: [m.PROJECTS], rights: [r.project_merge], },
    },
    "project_limit": {
        open:           { modules: [m.PROJECTS], rights: [r.project_limits_access],   icon: 'open',     text: "Open limiet"    }, 
        modify:         { modules: [m.PROJECTS], rights: [r.project_limits_modify], },
        create:         { modules: [m.PROJECTS], rights: [r.project_limits_create],   icon: 'create',   text: "Nieuwe limiet"   },
        remove:         { modules: [m.PROJECTS], rights: [r.project_limits_remove],   icon: 'remove',   text: "Verwijder limiet" },                
    },
    "project_additionalwork": {
        open:           { modules: [m.PROJECTS], rights: [r.project_additionalwork_access],   icon: 'open',     text: "Open extra werk"    }, 
        modify:         { modules: [m.PROJECTS], rights: [r.project_additionalwork_modify], },
        create:         { modules: [m.PROJECTS], rights: [r.project_additionalwork_create],   icon: 'create',   text: "Voeg extra werk toe"   },
        remove:         { modules: [m.PROJECTS], rights: [r.project_additionalwork_remove],   icon: 'remove',   text: "Verwijder extra werk" },                
        invoice:        { modules: [m.PROJECTS], rights: [r.project_additionalwork_invoice],   icon: 'invoice',   text: "Factureer extra werk" },                
    },
    "project_othercosts": {
        open:           { modules: [m.PROJECTS], rights: [r.project_othercosts_access],   icon: 'open',     text: "Open overige kosten"    }, 
        modify:         { modules: [m.PROJECTS], rights: [r.project_othercosts_modify], },
        create:         { modules: [m.PROJECTS], rights: [r.project_othercosts_create],   icon: 'create',   text: "Voeg overige kosten toe"   },
        remove:         { modules: [m.PROJECTS], rights: [r.project_othercosts_remove],   icon: 'remove',   text: "Verwijder overige kosten" },                
    },
    "project_hours": {
        open:           { modules: [m.PROJECTS], rights: [r.project_hours_access],   icon: 'open',     text: "Open uren"    }, 
        modify:         { modules: [m.PROJECTS], rights: [r.project_hours_modify], },
        create:         { modules: [m.PROJECTS], rights: [r.project_hours_modify],   icon: 'create',   text: "Voeg uren toe"   },
        remove:         { modules: [m.PROJECTS], rights: [r.project_hours_modify],   icon: 'remove',   text: "Verwijder uren" },                
        export:         { modules: [m.PROJECTS], rights: [r.project_hours_export],   icon: 'export',   text: "Exporteer uren" },                
    },
    "project_employees": {
        open:           { modules: [m.PROJECTS], rights: [r.project_employees_access],   icon: 'open',     text: "Open medewerkers"    }, 
        create:         { modules: [m.PROJECTS], rights: [r.project_employees_create],   icon: 'create',   text: "Voeg medewerker toe"   },
        remove:         { modules: [m.PROJECTS], rights: [r.project_employees_remove],   icon: 'remove',   text: "Verwijder medewerker van project" },                
    },
    "project_planning": {
        open:           { modules: [m.PROJECTS], rights: [r.project_planning_access],   icon: 'open',     text: "Open planning"    }, 
        modify:         { modules: [m.PROJECTS], rights: [r.project_planning_modify], },
        create:         { modules: [m.PROJECTS], rights: [r.project_planning_create],   icon: 'create',   text: "Voeg planningsregel toe"   },
        remove:         { modules: [m.PROJECTS], rights: [r.project_planning_remove],   icon: 'remove',   text: "Verwijder planningsregel" },                
    },
    "project_chapter": {
        open:           { modules: [m.PROJECTS], rights: [r.project_access],   icon: 'open',     text: "Open hoofdstuk"      }, 
        modify:         { modules: [m.PROJECTS], rights: [r.project_modify], },
        create:         { modules: [m.PROJECTS], rights: [r.project_modify],   icon: 'create',   text: "Nieuw hoofdstuk"     },
        remove:         { modules: [m.PROJECTS], rights: [r.project_modify],   icon: 'remove',   text: "Verwijder hoofdstuk" },                
    },

    "project_note": {
        open:   { modules: [m.PROJECTS], rights: [r.project_note_access], }, 
        create: { modules: [m.PROJECTS], rights: [r.project_note_modify], },
        modify: { modules: [m.PROJECTS], rights: [r.project_note_modify], },
    },
    "project_attachment": {
        open:   { modules: [m.PROJECTS], rights: [r.project_attachments_access], icon: 'open',   text: "Open bijlage"}, 
        create: { modules: [m.PROJECTS], rights: [r.project_attachments_create], icon: 'add',   text: "Nieuwe bijlage"}, 
        remove: { modules: [m.PROJECTS], rights: [r.project_attachments_remove], icon: 'remove', text: "Verwijder bijlage"},
    },
    "project_flag": {
        modify: { modules: [m.PROJECTS], rights: [r.project_flags], },
    },

    //////////////////////////////////////////////////////////////////////////////////////////
    //
    // SalesOrders
    //
    //////////////////////////////////////////////////////////////////////////////////////////
    "salesorder": {
        open:           { modules: [m.SALESORDERS], rights: [r.salesorder_access],         icon: 'open',     text: "Open verkooporder"    }, 
        modify:         { modules: [m.SALESORDERS], rights: [r.salesorder_modify], }, 
        create:         { modules: [m.SALESORDERS], rights: [r.salesorder_create],         icon: 'create',   text: "Nieuwe verkooporder"   },
        remove:         { modules: [m.SALESORDERS], rights: [r.salesorder_remove],         icon: 'remove',   text: "Verwijder verkooporder" },                
        excel:          { modules: [m.SALESORDERS], rights: [r.salesorder_export],         icon: 'excel',    text: "Exporteer naar Excel"  },
        search:         { modules: [m.SALESORDERS], rights: [r.salesorder_search],         icon: 'search',   text: "Zoeken"  },                          
        archive:        { modules: [m.SALESORDERS], rights: [r.salesorder_archive],        icon: 'archive',  text: "Zet status gereed"  },                          
        unarchive:      { modules: [m.SALESORDERS], rights: [r.salesorder_unarchive],      icon: 'unarchive',text: "Maak actueel"  },                          
    },
    "salesorder_invoice": {
        open:           { modules: [m.SALESORDERS], rights: [r.salesorder_create_invoice],   icon: 'salesinvoice', text: "Maak factuur"    }, 
        modify:         { modules: [m.SALESORDERS], rights: [r.salesorder_create_invoice], },
    },
    "salesorder_createterm": {
        open:           { modules: [m.SALESORDERS], rights: [r.salesorder_modify],   icon: 'moneybills', text: "Termijnbedragen toevoegen"    }, 
        create:         { modules: [m.SALESORDERS], rights: [r.salesorder_modify],   icon: 'moneybills', text: "Termijnbedragen toevoegen"    }, 
        modify:         { modules: [m.SALESORDERS], rights: [r.salesorder_modify], },
    },
    "salesorder_note": {
        open:   { modules: [m.SALESORDERS], rights: [r.salesorder_note_access], }, 
        create: { modules: [m.SALESORDERS], rights: [r.salesorder_note_modify], },
        modify: { modules: [m.SALESORDERS], rights: [r.salesorder_note_modify], },
    },
    "salesorder_attachment": {
        open:   { modules: [m.SALESORDERS], rights: [r.salesorder_attachments_access], icon: 'open',   text: "Open bijlage"}, 
        create: { modules: [m.SALESORDERS], rights: [r.salesorder_attachments_create], icon: 'add',   text: "Nieuwe bijlage"}, 
        remove: { modules: [m.SALESORDERS], rights: [r.salesorder_attachments_remove], icon: 'remove', text: "Verwijder bijlage"},
    },
    "salesorder_flag": {
        modify: { modules: [m.SALESORDERS], rights: [r.salesorder_flags], },
    },

    



    //////////////////////////////////////////////////////////////////////////////////////////
    //
    // Workorder
    // 
    //////////////////////////////////////////////////////////////////////////////////////////
    "workorder": {
        open:           { modules: [m.WORKORDERS], rights: [r.workorder_access],         icon: 'open',     text: "Open werkbon"    }, 
        modify:         { modules: [m.WORKORDERS], rights: [r.workorder_modify], }, 
        create:         { modules: [m.WORKORDERS], rights: [r.workorder_create],         icon: 'create',   text: "Nieuwe werkbon"   },
        remove:         { modules: [m.WORKORDERS], rights: [r.workorder_remove],         icon: 'remove',   text: "Verwijder werkbon" },                
        copy:           { modules: [m.WORKORDERS], rights: [r.workorder_create],         icon: 'copy',     text: "Kopieer werkbon"   },
        excel:          { modules: [m.WORKORDERS], rights: [r.workorder_export],         icon: 'excel',    text: "Exporteer naar Excel"  },
        search:         { modules: [m.WORKORDERS], rights: [r.workorder_search],         icon: 'search',   text: "Zoeken"  },
        pdf:            { modules: [m.WORKORDERS], rights: [r.workorder_access],         icon: 'pdf',      text: "Toon PDF"    },                           
        assign:         { modules: [m.WORKORDERS], rights: [r.workorder_modify],         icon: 'create',   text: "Maak afspraak"    },                           
    },
    "workorder_send": {
        open:           { modules: [m.WORKORDERS], rights: [r.workorder_send],        icon: 'send',   text: "Werkbon versturen"  },
        modify:         { modules: [m.WORKORDERS], rights: [r.workorder_send],        icon: 'send',   text: "Werkbon versturen"  },
    },
    "workorder_planboard": {
        open:           { modules: [m.WORKORDERS], rights: [r.workorder_planning_access],        icon: 'task',   text: "Werkbon plannen"  },
        modify:         { modules: [m.WORKORDERS], rights: [r.workorder_planning_modify], },
    },
    "workorder_note": {
        open:   { modules: [m.WORKORDERS], rights: [r.workorder_note_access], }, 
        create: { modules: [m.WORKORDERS], rights: [r.workorder_note_modify], },
        modify: { modules: [m.WORKORDERS], rights: [r.workorder_note_modify], },
    },
    "workorder_attachment": {
        open:   { modules: [m.WORKORDERS], rights: [r.workorder_attachments_access], icon: 'open',   text: "Open bijlage"}, 
        create: { modules: [m.WORKORDERS], rights: [r.workorder_attachments_create], icon: 'add',   text: "Nieuwe bijlage"}, 
        remove: { modules: [m.WORKORDERS], rights: [r.workorder_attachments_remove], icon: 'remove', text: "Verwijder bijlage"},
    },
    "workorder_flag": {
        modify: { modules: [m.WORKORDERS], rights: [r.workorder_flags], },
    },

    //////////////////////////////////////////////////////////////////////////////////////////
    //
    // Dasboard
    // 
    //////////////////////////////////////////////////////////////////////////////////////////
    "dashboard_widget": {
        revenue:                  { modules: [m.SALESINVOICES], rights: [r.widget_revenue]                  }, 
        cost:                     { modules: [m.PURCHASEINVOICES], rights: [r.widget_cost]                     },                
        openinvoices:             { modules: [m.SALESINVOICES], rights: [r.widget_invoices_open]            },                
        todo:                     { modules: [], rights: [r.widget_todo]                     },                        

    // cost_revenue:             { rights: [r.widget_cost_revenue]             },
     // invoices_expired:         { rights: [r.widget_invoices_expired]         },
     // invoices_open:            { rights: [r.widget_invoices_open]            },
     // purchaseinvoices_payable: { rights: [r.widget_purchaseinvoices_payable] },
     // sent_items:               { rights: [r.widget_sent_items]               },
     // problems:                 { rights: [r.widget_problems]                 },
    },
 





/*    
    { name: 'relation.merge',                      rights: [],      icon: 'merge',    text: 'Relaties samenvoegen'      ,     dialog: { create: "relation.merge"         }},

    { name: 'relation.open',                       rights: [],      icon: 'open',     text: 'Open relatie'              ,     dialog: { open:   "relation"               }},
    { name: 'relation.modify',                     rights: [],      icon: 'modify',   text: 'Relatie aanpasen'          ,  },
    { name: 'relation.create',                     rights: [],      icon: 'create',   text: 'Nieuwe relatie'            ,     dialog: { create: "relation"               }},
    { name: 'relation.remove',                     rights: [],      icon: 'remove',   text: 'Verwijder relatie'         ,  },
    { name: 'relation.address.open',               rights: [],      icon: 'open',     text: 'Open adres'                ,     dialog: { open:   "relation.address"       }},
    { name: 'relation.address.create',             rights: [],      icon: 'create',   text: 'Nieuw adres'               ,     dialog: { create: "relation.address"       }},
    { name: 'relation.contact.open',               rights: [],      icon: 'open',     text: 'Open contactpersoon'       ,     dialog: { open:   "relation.contact"       }},
    { name: 'relation.contact.create',             rights: [],      icon: 'create',   text: 'Nieuwe contactpersoon'     ,     dialog: { create: "relation.contact"       }},
    { name: 'relation.bankaccount.open',           rights: [],      icon: 'open',     text: 'Open bankrekening'         ,     dialog: { open:   "relation.bankaccount"   }},    
    { name: 'relation.bankaccount.create',         rights: [],      icon: 'create',   text: 'Nieuwe bankrekening'       ,     dialog: { create: "relation.bankaccount"   }},    
    { name: 'relation.bankaccount.remove',         rights: [],      icon: 'remove',   text: 'Verwijder bankrekening'    , },    

*/

}

const xactions = [

    { name: 'auth.confirmpassword',                rights: [],      icon: 'security',   text: 'Bevestig wachtwoord', dialog: {open: 'confirmpassword'} },
    { name: 'auth.changepassword',                 rights: [],      icon: 'security',   text: 'Verander wachtwoord', dialog: {open: 'changepassword'} },
    { name: 'auth.changeusername',                 rights: [],      icon: 'security',   text: 'Verander gebruikersnaam', dialog: {open: 'changeusername'} },
    { name: 'auth.changepersonname',               rights: [],      icon: 'security',   text: 'Verander uw naam', dialog: {open: 'changepersonname'} },
    
    { name: 'auth.setup2fa',                       rights: [],      icon: 'security',   text: '2factor authenticatie', dialog: {open: 'setup2fa'} },

    { name: 'note.open',                           rights: [],      icon: 'open',     text: 'Open notitie'               ,   dialog: { open: "note"                      }},
    { name: 'attachments.open',                    rights: [],      icon: 'open',     text: 'Open bijlagen'              ,   dialog: { open: "attachments"               }},
    { name: 'attachment.create',                   rights: [],      icon: 'create',   text: 'Upload bijlage'             ,   dialog: { open: "attachment.upload"         }},
    { name: 'attachment.download',                 rights: [],      icon: 'download', text: 'Download bijlage'           ,   },
    { name: 'attachment.remove',                   rights: [],      icon: 'remove',   text: 'Verwijder bijlage'          ,   },

    { name: 'masterdata.department.open',          rights: [],      icon: 'open',     text: 'Open afdeling'             ,     dialog: {open:   "department"              }},
    { name: 'masterdata.department.create',        rights: [],      icon: 'create',   text: 'Nieuwe afdeling'           ,     dialog: {create: "department"              }},
    { name: 'masterdata.department.remove',        rights: [],      icon: 'remove',   text: 'Verwijder afdeling'        ,  },
    { name: 'masterdata.jobtitles.open',           rights: [],      icon: 'open',     text: 'Open functiegroep'         ,     dialog: {open:   "jobtitle"                }},
    { name: 'masterdata.jobtitles.create',         rights: [],      icon: 'create',   text: 'Nieuwe functiegroep'       ,     dialog: {create: "jobtitle"                }},
    { name: 'masterdata.jobtitles.remove',         rights: [],      icon: 'remove',   text: 'Verwijder functiegroep'    ,  },
    { name: 'masterdata.dayparts.open',            rights: [],      icon: 'open',     text: 'Open dagdeel'         ,     dialog: {open:   "daypart"                }},
    { name: 'masterdata.dayparts.create',          rights: [],      icon: 'create',   text: 'Nieuwe dagdeel'       ,     dialog: {create: "daypart"                }},
    { name: 'masterdata.dayparts.remove',          rights: [],      icon: 'remove',   text: 'Verwijder dagdeel'    ,  },
    { name: 'masterdata.absencetypes.open',        rights: [],      icon: 'open',     text: 'Open verloftype'         ,     dialog: {open:   "absencetype"                }},
    { name: 'masterdata.absencetypes.create',      rights: [],      icon: 'create',   text: 'Nieuwe verloftype'       ,     dialog: {create: "absencetype"                }},
    { name: 'masterdata.absencetypes.remove',      rights: [],      icon: 'remove',   text: 'Verwijder verloftype'    ,  },
    { name: 'masterdata.workordertypes.open',      rights: [],      icon: 'open',     text: 'Open werkbontype'         ,     dialog: {open:   "workordertype"                }},
    { name: 'masterdata.workordertypes.create',    rights: [],      icon: 'create',   text: 'Nieuwe werkbontype'       ,     dialog: {create: "workordertype"                }},
    { name: 'masterdata.workordertypes.remove',    rights: [],      icon: 'remove',   text: 'Verwijder werkbontype'    ,  },
    { name: 'masterdata.costtypes.open',           rights: [],      icon: 'open',     text: 'Open kostentype'           ,     dialog: {open:   "costtype"                }},
    { name: 'masterdata.costtypes.create',         rights: [],      icon: 'create',   text: 'Nieuwe kostentype'         ,     dialog: {create: "costtype"                }},
    { name: 'masterdata.costtypes.remove',         rights: [],      icon: 'remove',   text: 'Verwijder kostentype'      ,  },
    { name: 'masterdata.disputes.open',            rights: [],      icon: 'open',     text: 'Open dispuut'              ,     dialog: {open:   "dispute"                 }},
    { name: 'masterdata.disputes.create',          rights: [],      icon: 'create',   text: 'Nieuwe dispuut'            ,     dialog: {create: "dispute"                 }},
    { name: 'masterdata.disputes.remove',          rights: [],      icon: 'remove',   text: 'Verwijder dispuut'         ,  },
    { name: 'masterdata.creditrestriction.open',   rights: [],      icon: 'open',     text: 'Open betalingskorting'     ,     dialog: {open:   "creditrestriction"       }},
    { name: 'masterdata.creditrestriction.create', rights: [],      icon: 'create',   text: 'Nieuwe betalingskorting'   ,     dialog: {create: "creditrestriction"       }},
    { name: 'masterdata.creditrestriction.remove', rights: [],      icon: 'remove',   text: 'Verwijder betalingskorting',  },
    { name: 'masterdata.standardtexts.open',       rights: [],      icon: 'open',     text: 'Open standaard tekst'      ,     dialog: {open:   "standardtext"            }},
    { name: 'masterdata.standardtexts.create',     rights: [],      icon: 'create',   text: 'Nieuwe standaard tekst'    ,     dialog: {create: "standardtext"            }},
    { name: 'masterdata.standardtexts.remove',     rights: [],      icon: 'remove',   text: 'Verwijder standaard tekst'    },
    { name: 'masterdata.tariff.open',              rights: [],      icon: 'open',     text: 'Open tarief'               ,     dialog: {open:   "tariff"                  }},
    { name: 'masterdata.tariff.create',            rights: [],      icon: 'create',   text: 'Nieuwe tarief'             ,     dialog: {create: "tariff"                  }},
    { name: 'masterdata.tariff.remove',            rights: [],      icon: 'remove',   text: 'Verwijder tarief'          ,  },
    { name: 'masterdata.tariff.up',                rights: [],      icon: 'up',       text: 'Prioriteit omhoog'         ,  },
    { name: 'masterdata.tariff.down',              rights: [],      icon: 'down',     text: 'Prioriteit omlaag'         ,  },

    { name: 'settings.rightsgroup.open',           rights: [],      icon: 'open',     text: 'Open rechtengroep'         ,     dialog: { open:   "rightsgroup"            }},
    { name: 'settings.rightsgroup.create',         rights: [],      icon: 'create',   text: 'Nieuwe rechtengroep'       ,     dialog: { create: "rightsgroup"            }},
    { name: 'settings.rightsgroup.remove',         rights: [],      icon: 'remove',   text: 'Verwijder rechtengroep'    ,  },
    { name: 'settings.employee.open',              rights: [],      icon: 'open',     text: 'Open medewerker'           ,     dialog: { open:   "employee"               }},
    { name: 'settings.employee.create',            rights: [],      icon: 'create',   text: 'Nieuwe medewerker'         ,     dialog: { create: "employee"               }},
    { name: 'settings.employee.remove',            rights: [],      icon: 'remove',   text: 'Verwijder medewerker'      ,  },
    { name: 'settings.employee_tariff.open',       rights: [],      icon: 'open',     text: 'Open tarief'               ,     dialog: { open:   "employee_tariff"        }},
    { name: 'settings.user.open',                  rights: [],      icon: 'open',     text: 'Open gebruiker'            ,     dialog: { open:   "companyuser"            }},
    { name: 'settings.user.remove',                rights: [],      icon: 'remove',   text: 'Verwijder gebruiker'       ,  },
    { name: 'settings.user.invitation.create',     rights: [],      icon: 'create',   text: 'Nieuwe gebruiker'          ,     dialog: { create: "companyuserinvitation"  }},
    { name: 'settings.user.invitation.open',       rights: [],      icon: 'open',     text: 'Open uitnodiging'          ,     dialog: { open:   "companyuserinvitation"  }},
    { name: 'settings.user.invitation.remove',     rights: [],      icon: 'remove',   text: 'Verwijder uitnodiging'     ,  },
    { name: 'settings.emailtemplate.open',         rights: [],      icon: 'open',     text: 'Open email template'       ,     dialog: { open:   "emailtemplate"          }},
    { name: 'settings.emailtemplate.modify',       rights: [],      icon: 'open',     text: 'Open email template'       ,     dialog: { open:   "emailtemplate"          }},
    { name: 'settings.emaillogo.modify',           rights: [],      icon: 'open',     text: 'Open email logo'           ,     dialog: { open:   "emaillogo"              }},
    { name: 'settings.company.modify',             rights: [],      icon: 'modify',   text: 'Wijzig bedrijfsgegevens'   , },
    { name: 'settings.tendergroup.open',           rights: [],      icon: 'open',     text: 'Open offertegroep'         ,     dialog: { open:   "tendergroup"          }},
    { name: 'settings.tendergroup.modify',         rights: [],      icon: 'modify',   text: 'Wijzig offertegroep'       , },
    { name: 'settings.tendergroup.create',         rights: [],      icon: 'create',   text: 'Nieuwe offertegroep'       ,     dialog: { create: "tendergroup"               }},

    { name: 'relation.open',                       rights: [],      icon: 'open',     text: 'Open relatie'              ,     dialog: { open:   "relation"               }},
    { name: 'relation.modify',                     rights: [],      icon: 'modify',   text: 'Relatie aanpasen'          ,  },
    { name: 'relation.create',                     rights: [],      icon: 'create',   text: 'Nieuwe relatie'            ,     dialog: { create: "relation"               }},
    { name: 'relation.remove',                     rights: [],      icon: 'remove',   text: 'Verwijder relatie'         ,  },
    { name: 'relation.merge',                      rights: [],      icon: 'merge',    text: 'Relaties samenvoegen'      ,     dialog: { create: "relation.merge"         }},
    { name: 'relation.address.open',               rights: [],      icon: 'open',     text: 'Open adres'                ,     dialog: { open:   "relation.address"       }},
    { name: 'relation.address.create',             rights: [],      icon: 'create',   text: 'Nieuw adres'               ,     dialog: { create: "relation.address"       }},
    { name: 'relation.contact.open',               rights: [],      icon: 'open',     text: 'Open contactpersoon'       ,     dialog: { open:   "relation.contact"       }},
    { name: 'relation.contact.create',             rights: [],      icon: 'create',   text: 'Nieuwe contactpersoon'     ,     dialog: { create: "relation.contact"       }},
    { name: 'relation.bankaccount.open',           rights: [],      icon: 'open',     text: 'Open bankrekening'         ,     dialog: { open:   "relation.bankaccount"   }},    
    { name: 'relation.bankaccount.create',         rights: [],      icon: 'create',   text: 'Nieuwe bankrekening'       ,     dialog: { create: "relation.bankaccount"   }},    
    { name: 'relation.bankaccount.remove',         rights: [],      icon: 'remove',   text: 'Verwijder bankrekening'    , },    
    { name: 'manday.open',                         rights: [],      icon: 'open',     text: 'Open mandagenstaat'        ,     dialog: { open:   "manday"                 }},
    { name: 'manday.modify',                       rights: [],      icon: 'modify',   text: 'Mandagenstaat aanpassen'   },
    { name: 'manday.create',                       rights: [],      icon: 'create',   text: 'Nieuwe mandagenstaat'      ,     dialog: { create: "manday"                 }},
    { name: 'manday.remove',                       rights: [],      icon: 'remove',   text: 'Verwijder mandagenstaat'   , },
    { name: 'manday.archive',                      rights: [],      icon: 'archive',  text: 'Mandagenstaat is gereed'   , },
    { name: 'manday.copy',                         rights: [],      icon: 'copy',     text: 'Kopieer deze mandagenstaat',     dialog: { copy:   "manday"                 }},
    { name: 'manday.createinvoice',                rights: [],      icon: 'modify',   text: 'Maak factuur'              , },
    { name: 'product.open',                        rights: [],      icon: 'open',     text: 'Open artikel'              ,     dialog: { open:  "product"                 }},
    { name: 'product.create',                      rights: [],      icon: 'create',   text: 'Nieuw artikel'             ,     dialog: { create:"product"                 }},
    { name: 'product.modify',                      rights: [],      icon: 'modify',   text: 'Pas artikel aan'           ,    },
    { name: 'product.remove',                      rights: [],      icon: 'remove',   text: 'Verwijder artikel'         , },
    { name: 'product.copy',                        rights: [],      icon: 'copy',     text: 'Kopieer dit artikel'       ,     dialog: { copy:    "product"               }},

    { name: 'product.group.open'  ,                rights: [],      icon: 'open',     text: 'Open artikelgroep'         ,     dialog: { open:   "productgroup"           }},
    { name: 'product.group.create',                rights: [],      icon: 'create',   text: 'Nieuwe artikelgroep'       ,     dialog: { create: "productgroup"           }},
    { name: 'product.group.remove',                rights: [],      icon: 'remove',   text: 'Verwijder artikelgroep'    , },
    { name: 'product.vendor.open'  ,               rights: [],      icon: 'open',     text: 'Open leverancier'          ,     dialog: { open:   "vendor"                 }},
    { name: 'product.vendor.create',               rights: [],      icon: 'create',   text: 'Nieuwe leverancier'        ,     dialog: { create: "vendor"                 }},
    { name: 'product.vendor.remove',               rights: [],      icon: 'remove',   text: 'Verwijder leverancier'     , },
    { name: 'product.supplier.open'  ,             rights: [],      icon: 'open',     text: 'Open fabrikant'            ,     dialog: { open:   "supplier"               }},
    { name: 'product.supplier.create',             rights: [],      icon: 'create',   text: 'Nieuwe fabrikant'          ,     dialog: { create: "supplier"               }},
    { name: 'product.supplier.remove',             rights: [],      icon: 'remove',   text: 'Verwijder fabrikant'       , },

    //////////////////////////////////////////////////////////////////////////////////////////
    //
    // Purchase Invoices
    //
    //////////////////////////////////////////////////////////////////////////////////////////
    { name: 'purchaseinvoice.open',             rights: [],     icon: 'open',        text: 'Open inkoopfactuur'         ,    dialog: { open:   "purchaseinvoice"         }},
    { name: 'purchaseinvoice.create',           rights: [],     icon: 'create',      text: 'Nieuwe inkoopfactuur'       ,    dialog: { create: "purchaseinvoice.create"         }},
    { name: 'purchaseinvoice.modify',           rights: [r.purchaseinvoice_modify],     icon: 'modify',      text: 'Pas inkoopfactuur aan'      , },
    { name: 'purchaseinvoice.backtonew',        rights: [],     icon: 'modify',      text: "Terug naar status 'nieuw'"  , },
    { name: 'purchaseinvoice.accept',           rights: [],     icon: 'accept',      text: 'Accepteer inkoopfactuur'    , },
    { name: 'purchaseinvoice.reject',           rights: [],     icon: 'reject',      text: 'Weiger inkoopfactuur'       , },
    { name: 'purchaseinvoice.setpaid',          rights: [],     icon: 'setpaid',     text: 'Markeer als betaald'        , },
    { name: 'purchaseinvoice.setunpaid',        rights: [],     icon: 'setunpaid',   text: 'Markeer als niet betaald'   , },

    //////////////////////////////////////////////////////////////////////////////////////////
    //
    // Payment Orders
    //
    //////////////////////////////////////////////////////////////////////////////////////////
    { name: 'paymentorder.open',                      rights: [],       icon: 'open',        text: 'Open betaalopdracht'        ,    dialog: { open:   "paymentorder"  }},
    { name: 'paymentorder.create',                    rights: [],       icon: 'create',      text: 'Nieuwe betaalopdracht'      ,    dialog: { create: "paymentorder"  }},
    { name: 'paymentorder.modify',                    rights: [],       icon: 'modify',      text: 'Pas betaalopdracht aan'     , },
    { name: 'paymentorder.remove',                    rights: [],       icon: 'remove',      text: 'Verwijder betaalopdracht'   , },
    { name: 'paymentorder.download',                  rights: [],       icon: 'download',    text: 'Download betaalopdracht'    , },
    { name: 'paymentorder.download.g' ,               rights: [],       icon: 'download',    text: 'Download G-betaalopdracht'  , },
    { name: 'paymentorder.download.ng',               rights: [],       icon: 'download',    text: 'Download betaalopdracht'    , },
    { name: 'paymentorder.setpaid',                   rights: [],       icon: 'modify',      text: 'Zet als betaald'            , },

    //////////////////////////////////////////////////////////////////////////////////////////
    //
    // Sales Invoices
    //
    //////////////////////////////////////////////////////////////////////////////////////////
    { name: 'salesinvoice.open',                      rights: [],       icon: 'open',   text: 'Open verkoopfactuur'             ,   dialog: { open: "salesinvoice"  }},
    { name: 'salesinvoice.pdf',                       rights: [],       icon: 'open',   text: 'Toon PDF'                        , },
    { name: 'salesinvoice.create',                    rights: [],       icon: 'create', text: 'Nieuwe verkoopfactuur'           ,   dialog: { create:"salesinvoice"  }},
    { name: 'salesinvoice.modify',                    rights: [],       icon: 'modify', text: 'Pas verkoopfactuur aan'          , },
    { name: 'salesinvoice.backtoconcept',             rights: [],       icon: 'back', text: "Terug naar status Concept"       , },
    { name: 'salesinvoice.remove',                    rights: [],       icon: 'remove', text: 'Verwijder verkoopfactuur'        , },
    { name: 'salesinvoice.copy',                      rights: [],       icon: 'copy', text: 'Maak kopie van deze factuur'     , dialog:   {open: "salesinvoice" }},
    { name: 'salesinvoice.credit',                    rights: [],       icon: 'credit', text: 'Maak credit factuur'             , dialog:   {open: "salesinvoice" }},
    { name: 'salesinvoice.send',                      rights: [],       icon: 'modify', text: 'Verstuur verkoopfactuur'         , },
    { name: 'salesinvoice.example',                   rights: [],       icon: 'open',   text: 'Toon voorbeeld '                 , },
    { name: 'salesinvoice.sendreminder',              rights: [],       icon: 'reminder', text: 'Stuur herinnering'               , dialog:   {open: "salesinvoicereinder" }},
    { name: 'salesinvoice.payment.overview',          rights: [],       icon: 'payment', text: 'Betalingen' , dialog:   {open: "salesinvoicepaymentoverview" }},
    { name: 'salesinvoice.payment.open',              rights: [],       icon: 'payment', text: 'Open betaling'                    , dialog:   {open: "salesinvoicepayment" }},
    { name: 'salesinvoice.payment.create',            rights: [],       icon: 'payment', text: 'Registreer betaling'              , dialog:   {create: "salesinvoicepayment" }},
    { name: 'salesinvoice.payment.remove',            rights: [],       icon: 'remove', text: 'Verwijder betaling'               },
    { name: 'salesinvoice.log',                       rights: [],       icon: 'log', text: 'Factuurgeschiedenis'               , dialog:   {open: "salesinvoicelog" }},
    
    //////////////////////////////////////////////////////////////////////////////////////////
    //
    // Tender
    //
    //////////////////////////////////////////////////////////////////////////////////////////
    { name: 'tender.open' ,                         rights: [],     icon: 'open',   text: 'Open offerte'                      , dialog: {open:  "tender"  }},
    { name: 'tender.create' ,                       rights: [],     icon: 'create', text: 'Nieuwe offerte'                    , dialog: {create:"tender"  }},
    { name: 'tender.modify' ,                       rights: [],     icon: 'modify', text: 'Pas offerte aan'                   , },
    { name: 'tender.remove' ,                       rights: [],     icon: 'remove', text: 'Verwijder offerte'                 , },
    { name: 'tender.copy' ,                         rights: [],     icon: 'modify', text: 'Pas offerte aan'                   , },
    { name: 'tender.send' ,                         rights: [],     icon: 'modify', text: 'Verstuur offerte'                  , },
    { name: 'tender.example',                       rights: [],     icon: 'open',   text: 'Toon voorbeeld '                   , },

    //////////////////////////////////////////////////////////////////////////////////////////
    //
    // Project
    //
    //////////////////////////////////////////////////////////////////////////////////////////
    {name: 'project.open',                          rights: [],     icon: 'open',   text: 'Open project'                      ,    dialog: {open:  "project"  }},
    {name: 'project.create',                        rights: [],     icon: 'create', text: 'Nieuwe project'                    ,    dialog: {create:"project"  }},
    {name: 'project.modify',                        rights: [],     icon: 'modify', text: 'Pas project aan'                   , },
    {name: 'project.remove',                        rights: [],     icon: 'remove', text: 'Verwijder project'                 , },
    {name: 'project.copy',                          rights: [],     icon: 'modify', text: 'Pas project aan'                   , },

    //////////////////////////////////////////////////////////////////////////////////////////
    //
    // Hours
    //
    //////////////////////////////////////////////////////////////////////////////////////////
    { name: 'hours.open',                           rights: [],     icon: 'open',   text: 'Open uren'                       ,    dialog: { open:  "hours"  }},
    { name: 'hours.create',                         rights: [],     icon: 'create', text: 'Nieuwe uren'                     ,    dialog: { create:"hours"  }},
    { name: 'hours.modify',                         rights: [],     icon: 'modify', text: 'Pas uren aan'                    , },
    { name: 'hours.remove',                         rights: [],     icon: 'remove', text: 'Verwijder uren'                  , },
    { name: 'hours.copy',                           rights: [],     icon: 'modify', text: 'Pas uren aan'                    , },

    //////////////////////////////////////////////////////////////////////////////////////////
    //
    // Workorder
    // 
    //////////////////////////////////////////////////////////////////////////////////////////
    { name: 'workorder.open',                      rights: [],      icon: 'open',     text: 'Open werkbon'                    ,   dialog: { open: "workorder"  }},
    { name: 'workorder.create',                    rights: [],      icon: 'create',   text: 'Nieuwe werkbon'                  ,   dialog: { create:"workorder"  }},
    { name: 'workorder.search',                    rights: [],      icon: 'zoeken',   text: 'Werkbonnen zoeken'                  ,   dialog: { create:"workorder"  }},
    { name: 'workorder.pdf',                       rights: [],      icon: 'open',     text: 'Toon PDF'                        , },
    { name: 'workorder.modify',                    rights: [],      icon: 'modify',   text: 'Pas werkbon aan'          , },
    { name: 'workorder.remove',                    rights: [],      icon: 'remove',   text: 'Verwijder werkbon'        , },
    { name: 'workorder.copy',                      rights: [],      icon: 'copy',     text: 'Maak kopie van deze werkbon'     , dialog:   {open: "workorder" }},
    { name: 'workorder.send',                      rights: [],      icon: 'modify',   text: 'Verstuur werkbon'         , },
    { name: 'workorder.assign',                    rights: [],      icon: 'modify',   text: 'Werkbon toewijzen'               , },
    { name: 'workorder_planboard',                  rights: [r.workorder_planning_access],      icon: 'modify',   text: 'Planbord'               , },
    
    //////////////////////////////////////////////////////////////////////////////////////////
    //
    // planning
    // 
    //////////////////////////////////////////////////////////////////////////////////////////
    { name: 'planning.modify',                    rights: [],      icon: 'modify',   text: 'Pas afspraak aan'          , },
    { name: 'planning.remove',                    rights: [],      icon: 'remove',   text: 'Verwijder afspraak'        , },


    //////////////////////////////////////////////////////////////////////////////////////////
    //
    // Accountancy
    //
    //////////////////////////////////////////////////////////////////////////////////////////
    { name: 'accountancy.open',                     rights: [],     icon: 'open',     text: 'Open boekhoudkoppeling'           },
    { name: 'accountancy.create',                   rights: [],     icon: 'create',   text: 'Maak boekhoudkoppeling'           },
    { name: 'accountancy.modify',                   rights: [],     icon: 'modify',   text: 'Pas boekhoudkoppeling aan'        },
    { name: 'accountancy.remove',                   rights: [],     icon: 'remove',   text: 'Verwijder boekhoudkoppeling'      },
    { name: 'accountancy.purchase.log' ,            rights: [],     icon: 'log',      text: 'Toon log'                         },
    { name: 'accountancy.purchase.pdf' ,            rights: [],     icon: 'pdf',      text: 'Toon factuur'                     },
    { name: 'accountancy.purchase.download',        rights: [],     icon: 'download', text: 'Toon info uit boekhouding'        },
    { name: 'accountancy.purchase.sync',            rights: [],     icon: 'link',     text: 'Stuur naar boekhouding'           },
    { name: 'accountancy.purchase.debug',           rights: [],     icon: 'info',     text: 'Toon debug info'                  },
    { name: 'accountancy.sales.log' ,               rights: [],     icon: 'log',      text: 'Toon log'                         },
    { name: 'accountancy.sales.pdf' ,               rights: [],     icon: 'pdf',      text: 'Toon factuur'                     },
    { name: 'accountancy.sales.download',           rights: [],     icon: 'download', text: 'Toon info uit boekhouding'        },
    { name: 'accountancy.sales.sync',               rights: [],     icon: 'link',     text: 'Stuur naar boekhouding'           },
    { name: 'accountancy.sales.debug',              rights: [],     icon: 'info',     text: 'Toon debug info'                  },
    { name: 'accountancy.relation.log' ,            rights: [],     icon: 'log',      text: 'Toon log'                         },
    { name: 'accountancy.relation.show',            rights: [],     icon: 'pdf',      text: 'Toon relatie'                     },
    { name: 'accountancy.relation.download',        rights: [],     icon: 'download', text: 'Toon info uit boekhouding'        },
    { name: 'accountancy.relation.sync',            rights: [],     icon: 'link',     text: 'Stuur naar boekhouding'           },
    { name: 'accountancy.relation.debug',           rights: [],     icon: 'info',     text: 'Toon debug info'                  },

    { name: 'customfield.open',                     rights: [],     icon: 'open',     text: 'Open invoerveld'      ,     dialog: {open:   "customfield" }},
    { name: 'customfield.create',                   rights: [],     icon: 'create',   text: 'Nieuw invoerveld'     ,     dialog: {create: "customfield" }},
    { name: 'customfield.remove',                   rights: [],     icon: 'remove',   text: 'Verwijder invoerveld' ,  },


];

export default actions;