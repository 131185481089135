import { clsModel, fnCreate } from '@cls/clsModel'
import { standardtexts as api } from '@/app/api'

var modelName = "standardtext";
const fields = ['id', 'title', 'text'];

class clsStandardText extends clsModel {

    title = null;
    text = null;
    
//    get modelRep() {
//        return this.title;
//    }
        
    constructor() {
        super({
          api: api,   
          modelName: modelName, 
          mandatoryFields: ['title', 'text'],

          fillable: fields
        })
    } 
        
 }
 export default fnCreate(clsStandardText , 'clsStandardText');
