import { render, staticRenderFns } from "./DialogConfirm.vue?vue&type=template&id=e4f0fab8&scoped=true"
import script from "./DialogConfirm.vue?vue&type=script&lang=js"
export * from "./DialogConfirm.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../frontend.kantoor/app/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e4f0fab8",
  null
  
)

export default component.exports