<template>
    <div>
        <LocalTable :model="model" modelLinesProp='bankaccounts' no-hover no-dense :dt="dt" class="full-height paymentorderlines">

            <template v-slot:menu ="{ item }">
                <ActionMenuItem action="relation_bankaccount.open" @click="dlg.open('relation_bankaccount', item)">Open bankrekening</ActionMenuItem>
                <v-divider></v-divider>
                <ActionMenuItem :disabled="model.disabled" action="relation_bankaccount.remove" icon="remove" @click="()=>onRemoveBankAccount(item)">Verwijder bankrekening</ActionMenuItem>
            </template>

            <template v-slot:item.total_payable ="{ item }">                                
                <span v-if="item.hasDiscount">
                    <Amount class="" :value="item.total_minus_discount" currency="EUR"></Amount>
                    <Amount class="ml-2 text-small text-line-through" :value="item.total_payable" currency="EUR"></Amount>
                </span>
                <span v-else>
                    <Amount class="" :value="item.total_payable" currency="EUR"></Amount>
                </span>
            </template>
            
            <template v-slot:item.type ="{ item }">
                <span> {{ repType(item) }}</span>
            </template>
            <template v-slot:item.status ="{ item }">
                <span> {{ repStatus(item) }}</span>
            </template>


        </LocalTable>
    </div>
</template>



<script setup>
    import LocalTable from '@datatable/LocalTable'  
    import clsLocalTable from '@cls/clsLocalTable'
    import ActionMenuItem from "@controls/menu/ActionMenuItem.vue";
    import string from '@lib/string'
    import ActionButton from '@controls/buttons/ActionButton'
    import bool from '@shared/lib/bool'
    import noty from '@shared/lib/noty'
    import dlg from '@app/dlg'
    import { ref} from 'vue'
    const props = defineProps({
        model: {
            type: [Object]
        },

    });

    var headers = [
        { type: 'cmd' },
        { text: "IBAN", value: "iban", type: "open", open:"relation_bankaccount" },
        { text: "Type", value: "type" },
        { text: "Status", value: "status", fnClass: (value, VALUE, item) => item.archived_at ? "text-error" : "text-success" },
        { visible: false, text: "BIC", value: "bic" },
        
        { text: "Aangemaakt",      value: "created_at" },
        { text: "Aangemaakt door", value: "created_by" },
        { text: "Gearchiveerd",      value: "archived_at" },
        { text: "Gearchiveerd door", value: "archived_by" },
    ]

    let dt = ref(new clsLocalTable("dt_relation_bankaccounts", headers, {
        noDataText: "Er zijn nog geen bankrekeningen toegevoegd", 
        model: props.model, 
        modelLinesProp: "bankaccounts"    
    })).value;

    function repType(item) {
        if (bool.isTrue(item.is_g_account)) {
            return "G-rekening";
        }
        return "Bankrekening";
    }
    function repStatus(item) {
        if (item.archived_at) {
            return "niet actief";
        }
        return "actief";
    }

    async function onRemoveBankAccount(item) {
        try {
            await noty.confirm("Weet u zeker dat u deze bankrekening bij deze relatie wilt verwijderen?")
        } catch (e) {
            return;
        }
        props.model.removeBankAccount(item.id);
    }


</script>