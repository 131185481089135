import clsApi from '@cls/clsApi'

class clsSalesInvoiceBulkReminderApi extends clsApi {

    loadSendData(id_relation) { 
        return this.post(`loadsenddata`, {id_relation: id_relation}); 
    }
    emailSend(model) { 
        return this.post(`sendviaemail`, model); 
    }
    async downloadSend(id_relation) {         
        return await this.downloadPostRaw(`sendviadownload`, {id_relation: id_relation});  
    }
    async examplePdf(id_relation) {         
        return await this.postRaw(`examplepdf`, {id_relation: id_relation});  
    }
}

export default clsSalesInvoiceBulkReminderApi;
