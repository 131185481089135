import eventbus from '@app/eventbus'
import fnExpose from '@shared/lib/expose'

//
// Helper for showing alerts, confirmations, snackbars et cetera.

//      import noty from '@lib/noty'

var noty = {};

/*
 *      noty.alert("Er is iets niet in de haak.");
 *      noty.alert("Er is iets niet in de haak.", {title: 'Let ERG goed op'});
 *      noty.alert("Er zijn warnings.", {warnings: ['Deze is al eerder ingevoerd', 'De tekst is wel erg lang']});
 */
noty.alert = function(body, options) {
    options = options || {}
    options.body = body;
    eventbus.dialog.alert(options);
}

/*
 *      noty.snack.info("The data is changed.");
 *      noty.snack.error("Somethings wrong!", {timeout: 10000});
 */
noty.snack = {
    info: function(msg, options) {
        options = options ||{};
        options.text = msg;
        eventbus.snackbar.info(options);
    }, 
    success: function(msg, options) {
        options = options ||{};
        options.text = msg;
        options.type = 'success';
        eventbus.snackbar.info(options);
    },
    error: function(msg, options) {
        options = options ||{};
        options.type = "error";
        options.text = msg;
        eventbus.snackbar.info(options);
    }
}

/*
 *      noty.confirm("De data wordt definitief verwijderd.", "Weet u het zeker?")
 *          .then( () => removeData())
 *          .catch( () => noty.snack.error("Somethings wrong!"))
 *
 *      or: 
 *
 *      try {
 *          await noty.confirm("De data wordt definitief verwijderd.", {title: "Weet u het echt zeker?"});
 *          removeData();        
 *      } catch (e) {
 *          noty.snack.error("Somethings wrong!");
 *      }
 * 
 *      noty.confirm("Melding body", {title: 'Let op hoor', warnings: ["code geel", "code oranje"]})
 */

noty.confirm = async function(msg, options) {
    options = options ||{};
    options.body = msg;
    return eventbus.dialog.confirm.promise(options);
}

/**
 * Helper to avoid having to catch every time. 
 * Returns: 
 *      true when user confirmed (pressed ok)
 *      false when user canceled (pressed cancel)
 *
 * throws when another failure occurs.
 */
noty.tryConfirm = async function(msg, options) {
    try {
        await noty.confirm(msg, options);
    }
    catch (e) {
        if (e.canceled) {
            return false;
        }
        throw e;
    }
    return true;
}

/**
 * Very simple prompt which just request one input value. 
 * 
 * @param {*} label   - the lable for the input value
 * @param {*} title   - the title of the prompt 
 * @param {*} options - any options
 * @returns 
 */
noty.prompt = async function(label, title, value, options) {
    options = options ||{};
    options.label = label;
    options.title = title;
    options.value = value;
    return eventbus.dialog.prompt.promise(null /* no model name */, options);
}

/**
 * Very simple prompt which just request the users password. 
 * 
 * @param {*} label   - the lable for the input value
 * @param {*} title   - the title of the prompt 
 * @param {*} options - any options
 * @returns 
 */
noty.promptpassword = async function(label, title, value, options) {
    options = options ||{};
    options.label = label;
    options.title = title;
    options.value = value;
    return eventbus.dialog.promptpassword.promise(null /* no model name */, options);
}

fnExpose(noty, 'noty')
export default noty;