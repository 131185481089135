<template>
    <div>

        <v-form ref="formLetter" lazy-validation>
            <v-card class="mb-2" dense>
            <v-tabs v-model="tab">        
                <v-tab>Inleidende tekst</v-tab>                
                <v-tab>Afsluitende tekst</v-tab>                
            </v-tabs>
            <v-divider></v-divider>
            <v-tabs-items class="relation-letter-tabs mt-2" v-model="tab">                
                <v-tab-item class="letter-start" transition="none" reverse-transition="none">                        
                    <v-card-subtitle class="opacity-40">
                        Optioneel. Wanneer ingevuld wordt deze tekst ingevoegd boven de offerteregels.
                    </v-card-subtitle>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-row dense>
                            <v-col sm="12" xl="6">
                                <RichTextArea :disabled="model.isOpenOrLater" xl :model='model' placeholder='Offerte brief' v-model='model.tend_letter'>
                                    <template v-slot:toolbar-cmd-right="{editor}">
                                        <span class="ml-4">Begin nieuwe pagina na inleidende tekst</span>
                                        <span class="mt-2 mr-2 ml-4">
                                            <Checkbox :disabled="model.isOpenOrLater" class="d-inline-block width-auto" switcher v-model="(model.paragraphs[0]||{}).thp_new_page"></Checkbox>
                                        </span>
                                    </template>
                                    <template v-slot:toolbar-right="{editor}">
                                        <ActionButton icon="text" class="mr-2" sub action="tender.modify" dusk="btn-select-tender-letter" :model="model" ml :disabled="model.isOpenOrLater"  @click='()=>addStandardText(editor)'>Standaard Tekst</ActionButton>
                                    </template>
                                </RichTextArea>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-tab-item>

                <v-tab-item class="letter-start" transition="none" reverse-transition="none">                        
                    <v-card-subtitle class="opacity-40">
                        Optioneel. Wanneer ingevuld wordt deze tekst ingevoegd onder de offerteregels.
                    </v-card-subtitle>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-row dense>
                            <v-col sm="12" xl="6">
                                <RichTextArea :disabled="model.isOpenOrLater" xl :model='model' placeholder='Offerte brief' v-model='model.tend_closing_text'>
                                    <template v-slot:toolbar-cmd-right="{editor}">
                                        <span class="ml-4">Begin afsluitende tekst op nieuwe pagina</span>
                                        <span class="mt-2 mr-2 ml-4">
                                            <Checkbox :disabled="model.isOpenOrLater" class="d-inline-block width-auto" switcher v-model="model.tend_closing_text_new_page"></Checkbox>
                                        </span>
                                    </template>
                                    <template v-slot:toolbar-right="{editor}">
                                        <ActionButton icon="text" class="mr-2" sub action="tender.modify" dusk="btn-select-tender-letter" :model="model" ml :disabled="model.isOpenOrLater"  @click='()=>addStandardText(editor)'>Standaard Tekst</ActionButton>
                                    </template>
                                </RichTextArea>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-tab-item>

            </v-tabs-items>
            </v-card>
        </v-form>
    </div>
</template>



<script setup>
    
    import RichTextArea from '@controls/input/RichTextArea'
    import ActionButton from '@controls/buttons/ActionButton'
    import Checkbox from '@controls/check/Checkbox.vue'
    import {ref} from 'vue'

    const tab = ref(0);

    import dlg from '@app/dlg'
    const props = defineProps({
        model: {
            type: [Object]
        },
    });

    async function addStandardText(editor) {
        let selected = null;
        try { 
            selected = await dlg.promised.open("select_standardtext", {multiselect:false})
            if (!selected || !selected.length) {
                return;
            }
        } catch (e) { return; }  // canceled
        if (selected && selected.length && selected[0].text) {
            editor.chain().focus().insertContent(selected[0].text).run()
        }
    }

</script>
