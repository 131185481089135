<template>

<div>    
    <ActionCombo v-if="items.length > 1" :noClearable="noClearable" :placeholder="placeholder" :skeleton="compSkeleton" :disabled="compDisabled" :rules="rules" v-model="dvalue" :items="items">
    </ActionCombo>
    <TextField v-else disabled :skeleton="compSkeleton" sc_value="singleItem" v-model="singleItem"></TextField>
</div>
</template>

<script setup>

    // import CompanyIBAN from '@/ui/controls/CompanyIBAN'
    // <CompanyIBAN :model="model" :rules="model.rules.iban" v-model="model.iban"></CompanyIBAN>
    // or 
    // <CompanyIBAN :disabled="model.disabled" :rules="model.rules.iban" v-model="model.iban"></CompanyIBAN>

    const props = defineProps({
        disabled: {
            type: [Boolean]
        },
        rules: {
            type: [Array]
        },
        noClearable: {
            type: [Boolean],
            default: false
        },        
        skeleton: {
            type: [Boolean]
        },
        value: {
            type: [String, Number]
        },
        placeholder: {
            type: [String],
            default: 'IBAN'
        },
        model: {
            type: [Object]
        },
        g: {
            type: [Boolean]
        }

    })
    
    import ActionCombo from '@controls/combo/ActionCombo'
    import {company} from '@app/settings';
    import {onMounted, computed} from 'vue'
    import string from '@lib/string'
    import TextField from '@controls/input/TextField'

  const emit = defineEmits(['input'])

    const dvalue = computed({
        get() {
            return props.value
        },
        set: function(v) {
            emit('input', v)
        }	
    })

    const compSkeleton = computed({
        get() {
            if (props.skeleton) {
                return true;
            }
            if (!props.model) {
                return false;
            }
            return props.model.isDataLoading;
        }
    });
    const compDisabled = computed({
        get() {
            if (props.disabled) {
                return true;
            }
            if (!props.model) {
                return false;
            }
            return props.model.disabled;
        }
    });
    
    const items = computed({
        get() {
            let items = [];
            if (props.g) {
                if (dvalue.value && !string.isInRange(dvalue.value, company.ibang)) {
                    items.push({name: dvalue.value, id: dvalue.value});
                }
                if (string.isNotEmpty(company.ibang)) {
                    items.push({name: company.ibang, id: company.ibang});
                }
            } else {
                if (dvalue.value && !string.isInRange(dvalue.value, company.iban1, company.iban2)) {
                    items.push({name: dvalue.value, id: dvalue.value});
                }
                if (string.isNotEmpty(company.iban1)) {
                    items.push({name: company.iban1, id: company.iban1});
                }
                if (string.isNotEmpty(company.iban2)) {
                    items.push({name: company.iban2, id: company.iban2});
                }
            }
            return items;
        },
    })

    const singleItem = computed({
        get() {
            if (!items.value || !items.value.length) {
                return "";
            }
            return items.value[0].name;
        },
    })

    onMounted(() => {
        if (!window.omt) {
            window.omt = 0;
        }
        window.omt++;
    })

</script>
