<template>
    <div>
        <v-simple-table dense class="header-contrast no-hover border-last-row border-header-row">
            <thead>
                <th class="pl-4 pa-2 pl-2 text-left">Urentype</th>
                <th class="pr-4 pa-2 w-3 text-right">Bedrag</th>
                <th class="pa-1 w-1 "></th>
            </thead>
            <tbody>
                <tr v-if="!model.budgethours.length">
                    <td colspan="4">Er zijn geen urenregels gemaakt</td>
                </tr>
                
                <tr v-for='(line, index) in model.budgethours' :key='`bc-${index}`'>
                    <td class="pa-1">
                        <HourTypeCombo no-border scopen="false" sccreate="false" :model='model' placeholder="Urentype" v-model='line.id_hour_type'></HourTypeCombo>
                    </td>
                    <td class="pa-1">
                        <NumberField no-border amount :model='model' v-model='line.amount' ></NumberField>
                    </td>
                    <td class="pa-0">                 
                        <ActionButton ml dusk='btn-line-remove' :model="model" icon-button icon="remove" 
                            action="project.modify" @click="() => removeLine(line)">
                        </ActionButton>
                    </td>
                </tr>

            </tbody>
            <tfoot class="border-top">
                <td class="pa-2 pl-4">
                    <ActionButton :model="model" action="project.modify" sub dusk="btn-add-costline" icon="add" @click='(evt)=>addLine(evt)'>Nieuwe urenregel</ActionButton>
                </td>
                <td class="pr-4 text-right text-bold">
                    <AmountText v-if="model.budgethours.length" v-model='model.totalHours'/>    
                </td>
                <td ></td>
            </tfoot>
        </v-simple-table>
    </div>

</template>

<script setup>
    import NumberField from '@controls/input/NumberField'
    import ActionButton from '@controls/buttons/ActionButton'
    import HourTypeCombo from '@controls/combo/HourType'
    import AmountText from '@controls/text/Amount.vue'

    import {ref} from 'vue'

    const props = defineProps({
        model: {
            type: [Object]
        },
    });

    function addLine(evt) {
        props.model.addHourLine();
    }
    function removeLine(item) {
        props.model.removeHourLine(item);
    }


</script>