<template>
    <Dialog :dialog="dlg">

        <v-form ref="form" @submit.prevent="()=>false" lazy-validation>
            <div class="mb-4">Om door te gaan met deze actie moet u eerst uw wachtwoord invoeren.</div>
            <v-row dense>
                <v-col>
                    <PasswordField inner-icon placeholder="Wachtwoord" xdense
                        :rules="model.rules.mandatory" 
                        v-model="model.password"></PasswordField>
                </v-col>
            </v-row>
        </v-form>
    </Dialog>

</template>

<script setup>

import eventbus from '@app/eventbus'
import ActionButton from '@shared/ui/controls/buttons/ActionButton'
import PasswordField from '@controls/input/PasswordField'
import Dialog from '@shared/ui/dialogs/ActionDialog'
import clsDialog from '@cls/clsDialog'
import useModel from '@shared/models/clsConfirmPassword'; 
import { ref} from 'vue'

const form = ref(null); 

let model = useModel();
let dlg = ref(
    new clsDialog("confirmpassword", "Voer uw wachtwoord in", model, [form], {
        noSubTitle: true,
        icon: 'security',
        save: 'Doorgaan'
    } )).value;


</script>
