import { clsModel, fnCreate } from '@cls/clsModel'
import { project_othercost as api } from '@/app/api'

var modelName = "project_othercosts";
const fields = [
    'id', 
    'id_project',
    'poc_name',
    'poc_amount',
    'poc_description',
    'id_project_chapter',
];

class clsProjectOtherCost extends clsModel {

    id_project = null;
    poc_name = null;
    poc_amount = null;
    poc_description = null;
    chapters = null;

    get useChapters() {
        return this.chapters && this.chapters.length;
    }

    get modelRep() {
        return this.poc_name;
    }    
    
    async load(id, params, extraData) {
        if (extraData && extraData.chapters) {
            this.chapters = extraData.chapters;
        } else {
            this.chapters = [];
        }
        return super.load(id, params, extraData);
    }

    fill(data, ignoreInitialState) {
        data.poc_amount = data.poc_amount ||0;
        return super.fill(data, ignoreInitialState);        
    }

    constructor() {
        super({
          api: api,   
          modelName: modelName, 
          fillable: fields
        })
    } 
        
 }
 export default fnCreate(clsProjectOtherCost , 'clsProjectOtherCost');
