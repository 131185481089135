<template>
    <Dialog :dialog="dlg">

        <AlertArea class="mb-4">
            Hiermee laat u deze offerte vervallen en maakt u een nieuwe versie. De nieuwe offerte is een kopie van de oude. Deze kunt u aanpassen en uitsturen ter acceptatie.
        </AlertArea>

        <v-form ref="form" lazy-validation>
            
                <Form>
                    <FormRow label="Actualiseer prijzen" col-label="8" col-value-right subLabel="Neem meest actuele prijzen over van artikelen">                    
                        <Checkbox :model="model" class="ml-4" switcher v-model="model.actualize"></Checkbox>
                    </FormRow>
                </Form>
        </v-form>

    </Dialog>
</template>



<script setup>
    import useModel from '@/models/tender/replacetender'
    import Form from '@controls/forms/Form'
    import FormRow from '@controls/forms/FormRow'
    import Dialog from '@shared/ui/dialogs/ActionDialog'
    import Checkbox from '@controls/check/Checkbox.vue'
    import AlertArea from '@shared/ui/markup/AlertArea'

    import { ref } from 'vue'
    import clsDialog from '@cls/clsDialog'

    const form = ref(null); 
    let model = useModel();

    let dlg = ref(
        new clsDialog("tender_replace", "Maak nieuwe versie", model, [form], {
            icon: 'pdf-plus',
            save: 'Ga door'
        } )).value;

    window.remo = model;
</script>
