<template>
    <v-simple-table dense class="footer-contrast header-contrast no-hover no-bordered no-border-bottom border-header-row-bottom">
        <thead>
            <th class="pl-4 pa-2 text-left">Meer/minderwerk</th>
            <th class="pr-2 pa-2 w-3_5 text-right">Begroot</th>
            <th class="pr-2 pa-2 w-3_5 text-right">Gefactureerd</th>
            <th class="col-contrast w-0_5 px-2"></th>
        </thead>
        <tbody>              
            <tr>
                <td>
                    <span @click="expandExtraWork=!expandExtraWork" class="cursor-pointer">
                        <span class="top--1 mr-2"><Icon :type='expandExtraWork?"collapse":"expand"'></Icon></span>
                        <span>Meerwerk</span>
                    </span>
                </td>
                <td class="pa-2 text-right col-contrast">
                    <AmountText leading-min :value='model.chapterResult.extraWork.total_amount'/>    
                </td>
                <td class="pa-2 text-right col-contrast">
                    <AmountText leading-min :value='model.chapterResult.extraWork.total_amount_invoiced'/>    
                </td>
                <td :class="clsColIcon">

                    <v-menu v-if="model.chapterResult.extraWork.warnings.length" :close-on-content-click="false" :nxudge-width="200" offset-x >
                        <template v-slot:activator="{ on, attrs }">
                            <span class="cursor-pointer " v-on="on"><Icon type='tip' :color="model.chapterResult.extraWork.warnings.length?'error':'primary'"></Icon></span>
                        </template>
                        <v-card>
                            <v-card-title>
                                Meerwerk
                            </v-card-title>
                            <v-divider v-if="model.chapterResult.extraWork.warnings.length">
                            </v-divider>
                                <v-card-text v-if="model.chapterResult.extraWork.warnings.length">
                                <UlCustom v-if="model.chapterResult.extraWork.warnings.length">
                                    <LiCustom warning v-for='(warning, index) in model.chapterResult.extraWork.warnings' :key="`extrawork_warning_${index}`">{{ warning }}</LiCustom>    
                                </UlCustom>
                            </v-card-text>
                        </v-card>
                    </v-menu>
                </td>
            </tr>          
            <tr v-if="expandExtraWork" class="opacity-50">
                <td>
                    <span class="ml-2 d-inline-block"><span class="d-inline-block"><Icon type="child-expanded" class=""></Icon></span></span>                                        
                    Goedgekeurd
                </td>
                <td class="pa-2 text-right col-contrast">
                    <AmountText leading-min :value='model.chapterResult.extraWork.amount_agreed'/>    
                </td>
                <td class="pa-2 text-right col-contrast">
                    <AmountText leading-min :value='model.chapterResult.extraWork.amount_agreed_invoiced'/>    
                </td>
                <td :class="clsColIcon">
                </td>
            </tr>          
            <tr v-if="expandExtraWork" class="opacity-50">
                <td>
                    <span class="ml-2 d-inline-block"><span class="d-inline-block"><Icon type="child-expanded" class=""></Icon></span></span>                                        
                    Ingediend
                </td>
                <td class="pa-2 text-right col-contrast">
                    <AmountText leading-min :value='model.chapterResult.extraWork.amount_submitted'/>    
                </td>
                <td class="pa-2 text-right col-contrast">
                    <AmountText leading-min :value='model.chapterResult.extraWork.amount_submitted_invoiced'/>    
                </td>

                <td :class="clsColIcon">
                </td>
            </tr>                                          
            <tr v-if="expandExtraWork" class="opacity-50">
                <td>
                    <span class="ml-2 d-inline-block"><span class="d-inline-block"><Icon type="child-expanded" class=""></Icon></span></span>                                        
                    Afgekeurd
                </td>
                <td class="pa-2 text-right col-contrast">
                    <AmountText leading-min :value='model.chapterResult.extraWork.amount_rejected'/>    
                </td>
                <td class="pa-2 text-right col-contrast">
                    <AmountText leading-min :value='model.chapterResult.extraWork.amount_rejected_invoiced'/>    
                </td>
                <td :class="clsColIcon">
                    
                </td>
            </tr>          
            <tr>
                <td>
                    <span @click="expandLessWork=!expandLessWork" class="cursor-pointer">
                        <span class="top--1 mr-2"><Icon :type='expandLessWork?"collapse":"expand"'></Icon></span>
                        <span>Minderwerk</span>
                    </span>
                </td>
                <td class="pa-2 text-right col-contrast">
                    <AmountText leading-min :value='model.chapterResult.lessWork.total_amount'/>    
                </td>
                <td class="pa-2 text-right col-contrast">
                    <AmountText leading-min :value='model.chapterResult.lessWork.total_amount_invoiced'/>    
                </td>
                <td :class="clsColIcon">
                    <v-menu v-if="model.chapterResult.lessWork.warnings.length" :close-on-content-click="false" :nxudge-width="200" offset-x >
                        <template v-slot:activator="{ on, attrs }">
                            <span class="cursor-pointer " v-on="on"><Icon type='tip' :color="model.chapterResult.lessWork.warnings.length?'error':'primary'"></Icon></span>
                        </template>
                        <v-card>
                            <v-card-title>
                                Meerwerk
                            </v-card-title>
                            <v-divider v-if="model.chapterResult.lessWork.warnings.length">
                            </v-divider>
                                <v-card-text v-if="model.chapterResult.lessWork.warnings.length">
                                <UlCustom v-if="model.chapterResult.lessWork.warnings.length">
                                    <LiCustom warning v-for='(warning, index) in model.chapterResult.lessWork.warnings' :key="`lesswork_warning_${index}`">{{ warning }}</LiCustom>    
                                </UlCustom>
                            </v-card-text>
                        </v-card>
                    </v-menu>
                </td>
            </tr>          
            <tr v-if="expandLessWork" class="opacity-50">
                <td>
                    <span class="ml-2 d-inline-block"><span class="d-inline-block"><Icon type="child-expanded" class=""></Icon></span></span>                                        
                    Goedgekeurd
                </td>
                <td class="pa-2 text-right col-contrast">
                    <AmountText leading-min :value='model.chapterResult.lessWork.amount_agreed'/>    
                </td>
                <td class="pa-2 text-right col-contrast">
                    <AmountText leading-min :value='model.chapterResult.lessWork.amount_agreed_invoiced'/>    
                </td>

                <td :class="clsColIcon">
                    
                </td>
            </tr>          
            <tr v-if="expandLessWork" class="opacity-50">
                <td>
                    <span class="ml-2 d-inline-block"><span class="d-inline-block"><Icon type="child-expanded" class=""></Icon></span></span>                                        
                    Ingediend
                </td>
                <td class="pa-2 text-right col-contrast">
                    <AmountText leading-min :value='model.chapterResult.lessWork.amount_submitted'/>    
                </td>
                <td class="pa-2 text-right col-contrast">
                    <AmountText leading-min :value='model.chapterResult.lessWork.amount_submitted_invoiced'/>    
                </td>

                <td :class="clsColIcon">
                    
                </td>
            </tr>                                          
            <tr v-if="expandLessWork" class="opacity-50">
                <td>
                    <span class="ml-2 d-inline-block"><span class="d-inline-block"><Icon type="child-expanded" class=""></Icon></span></span>                                        
                    Afgekeurd
                </td>
                <td class="pa-2 text-right col-contrast">
                    <AmountText leading-min :value='model.chapterResult.lessWork.amount_rejected'/>    
                </td>
                <td class="pa-2 text-right col-contrast">
                    <AmountText leading-min :value='model.chapterResult.lessWork.amount_rejected_invoiced'/>    
                </td>

                <td :class="clsColIcon">
                    
                </td>
            </tr>          
        </tbody>
        <tfoot>
            <tr>
                <td class="pl-4 pa-2 text-left text-bold">Totaal</td>
                <td class="pa-2 text-right" :class="model.chapterResult.extraWorkTotal.total_amount<0?'text-error':'text-success'">
                    <AmountText leading-min :value='model.chapterResult.extraWorkTotal.total_amount'/>    
                </td>
                <td class="pa-2 text-right" :class="model.chapterResult.extraWorkTotal.total_amount_invoiced<0?'text-error':'text-success'">
                    <AmountText leading-min :value='model.chapterResult.extraWorkTotal.total_amount_invoiced'/>    
                </td>
                <td :class="clsColIcon">
                    
                </td>
            </tr>
        </tfoot>
    
    </v-simple-table>
</template>

<script setup>

    import AmountText from '@controls/text/Amount.vue'
    import { ref } from 'vue';
    import Icon from '@controls/icons/Icon'

    import UlCustom from "@controls/list/UlCustom.vue";
    import LiCustom from "@controls/list/LiCustom.vue";

    const clsColIcon="col-contrast px-2";

    const expandExtraWork = ref(false);
    const expandLessWork = ref(false);
    const props = defineProps({
        model: {
            type: [Object]
        },
    });



</script>