//
// Usage: 
//  import const from '@app/const'
//
import clsConst from '@/app/clsConst'
import fnExpose from '@shared/lib/expose'

let obj = new clsConst();
fnExpose(obj, 'system', 'const')

export default obj;