<template>    

        <v-textarea            
            outlined 
            dense               
            :class="noBorder ? 'no-border' : ''"
            :value="dvalue"
            @input="dvalue = $event"        
            hide-details 
            :disabled="compDisabled||compSkeleton"        
            :rules="rules"
            :placeholder="placeholder"
            :rows="rows"
        >
            <template v-slot:append> 
                <slot name="append"></slot>
            </template>
            <template v-slot:append-outer> 
                <slot name="append-outer"></slot>
            </template>

        </v-textarea>

</template>


<script setup>
//   import TextArea from '@controls/input/TextArea'
//   <TextArea :model="model" placeholder="Notitie" v-model="model.note"></TextArea>

    const props = defineProps({
        disabled: {
            type: [Boolean]
        },
        rules: {
            type: [Array]
        },
        skeleton: {
            type: [Boolean]
        },
        value: {
            type: [String, Number]
        },
        placeholder: {
            type: [String],
            default: ''
        },
        rows: {
            type: [String, Number],
            default: 5
        },
        noBorder: {
            type: [Boolean]
        },
        model: {
            type: [Object]
        }

    })
    
    import {computed} from 'vue'

    const emit = defineEmits(['input'])

    const dvalue = computed({
        get() {
            return props.value
        },
        set: function(v) {
            emit('input', v)
        }	
    })
    const compSkeleton = computed({
        get() {
            if (props.skeleton) {
                return true;
            }
            if (!props.model) {
                return false;
            }
            return props.model.isDataLoading;
        }
    });
    const compDisabled = computed({
        get() {
            if (props.disabled) {
                return true;
            }
            if (!props.model) {
                return false;
            }
            return props.model.disabled;
        }
    });

</script>
