<template>
    <div>
        <DrawerContent :drawer="model.isMultiChapter">
            <template v-slot:drawer>

                <v-list dense class="bg-transparent navigation-menu">
                        <v-list-item @click="model.pr_chapter=0" :class="{'v-list-item--active': !model.pr_chapter}" class="cursor-pointer">
                            <v-list-item-content >
                                <v-list-item-title class="text-bold">
                                    <strong>Totaal overzicht</strong>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                    <v-list-item @click="model.pr_chapter=chapter.id" :class="{'v-list-item--active': chapter.id==model.pr_chapter}" class="cursor-pointer"  
                        v-for="(chapter, index) in model.chaptersv2" :value="chapter.id" :key="`chd${index}`">
                        <v-list-item-content >
                            <v-list-item-title>
                                {{chapter.name}}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>



            </template>

            <v-card dense>
            <v-card-text class="pa-0">
                <v-tabs dense v-model="tab" @change="onDossierTabChange">        
                <v-tab v-if="true && action.can('project.financials')">Resultaat</v-tab>
                <v-tab :disabled="!action.can('purchaseinvoice.open')">Inkoopfacturen</v-tab>
                <v-tab :disabled="!action.can('salesinvoice.open')">Verkoopfacturen</v-tab>
                <v-tab v-if="feature.canTender"  :disabled="!action.can('tender.open')">Offertes</v-tab>
                <v-tab v-if="feature.canSalesOrder" >Orders</v-tab>
            </v-tabs>
            <v-tabs-items class="project-dossier-tabs" v-model="tab">
                <v-tab-item v-if="true && action.can('project.financials')" transition="none" reverse-transition="none">
                    <TabDossierResultRework :model="model"/>
                </v-tab-item>
                <v-tab-item transition="none" reverse-transition="none">
                    <TabDossierPurchaseInvoices :model="model" :activeTab="tab==tabPurchase"/>
                </v-tab-item>
                <v-tab-item transition="none" reverse-transition="none">
                    <TabDossierSalesInvoices :model="model" :activeTab="tab==tabSalesInvoice"/>
                </v-tab-item>
                <v-tab-item v-if="feature.canTender" transition="none" reverse-transition="none">
                    <TabDossierTenders :model="model" :activeTab="tab==tabTender"/>
                </v-tab-item>
                <v-tab-item v-if="feature.canSalesOrder" class="project-orders" transition="none" reverse-transition="none">                        
                    <v-card dense>
                        <v-card-text class="pa-0">
                            <TabDossierOrders :model="model"/>
                        </v-card-text>
                    </v-card>
                </v-tab-item>

            </v-tabs-items>

            </v-card-text>
        </v-card>

        </DrawerContent>
</div>
</template>



<script setup>
    import {ref, watch, computed} from 'vue'
    import action from '@app/action'
    import TabDossierPurchaseInvoices from './TabDossierPurchaseInvoices'
    import TabDossierSalesInvoices from './TabDossierSalesInvoices'
    import TabDossierTenders from './TabDossierTenders'    
    import TabDossierOrders from './TabDossierOrders'    
    import TabDossierResultRework from './result/TabDossierResult'    
    import feature from "@app/features";


    import DrawerContent from '@shared/ui/dialogs/DrawerContent'

    const props = defineProps({
        model: {
            type: [Object]
        },
        parentTabActive: {
            type: [Boolean]
        }
    });
    const tab = ref(0);
    const tabResult = 0
    const tabPurchase = 1
    const tabSalesInvoice = 2
    const tabTender = 3

    function onDossierTabChange(tab,b,c) {
        if (tab == tabResult) {
            if (props.model.isResultDirty) {
                props.model.refreshResultData();
            }
        }
    }

    const compActiveParentTab = computed({
        get() {
            return props.parentTabActive
        }
    })

    // When the dossier parent tab is activated and the current tab is the result tab and the result model is dirty, refresh. 
    watch(compActiveParentTab, (newValue, oldValue) => {
        if (!newValue) {                    // The parent tab is not active. Skip action.
            return;
        }
        if (tab.value != tabResult) {       // The current tab is not the result tab. No need for action,
            return;
        }        
        if (!props.model.isResultDirty) {   // The result is not dirty, so no need to reload.
            return;
        }
        props.model.refreshResultData();    // Otherwise, reload teh result.
    })


</script>