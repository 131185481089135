import actions from '@/app/actions.2'
import noty from '@shared/lib/noty'
import eventbus from '@app/eventbus'

/**
 * This component handles validation of rights to execute an action. 
 *  
 * To implement user specific authorisation decisions, override method isUserAuthorizedFor.  
 *
 * Note that the actions are defined in the application @/app/actions.2.js file. 
 * 
 * Examples: 
 * 1) Use a button, which is enabled when use has authorization to execute action
 *    <Button action="unit.open" @click="()=>dlg.open('unit', {id: id})"/> 
 *
 * 2) When a user is authorized to execute an action, notify the user
 *    if (action.can('unit.modify')) {
 *        noty.alert("You can modify units if you want.");
 *    } 
 * 
 * 3) ... 
 * 
 * 
 */
 class clsAction {
 
    debug = true;

    /**
      */
    constructor() {        
    }

    /**
     * Log execution of an action when the debug flag is true 
     */
    _log(a,b,c,d,e,f,g) {
        if (this.debug) {
            console.log("app/action",a,b,c,d,e,f,g);
        }
    }

    /**
     * Get the action via the action or via the name.
     * <Button action="unit.open" @click="dlg.open('unit', {id: id})"/> 
     * 
     * ==> action is format: ENTITY.ACTION
     * For example, salesinvoice.open
     *
     * When the input is not a string, it is considered to be an action already and returned as is.
     * 
     * @param {} action 
     */
    _action(action) {
        if (typeof(action) == "string") {
            var arr = action.split(".");
            var resolvedAction = actions[arr[0]]?.[arr[1]];
            if (!resolvedAction) {
                throw `Action ${action} is not defined`;
            }
            action = resolvedAction;
        }
        return action;
    }
    /**
     * Does the action exist?
     * Example: if (action.exists("unit.create")) {createIt();}
     * @param {*} action 
     */
    exists(action) {
        var arr = (action||"").split(".");
        var resolvedAction = actions[arr[0]]?.[arr[1]];
        return !!resolvedAction;
    }
    /**
     * Override to define the rule for whether a user is authorized to execute the given action.
     * 
     * @param {*} action. Can be an action object or an action name (string).  
     * @returns 
     */
    isUserAuthorizedFor(action) {
        return true;
    }
            
    /**
     * Execute the given action when user is authorized.
     * Usage: action.execute("purchaseinvoice.backtonew", ()=>model.backToNew())
     * 
     * Note that this method notifies when no rights are on the action. 
     * Also note that when nothing can be executed from here - e.g. opening a dialog, or 
     * excuting the callback method, this method just does nothing. 
     * 
     * The following scenario's apply: 
     * 1) the 2nd parameter is a function. 
     *    --> When the current user is allowed to execute the given action, the callback is executed.
     *
     *  
     * @param {*} route 
     */
    async execute(action, fnCallback) {
        action = this._action(action);
        if (!this.isUserAuthorizedFor(action)) {
            noty.alert("U heeft niet genoeg rechten deze taak uit te voeren.");
            return;
        }
        if (typeof (fnCallback) != "function") {
            throw "callback is not a function";
        }

        // Execute the callback
        return fnCallback();
    }

    /**
     * Can the action be executed?
     * This is just a shortcut for isUserAuthorizedFor.
     * @param {*} action 
     */
    can(action) {
        action = this._action(action);
        return this.isUserAuthorizedFor(action);
    }

    /**
     * Get an icon to use for this action
     * @param {*} action - either a action object or a name. 
     */
    iconFor(action) {
        action = this._action(action);
        
        return action.icon;
    }

    /**
     * Get the text for this action
     * @param {*} action - either a action object or a name. 
     */
    textFor(action) {
        action = this._action(action);
    
        return action.text;
    }
 }
 
 export default clsAction;