<template>
    <Dialog :dialog="dlg">
        <v-form ref="form" lazy-validation>
            <FormRow label="Naam">                
                <TextField :model="model" max-length="48" dusk="name" :rules="model.rules.mandatory" placeholder="Naam" v-model="model.name"></TextField>
            </FormRow>
            <FormRow col-label="4" label="Projectleider">    
                <EmployeeCombo placeholder="Optioneel" :model='model' v-model='model.id_employee_manager'></EmployeeCombo>
            </FormRow>
            <FormRow col-label="4" label="Werkvoorbereider">    
                <EmployeeCombo placeholder="Optioneel" :model='model' v-model='model.id_employee_planner'></EmployeeCombo>
            </FormRow>
            <FormRow col-label="4" label="Uitvoerder">    
                <EmployeeCombo placeholder="Optioneel" :model='model' v-model='model.id_employee_executor'></EmployeeCombo>
            </FormRow>

        </v-form>

    </Dialog>
</template>



<script setup>
    import useModel from '@/models/project/projectgroup'
    import FormRow from '@controls/forms/FormRow'
    import TextField from '@controls/input/TextField'
    import Dialog from '@shared/ui/dialogs/ActionDialog'
    import EmployeeCombo from '@controls/combo/Employee'

    import { ref } from 'vue'
    import clsDialog from '@cls/clsDialog'

    const form = ref(null); 

    let model = useModel();
    let dlg = ref(
        new clsDialog("projectgroup", "Projecttype", model, [form])).value;

</script>
