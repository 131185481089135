import clsAction from '@cls/clsAction'
import user from '@app/user'
import actions from '@/app/actions.2'
import feature from '@app/features'

/**
 * This component handles execution of actions which might or might not be access restricted.
 *
 * To implement user specific authorisation decisions, override method isUserAuthorizedFor.  
 *
 * Note that the actions are defined in the application @/app/actions.2.js file. 
 * 
 */
class clsActionExt extends clsAction {
 

    /**
     * Does the current company user have access to the modules required to be involved in this action?
     * E.g. for opening a workorder, the module workorder is required.
     * 
     * Note that this is only part of the authorization check. 
     * When the company has access to the module workorders, it might still be that the current user has no rights on any workorder action.
     *  
     * @param {*} action 
     */
    hasRequiredModule(action) {
        action = this._action(action);

        var modules = action.modules || [];
        // When no modules required for the action, fine. Access granged.
        if (!modules?.length) {
            return true; 
        }
        // any one of the required modules is fine. Skip when no modules required.
        return !!modules.find( (mod) => feature.canModule(mod)); // return bool
    }

    /**
     * Does the current user have rights to access this action?
     * When the action has no rights: true
     *  
     * @param {*} action 
     */
    hasRequiredRight(action) {
        action = this._action(action);

        var rights = action.rights || [];
        // When no rights are required (which is the case for example to access the dashboard)
        if (!rights?.length) {
            return true; 
        }
        return user.hasRight(rights);
    }
    
    /**
     * Check whether the current user is granted rights to execute the given action.
     * The action is one defined in actions.2.js 
     * 
     * Check: 
     *  1)  - when the action requires rights, has the user one of them?
     *  2)  - when the action requires module access, is the module activated?
     * 
     * 
     * @param {*} action. Action object
     * @returns 
     */
    isUserAuthorizedFor(action) {
        action = this._action(action);
        // return this._testIsUserAuthorizedFor(action);

        // First of all, the company must have access to the module for the action
        if (!this.hasRequiredModule(action)) {
            return false;
        }

        // Hereafter, the user must have been granted any of the required rights  
        if (!this.hasRequiredRight(action)) {
            return false;
        }
        
        return true;
    }

    _testIsUserAuthorizedFor(action) {

//         if (action == actions.relation.open)                 { return true; }
//         if (action == actions.relation.modify)               { return false; }
//         if (action == actions.relation.create)               { return false; }
//         if (action == actions.relation.remove)               { return false; }

        /*
        if (action == actions.relation.open)                 { return true; }
        if (action == actions.relation.modify)               { return true; }
        if (action == actions.relation.create)               { return true; }
        if (action == actions.relation.remove)               { return true; }
        if (action == actions.relation.unarchive)            { return true; }
        if (action == actions.relation.search)               { return true; }
        if (action == actions.relation.excel)                { return true; }
        if (action == actions.relation.import)               { return true; }
        if (action == actions.relation_merge.open)           { return true; } // -- idem
        if (action == actions.relation_merge.modify)         { return true; } // -- dito
        if (action == actions.relation_settings.open)        { return true; }
        if (action == actions.relation_settings.modify)      { return true; }
        if (action == actions.relation_bankaccount.open)     { return true; }
        if (action == actions.relation_bankaccount.create)   { return true; }
        if (action == actions.relation_bankaccount.modify)   { return false; }
        if (action == actions.relation_bankaccount.remove)   { return false; }
        if (action == actions.relation_address.open)         { return true; }
        if (action == actions.relation_address.modify)       { return false; }
        if (action == actions.relation_address.create)       { return true; }
        if (action == actions.relation_address.remove)       { return false; }
        if (action == actions.relation_contact.open)         { return true; }
        if (action == actions.relation_contact.modify)       { return false; }
        if (action == actions.relation_contact.create)       { return true; }
        if (action == actions.relation_contact.remove)       { return false; }

        if (action == actions.relation_note.open)            { return true; }
        if (action == actions.relation_note.modify)          { return false; }
        if (action == actions.relation_attachment.open)      { return true; }
        if (action == actions.relation_attachment.create)    { return false; }
        if (action == actions.relation_attachment.remove)    { return true; }
*/
        // console.log('comparing with manday_note: ', action?.text, action, actions.manday_note.open, action == actions.manday_note.open)
/*
        if (action == actions.manday.open)                  { return true; }
        if (action == actions.manday.modify)                { return true; }
        if (action == actions.manday.create)                { return true; }
        if (action == actions.manday.copy)                  { return false; }
        if (action == actions.manday.remove)                { return false; }
        if (action == actions.manday.unarchive)             { return true; }
        if (action == actions.manday.excel)                 { return false; }
        if (action == actions.manday.send)                  { return true; }
        if (action == actions.manday.invoice)               { return true; }
        if (action == actions.manday_note.open)             { return true; }
        if (action == actions.manday_note.modify)           { return false; }
        if (action == actions.manday_note.create)           { return false; }
        if (action == actions.manday_attachment.open)       { return true; } 
        if (action == actions.manday_attachment.create)     { return false; } 
        if (action == actions.manday_attachment.remove)     { return false; } 
        if (action == actions.manday_flag.open)             { return true; }
        if (action == actions.manday_flag.modify)           { return true; }
*/
/*
    if (action == actions.productgroup.open)             { return false;}
    if (action == actions.productgroup.modify)           { return false;}
    if (action == actions.productgroup.create)           { return false;}
    if (action == actions.productgroup.remove)           { return false;}
*/
/*
    if (action == actions.supplier.open)             { return true;}
    if (action == actions.supplier.modify)           { return false;}
    if (action == actions.supplier.create)           { return true;}
    if (action == actions.supplier.remove)           { return true;}
*/
/*
        if (action == actions.product.open)          { return true; }
        if (action == actions.product.modify)        { return false; }
        if (action == actions.product.create)        { return false; }
        if (action == actions.product.copy)          { return false; }
        if (action == actions.product.remove)        { return false; }
        if (action == actions.product.excel)         { return false; }
        if (action == actions.product_note.open)     { return true; }
        if (action == actions.product_note.create)   { return false; }
        if (action == actions.product_note.modify)   { return false; }
*/
/*
        if (action == actions.paymentorder.open)        { return true; }
        if (action == actions.paymentorder.modify)      { return false; }
        if (action == actions.paymentorder.create)      { return false; }
        if (action == actions.paymentorder.remove)      { return true; }
        if (action == actions.paymentorder.excel)       { return false; }
        if (action == actions.paymentorder.download)        { return true; }
        if (action == actions.paymentorder.setpaid)     { return true; }
        if (action == actions.paymentorder.backtoconcept)     { return true; }

        if (action == actions.paymentorder_note.open)   { return false; }
        if (action == actions.paymentorder_note.create) { return false; }
        if (action == actions.paymentorder_note.modify) { return false; }

        if (action == actions.paymentorder_flag.open)    { return true; }
        if (action == actions.paymentorder_flag.modify)  { return true; }
*/
/*
    if (action == actions.purchaseinvoice.open)             { return true; }
    if (action == actions.purchaseinvoice.modify)           { return false; }
    if (action == actions.purchaseinvoice.create)           { return true; }
    if (action == actions.purchaseinvoice.remove)           { return false; }
    if (action == actions.purchaseinvoice.excel)            { return false; }
    if (action == actions.purchaseinvoice.search)           { return true; }
    if (action == actions.purchaseinvoice.accept)           { return true; }
    if (action == actions.purchaseinvoice.reject)           { return true; }
    if (action == actions.purchaseinvoice.paymentstatus)    { return true; }

    if (action == actions.purchaseinvoice.backtonew) {return true; }

    if (action == actions.purchaseinvoice_note.open)    { return true; }
    if (action == actions.purchaseinvoice_note.create)  { return false; }
    if (action == actions.purchaseinvoice_note.modify)  { return false; }

    if (action == actions.purchaseinvoice_attachment.open)  { return true; }
    if (action == actions.purchaseinvoice_attachment.create)    { return true; }
    if (action == actions.purchaseinvoice_attachment.modify)    { return true; }

    if (action == actions.purchaseinvoice_flag.modify)      { return false; }

    if (action == actions.purchaseinvoice_reject.open) {return true; }

    if (action == actions.purchaseinvoice_upload.open) {return true; }
    if (action == actions.purchaseinvoice_upload.create) {return true; }

    */
/*   
        if (action == actions.salesinvoice.open)           { return true; }
        if (action == actions.salesinvoice.create)         { return false; }
        if (action == actions.salesinvoice.remove)         { return false; }
        if (action == actions.salesinvoice.copy)           { return false; }
        if (action == actions.salesinvoice.credit)         { return false; }
        if (action == actions.salesinvoice.pdf)            { return false; }
        if (action == actions.salesinvoice.excel)          { return false; }
        if (action == actions.salesinvoice_reminder.open)           { return true; }
        if (action == actions.salesinvoice_reminder.modify)         { return false; }
        if (action == actions.salesinvoice_payments.open)           { return true; }
        if (action == actions.salesinvoice_history.open)           { return false; }
        if (action == actions.salesinvoice_note.open)           { return true; }
        if (action == actions.salesinvoice_note.modify)         { return false; }
        if (action == actions.salesinvoice_note.create)         { return false; }
        if (action == actions.salesinvoice_flag.modify)         { return false; }
        if (action == actions.salesinvoice_payment.open)           { return true; }
        if (action == actions.salesinvoice_payment.modify)         { return false; }
        if (action == actions.salesinvoice_payment.create)         { return false; }
        if (action == actions.salesinvoice_payment.remove)         { return false; }

        if (action == actions.salesinvoice.example)        { return true; }
        if (action == actions.salesinvoice.backtoconcept)  { return false; }
        if (action == actions.salesinvoice.search)         { return true; }
        if (action == actions.salesinvoice.modify)         { return false; }

        if (action == actions.salesinvoice_send.open)           { return true; }
        if (action == actions.salesinvoice_send.modify)         { return false; }
  

        if (action == actions.salesinvoice_attachment.open)           { return true; }
        if (action == actions.salesinvoice_attachment.create)         { return false; }
        if (action == actions.salesinvoice_attachment.remove)         { return false; }
*/

    //////////////////////////////////////////////////////////////////////////////////////////
    //
    // Tender
    //
    //////////////////////////////////////////////////////////////////////////////////////////
    /*
        if (action == actions.tender.open)                 { return true; } 
        if (action == actions.tender.modify)               { return false; } 
        if (action == actions.tender.create)               { return false; } 
        if (action == actions.tender.remove)               { return false; } 
        if (action == actions.tender.copy)                 { return false; } 
        if (action == actions.tender.pdf)                  { return false; } 
        if (action == actions.tender.example)              { return false; } 
        if (action == actions.tender.excel)                { return false; } 
        if (action == actions.tender.search)               { return true; } 
        if (action == actions.tender_send.open)            { return false; }
        if (action == actions.tender_send.modify)          { return false; }
        if (action == actions.tender_history.open)         { return true; }
        if (action == actions.tender_createinvoice.open)   { return true; }
        if (action == actions.tender_createinvoice.modify) { return true; }
        if (action == actions.tender_note.open)            { return true; }
        if (action == actions.tender_note.create)          { return false; }
        if (action == actions.tender_note.modify)          { return false; }
        if (action == actions.tender_attachment.open)      { return true; }
        if (action == actions.tender_attachment.create)    { return false; }
        if (action == actions.tender_attachment.remove)    { return false; }
        if (action == actions.tender_flag.modify)          { return false; }
    
*/

    //////////////////////////////////////////////////////////////////////////////////////////
    //
    // Project
    //
    //////////////////////////////////////////////////////////////////////////////////////////
    /* 
        if (action == actions.project.open)                     { return true; }
        if (action == actions.project.modify)                   { return true; }
        if (action == actions.project.create)                   { return true; }
        if (action == actions.project.remove)                   { return true; }
        if (action == actions.project.copy)                     { return true; }
        if (action == actions.project.excel)                    { return true; }
        if (action == actions.project.search)                   { return true; }
        if (action == actions.project.status)                   { return true; }
        if (action == actions.project.financials)               { return true; }
        if (action == actions.project_limit.open)               { return true; }
        if (action == actions.project_limit.modify)             { return true; }
        if (action == actions.project_limit.create)             { return true; }
        if (action == actions.project_limit.remove)             { return true; }
        if (action == actions.project_additionalwork.open)      { return true; }
        if (action == actions.project_additionalwork.modify)    { return true; }
        if (action == actions.project_additionalwork.create)    { return true; }
        if (action == actions.project_additionalwork.remove)    { return true; }
        if (action == actions.project_additionalwork.invoice)   { return true; }
        if (action == actions.project_othercosts.open)          { return true; }
        if (action == actions.project_othercosts.modify)        { return true; }
        if (action == actions.project_othercosts.create)        { return true; }
        if (action == actions.project_othercosts.remove)        { return true; }
        if (action == actions.project_hours.open)               { return true; }
        if (action == actions.project_hours.modify)             { return true; }
        if (action == actions.project_hours.create)             { return true; }
        if (action == actions.project_hours.remove)             { return true; }
        if (action == actions.project_hours.export)             { return true; }
        if (action == actions.project_employees.open)           { return true; }
        if (action == actions.project_employees.create)         { return true; }
        if (action == actions.project_employees.remove)         { return true; }
        if (action == actions.project_planning.open)            { return true; }
        if (action == actions.project_planning.modify)          { return true; }
        if (action == actions.project_planning.create)          { return true; }
        if (action == actions.project_planning.remove)          { return true; }
        if (action == actions.project_note.open)                { return true; }
        if (action == actions.project_note.create)              { return true; }
        if (action == actions.project_note.modify)              { return true; }
        if (action == actions.project_attachment.open)          { return true; }
        if (action == actions.project_attachment.create)        { return true; }
        if (action == actions.project_attachment.remove)        { return true; }
        if (action == actions.project_flag.modify)              { return true; }
*/

/*
    //////////////////////////////////////////////////////////////////////////////////////////
    //
    // Workorder
    // 
    //////////////////////////////////////////////////////////////////////////////////////////
        if (action == actions.workorder.open)                { return true; }
        if (action == actions.workorder.modify)              { return true; }
        if (action == actions.workorder.create)              { return true; }
        if (action == actions.workorder.remove)              { return true; }
        if (action == actions.workorder.copy)                { return true; }
        if (action == actions.workorder.excel)               { return true; }
        if (action == actions.workorder.search)              { return true; }
        if (action == actions.workorder.pdf)                 { return true; }
        if (action == actions.workorder.assign)              { return true; }
        if (action == actions.workorder_send.open)           { return true; }
        if (action == actions.workorder_send.modify)         { return true; }
        if (action == actions.workorder_planboard.open)      { return true; }
        if (action == actions.workorder_planboard.modify)    { return true; }
        if (action == actions.workorder_note.open)           { return true; }
        if (action == actions.workorder_note.create)         { return true; }
        if (action == actions.workorder_note.modify)         { return true; }
        if (action == actions.workorder_attachment.open)     { return true; }
        if (action == actions.workorder_attachment.create)   { return true; }
        if (action == actions.workorder_attachment.remove)   { return true; }
        if (action == actions.workorder_flag.modify)         { return true; }
*/


//        if (action == actions.unit.open) {
//            return false;
//        }
        // console.log('IsAuthorized for ? ',action);
        // if (action.name == "product.supplier.create") {
        //     return false;
        // }
        // if (to.meta.isAuthorized) {
        return true;
    }
 }
 
 export default clsActionExt;