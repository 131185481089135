/*
 *
 * Certain components, we want to expose through the _m variable. 
 * The main purpose is debugging and trouble shooting.
 * 
 * import fnExpose from '@shared/lib/expose';
 * 
 * E.g. fnExpose(lib, 'lib') ==> _m.lib 
 * E.g. fnExpose(entities, 'store', 'entities') => _m.store.entities
 *
 * To expose only once: 
 * 
 * let exposed = false;
 * ...
 * exposed = exposed || fnExpose(obj, 'store', 'obj')
 *
 * 
 */

function fnExpose(item, ...levels) {
    if (!levels || !levels.length) {
        console.error('expose => at least the name should be specified.');
        return;
    }
    if (!window._m) {
        window._m = {};
    }
    // flat out the levels in case one of the levels is an array.
    let flatLevels = [];
    levels.forEach( (l) => {
        if (Array.isArray(l)) {
            l.forEach( (x) => flatLevels.push(x));
        } else {
            flatLevels.push(l);
        }
    })
//    console.log('fnExpose', item, flatLevels)

    let currentNesting = window._m; 
    for (var n = 0; n < flatLevels.length; n++) {
        var level = flatLevels[n];
        if (n == flatLevels.length-1) {
            if (!currentNesting[level]) {
                currentNesting[level] = item;
            } else {
                console.error('expose: tried to overwrite', flatLevels, item)
            }
        } else {
            if (!currentNesting[level]) {
                currentNesting[level] = {};
            }
            currentNesting = currentNesting[level];
        }
    }
    return true;
}

export default fnExpose;
