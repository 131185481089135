<template>
    <Dialog :dialog="dlg">

        <AlertArea success class="mb-4">
            Hiermee maakt u deze offerte tot opdracht.<br>
        </AlertArea>

        <v-form ref="form" lazy-validation>
            
                <Form>
                    <FormRow label="Stuur offerte bevestiging" col-label="8" col-value-right subLabel="Stuur bevestiging van deze offerte naar klant.">                    
                        <Checkbox :model="model" class="ml-4" switcher v-model="model.send_confirmation"></Checkbox>
                    </FormRow>
                </Form>
        </v-form>

    </Dialog>
</template>



<script setup>
    import useModel from '@/models/tender/accepttender'
    import Form from '@controls/forms/Form'
    import FormRow from '@controls/forms/FormRow'
    import Dialog from '@shared/ui/dialogs/ActionDialog'
    import Checkbox from '@controls/check/Checkbox.vue'
    import AlertArea from '@shared/ui/markup/AlertArea'

    import { ref } from 'vue'
    import clsDialog from '@cls/clsDialog'

    const form = ref(null); 
    let model = useModel();

    let dlg = ref(
        new clsDialog("tender_accept", "Offerte wordt opdracht", model, [form], {
            icon: 'check',
            save: 'Ga door'
        } )).value;

    window.remo = model;
</script>
