import axios from "axios";
import eventbus from '@app/eventbus'
import noty from '@shared/lib/noty'

axios.defaults.withCredentials = true;
axios.defaults.timeout = 0;
axios.defaults.retry = 0;
axios.defaults.baseURL = process.env.VUE_APP_BASEURL;

/**
 * Each http client will add an X-XSRF-TOKEN header to each request. 
 * The X-XSRF-TOKEN is retrieved from the token which is retrieved via the cookie.
 * Therefore, this is a global interceptor.
 */
axios.interceptors.request.use(function (config) {
        let cookieTokenName = process.env.VUE_APP_XSRF_TOKEN_NAME || "CSRF-TOKEN"; 
        let match = document.cookie.match(new RegExp("(^| )" + cookieTokenName + "=([^;]+)"));
        let token = match ? match[2] : '';
    
//        console.log("Found cxsr cookie", process.env.VUE_APP_XSRF_TOKEN_NAME, token);
        if (token) {
            axios.defaults.headers.common['X-XSRF-TOKEN'] = decodeURIComponent(token);     
            axios.defaults.headers.common['CSRF-NAME'] = cookieTokenName;     
        }
        // If the requested url contains xsrf-token, should we do this as well?
        // if (url.indexOf('xsrf-token') < 0) { ...

        return config;

    }, function (error) {
        return Promise.reject(error);
    }
);



const HTTP_CODE_UNAUTHORIZED    = 401; // Session is logged out due to expiration or functional logout
const HTTP_CODE_SESSION_EXPIRED = 419; // Session is expired. Typically due to CSRF cooky expiry
const HTTP_CODE_WARNINGS        = 475; // Warnings are present in the response
const HTTP_CODE_AUTH_ACCESS_DENIED = 481; // Authorisation is denied because of authentication denial. 
                                          // E.g. a support user logs in from an unknown IP
const HTTP_CODE_TASK_ACCESS_DENIED = 482; // The current user has no access to the current task


/**
 * Get the first (validation) error from an exception. 
 * The exception looks like: 
 *  {
 *      response: {
 *          data: {
 *              errors: {
 *                  email: [
 *                      "Geen gebruiker met dit e-mail adres"
 *                  ]
 *              }
 *          }
 *      }
 *  }
 * 
 * 
 * @param {*} e 
 * @param {*} defaultMessage 
 * @returns 
 */
function getExceptionErrorMessage(e, defaultMessage) {
    if (e && e.response && e.response.data && e.response.data.errors && e.response.data.errors) {
        var errors = e.response.data.errors;            
        var msg = null;
        for (var key in errors) {
            var value = errors[key];
            if (value && value.length && value[0]) {
                return value[0];
            }
        }
    } 
    return defaultMessage;
}
function getWarningMessages(e, defaultMessage) {
    let arrWarnings = [];   

    if (e && e.response && e.response.data && e.response.data.errors) {
        var errors = e.response.data.errors;         
        for (var key in errors) {
            var value = errors[key];
            if (value && value.length && value[0]) {
                arrWarnings.push(value[0]);
            }
        }
    } 
    if (!arrWarnings.length && !!defaultMessage) {
        arrWarnings.push(defaultMessage);
    }
    return arrWarnings;
}

/**
 * Create an Axios Client. 
 * All clients adds the X-CSRF-TOKEN which is retrieved from the specified named cookie.
 * When error handling is     
 * 
 * @param {*} errorHandler 
 */
function createAxiosClient(useErrorHandler) {
    var client = axios.create({
        baseURL: process.env.VUE_APP_BASEURL,
        withCredentials: true, // required to handle the CSRF token. However, its behavior is overruled in the request interceptor.
        timeout: 0,
        retry: 0,
    });

    /**
     * For convenience, to access response data and throw when nothing received. 
     * E.g. :
     * 
     *  var httpClient  = http.createClient();  
     *  var response    = await httpClient.post("/login", payload);
     *  var loginResult = httpClient.data(response, "Geen data ontvangen")
     * 
     */
    client.data = (response, msg) => {
        if (!response || !response.data) {
            throw (msg || "Geen data ontvangen") 
        }
        return response.data;
    }

    /**
     * Each http client will add an X-XSRF-TOKEN header to each request. 
     * The X-XSRF-TOKEN is retrieved from the token which is retrieved via the cookie.
     */
    client.interceptors.request.use(function (config) {
            let cookieTokenName = process.env.VUE_APP_XSRF_TOKEN_NAME || "CSRF-TOKEN"; 
            let match = document.cookie.match(new RegExp("(^| )" + cookieTokenName + "=([^;]+)"));
            let token = match ? match[2] : '';
            if (token) {
                config.headers['X-XSRF-TOKEN'] = decodeURIComponent(token);     
            }
            // If the requested url contains xsrf-token, should we do this as well?
            // if (url.indexOf('xsrf-token') < 0) { ...

            return config;

        }, function (error) {
            return Promise.reject(error);
        }
    );

    if (useErrorHandler) {
        client.interceptors.response.use( (response) => response, 
            function (error) {

                let hideError = error && error.config && error.config.hideError;
                if (hideError){
                    console.error('Error loading data')
                }
                // Any status codes that falls outside the range of 2xx cause this function to trigger
                // This means that the server threw an error which we did not catch and convert to a client message. 
                // In case a valication error occured, we can show it to the user. 
                // In other cases, we will just show a generic message.
                
                // Handle unauthorized response. 
                // This means that we are inactive for too long or logged out for other reasons.
                if (error.response && (  error.response.status == HTTP_CODE_UNAUTHORIZED
                                      || error.response.status == HTTP_CODE_SESSION_EXPIRED)
                    ) {
                    eventbus.auth.loggedOut({scenario: 'expired'}); // Let the application handle.

                    return Promise.reject(error);
                }
                if (error.response && (  error.response.status == HTTP_CODE_TASK_ACCESS_DENIED) ) {
                    var msg = "U heeft geen rechten om deze actie uit te voeren.<br>Uw applicatiebeheerder kan u hier indien gewenst verder mee helpen.";
                    var code = error.response?.data?.code;
                    if (code) {
                        msg = `${msg}<br><br>Code: ${code}`;
                    }
                    noty.alert(msg, {title: "U heeft geen rechten."});
                    return Promise.reject(error);
                }
                if (error.response && (  error.response.status == HTTP_CODE_AUTH_ACCESS_DENIED) ) {
                    var msg = error.response.message || "U heeft geen toegang tot de applicatie";
                    noty.alert(msg);
                    return Promise.reject(error);
                }
                

                if (!hideError) {
                    if (error.response && error.response.status == HTTP_CODE_WARNINGS) {
                        let arrWarnings = getWarningMessages(error, 'Er is een fout op onze webserver opgetreden.\nWe hebben de fout opgeslagen en gaan aan de slag deze op te lossen.');
                        return noty.confirm(null, {                            
                            warnings: arrWarnings,
                            labelConfirm: "Ga door"
                        })
                    }

                    let msg = getExceptionErrorMessage(error, 'Er is een fout op onze webserver opgetreden.\nWe hebben de fout opgeslagen en gaan aan de slag deze op te lossen.');
                    eventbus.api.error({
                        title: 'Er trad een fout op',
                        error: msg
                    });
                }
                return Promise.reject(error);
            }
        );
        
    }

    return client;
}





//
// Usage: 
//  import http from '@app/http'
//  var result = http.get(myurl)
//

var ax = {
    createClient: function(useErrorHandler) {
        return createAxiosClient(useErrorHandler);
    }
}

export default ax;