//
// Usage: 
//  import action from '@app/action'
//

import clsAction from '@/app/clsAction'
import fnExpose from '@shared/lib/expose'
import {ref} from 'vue'

let obj =  ref (new clsAction());
fnExpose(obj.value, 'system', 'action')

export default obj.value;