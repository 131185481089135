import { clsModel, fnCreate } from '@cls/clsModel'
import { productgroups as api } from '@/app/api'


var modelName = "productgroup";

class clsProductGroup extends clsModel {

    name     = "";

    constructor() {
        super({
          api: api,   
          modelName: modelName, 
          mandatoryFields: ['name']
        })
    } 

    /**
     * Fill the data 
     * 
     * @param {*} data 
     */
    fill(data) {
        data = data || {};
        super.fill(data);

        this.name     = data.name || ""
    }

    /**
     * Get the JSON representation for saving the data
     * 
     * @param {*} data 
     */
    toJSON() {
        return this.propsToJSON(["id", "name"]);
    }
        
 }
 export default fnCreate(clsProductGroup , 'clsProductGroup');
