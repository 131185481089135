<template>

    <ActionCombo :noClearable="noClearable" :placeholder="placeholder" :skeleton="compSkeleton" :disabled="compDisabled" :rules="rules" v-model="dvalue" :items="items"
       open create route="masterdata.creditrestrictions" baseType="creditrestriction"
    >
    </ActionCombo>

</template>

<script setup>

    // import CreditrestrictionCombo from '@controls/combo/Creditrestriction'
    // <CreditrestrictionCombo :model="model" :rules="model.rules.id_credit_restriction" v-model="model.id_credit_restriction"></CreditrestrictionCombo>
    // <CreditrestrictionCombo :disabled="model.disabled" :rules="model.rules.id_credit_restriction" v-model="model.id_credit_restriction"></CreditrestrictionCombo>

    const props = defineProps({
        disabled: {
            type: [Boolean]
        },
        rules: {
            type: [Array]
        },
        noClearable: {
            type: [Boolean],
            default: false
        },
        skeleton: {
            type: [Boolean]
        },
        value: {
            type: [String, Number]
        },
        placeholder: {
            type: [String],
            default: 'Betalingskorting'
        },
        model: {
            type: [Object]
        },
        isForSearch: {
            type: [Boolean]
        },

    })
    
    import ActionCombo from '@controls/combo/ActionCombo'
    import {creditrestriction as list} from '@app/list';
    import {computed} from 'vue'

    const emit = defineEmits(['input'])

    const compSkeleton = computed({
        get() {
            if (props.skeleton) {
                return true;
            }
            if (!props.model) {
                return false;
            }
            return props.model.isDataLoading;
        }
    });
    const compDisabled = computed({
        get() {
            if (props.disabled) {
                return true;
            }
            if (!props.model) {
                return false;
            }
            return props.model.disabled;
        }
    });


    const dvalue = computed({
        get() {
            return props.value
        },
        set: function(v) {
            emit('input', v)
        }	
    })

    const items = computed({
        get() {
            if (!props.isForSearch) {
                return list.listActual(dvalue.value) || []
            }
            return [{id: 'any', name: 'Heeft kredietbeperking'}, {id: 'none', name: 'Heeft geen kredietbeperking'}, ...list.listActual(dvalue.value)];
            
        },
    })

</script>
