import { clsModel, fnCreate } from '@cls/clsModel'
import { supportrequest as api } from '@/app/api'

var modelName = "support_request";
const fields = ["pickedup_at", "expires_at", "created_at", "pickedup_by"];

class clsSupportRequest extends clsModel {

    expires_at  = null;
    created_at  = null;
    pickedup_by = null;
    pickedup_at = null;
    
    get hasRequest() {
        return !!this.expires_at;
    }

    get modelRep() {
        return "Support verzoek";
    }
    async removeRequest() {
        this.isDataLoading = true; 
        try {
            await api.remove();
            this.fill({});
        } finally {
            this.isDataLoading = false;
        }
    }

    constructor() {
        super({
          api: api,   
          modelName: modelName, 
    
          fillable: fields
        })
    } 
        
 }
 export default fnCreate(clsSupportRequest , 'clsSupportRequest');
