<template>
    <Dialog :dialog="dlg">

        <AlertArea success>
            Hiermee zet u de status van deze offerte op gereed.<br>
            Dit betekent dat u klaar bent met deze offerte, en er geen acties meer mee gaat uitvoeren.                
            <br><br>
            Deze offerte kunt u hierna terugvinden onder het menu 'Gereed'.
            <br>
        </AlertArea>

    </Dialog>
</template>



<script setup>
    import useModel from '@/models/tender/finishtender'
    import Dialog from '@shared/ui/dialogs/ActionDialog'
    import AlertArea from '@shared/ui/markup/AlertArea'

    import { ref } from 'vue'
    import clsDialog from '@cls/clsDialog'
    let model = useModel();

    let dlg = ref(
        new clsDialog("tender_finish", "Offerte gereed", model, [], {
            icon: 'archive',
            save: 'Ga door'
        } )).value;

    window.remo = model;
</script>
