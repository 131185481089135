import '@tiptap/extension-text-style';
import { Extension } from '@tiptap/core';

/**
 * This extension allows the width style to be added to textStyle (span) elements. 
 * For example, html like: <span style='width:100px'>My Text</span> is accepted as valid. 
 * We need this just for being able to accept server 'generated' markup. We do not allow clients to use it with commands. 
 * 
 * Note that width in itself is invalid on span elements. 
 * This will practically be used together with the 'display' extension. 
 * For example: 
 *          <span style='width:100px;display:inline-block;'>Label</span> : <span>Value</span> 
 * 
 */

const TextWidth = Extension.create({
    name: 'textWidth',
    addOptions() {
        return {
            types: ['textStyle'],
        };
    },
    addGlobalAttributes() {
        return [
            {
                types: this.options.types,
                attributes: {
                    textWidth: {
                        default: null,
                        parseHTML: element => { var _a; return (_a = element.style.width) === null || _a === void 0 ? void 0 : _a.replace(/['"]+/g, ''); },
                        renderHTML: attributes => {
                            if (!attributes.textWidth) {
                                return {};
                            }
                            return {
                                style: `width: ${attributes.textWidth}`,
                            };
                        },
                    },
                },
            },
        ];
    },
    addCommands() {
        return {
            setTextWidth: textWidth => ({ chain }) => {
                return chain()
                    .setMark('textStyle', { textWidth })
                    .run();
            },
            unsetTextWidth: () => ({ chain }) => {
                return chain()
                    .setMark('textStyle', { textWidth: null })
                    .removeEmptyTextStyle()
                    .run();
            },
        };
    },
});

export { TextWidth, TextWidth as default };