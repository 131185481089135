import clsSettings from '@cls/clsSettings'

var modelName="manday"
class clsMandaySettings extends clsSettings {

    /**
     * clone the current setting.
     * load fresh data from the server.
     * autoLoad: load the data automagically
     * noDestroy: prevent object being cleaned up on unmount.
     *            Actually, can not think for a use case.
     */
    clone(autoLoad, noDestroy) {
        var obj = new clsMandaySettings();

        if (!noDestroy) {
            obj.registerDestroyOnUnmount();            
        }
        if (autoLoad) {
            obj.load();
        }
    
        return obj;
    }

    // 
    csm_manday_show_country = 0;
    csm_manday_show_bsn     = 0;
    csm_manday_show_department = 0;

    constructor() {
        super({modelName: modelName});
    }

    fill(data) {
        this.csm_manday_show_country        = data.csm_manday_show_country;
        this.csm_manday_show_bsn            = data.csm_manday_show_bsn;
        this.csm_manday_show_department     = data.csm_manday_show_department;
    }

    toJSON() {
        return {
            csm_manday_show_country : this.csm_manday_show_country,
            csm_manday_show_bsn : this.csm_manday_show_bsn,
            csm_manday_show_department : this.csm_manday_show_department,
        };
    }

}

export default clsMandaySettings;
