<template>
    <Dialog :dialog="dlg" >

        <v-form ref="form" lazy-validation>
            <Form>
                <FormRow label="Naam">            
                    <TextField :rules="model.rules.mandatory" dusk="name" :model="model" max-length="45" placeholder="Bvb: 25-25-50"  v-model="model.ot_name"></TextField>
                </FormRow>

                <div class="lines ml--4 mr--4 mt-4">
                    <v-simple-table dense class="header-contrast no-hover border-last-row border-header-row">
                    <thead>
                        <th class="w-2 pl-4 pa-2 pl-2 text-right">Percentage</th>
                        <th class="pl-4 pa-2 text-left">Omschrijving</th>
                        <th class="pa-1 w-1 "></th>
                    </thead>
                    <tbody>
                        <tr v-if="!model.lines.length">
                            <td colspan="3"><span v-if="!model.isLoading">Er zijn nog geen regels aangemaakt</span></td>
                        </tr>
                        
                        <tr v-for='(line, index) in model.lines' :key='`otl-${index}`'>
                            <td class="pa-1">
                                <NumberField no-border percentage :model='model' v-model='line.otl_pct' ></NumberField>
                            </td>
                            <td class="pa-1">
                                <TextField :rules="model.rules.mandatory" no-border max-length="128" :model='model' placeholder="Termijn omschrijving" v-model='line.otl_name'></TextField>
                            </td>
                            <td class="pa-0">                 
                                <ActionButton ml dusk='btn-line-remove' :model="model" icon-button icon="remove" 
                                    action="salesorderterm.modify" @click="() => removeTermLine(line)">
                                </ActionButton>
                            </td>
                        </tr>

                    </tbody>
                    <tfoot class="border-top">
                        <td class="pr-4 pt-2 text-right" >
                            <Percentage v-if="model.totalPct" :class="{'text-error':model.totalPct!=100, 'text-success':model.totalPct==100}" v-model="model.totalPct" symbol=" %"></Percentage>
                        </td>
                        <td colspan="2">
                        </td>
                    </tfoot>
                </v-simple-table>
                </div>

            </Form>
        </v-form>
        
    <template v-slot:footer-left>
        <ActionButton :model="model" action="salesorderterm.modify" sub dusk="btn-add-otlline" icon="add" @click='(evt)=>addTermLine(evt)'>Nieuwe regel</ActionButton>
    </template>

    </Dialog>
</template>



<script setup>
    import useModel from '@/models/salesorder/salesorderterm'
    import Form from '@controls/forms/Form'
    import FormRow from '@controls/forms/FormRow'
    import TextField from '@controls/input/TextField'
    import Dialog from '@shared/ui/dialogs/ActionDialog'
    import { ref, computed } from 'vue'
    import clsDialog from '@cls/clsDialog'
    import NumberField from '@controls/input/NumberField'
    import ActionButton from '@controls/buttons/ActionButton'
    import Percentage from "@controls/text/Percentage.vue";
    
    const form = ref(null);

    let model = useModel();
    let dlg = ref(
        new clsDialog("salesorderterm", "Termijnverdeling", model, [form], {
            showNote: false,
            showAttachment: false,
            icon: 'order'
        } )).value;

    window.sot = dlg;


    function removeTermLine(line) {
        model.removeLine(line);
    }

    function addTermLine(evt) {
        model.addLine();
        dlg.setFocusToFirstInputInLastLine(evt);
    }



</script>
