<template>
    <tr>
        <td class="col-cmd">                 
            <ActionButton ml dusk='btn-line-remove' :disabled="model.isOpenOrLater" :model="model" icon-button icon="remove" 
                no-execute action="salesinvoice.modify" @click="() => removeLine(item)">
            </ActionButton>
        </td>
        <td v-for="(header, index) in columnHeaders" :key="index" :class="header.cellClass" class="pr-0 overflow-y">
            <span v-if="header.type=='drag'">
                <Icon class="cursor-move-y" type="drag"></Icon>
            </span>
            <span v-else-if="header.value=='ihp_amount'">
                <NumberField max="1000000" :disabled="item.isFixedGeneratedLine||model.isOpenOrLater" decimal dusk="amount"  no-border :model='model' :rules='model.rules.ihp_amount' v-model='item.ihp_amount' ></NumberField>
            </span>
            <span v-else-if="header.value=='id_unity'">
                <UnitCombo dusk="unit" :disabled="item.isFixedGeneratedLine||model.isOpenOrLater" no-border :model='model' :rules='model.rules.id_unity' v-model='item.id_unity'></UnitCombo>
            </span>
            <span v-else-if="header.value=='ihp_pd_name'">
                <RichTextLine dusk="name" :disabled="model.isOpenOrLater" no-border ref="rfocus" :model="model" placeholder="Omschrijving" v-model="item.ihp_pd_name"></RichTextLine>
            </span>
            <span v-else-if="header.value=='id_product'">
                <ProductPicker :disabled="item.isFixedGeneratedLine||model.isOpenOrLater" @expand="()=>onExpand(item)" dusk="product" :field-map="productSelectFieldMap" :filter="{'type': 'rp', 'selectfor':'salesinvoice'}" no-border :model='model' :rules='model.rules.id_product' :valueModel="item" v-model='item.id_product'></ProductPicker>
            </span>
            <span class="txt" v-else-if="header.value=='pd_supplier_code'">
                {{item[header.value]}}
            </span>
            <span class="txt" v-else-if="header.value=='pd_supplier_name'">
                {{item[header.value]}}            
            </span>
            <span class="txt" v-else-if="header.value=='ean'">
                {{item[header.value]}}
            </span>
            <span class="txt" v-else-if="header.value=='sup_name'">
                {{item[header.value]}}
            </span>
            <span v-else-if="header.value=='id_mandays_registration'">
                <MandayPicker :disabled="item.isFixedGeneratedLine||model.isOpenOrLater" :id-parent="model.id" dusk="manday" :field-map="mandaySelectFieldMap" :filter="{'selectfor':'salesinvoice', 'id_relation': model.id_relation}"  no-border :model='model' :rules='model.rules.id_mandays_registration' :valueModel="item" v-model='item.id_mandays_registration'></MandayPicker>
            </span>
            <span v-else-if="header.value=='id_project'">
                <ProjectPicker :disabled="model.isFixedProject" dusk="project" :filter="{'selectfor':'salesinvoice'}" :valueModel="item" no-border :model='model' :rules='model.rules.id_project' v-model='item.id_project'></ProjectPicker>
            </span>            
            <span v-else-if="header.value=='id_order'">
                <DrillDown open="salesorder" :id="item.id_order" :rep="item.ord_number" empty="-" :title="item.ord_name"></DrillDown> 
            </span>

            <span v-else-if="header.value=='ihp_sales_price'">
                <NumberField max="20000000" dusk="price" :disabled="item.isFixedGeneratedLine||model.isOpenOrLater" no-border amount :model='model' :rules='model.rules.ihp_sales_price' v-model='item.ihp_sales_price' :currency='item.crnc_code'></NumberField>
            </span>
            <span v-else-if="header.value=='ihp_discount'">
                <NumberField dusk="discount" :disabled="item.isFixedGeneratedLine||model.isOpenOrLater" no-border percentage :model='model' :rules='model.rules.ihp_discount' v-model='item.ihp_discount'></NumberField>
            </span>
            <span v-else-if="header.value=='id_vat'">
                <span v-if="model.inv_vat_shifted" class="pl-3 d-inline-block pt-1">Verlegd</span>
                <VatCombo dusk="vat" :disabled="model.isOpenOrLater" no-clearable standard-types no-border v-else :model='model' :rules='model.rules.mandatory' v-model='item.id_vat'></VatCombo>
            </span>
            <span class="txt" v-else-if="header.value=='amount_excl'">
                <AmountText dusk="totalprice" no-border v-model='item.amount_excl' :currency='model.currency'/>    
            </span>

        </td>
    </tr>
</template>


<script setup>
    
    
    import TextField from '@controls/input/TextField'
    import NumberField from '@controls/input/NumberField'
    import VatCombo from '@controls/combo/Vat'
    import Icon from '@controls/icons/Icon'
    import UnitCombo from '@controls/combo/Unit'    
    import ProductPicker from '@controls/picker/Product'
    import MandayPicker from '@controls/picker/Manday'
    import ProjectPicker from '@controls/picker/Project'
    import AmountText from '@controls/text/Amount.vue' 
    import ActionButton from '@controls/buttons/ActionButton'
    import RichTextLine from '@controls/input/RichTextLine'
    import noty from '@shared/lib/noty'
    import DrillDown from '@controls/buttons/DrillDown'

    // Take over the following fields from the corresponding properties in the selected item: 
    const productSelectFieldMap = {
    //  selectfield:              invoiceline field
        pd_name:                  'ihp_pd_name',
        pd_supplier_code:         'pd_supplier_code',
        pd_supplier_name:         'pd_supplier_name',
        pd_sales_price_excl_vat:  'ihp_sales_price', 
        id_vat:                   'id_vat',
        id_unity:                 'id_unity',
        sup_name:                 'sup_name',
    };
    // Take over the following fields from the corresponding properties in the selected item: 
    const mandaySelectFieldMap = {
    //  selectfield:              invoiceline field
        mdr_title:                'ihp_pd_name',
    };

    const props = defineProps({
        model: {
            type: [Object]
        },
        item: {
            type: [Object]
        }, 
        dt: {
            type: [Object]
        },
        columnHeaders: {
            type: [Object, Array]
        },        
    });
    
    async function removeLine(item) {
        if (item.isExtraWorkLine) {
            if (! await noty.tryConfirm('Deze regel is aangemaakt vanuit project meer/minderwerk. Weet u zeker dat u deze regel wilt verwijderen?')) {
                return;
            }
        }
        if (item.isOrderLine) {
            if (! await noty.tryConfirm('Deze regel is aangemaakt vanuit een verkoop-order. Weet u zeker dat u deze regel wilt verwijderen?<br><br>Wanneer u deze regel verwijderd wordt de regel in de verkoop-order op niet gefactureerd gezet.')) {
                return;
            }
        }
        props.model.removeLine(item)
    }


    async function onExpand(line) {
        if (! await noty.tryConfirm('Wanneer u doorgaat wordt deze regel vervangen door regels met alle artikelen waaruit het recept is opgebouwd.')) {
            return;
        }
        props.model.expandRecipe(line)
    }

</script>
