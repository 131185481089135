<template>
    <Dialog :dialog="dlg">
            <template v-slot:tabs-left>
                <v-tabs dusk="tabs-workorder" dense v-model="dlg.tab">        
                    <v-tab dusk="tab-workorder-header">Werkbon</v-tab>
                    <v-tab dusk="tab-workorder-details" :disabled="model.isLoading">Invoervelden</v-tab>
                </v-tabs>
            </template>

            <v-form xref="form" lazy-validation>
                <v-tabs-items class="product-tabs xmt-2" v-model="dlg.tab">                
                    <v-tab-item transition="fade-transition" reverse-transition="fade-transition">                        
                        <v-form ref="formHeader">
                            <Form>
                                <FormRow label="Type">            
                                    <WorkorderTypeCombo dusk="workordertype" :model="model" :rules="model.rules.mandatory" v-model="model.id_workordertype"></WorkorderTypeCombo>
                                </FormRow>

                                <FormRow label="Titel">            
                                    <TextField v-model="model.title" :model="model" dusk="title" max-length="48" placeholder="Titel" :rules="model.rules.mandatory"></TextField>
                                </FormRow>
                                <FormRow label="Omschrijving">            
                                    <TextField v-model="model.description" :model="model" dusk="description" max-length="255" placeholder="Description" :rules="model.rules.mandatory"></TextField>
                                </FormRow>
                                <FormRow label="Project">    
                                    <ProjectPicker :model='model' v-model='model.id_project'></ProjectPicker>        
                                </FormRow>
                                <FormRow label="Relatie">    
                                    <RelationCombo :model='model' :rules='model.rules.mandatory' v-model='model.id_relation'></RelationCombo>
                                </FormRow>
                                <FormRow label="Adres">
                                    <RelationAddressCombo :model="model" :rules="model.rules.id_address" :id_parent="model.id_relation" v-model="model.id_address"></RelationAddressCombo>
                                </FormRow>
                                <FormRow label="Contact">
                                    <RelationContactCombo :model="model" :rules="model.rules.id_person" :id_parent="model.id_relation" v-model="model.id_person"></RelationContactCombo>
                                </FormRow>
                                <FormRow label="Doeldatum">            
                                    <DatePicker :model="model" v-model='model.targetdate' :rules="model.rules.mandatory"></DatePicker>
                                </FormRow>
                                <FormRow label="Urenschatting">            
                                    <TimeField dusk="estimate" v-model='model.estimate' :model='model' :rules="model.rules.mandatory"></TimeField>
                                </FormRow>
                                <FormRow label="Factureerbaar">            
                                    <Checkbox switcher :model='model' v-model='model.invoicable'></Checkbox>
                                </FormRow>
                                <FormRow label="Status">            
                                    <WorkorderStatus v-model="model.status" :model="model" :rules="model.rules.mandatory"></WorkorderStatus>
                                </FormRow>
                                <FormRow label="Prioriteit">            
                                    <Priority no-clearable v-model="model.priority" :model="model" :rules="model.rules.mandatory"></Priority>
                                </FormRow>
                                <FormRow label="Herhaling">
                                    <v-row>
                                        <v-col sm="auto">
                                            <Checkbox class="mt-1" :model='model' v-model='model.repeat'></Checkbox>
                                        </v-col>
                                        <v-col sm="auto"><label>Na</label></v-col>
                                        <v-col>
                                           <NumberField :disabled="!model.repeat" v-model='model.recurrence' number :model='model' :rules='model.rules.recurrence'                                            
                                           ></NumberField>                                           
                                        </v-col>            
                                        <v-col>
                                            <ActionCombo no-clearable :disabled="!model.repeat" v-model='model.recurrencetype' :model='model' :items='recurrencetypes' :rules='model.rules.mandatory' ></ActionCombo>
                                        </v-col>
                                    </v-row>                                        
                                </FormRow>

                            </Form>
                        </v-form>
                    </v-tab-item>
                    <v-tab-item>
                        <CustomFieldValueList 
                            :model="model" :fields="model.customfields.list">
                        </CustomFieldValueList>
                    </v-tab-item>
                </v-tabs-items>
            </v-form>

    </Dialog>
</template>



<script setup>
    import useModel from '@/models/workorder/workorder'
    import Form from '@controls/forms/Form'
    import FormColumn from '@controls/forms/FormColumn'
    import FormRow from '@controls/forms/FormRow'
    import TextField from '@controls/input/TextField'
    import TextArea from '@controls/input/TextArea'
    import Dialog from '@shared/ui/dialogs/ActionDialog'
    import CountryCombo from '@controls/combo/Country'
    import Checkbox from "@controls/check/Checkbox.vue";
    import VatCombo from '@controls/combo/Vat'
    import UnitCombo from '@controls/combo/Unit'
    import LedgerCombo from '@controls/combo/Ledger'
    import SupplierCombo from '@controls/combo/Supplier'
    import NumberField from '@controls/input/NumberField'
    import ProductGroupCombo from '@controls/combo/ProductGroup'
    import ActionButton from '@controls/buttons/ActionButton'
    import ActionMenuItem from "@controls/menu/ActionMenuItem.vue";
    import eventbus from '@app/eventbus'
    import RelationAddressCombo from '@controls/combo/RelationAddress'
    import RelationContactCombo from '@controls/combo/RelationContact'
    import RelationCombo from '@controls/combo/Relation'
    import ActionCombo from '@controls/combo/ActionCombo'
    import ProjectPicker from '@controls/picker/Project'
    import DatePicker from '@controls/input/DatePicker'
    import { computed, ref } from 'vue'
    import clsDialog from '@cls/clsDialog'
    import WorkorderStatus from '@controls/combo/WorkorderStatus'
    import Priority from '@controls/combo/Priority'
    import WorkorderTypeCombo from '@controls/combo/WorkorderTypes'
    import TimeField from '@controls/input/TimeField'
    import CustomFieldValueList from '@/ui/controls/CustomFieldValueList'

    let recurrencetypes = [{id: 'd', name: 'Dagen'}, {id: 'w', name: 'Weken'}, {id: 'm', name: 'Maanden'},{id: 'y', name: 'Jaar'},  ]

    const formHeader = ref(null); 

    let model = useModel();
    let dlg = ref(
        new clsDialog("workorder", "Werkbon", model, [formHeader], {
            showNote: true,
            showFlag: true,
        } )).value;


    window.wodlg = dlg;
</script>
