import { clsModel, fnCreate } from '@cls/clsModel'
import { tenders as api } from '@/app/api'
import string from '@lib/string'

// We operate on a sales invoice, no special model name for sending an invoice. 
var modelName = "tender_send";
const fields = ['id', 'id_tender', 'chapters', 'id_chapters', 'actualize', 'changestatus', 'tend_number', 'cnt_chapters'];

class clsTenderImport extends clsModel {

    id_tender    = null;
    chapters     = [];
    actualize    = true;
    changestatus = false;
    tend_number  = null;
    id_chapters  = [];
    _cnt_chapters = null;

    
    get cnt_chapters() { return this._cnt_chapters; }
    set cnt_chapters(v) { 
        this._cnt_chapters = v; 
        if (!v || v < 2) {
            this.chapters = [];
            return;
        }
        // When more chapters are found, select the chapters
        this.loadChapters();
    }
    
    get repChapters() {
        var items = (this.chapters||[]).filter( (ch) => !!ch.checked).map( (ch) => ch.ix);
        return (items||[]).join(', ')||"";
    }
    set repChapters(v) {
        v = v || "";
        var arr = string.split(v, ",");
        (this.chapters||[]).forEach( (ch) => {
            ch.checked = !!arr.find( (ix) => ch.ix == ix)
        })
    }


    // We don't load. Just fill default values.
    async loadChapters() {
        this.isDataLoading = true; 
        try {
            var result = await api.listChapters(this.id_tender);
            var chapters = (result.data||[]).map( (chapter, ix) => { return {ix: ix+1, checked: true, ...chapter}});
            this.chapters = chapters;
        } finally {            
            this.isDataLoading = false; 
        }
    }


    // We don't load. Just fill default values.
    async load(id, params) {
        return this.fill({});
    }

    // We actually do not save anything, just return the intended result.
    async save() {
        var chapters = (this.chapters||[]).filter( (ch) => !!ch.checked).map( (ch) => ch.id);
        var data = {id: this.id_tender, chapters: chapters, actualize: this.actualize};
        return data;
    }

    fill(data) {
        data = {}
        data.id_tender    = null;
        data.id_chapters  = [];
        data.actualize    = true;
        data.changestatus = false;
        data.tend_number  = null;
        data.cnt_chapters = null;

        return super.fill(data);
    }

    get selectedChapters() {
        return (this.chapters||[]).filter( (ch) => !!ch.checked).map( (ch) => ch.id);
    }

    constructor() {
        super({
          api: api,   
          modelName: modelName, 

          fillable: fields
        })
    } 
        
 }
 export default fnCreate(clsTenderImport, 'clsTenderImport');
