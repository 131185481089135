<template>

    <span>
        <span class="opacity-50" v-if="compDisabled">
            <Icon class="xeopacity-50"  color="disabled" small type="notefilled"></Icon>
        </span>
        <span class="cursor-pointer disabled" v-else-if="!hasNote" @click="onClick()">
            <Icon class="icon-note-empty" small type="noteempty"></Icon>
        </span>
        <span class="cursor-pointer" v-else @click="onClick()" >
            <Icon class="icon-note-filled" small type="notefilled"></Icon>
        </span>
    </span>

</template>

<script setup>

    import {computed} from 'vue'
    import string from "@lib/string";
    import Icon from '@controls/icons/Icon'
    import action from '@app/action'


    /**
     * import Note from from '@datatable/columns/Note'
     * 
     *     <template v-slot:item.note="{ item }">
     *       <Note check v-model="item.Note"" @click="openNote"></Note>
     *     </template>
     * 
     */
    const props = defineProps({
        noteType: {
            type: [String]
        },
        value: {
            type: [String, Number]
        },
    })

    const compDisabled = computed({
        get() {
            if (!props.noteType) {
                return false;
            }
            return !action.can(`${props.noteType}.open`)            
        }
    })

    const emit = defineEmits(['click'])
    function onClick(evt) {
        emit('click', evt);
    }

    const hasNote = computed({
        get() {
            return !!Number(props.value);
        }
    })

</script>
