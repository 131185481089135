<template>

<div>    
    <v-row dense>        
        
        <v-col class="col-auto" v-for="(item, ix) in compPrependFixed" :key="750+ix">
            <div class="send-attachment">
                <v-row dense>
                    <v-col class="col-auto"><span ><Icon small color="error" type='attachment'></Icon></span></v-col> 
                    <v-col class="col-auto px-4">{{item.name}}</v-col> 
                </v-row>                    
            </div>
        </v-col>
        <v-col class="col-auto" v-for="(item, ix) in selected" :key="ix">
            <div class="send-attachment">
                <v-row dense>
                    <v-col class="col-auto" :title="item.description||null"><span ><Icon small color="error" :type='item.type'></Icon></span></v-col> 
                    <v-col class="col-auto px-4">{{item.name}}</v-col> 
                    <v-col class="col-auto"><span class="cursor-pointer" @click="()=>onRemoveAttachment(item)"><Icon small type='remove'></Icon></span></v-col> 
                </v-row>                    
            </div>
        </v-col>
    </v-row>
    <v-divider v-if="(selected.length||compPrependFixed.length) && compShowDropDown" class="my-2"></v-divider>
    <v-row dense v-if="compShowDropDown">
        <v-col>
            <ActionButton sub dropdown :model="model">Bijlage toevoegen
                <template v-slot:dropdown>

                        <ActionMenuItem v-if="!compSelectable.length && selected.length">Alle bijlagen zijn bijgevoegd</ActionMenuItem> 
                        <ActionMenuItem v-else-if="!compSelectable.length && !selected.length">Geen bijlagen beschikbaar</ActionMenuItem> 

                        <ActionMenuItem v-for="(item, ix) in compSelectable" :key="ix"   
                            @click="()=>onAttachment(item)">{{item.name}}</ActionMenuItem> 

                        <v-divider></v-divider>
                        <ActionMenuItem icon="add" class="text-primary" @click="()=>onAddAttachment()">Bijlage uploaden</ActionMenuItem> 
                </template>
            </ActionButton>
        </v-col>
        <v-col>
            <slot name="right"></slot>
        </v-col>
    </v-row>
</div>


</template>

<script setup>
import ActionButton from '@controls/buttons/ActionButton'
import ActionMenuItem from '@controls/menu/ActionMenuItem.vue'
import Icon from '@controls/icons/Icon'
    // import AttachmentSelector from '@/ui/controls/AttachmentSelector'
    //    <AttachmentSelector
    //        :model="model"
    //        :selected="model.selected"
    //        :selectable="model.selectable">
    //    </AttachmentSelector>

    const props = defineProps({
        disabled: {
            type: [Boolean]
        },
        rules: {
            type: [Array]
        },
        skeleton: {
            type: [Boolean]
        },
        selected: {
            type: [Array]
        },
        selectables: {
            type: [Array]
        },
        model: {
            type: [Object]
        },
        prependFixed: {
            type: [Array],        
        },
        hideEmptyDropdown: {
            type: [Boolean]
        },
    })
    
    import ActionCombo from '@controls/combo/ActionCombo'
    import {computed} from 'vue'
    import string from '@lib/string'
    const emit = defineEmits(['attach', 'deattach', 'uploaded'])
    import dlg from '@app/dlg'

    const compSkeleton = computed({
        get() {
            if (props.skeleton) {
                return true;
            }
            if (!props.model) {
                return false;
            }
            return props.model.isDataLoading;
        }
    });
    const compDisabled = computed({
        get() {
            if (props.disabled) {
                return true;
            }
            if (!props.model) {
                return false;
            }
            return props.model.disabled;
        }
    });

    // Get the items which are selectable. 
    // Those are the initial selectables minus the already selected ones.
    const compSelectable = computed({
        get() {
            let notSelected = (props.selectables ||[]).filter( (item) => !(props.selected||[]).find( (x) => x && (x.id == item.id)));
            return notSelected || [];
        },
    })
    const compPrependFixed = computed({
        get() {
            let items = (props.prependFixed ||[]).filter( (item) => !!item.name);
            return items || [];
        },
    })
    const compShowDropDown = computed({
        get() {
            if (!props.hideEmptyDropdown) {
                return true;
            }
            let selectables = compSelectable.value;
            if ( !selectables || !selectables.length) {
                return false;
            }
            return true;
        },
    })


    /**
     * Lets not modify the parent properties.
     */
    function onAttachment(attachment) {
        emit('attach', attachment);
    }
    function onRemoveAttachment(attachment) {
        emit('unattach', attachment);
    }

    async function onAddAttachment() {
        var result = await dlg.promised.open("attachment_upload", {id_optimit_type: props.model.id_optimit_type, id_entity:props.model.id}, 
            {rightsModelName: props.model.modelName});
        emit('uploaded');
    }
    

</script>
