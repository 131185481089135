import { clsModel, fnCreate } from '@cls/clsModel'
import { project_chapter as api } from '@/app/api'
import {relation} from '@app/list'

var modelName = "project_chapter";
const fields = [
    'id', 
    'id_project',
    'name',
    'contract_sum',
    'budgetcosts',
    'budgethours',
];

class clsProjectChapter extends clsModel {

    id_project = null
    name = null
    contract_sum = null
    budgetcosts = []
    budgethours = []

    get modelRep() {
        return this.name;
    }    

    addCostLine() {
        this.budgetcosts.push({id_costtype: null, amount: 0})
    }
    removeCostLine(line) {
        this.budgetcosts = this.budgetcosts.filter( (x) => x!=line);
    }
    get totalCosts() {
        return (this.budgetcosts||[]).reduce( (acc, bc) => acc + Number(bc.amount)||0, 0); 
    }

    addHourLine() {
        this.budgethours.push({id_hour_type: null, amount: 0})
    }
    removeHourLine(line) {
        this.budgethours = this.budgethours.filter( (x) => x!=line);
    }
    get totalHours() {
        return (this.budgethours||[]).reduce( (acc, bc) => acc + Number(bc.amount)||0, 0); 
    }
    
    fill(data, ignoreInitialState) {
        data.contract_sum = data.contract_sum || 0;
        data.budgethours = data.budgethours || [];
        data.budgetcosts = data.budgetcosts || [];
        return super.fill(data, ignoreInitialState);        
    }

    constructor() {
        super({
          api: api,   
          modelName: modelName, 
          fillable: fields
        })
    } 
        
 }
 export default fnCreate(clsProjectChapter , 'clsProjectChapter');
