import {vat as list} from '@app/list';
import {numeric} from '@lib/numeric'

//
//
// Helper for working with VAT. 
// Note that this helper assumes that the vat list is filled in the application.
//
// The result is rounded on 2 decimals.
//
// Usage: 
//  import vat from '@lib/vat'
//  let amountIncl = vat.excl2incl(this.id_vat, 100); // 121
//  let amountExcl = vat.incl2excl(this.id_vat, 121); // 100

var vat = {};

vat.ID_VAT_HIGH    = 1; // constant value. Will not be changed in database
vat.ID_VAT_LOW     = 6; // constant value. Will not be changed in database
vat.ID_VAT_SHIFTED = 4; // constant value. Will not be changed in database
vat.ID_VAT_FOREIGN = 5; // constant value. Will not be changed in database
vat.ID_VAT_NONE    = 3; // constant value. Will not be changed in database

/**
 * Get the readable name for the vat type
 */
vat.name = function(id_vat) {
    let item = list.one(id_vat);
    if (!item) {
        return "?";
    }
    return item.name;
}

/**
 * Does the given vat have 0% vat? 
 * This is the case for 'shifted', foreign and 0%.
 */
vat.is0pct = function(id_vat) {
    let item = list.one(id_vat);
    if (!item) {
        return value;
    }
    return !(Number(item.pct) || 0);
}
/**
 * Calculate the incl vat to excl vat by the vat id
 * 
 * @param {*} id_vat 
 * @param {*} value 
 * @returns 
 */
vat.incl2excl = function(id_vat, value) {
    if (!value) {
        return 0;
    }
    let item = list.one(id_vat);
    if (!item) {
        return value;
    }
    let pct = Number(item.pct) || 0;
    if (!pct) {
        return value;
    }
    return numeric.round((value / (100 + pct)) * 100, 2);
}
/**
 * Calculate the vat component in the incl vat amount by vat id
 * 
 * @param {*} id_vat 
 * @param {*} value 
 * @returns 
 */
vat.incl2vat = function(id_vat, value) {
    if (!value) {
        return 0;
    }
    let item = list.one(id_vat);
    if (!item) {
        return 0;
    }
    let pct = Number(item.pct) || 0;
    if (!pct) {
        return 0;
    }
    return numeric.round((value / (100 + pct)) * pct, 2);
}
vat.excl2incl = function(id_vat, value) {
    if (!value) {
        return 0;
    }
    let item = list.one(id_vat);
    if (!item) {
        return value;
    }
    let pct = Number(item.pct) || 0;
    if (!pct) {
        return value;
    }
    return numeric.round((value * (100 + pct)) / 100, 2);
}
vat.excl2vat = function(id_vat, value) {
    if (!value || !id_vat) {
        return 0;
    }
    let item = list.one(id_vat);
    if (!item) {
        return 0;
    }
    let pct = Number(item.pct) || 0;
    if (!pct) {
        return 0;
    }
    return numeric.round((value * pct) / 100, 2);
}
vat.vat2excl = function(id_vat, value) {
    if (!value || !id_vat) {
        return 0;
    }
    let item = list.one(id_vat);
    if (!item) {
        return 0;
    }
    let pct = Number(item.pct) || 0;
    if (!pct) {
        return 0;
    }
    return numeric.round((value / (pct/100)) , 2);
}

/**
 * Is the vat line shifted?
 */
vat.isShifted = function(id_vat) {
    let item = list.one(id_vat);
    if (!item) {
        return false;
    }
    return item.is_shifted;    
}

/**
 * Is the vat line foreign?
 */
vat.isForeign = function(id_vat) {
    let item = list.one(id_vat);
    if (!item) {
        return false;
    }
    return item.is_abroad;            
}


export default vat;