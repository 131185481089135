<template>
    <v-dialog class="action-dialog action-dialog-small"
        :key="model.dlgKey"
        :content-class="`${cls} action-dialog-small`" 
        persistent 
        scrollable
        v-model="model.open" 
        :maxWidth="maxWidth" 
        @keydown.escape="(evt) => model.close(evt)">
            <v-card flat :loading="model.isLoading">

                <v-card-title flat class="px-4 pt-0 pb-0">
                    <slot name="header-title">
                        <v-list class="py-0 dialog-header-title">
                            <v-list-item class="px-0 py-2">
                                <v-list-item-action class="icon-container ma-0 pa-2 mr-4" v-if="icon" >
                                    <slot name="header-icon">
                                        <Icon color="primary" :type="icon"></Icon>
                                    </slot>
                                </v-list-item-action>                                            
                                <v-list-item-content class="py-0">
                                    <v-list-item-title class="mb-0">
                                        <slot name="header-maintitle">
                                            <h1 class="capitalize"><slot name="title">{{title}}</slot></h1>
                                        </slot>
                                    </v-list-item-title>
                                    <v-list-item-subtitle  v-if="subTitle">
                                        <slot name="header-subtitle">
                                            <caption class="capitalize">{{subTitle}}</caption>
                                        </slot>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>                                    
                        </v-list>
                    </slot>
                    <v-spacer></v-spacer>                            
                    <div class="commands">
                        <slot name="header-pre-actions"></slot>
                        <slot name="header-close">
                            <HeaderButton  dusk="btn-dialog-close" no-count class="ml-2" type="close" :disabled="model.isLoading" @click="model.close()"/>
                        </slot>
                        <slot name="header-post-actions"></slot>
                    </div>
                </v-card-title>                           

                <v-card-text flat class="pa-0">
                    <v-divider class="mb-0"></v-divider>
                </v-card-text>
                
                <slot name="content">
                    <v-card-text class="content pt-4">
                        <v-form ref="promptForm">
                            <slot></slot>
                        </v-form>
                    </v-card-text>
                </slot>
                <v-divider></v-divider>
                <v-card-actions class="py-4">
                    <slot name="footer-left"></slot>
                    <v-spacer></v-spacer>                            
                    <slot name="footer-right"></slot>
                    <div class="commands">
                        <slot name="footer-pre-actions"></slot>
                        <slot v-if="cancel" name="footer-cancel">
                            <ActionButton dusk="btn-cancel" :disabled="model.isLoading" @click="model.close()">
                                <slot name="footer-cancel-text">{{cancel}}</slot>
                            </ActionButton>
                        </slot>

                        <slot v-if="save" name="footer-save">
                            <ActionButton dusk="btn-save" :disabled="model.isLoading||model.disabled" main primary ml @click="onSave">
                                <slot name="footer-save-text">{{save}}</slot>
                            </ActionButton>
                        </slot>

                        <slot name="footer-post-actions"></slot>

                    </div>
            </v-card-actions>           
        </v-card>

    </v-dialog>
</template>

<script setup>
////////////////////////////////////////////////////////////////////////////////////////////////////
//
// Example:
// <Prompt title="Wijzig offerte" save="Ga door" :model="prompt">
//     <FormRow label="Project">    
//        <ProjectPicker :filter="{'selectfor':'tender'}" :model='prompt' v-model='prompt.id_project'></ProjectPicker>
//     </FormRow>
// </Prompt>
// 
//
// import Prompt from '@shared/ui/dialogs/DialogCustomPrompt'
// import clsDialogCustomPrompt from '@cls/clsDialogCustomPrompt'
// import FormRow from '@controls/forms/FormRow'
// import ProjectPicker from '@controls/picker/Project'
// import {ref} from 'vue'
// 
// let prompt = ref(new clsDialogCustomPrompt(["id_project", "pro_number"]));
// 
// async function onDoSomething() {
//   var result = await prompt.setData({id_project: model.id_project, pro_number: model.pro_number})
//                            .prompt( async (obj) => console.log('Received data', obj))
// }
//
////////////////////////////////////////////////////////////////////////////////////////////////////

import Icon from '@controls/icons/Icon'
import ActionButton from '@controls/buttons/ActionButton'
import HeaderButton from '@shared/ui/dialogs/HeaderActionButton'
import {computed, ref, watch} from 'vue'

    const props = defineProps({
        model: {
            type: [Object] // subclass of clsDialogCustomPrompt
        },
        cls: {
            type: [String],
        },
        icon: {
            type: [String],
            default: 'edit'
        },
        title: {
            type: [String],
        },
        subTitle: {
            type: [String],
        },
        save: {
            type: [String],
            default: "Opslaan",
        },
        cancel: {
            type: [String],
            default: "Annuleren",
        },
        maxWidth: {
            type: [String],
            default: '650px'
        }
    })

    const promptForm = ref(null);
    const compOpen = computed({
        get() {
            return props.model.open
        }
    })
    watch(compOpen, (newValue, oldValue) => {            
        if (newValue) {
            if (promptForm?.value?.resetValidation) {
                promptForm.value.resetValidation();
            }
        }
    })    
    function onSave() {
        if (promptForm?.value?.validate) {
            if (!promptForm.value.validate()) {
                return false;
            }
        }
        props.model.save();
    }

</script>