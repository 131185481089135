<template>
    <Dialog :dialog="dlg">
        <v-form ref="form" lazy-validation>
            <Form>

                <FormRow label="Bedrag">
                    <NumberField dusk="poc_amount" :rules="model.rules.mandatory" amount :model="model" v-model="model.poc_amount"></NumberField>
                </FormRow>
                <FormRow v-if="model.useChapters" label="Hoofdstuk" label-v-top>
                    <ActionCombo :model="model" v-model="model.id_project_chapter" :items="model.chapters"></ActionCombo>
                    <span class="text-italic text-primary text-small">Het gebruik van hoofdstukken is optioneel in overige kosten.</span>
                </FormRow>
                <FormRow label="Omschrijving" label-v-top>
                    <TextField dusk="poc_name" :model="model" max-length="255" placeholder="Omschrijving" :rules="model.rules.mandatory" v-model="model.poc_name"></TextField>
                </FormRow>
                <FormRow label="Opmerking" label-v-top>
                    <TextArea dusk="poc_description" :model='model' max-length="255" placeholder='Meer informatie' v-model='model.poc_description'></TextArea>
                </FormRow>
            </Form>
        </v-form>

    </Dialog>
</template>



<script setup>
    import useModel from '@/models/project/othercost'
    import Form from '@controls/forms/Form'
    import FormRow from '@controls/forms/FormRow'
    import TextField from '@controls/input/TextField'
    import Dialog from '@shared/ui/dialogs/ActionDialog'
    import NumberField from '@controls/input/NumberField'
    import TextArea from '@controls/input/TextArea'
    import ActionCombo from '@controls/combo/ActionCombo'

    import { ref } from 'vue'
    import clsDialog from '@cls/clsDialog'

    const form = ref(null); 

    let model = useModel();
    let dlg = ref(
        new clsDialog("project_othercosts", "Overige kosten", model, [form], {
            icon: 'money'
        } )).value;

</script>
