import clsApi from '@cls/clsApi'

class clsMollie extends clsApi {

    load() {
        return this.get(`loadapikey`); 
    }
    save(model) {
        model = model ||{}
        return this.post(`saveapikey`, {password: model.password, apikey: model.apikey}); 
    }

    listIncasso() {
        return this.get(`incasso/list`); 
    }

}

export default clsMollie;
