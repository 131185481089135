<template>

    <ActionCombo :noClearable="noClearable" :placeholder="placeholder" :skeleton="skeleton" :model="model" :disabled="disabled" 
        :rules="rules" v-model="dvalue" :items="items"
        open create route="settings.salesorderterms" baseType="salesorderterm"
    
    ></ActionCombo>

</template>

<script setup>

    // import SalesOrderTermCombo from '@controls/combo/SalesOrderTerm'
    // <SalesOrderTermCombo :model="model" :rules="model.rules.mandatory" v-model="model.id_order_term"></SalesOrderTermCombo>

    const props = defineProps({
        disabled: {
            type: [Boolean]
        },
        rules: {
            type: [Array]
        },
        noClearable: {
            type: [Boolean],
            default: false
        },
        skeleton: {
            type: [Boolean]
        },
        value: {
            type: [String, Number]
        },
        placeholder: {
            type: [String],
            default: 'Termijnverdeling'
        },
        model: {
            type: [Object]
        }

    })
    
    import ActionCombo from '@controls/combo/ActionCombo'
    import {salesorderterm as list} from '@app/list';
    import {computed} from 'vue'

    const emit = defineEmits(['input'])

    const dvalue = computed({
        get() {
            return props.value
        },
        set: function(v) {
            emit('input', v)
        }	
    })

    const items = computed({
        get() {
            return list.listActual(dvalue.value) ||[]
        },
    })

</script>
