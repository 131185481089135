<template>

    <ActionCombo :noBorder="noBorder" :borderHover="borderHover" :noClearable="noClearable" :placeholder="placeholder" :skeleton="compSkeleton" :disabled="compDisabled" :rules="rules" v-model="dvalue" :items="items"
        :open="open" :create="create" route="masterdata.hourtypes" baseType="hourtype"
    ></ActionCombo>

</template>

<script setup>

    // import HourTypeCombo from '@controls/combo/HourType'
    // <HourTypeCombo :model="model" :rules="model.rules.id_hour_type" v-model="model.id_hour_type"></HourTypeCombo>

    const props = defineProps({
        disabled: {
            type: [Boolean]
        },
        rules: {
            type: [Array]
        },
        noClearable: {
            type: [Boolean],
            default: false
        },
        skeleton: {
            type: [Boolean]
        },
        value: {
            type: [String, Number]
        },
        placeholder: {
            type: [String],
            default: 'Urentype'
        },
        noBorder: {
            type: [Boolean]
        },
        borderHover: {
            type: [Boolean]
        },
        create: {
            type: [Boolean],
            default: true
        },
        open: {
            type: [Boolean],
            default: true
        },

        model: {
            type: [Object]
        }

    })
    
    import ActionCombo from '@controls/combo/ActionCombo'
    import {hourtype as list} from '@app/list';
    import {computed} from 'vue'

    const emit = defineEmits(['input'])

    const dvalue = computed({
        get() {
            return props.value
        },
        set: function(v) {
            emit('input', v)
        }	
    })

    const compSkeleton = computed({
        get() {
            if (props.skeleton) {
                return true;
            }
            if (!props.model) {
                return false;
            }
            return props.model.isDataLoading;
        }
    });
    const compDisabled = computed({
        get() {
            if (props.disabled) {
                return true;
            }
            if (!props.model) {
                return false;
            }
            return props.model.disabled;
        }
    });

    const items = computed({
        get() {
            return list.listActual(dvalue.value) ||[]
        },
    })

</script>
