import { clsModel, fnCreate } from '@cls/clsModel'
import Constants from '@app/consts'
import { tenders as api } from '@/app/api'

// We operate on a sales invoice, no special model name for sending an invoice. 
var modelName = "tender_createinvoice";
const fields = ['id', 'id_status', 'tend_number', 'set_next_state', 'open_invoice'];

class clsCreateInvoice extends clsModel {

    id = null;
    id_status = null;
    tend_number  = null;
    set_next_state = false;
    open_invoice = true;

    /**
     * Is this tender already accepted?
     */
    get isAccepted() {
        return this.id_status == Constants.tender.status.STATUS_ACCEPTED;
    }

    get subTitle() {
        return this.tend_number;
    }

    /**
     * Retrieve warnings before invoicing
     */
    async onBeforeOpen(params, extraData) {
//        if (params && params.id) {
//            await api.getWarnings({id: params.id}, "getinvoicewarnings");             
//        }    
    }

    // We don't load. Just fill default values.
    async load(id, params) {
        return this.fill(params);
    }

    /**
     * Instead of saving, we just return the data
     */
    async save() {
        let data = this.toJSON();
        return data;
    }

    fill(data) {
        data = data || {};
        data.set_next_state = true;
        data.open_invoice = true;
        return super.fill(data);
    }
    
    constructor() {
        super({
          modelName: modelName, 
          fillable: fields
        })
    } 
        
 }
 export default fnCreate(clsCreateInvoice, 'clsCreateInvoice');
