import { clsModel, fnCreate } from '@cls/clsModel'
import { relation_addresses as api } from '@/app/api'
import Constants from '@app/consts'
import string from '@lib/string'

const defaultCountryCode = Constants.defaults.country_code;
var modelName = "relation_address";

const fields = ["id",  "id_relation", "adr_city", "adr_street", "adr_street_number", "adr_zipcode", "is_delivery_address", "is_pickup_address", "country_code"];

class clsRelationAddress extends clsModel {

    id = null; // id_address
    id_relation = null;
    adr_city = null;
    adr_street = null;
    adr_street_number = null;
    adr_zipcode = null;
    is_delivery_address = null;
    is_pickup_address = null;    
    country_code = null;

    constructor() {
        super({
          api: api,   
          modelName: modelName, 
          mandatoryFields: ['adr_city'],
          fillable: fields
        })
    } 

    get modelRep() {
        return string.concat(" ", this.adr_street, this.adr_street_number, this.adr_city);  
    }

    /**
     * Override load to retrieve the address via 2 keys - a relation id and an address id.
     * @param {} id 
     * @param {*} params 
     * @returns 
     */
    load(id, params) {
        var self = this;
        this.fill();
        let id_relation = params.id_relation || params.id_parent;
        return this.withDataLoading( () => {
            return self.api.load2(id_relation, params.id)
              .then( ({data}) => {
                self.fill(data);
            })
        })    
    }

    async doCreate(defaults) {
        if (defaults) {
            defaults.id_relation = defaults.id_relation || defaults.id_parent;
        }
        return super.doCreate(defaults);
    }

    /**
     * Fill the data 
     * 
     * @param {*} data 
     */
    fill(data) {
        data = data || {};

        data.is_delivery_address = !!data.is_delivery_address;
        data.is_pickup_address = !!data.is_pickup_address;
        data.country_code = data.country_code || defaultCountryCode;
        super.fill(data);
    }

    /**
     * Get the JSON representation for saving the data
     * 
     * @param {*} data 
     */
//    toJSON() {
//        return this.propsToJSON([
//            "id", 
//            "id_relation",
//            "adr_city",
//            "adr_street",
//            "adr_street_number",
//            "adr_zipcode",
//            "is_delivery_address",
//            "is_pickup_address",
//            "country_code",            
//    
//        ]); // TODO
//    }        
 }
 export default fnCreate(clsRelationAddress , 'clsRelationAddress');
