<template>
    <Dialog :dialog="dlg">
        <v-form ref="form" lazy-validation>
            <Form>
                <FormRow col-label="2" label="Titel">                    
                    <TextField dusk="title" :model="model" :rules="model.rules.title" placeholder="Title" v-model="model.title"></TextField>
                </FormRow>
                <FormRow col-label="2" label="">                    
                    <RichTextArea dusk="text" :model='model' placeholder='Tekst' v-model='model.text'></RichTextArea>
                </FormRow>
            </Form>
        </v-form>

    </Dialog>
</template>



<script setup>
    import useModel from '@/models/standardtext'
    import Form from '@controls/forms/Form'
    import FormColumn from '@controls/forms/FormColumn'
    import FormRow from '@controls/forms/FormRow'
    import TextField from '@controls/input/TextField'
    import Dialog from '@shared/ui/dialogs/ActionDialog'
    import RichTextArea from '@controls/input/RichTextArea'


    import { ref } from 'vue'
    import clsDialog from '@cls/clsDialog'

    const form = ref(null); 
    let model = useModel();

    /**
     * The model contains a title field which is used by default as the dialog title. 
     * Override it.
     */
    class clsStandardTextDialog extends clsDialog {
        get title() {
            return "Standaard tekst";
        }
    }

    let dlg = ref(
        new clsStandardTextDialog("standardtext", "Standaard Tekst", model, [form], {
            maxWidth:800
        } )).value;

window.stdlg = dlg;
</script>
