import {relations as api} from '@app/api'
import string from '@lib/string'
/**
 * 
 *   Usage: See clsList for examples, API and implementation notes. 
 *   In addition to the default functionallity, this list is capable refreshing a relation so that the 
 *   addresses and contacts are available. 
 * 
 */
import clsList from '@cls/clsList'

 
class clsRelation extends clsList {
 
    /**
     * List relations which can be used in sales related functionallity
     * @param {} id 
     * @returns 
     */
    listSales(id) {
        let items = this.listActual(id);
        items = (items||[]).filter ((v) => !!v.use_in_sales || v.id == id); 
        return items;
    }
    /**
     * List relations which can be used in purchase related functionallity
     * @param {} id 
     * @returns 
     */
    listPurchase(id) {
        let items = this.listActual(id);
        items = (items||[]).filter ((v) => !!v.use_in_purchase || v.id == id); 
        return items;
    }

    /**
     * We have custom functionallity for updating after a save event.
     * Instead of refreshin gthe list, we refresh the affected relation only.
     * 
     * @param {*} entityName 
     * @param {*} data 
     */
    onModelSavedEvent(entityName, data) {
        // When a relation is saved, the refreshed item is sent along with the event.
        if (entityName == this.modelName) {
            if (data && data.rel_name) { // The data was sent along                
                this.updateRow(data);
            } else {
                this.refresh(data.id || data.id_relation, true)
            }            
        // Otherwise when an address, contact or bank account is saved, refresh the relation. 
        } else if (string.isInRange(entityName, "relation_contact", "relation_address", "relation_bankaccount")  && data.id_relation) {
            this.refresh(data.id_relation, true)
        }
        // else, the event is not for us.
    };
    /**
     * Refresh the relation from the server.
     */
    async refresh(id) {
        var obj = await api.load(id)
        if (obj && obj.data) {
            obj = obj.data;
        }
    
        // This does not work. as it overwrites the array index.
        //   this.updateRow(obj);

        var row = this.list.find( (x) => x.id == id);
        if (!row) {
            console.error("updateRow - updatable row not found.");
            return;    
        }
        // Overwrite all object properties, including the bank accountants, addresses, et all.
        for (var key in obj) {
            row[key] = obj[key];
        }
        // row.bankaccounts = obj.bankaccounts;
  
//        this.updateRow(obj);
    }
    
 }
 
export default clsRelation
