<template>
    <li>
        <span class="fa-li"><Icon size="xs" :type="compIcon" :color="compColor"></Icon></span>
        <slot></slot>
    </li>
</template>

<script setup>
import Icon from '@controls/icons/Icon'
import {computed} from 'vue'

//
// Usage: 
// import <UlCustom> from "@controls/list/UlCustom.vue";
// import <LiCustom> from "@controls/list/LiCustom.vue";
// <LuCustom>
//    <LiCustom check >One</LiCustom>    
//    <LiCustom check color="error">Two</LiCustom>    
//    <LiCustom warning>Two</LiCustom>    
// </LiCustom>
//

const props = defineProps({
    error: {
        type: [Boolean],
        default: false
    },
    warning: {
        type: [Boolean],
        default: false
    },
    check: {
        type: [Boolean],
        default: false
    },
})

const compIcon = computed({
    get() {
        if (!!props.check) {
            return "circle-check-filled";
        }
        if (!!props.warning) {
            return "warning-filled";
        }
        if (!!props.error) {
            return "error-filled";
        }
        return ""
    }
})

const compColor = computed({
    get() {
        if (!!props.error) {
            return "error";
        }
        if (!!props.warning) {
            return "warning";
        }
        if (!!props.check) {
            return "primary";
        }
        return ""
    }
})

</script>