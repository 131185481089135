<template>
    <Dialog :dialog="dlg">

        <AlertArea primary class="mb-4">
            Van deze offerte wordt een concept-factuur gemaakt.<br>
            Deze factuur wordt niet automatisch verzonden.<br> 
            U kunt de factuur aanpassen alvorens deze te versturen.
        </AlertArea>

        <v-form ref="form" lazy-validation>
            <v-row dense v-if="!model.isAccepted">
                <v-col>
                    <Checkbox label="Zet offertestatus naar 'Opdracht' na aanmaken factuur" :model="model" v-model="model.set_next_state"></Checkbox>
                </v-col>
            </v-row>
            <v-row dense v-else>
                <v-col>
                    <Checkbox label="Zet offertestatus naar 'Gereed' na aanmaken factuur" :model="model" v-model="model.set_next_state"></Checkbox>
                </v-col>
            </v-row>
            <v-row dense >
                <v-col>
                    <Checkbox label="Open direct de aangemaakte factuur" :model="model" v-model="model.open_invoice"></Checkbox>
                </v-col>
            </v-row>
        </v-form>

    </Dialog>
</template>



<script setup>
    import useModel from '@/models/tender/createinvoice'
    import Form from '@controls/forms/Form'
    import FormRow from '@controls/forms/FormRow'
    import Dialog from '@shared/ui/dialogs/ActionDialog'
    import Checkbox from '@controls/check/Checkbox.vue'
    import AlertArea from '@shared/ui/markup/AlertArea'

    import { ref } from 'vue'
    import clsDialog from '@cls/clsDialog'

    const form = ref(null); 
    let model = useModel();

    let dlg = ref(
        new clsDialog("tender_createinvoice", "Maak factuur", model, [form], {
            icon: 'invoice',
            save: 'Ga door'
        } )).value;

    window.cremo = model;
</script>
