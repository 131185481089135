/**
 * 
 *   Usage: See clsList for examples, API and implementation notes. 
 * 
 */
import clsList from '@cls/clsList'

 
class clsCurrencyList extends clsList {
 
     /**
     * Get one Currency name from the list
     * @param {} id 
     */
    oneName(id, defaultValue) {
        let result = this.one(id);
        return result && result.name || (defaultValue||'');
    }

    /**
     * Get one Currency symbol from the list
     * @param {} id 
     */
     oneSymbol(id, defaultValue) {
        let result = this.one(id);
        return result && result.symbol || (defaultValue||'');
    }

 }
 
export default clsCurrencyList
