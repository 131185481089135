<template>
    <div class="toolbar-field display-inline-block">
        <div class="toolbar-label text-extra-small">{{label}}</div>
        <slot></slot>
    </div>            
</template>

<script setup>
    // Usage: 
    // import ToolbarField from '@shared/ui/dialogs/ToolbarField'
    //        <ToolbarField label="Totaal">
    //            <Amount v-model="model.total_amount" currency="EUR"></Amount>
    //        </ToolbarField>
    import string from '@lib/string'
    import {computed} from 'vue'

    const props = defineProps({
        label: {
            type: [String]
        },        
    });

    const emit = defineEmits(['click'])
    function onClick() {
        emit('click')
    }
</script>