<template>
    <Dialog :dialog="dlg">
        <v-form ref="form" lazy-validation>
            <Form>
                <FormRow label="Type">           
                    <ActionCombo dusk="cr_type" no-clearable :skeleton="model.isDataLoading" :disabled="model.disabled" :rules="model.rules.cr_type" v-model="model.cr_type" :items="types"></ActionCombo>
                </FormRow>
                <FormRow label="Omschrijving">                    
                    <TextField dusk="cr_description" :skeleton="model.isDataLoading" :disabled="model.disabled" :rules="model.rules.cr_description" v-model="model.cr_description"></TextField>
                </FormRow>
                <FormRow label="Korte naam">                    
                    <TextField dusk="cr_shortname"  :skeleton="model.isDataLoading" :disabled="model.disabled" :rules="model.rules.cr_shortname" v-model="model.cr_shortname"></TextField>
                </FormRow>
                <FormRow label="Termijn"> 
                    <NumberField dusk="cr_days"  suffix="dagen" integer :skeleton="model.isDataLoading" :disabled="model.disabled" :rules="model.rules.cr_days"  v-model="model.cr_days"></NumberField>
                </FormRow>
                <FormRow label="Korting"> 
                    <NumberField dusk="cr_percentage"  percentage :skeleton="model.isDataLoading" :disabled="model.disabled" :rules="model.rules.cr_percentage"  v-model="model.cr_percentage"></NumberField>
                </FormRow>


            </Form>
        </v-form>

    </Dialog>
</template>



<script setup>
    import useModel from '@/models/creditrestriction'
    import Form from '@controls/forms/Form'
    import FormColumn from '@controls/forms/FormColumn'
    import FormRow from '@controls/forms/FormRow'
    import TextField from '@controls/input/TextField'
    import Dialog from '@shared/ui/dialogs/ActionDialog'
    import ActionCombo from '@controls/combo/ActionCombo'
    import NumberField from '@controls/input/NumberField'

    import { ref } from 'vue'
    import clsDialog from '@cls/clsDialog'

    var types = [{id: 'P', name: "Betalingskorting"}, {id: 'C', name: "Kredietbeperking"}];


    const form = ref(null); 

    let model = useModel();
    let dlg = ref(
        new clsDialog("creditrestriction", "Betalingskorting", model, [form] )).value;

</script>
