import clsAuthApi from '@cls/clsAuthApi'
import user from '@app/user'

class clsAuthApiExt extends clsAuthApi {


    onException(e) {
        console.error('clsAuthApi: ', e);
    }

    /**
     * For Optimit, we want the current company to be included when retrieving application data. 
     * 
     * @returns 
     */
    getHeadersForLoadAppData() {
        if (user.getHeaders) {
            return user.getHeaders();
        }

        return [];
    }
    
} 

export default clsAuthApiExt;
