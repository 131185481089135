import clsCurrentuser from '@cls/clsCurrentuser'
import authApi from '@lib/authApi'

class clsCurrentuserExt extends clsCurrentuser {

    administrations = [];
    id_administration = null;

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    //
    // The user rights on modules and rights
    // Note that we just store the data. Other util can interpret it as tey want.
    //
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    _modules = [];
    _rights = [];
    _subscription = [];
    get modules() { return this._modules; }
    get rights()  { return this._rights; }
    get subscription()  { return this._subscription; }
    
    // Note, modules can be set and the client will reflect the availabillity of the added modules.
    // Allthough this could make modules available on the frontend without actually having rights, 
    // on the backend, server middleware asserts on access to unauthorized modules.
    set modules(v) { this._modules = v; }

    /**
     * Called after the server data is loaded. 
     * Store kantoor specific properties.
     * - rights 
     * - modules
     * 
     * @param {*} data 
     */
    onAppDataLoaded(data) {
        this._modules = data?.m || [];
        this._rights  = data?.r || [];
        this._subscription  = data?.s || {};
    }

    /**
     * Is the given module available for usage via module list? 
     */
    hasModule(m) {
        return (this.modules||[]).indexOf(m) >=0;
    }
    /**
     * Does the user have the given right?
     * Note that the right can be either a single right or an array of rights.
     */
    hasRight(rgt) {
        if (!rgt) { 
            return false; // no right,   
        }
        var arrRgt = Array.isArray(rgt) ? rgt : [rgt];

        // Check whether any of the requested rights is in the user rights or the user has right 'a' 
        // Note that 'a' is used in frontend only. Backend validation will fail when spoofed. 
        return !!arrRgt.find( (r) => (this.rights||[]).find ( (userRight) => userRight == r || userRight == 'a'));

        // Test: var arrUserRights = [1,22,3]; var reqRights = [22]; !!reqRights.find( (r) => (arrUserRights||[]).find ( (userRight) => userRight == r || userRight == 'a'))
    }

    /**
     * Fill the data, and in addition to the default, we (might) have administrations.
     * Note that we explicitely clear modules, rights and subscription info as this is loaded
     * by the appdata loader.
     *  
     * @param {} data 
     */
    fill(data) {
        data = data ||{};
        super.fill(data);
        this.administrations = data.administrations || [];
        this.id_administration = data.id_administration || null;
        // modules and rights are loaded
        this._modules = [];
        this._rights  = [];
        this._subscription  = [];

    }

    // Rerfesh the subscription information.
    setSubscription(subscription) {
        this._subscription = subscription;
    }
    // Subscription is canceled. Check subscriptionCanceledDaysLeft whether any days are left in the subscription.
    get isSubscriptionTypeNone() {
        return this.subscription.ob_invoice_type == 'none';
    }
    // Subscription is canceled. Check subscriptionCanceledDaysLeft whether any days are left in the subscription.
    get isSubscriptionCanceled() {
        if (this.isSubscriptionTypeNone) { 
            return false; 
        }
        return !!this.subscription.subscription_canceled;
    }
    // The number of days left in the subscription in case the subscription is canceled.
    get subscriptionCanceledDaysLeft() {
        return this.subscription.subscription_days_left || 0;
    }
    // Is the subscription canceled and no more days are left?
    get isSubscriptionCanceledFinal() {
        if (this.isSubscriptionTypeNone) { 
            return false; 
        }
        return this.subscription.subscription_canceled_final;
    }
    // Is the subscription suspended
    get isSubscriptionSuspended() {
        return !!this.subscription.suspended;
    }
    // Is the trial period ended?
    get isTrialPeriodEnded() {
        return !!this.subscription.trial_expired;
    }
    // Is a trial period active?
    get isInTrialPeriod() {
        if (this.isSubscriptionTypeNone) { 
            return false; 
        }
        return !!this.subscription.trial_period;
    }
    // Are any days left in the trial period?
    get trialPeriodDaysLeft() {
        return this.subscription.trial_days_left || 0;
    }

    /**
     * Do we have at least one atministration and is it selected?
     */
    get hasAdministrationSelected() {
        return this.administrations.length > 0 && !!this.id_administration;
    }

    /**
     * Do we have multiple administration but none selected?
     */
    get hasMultipleAdministrationsButNoneSelected() {
        return this.administrations.length > 0 && !this.id_administration;
    }
    
    /**
     * Do we have no administration at all?
     */
    get hasNoAdministration() {
        return !this.administrations.length;
    }
    /**
     * Do we have at least one atministration?
     */
    get hasAdministration() {
        return this.administrations.length > 0;
    }
    /**
     * Do we have exactly one administration?
     */
    get hasSingleAdministration() {
        return this.administrations.length == 1;
    }
    /**
     * Do we have multiple administrations?
     */
    get  hasMultipleAdministrations() {
        return this.administrations.length > 1;
    }

    /**
     * Get the active administration record
     */
    get activeAdministration() {
        if (!this.administrations.length ||  !this.id_administration) {
            return null;
        }
        return  this.administrations.find ( (adm) => adm.id == this.id_administration);        
    }
    /**
     * Get the currently selected administration name.
     */
    get administrationName() {
        let adm = this.activeAdministration;
        if (!adm) {
            return null;
        }
        return adm.name;
    }
    /**
     * Get the headers which should for user perspective be sent along to the server.
     * In our case, we want the current administration to be sent along. 
     * 
     * Note that the server guards the validness of the provided data.
     *  
     * @return 
     *      the headers which should be included on server calls. 
     *      In addition to the default headers, the active company is added.
     */
    getHeaders() {
        var headers = super.getHeaders() || {};
        if (this.id_administration) {
            headers["X-OPTI-ACTI"] = this.id_administration;
        }
        return headers;
    }

    /**
     * In Optimit, after loading the authenticated user, 2 situations: 
     *  1) The user has access to 1 administration --> load just that.
     *  2) The user has access to more administratios --> user should select an administration. 
     * 
     * @returns 
     */
    async selectAdministration(id) {
        if (!id) {
            if (this.administrations && this.administrations.length == 1) {
                id = this.administrations[0].id;
            }
        }
        this.id_administration = id;
        var result = await this.loadAppData(); // logs out on failure
//        console.log('selectAdministration: result of loadappdata: ', result);
        return result;
    }

    /**
     * In Optimit, after loading the authenticated user, 2 situations: 
     *  1) The user has access to 1 administration --> load just that.
     *  2) The user has access to more administratios --> user should select an administration. 
     * 
     * When a preferredAdministration is provided, try to select that one without asking.
     * This is 
     * 
     * @returns 
     */
    async getAuthUser(preferredAdministration) {
        var result = await authApi.getAuthUser(this.loginToken);
        if (result && result.success) {
            this.fill(result.data);
//            console.log('getAuthUser for preferredAdmni:', preferredAdministration)
            let id_prefered_administration = null;

            if (this.hasMultipleAdministrations) {
                if (preferredAdministration) {
                    var adm = this.administrations.find( (adm) => adm.id == preferredAdministration)
                    if (adm && adm.id) {
                        id_prefered_administration = adm.id;
                    }
                }
            }
//            console.log('getAuthUser found admini:', id_prefered_administration, result)

            if (this.hasMultipleAdministrations && !id_prefered_administration) {
                result.action="select-administration";

            } else if (this.hasMultipleAdministrations && id_prefered_administration) {
                return await this.selectAdministration(id_prefered_administration);

            } else if (this.hasSingleAdministration) {
                return await this.selectAdministration(this.administrations[0].id);
            
            } else {
                result.action="no-administration";
            } 

        }
        else {
            this.logout();
        }
        return result;
    }

}

export default clsCurrentuserExt;
