<template>
    <div>
        <Datatable :dt="dt" class="full-height">
            <template v-slot:menu ="{ item }">
                <ActionMenuItem action="relation_address.open" @click="dlg.open('relation_address', item)"></ActionMenuItem>
                <v-divider></v-divider>
                <ActionMenuItem action="relation_address.remove" v-if="!item.archived_at" @click="dt.onStartRemove(item)"></ActionMenuItem>
                <ActionMenuItem action="relation_address.modify" v-else icon="unarchive" @click="dt.onStartUnArchive(item)">Maak adres actueel</ActionMenuItem>
            </template>


        </Datatable>

    </div>
</template>



<script setup>
    import useDataTable from '@app/useDataTable'
    import Datatable from '@datatable/Datatable'
    import ActionMenuItem from "@controls/menu/ActionMenuItem.vue";
    import { relation_addresses as api } from '@app/api'
    import string from '@lib/string'
    import bool from '@lib/bool'
    import action from '@app/action'
    import dlg from '@app/dlg'

    const props = defineProps({
        model: {
            type: [Object]
        },

    });

    var fmtType = function(a,b,item) {
        let type = string.concat(", ", 
                bool.isTrue(item.is_delivery_address) ? "Afleveradres" : "", 
                bool.isTrue(item.is_pickup_address) ? " Bezoekadres" : "", 
        );
        if(string.isEmpty(type)) {
            type = "Extra adres";
        }
        return type;
    }
    var headers = [
        { type: 'cmd' },
        { text: "Adres type",       value: "is_delivery_address"     , fmt:fmtType, type:"open"},
        { text: "Straat",                       value: "adr_street"     },
        { text: "Nummer",                       value: "adr_street_number"      },
        { text: "Postcode",                     value: "adr_zipcode"        },
        { text: "Plaats",                       value: "adr_city"       },
        { visible: false, text: "Landcode", value: "country_code"       },
        { text: "Land",                      value: "country"        },
    ]

    var dt = useDataTable("dt_relation_addresses", "relation_address", api, headers, {
        itemName: {
            prefix: 'het',
            single: 'adres',
            plural: 'adressen',
        }, parent: {
            id_relation: props.model.id
        },
        canArchive: false
    })

    window.dta = dt;
</script>