import { clsModel, fnCreate } from '@cls/clsModel'
import { departments as api } from '@/app/api'

var modelName = "department";
const fields = ['id', 'dep_name'];

class clsDepartment extends clsModel {

    dep_name = null;
    
    get modelRep() {
        return this.dep_name;
    }
        
    constructor() {
        super({
          api: api,   
          modelName: modelName, 
          mandatoryFields: ['name', 'dep_name'],

          fillable: fields
        })
    } 
        
 }
 export default fnCreate(clsDepartment , 'clsDepartment');
