import clsApi from '@cls/clsApi'

class clsSettingsApi extends clsApi {

    async load(modelName) {
        var url = `load/${modelName}`;
        return this.get(url);
    }

    async save(modelName, data) {
        var url = `save/${modelName}`;
        return this.post(url, data);
    }

    // async upload(modelName, type)

}

export default clsSettingsApi;
