<template>

    <ActionCombo :noBorder="noBorder" :borderHover="borderHover" :noClearable="noClearable" :placeholder="placeholder" :skeleton="compSkeleton" :disabled="compDisabled" :rules="rules" v-model="dvalue" :items="items"
        :open="open" :create="create" route="masterdata.costtypes" baseType="costtype"
    ></ActionCombo>

</template>

<script setup>

    // import CostTypeCombo from '@controls/combo/CostType'
    // <CostTypeCombo :model="model" :rules="model.rules.id_costtype" v-model="model.id_costtype"></CostTypeCombo>

    const props = defineProps({
        disabled: {
            type: [Boolean]
        },
        rules: {
            type: [Array]
        },
        noClearable: {
            type: [Boolean],
            default: false
        },
        skeleton: {
            type: [Boolean]
        },
        value: {
            type: [String, Number]
        },
        placeholder: {
            type: [String],
            default: 'Kostentype'
        },
        noBorder: {
            type: [Boolean]
        },
        borderHover: {
            type: [Boolean]
        },

        model: {
            type: [Object]
        },
        create: {
            type: [Boolean],
            default: true
        },
        open: {
            type: [Boolean],
            default: true
        },

    })
    
    import ActionCombo from '@controls/combo/ActionCombo'
    import {costtype as list} from '@app/list';
    import {computed} from 'vue'

    const emit = defineEmits(['input'])

    const dvalue = computed({
        get() {
            return props.value
        },
        set: function(v) {
            emit('input', v)
        }	
    })

    const compSkeleton = computed({
        get() {
            if (props.skeleton) {
                return true;
            }
            if (!props.model) {
                return false;
            }
            return props.model.isDataLoading;
        }
    });
    const compDisabled = computed({
        get() {
            if (props.disabled) {
                return true;
            }
            if (!props.model) {
                return false;
            }
            return props.model.disabled;
        }
    });

    const items = computed({
        get() {
            return list.listActual(dvalue.value) ||[]
        },
    })

</script>
