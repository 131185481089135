//
// Usage: 
//  import icon from '@app/icon'
//  var myIcon = icon.forType("invoice")
//

import clsIcon from '@/app/clsIcon'

let obj = new clsIcon();

export default obj;