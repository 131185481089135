import eventbus from '@app/eventbus'
import XLSX from 'xlsx'
import { onUnmounted, watch } from 'vue'
import string from '@lib/string'
import appAction from '@app/action'
import noty from '@shared/lib/noty'
import dlg from '@app/dlg'
import {datatables as dtsettings} from '@app/settings';

class clsDataTable {
    // When id_parent is specified, it is always added on server requests
    parent = {}; // E.g. {id_relation: 1234}
    mode = "default"; // default|multiselect|singleselect
    canArchive = true;
    cntSearch = 0;
    allHeaders = [];
    defaultHeaders = []; // The default header configuration
    headers = [];
    dense = true;
    api = null;
    autoSearch = true;
    checkboxed = false;
    items = [];
    useCustomMenu = false;   // user implements the item menu
    totalItems = 0
    noDataText = "Geen resultaten gevonden"
    loadingText="Gegevens laden..."                        
    loading=false
    bulkAction = "";
    modelName = "";         // When specified, it is the name of the model which we use for handling save events.
    modelNames = [];         // When specified, multiple names for models of which to handle save events.
    saveEvents = [];        // optional events which trigger us to refresh a line
    hideDefaultFooter = false;
    noPager = false;
    fnParseItem = null;
    useCommandPane = true;
    actionDblClick = true; // make it false to disable, make it a function for callback
    actionClick = null;
    hightlightOnClick = false;
    itemName = {
        prefix: 'de',
        single: 'regel',
        plural: 'regels'
    }
    footer = {
        itemsPerPageOptions: [20, 50],
        itemsPerPageText: 'Resultaten per pagina:'
    }
    // Overrides for functions like 'onBulkRemove', etc
    callbacks= {

    }
    canConfigureColumns = true;
    
    onAfterSearch = null;
    customSaveHandler = null;
    customRemoveHandler = null;
    preventRemoveHandler = null;
    preventSaveHandler = null;

    noSort = false;
    sortBy = null;
    sortDesc = null;

    // Allthough options is a bit too versatile of a property name, want to keep it the same as the 
    // terminology in the datatable.
    options = {
        sync: {
            itemsPerPage: 20
        }
    }
    items= []

    removeConfirmationMessage = null;
    // Two state members for remembering if a search action was triggered by a browse action.
    isBrowsingNext = false;
    isBrowsingPrevious = false;

    /**
     * A datatable can be used for 3 purposes: 
     * 1) - default -      To maintain a list of data. E.g. To manage your list of relations. 
     * 2) - singleselect - To select one item from a list to use it somewhere else.
     * 3) - multiselect  - To select one or moreitems from a list to use it somewhere else.
     * 
     * Note that in default mode, checkboxes can be present. In this case it is because
     * a bulk operation is started on one or more items (e.g. delete).
     * 
     */
    setModeDefault() {
        this.mode = "default"; // Just a regular datatable
        this.useCommandPane = true;
        this.onStopCheckbox();
    }
    setModeSingleSelect(fnCallBack) {    // one item can be selected via a 'select' link
        this.mode = "singleselect";
        this.useCommandPane = false;
        this.onStopCheckbox();
        if (fnCallBack) {
            this.callbacks.onSingleSelect = fnCallBack;
        }
    }
    setModeMultiSelect() {     // Multiple items can be selected via checkboxes 
        this.mode = "multiselect";
        this.useCommandPane = false;
        this.onStartCheckBox();
    }

    // The default filter.
    filter = {
        q: null, archived: false, exclude: []
    }
    setParent(parent, bThenSearch) {
        if (parent) {
            for (var field in parent) {
                var item = parent[field];

                if (item && undefined !== item.value) {
                    this.parent[field] = item.value;
                } else {
                    this.parent[field] = item;
                }
            }
        }
        if (bThenSearch) {
            this.search(false /* not an internal call */, true /* force search */);
        }
    }
    // 
    // Get the filter where function values are applied first. 
    get appliedFilter() {
        var result = {};
        for (var field in this.filter) {
            var item = this.filter[field];
            if (typeof(item) == 'function') {
                result[field] = item();
            } else {
                result[field] = item;
            }
        }
        return result;
    }
    initFilter(filter) {
        if (filter) {
            for (var field in filter) {
                var item = filter[field];

                if (item && undefined !== item.value) {
                    this.filter[field] = item.value;
                } else {
                    this.filter[field] = item;
                }
            }
            if (undefined == filter.archived) {
                filter.archived = false;
            }
        }
    }
    get isFilterArchived() {
        return !!this.filter.archived; 
    }
    filterArchived(noSearch) {
        this.filter.archived = true; 
        if (!noSearch) {
            this.search();
        }
    }
    filterUnarchived(noSearch) {
        this.filter.archived = false; 
        if (!noSearch) {
            this.search();
        }
    }
    get rowCount() {
        return (this.items||[]).length;
    }
    get selectedCount() {
        var cnt = 0;
        this.items.forEach( (item) => cnt+=(item.checked?1:0));
        return cnt;
    }
    get selectedRows() {
        return this.items.filter( (item) => !!item.checked);
    }
    selectedItems() {
        var arr = [];
        this.items.forEach( (item) => { if(item.checked) {arr.push(item);}});
        return arr;
    };
    selectedIDs = function() {
        var arr = [];
        this.items.forEach( (item) => { if(item.checked) {arr.push(item.id);}});
        return arr;
    };
    stateCheckAll=false
    /**
     * follow the checkAll value but don't change it.
     */
    onFollowCheckAll(){
        this.checkAll(this.stateCheckAll)            
    };

    checkAll(b) {
        if (undefined === b) {
            b = true;
        }
        for (var n = 0; n < this.items.length; n++) {
            this.items[n].checked = !!b;
        };
    }

    // :TODO: --> remove and 
    onExecute(action, item, extradata) {
        if (!action) {
            return;                
        }
        if (extradata && item) {
            for (var key in extradata) {
                if (!item[key]) {
                    item[key] = extradata[key];
                }
            }
        }    
        if (this.callbacks.onExecute) {
            this.callbacks.onExecute(action, item);
            return;
        }
        item.table = this; // allows the dialog to browse the items.
        item.action = action;
        appAction.execute(action, item);
    }    

    /**
     * Get the datatable settings from settings. This is a placeholder for later implementation.
     */
    getDataTableSettings() {
        return null;
    }

    /**
     * Store the visible flag and the index of the columns of the original configuration.
     * This allows the user to go back to 'factory settings' in any stage.
     */
    storeOriginalColumnConfig() {
        this.defaultHeaders = {};
        var self = this;
        (this.allHeaders||[]).forEach( (hdr, ix) => 
            self.defaultHeaders[hdr.value] = {
                visible: undefined === hdr.visible ? true : hdr.visible, 
                ixOrder: ix,
                sort: hdr.sort
            }
        )
    }
    /**
     * Get the configuration of the headers from the user settings.
     * The config looks like: 
     * 
     *   { 
     *       "noteCount"  : 1,
     *       "pi_status"  : 1,
     *       "type"       : 0,
     *       "is_paid"    : 1,
     *       "created_at" : 0,
     *       "rel_name"   : 1,
     *   }
     * 
     */
    applyColumnConfigToHeaders() {
        // Get the column configuration. When not saved yet, we are done here.
        var arrConfig = dtsettings.getColumns(this.name);
        if (!arrConfig ||!arrConfig.length) {
            return;
        }          

        // When a column configuration is stored, retrieve from the configuration: 
        // - The order of the columns
        // - The visibillity of the columns
        // - The sort order of the datatable. This is set in the header[x].sort property.

        // Assign order index to the current headers. 
        // This will be the base for sorting later on when the config is applied. 
        // By setting the sorting on the full header set, we are sure that when - in code - a header is added later on, 
        // that it is also rendered, and also on a logical place, allthough it is not in the config.  
        for (var n =0; n < (this.allHeaders||[]).length; n++) {
            this.allHeaders[n].ixOrder = n; 
        }
        // Store the original sort column in case it is not present in the stored config. 
        // This can be the case when (default sort-)columns are added after initial deployment.
        var originalSortColumn = (this.allHeaders||[]).find( (header) => !!header.sort);
        var orignalSortOrder = originalSortColumn ? originalSortColumn : null; 

        // Convert the configuration to an associative array for easier searching and add an order index as well.
        var config = {};
        for(var n = 0; n < arrConfig.length; n++) {
            var line = arrConfig[n];
                        if (line.value) {
                config[line.value] = {value: line.value, ixOrder:n, visible: line.visible, sort: line.sort}; 
            }
        }
        // Now, apply the visible and new index settings from the config to the headers.        
        for (var n =0; n < (this.allHeaders||[]).length; n++) {
            var value = this.allHeaders[n].value;
            this.allHeaders[n].sort = null;
                        // When no sort by is set yet, apply the default sortby from the settings.
            if (config[value]) {
                // If the column can not move, keep the index.
                if (this.allHeaders[n].canmove !== false) {
                    this.allHeaders[n].ixOrder = config[value].ixOrder;
                }
                // If the column can be hidden, apply the config. Otherwise, just visible
                this.allHeaders[n].visible = (this.allHeaders[n].canhide === false) || config[value].visible;
                // The sort from the config prevails.
                if (config[value].sort) {
                    this.allHeaders[n].sort = config[value].sort;
                }    
            }
        }
        // Is any column sorted?
        var configuredSortColumn = (this.allHeaders||[]).find( (header) => !!header.sort);
        if (!configuredSortColumn) {
            if (originalSortColumn) {
                originalSortColumn.sort = orignalSortOrder;
            }
        }

        // And now, inplace sort by the index.
        this.allHeaders.sort( (a,b) => a.ixOrder - b.ixOrder);
    }

    /**
     * For a couple of fields, we created as shortcut ease up the datatable headers config. 
     * For example: configuration of the flag field in a datatable: 
     * const headers = [
     *  ....
     *         { type: 'flag'},
     *  ....
     * ]
     * We need the value in the further processing of the configuration. 
     * Therefore, for those predefined columns, make sure the value is set.
     */
    preprocessHeaders(headers) {
        (headers||[]).forEach( (header) => {
            if (header.type == 'note') { 
                header.text  = "";
                header.value = "noteCount";
            }
            if (header.type == 'flag') { 
                header.text  = "";
                header.value = "flag";
            }
            if (header.type == 'cmd') {
                header.value = "flag";
            }
        })
        return headers;
    }

    /**
     * Set the headers according to the properties in the settings
     *  
     */
    setHeaders() {
        this.applyColumnConfigToHeaders();

        // Set the sort order according to the header settings.
        var sortColumn = (this.allHeaders||[]).find( (header) => !!header.sort);
        this.sortBy   = sortColumn ? sortColumn.value : null;
        this.sortDesc = sortColumn ? (sortColumn.sort == 'desc') : null;

        this.headers = (this.allHeaders ||[]).filter( (h) => h.type == "cmd" || h.visible!==false || h.canhide === false);            
        this.headers.forEach( (header) => {
                        // By default sortable
            if (!header.sortable === undefined) {
                header.sortable = true;
            }
            if (header.type == 'note') { 
                header.configText = "Notitie";
                header.value = "noteCount";
                header.class = "hdr-cmd";
            }
            if (header.type == 'location') { 
                header.configText = "Locatie";
                header.value = "location";
                header.class = "hdr-cmd";
            }
            if (header.type == 'attachment') { 
                header.configText = "Bijlagen";
                header.value = "attachments";
                header.class = "hdr-cmd";
            }
            if (header.type == 'flag') { 
                header.value = "flag";
                header.configText = "Vlag"
                header.class = "hdr-cmd";
            }
            if (header.type == 'cmd') {
                header.canhide = false;
                header.canmove = false;
                header.excel = false;
                header.text = "";
                header.sortable = false;
                header.value = "cmd";
                header.class = "hdr-cmd";
            }
            
            if (header.value == 'cmd' || string.isInRange(header.type, "cmd", "note", "flag", "attachment", "location")) {
                if (!header.cellClass) {
                    header.cellClass="col-cmd";
                }
            }
        });
        var pref = this.getDataTableSettings();
        if (!pref) {
            return;
        }

        // Otherwise, apply the preference to the headers
    }

    clear() {
        this.items = [];
    }
    setItems(items) {
        items = items ||[];
        for (var n = 0; n < items.length; n++) {
            items[n].checked = false;
        };
        this.items = items;
    }

    // Configure the columns in this datatable.
    async configure() {

        try {
            await eventbus.dialog.open.promise('configureDatatable', {id: this.name, noSort: this.noSort, headers: this.allHeaders, defaultHeaders: this.defaultHeaders})
            this.setHeaders();
            // this.dense = !this.dense;
        } catch(e) {} // promise is rejected on cancel. Ignore the error.

    }

    // First time search is initiated by the setup of the datatable. 
    // When specified, skip searching the first time (e.g. when filters must be used first).
    /**
     * Search can be triggered via datatable properties (e.g. go to next page or change page size). 
     * In this case, just use the internal properties as is. 
     * When search is user initiated, first set the page to the first page. 
     * It is extremely confusing when searching on a clear findable item and it is not visible because the 
     * datatable is still on page 2.
     *  
     */
    search(bInternalAction, bForce) {
        var isBrowsingNext = this.isBrowsingNext;
        var isBrowsingPrevious = this.isBrowsingPrevious;
        this.isBrowsingNext = false;
        this.isBrowsingPrevious = false;

        if (!bInternalAction) {
            if (this.options.sync.page > 1) {
                this.options.sync.page = 1;  // Will trigger a search action
                return;
            }
        }
        this.cntSearch++
        if (!bForce && !this.autoSearch && (this.cntSearch == 1)) {
            return;
        }

        if (this.loading) {
            return;
        }
        if(!this.api||!this.api.page) {
            console.error('api.page is not implemented');
            return;
        }        
        this.loading = true;
        var self = this;
        this.api.page(this.options.sync, this.appliedFilter, this.parent)
        .then(({ data }) => {
            let items = data.rows ||[];
            if (self.fnParseItem && typeof (self.fnParseItem) == 'function') {
                items = items.map( (x)=>self.fnParseItem(x));
            } 
            self.setItems(items)
            self.totalItems = data.total;
            if (self.onAfterSearch) {
                self.onAfterSearch(data, this);
            }
            // If we were browsing, and in the process a new page was loaded, 
            // after the page action, the new available row must be selected.
            if (isBrowsingNext) {
                if (self.items?.length) {
                    var row = this.items[0];
                    this.openDialog(row);
                }
            } else if (isBrowsingPrevious) {
                if (self.items?.length) {
                    var row = this.items[this.items.length -1];
                    this.openDialog(row);
                }
            }    
            if (isBrowsingNext||isBrowsingPrevious) {
                // Ok. When we were browsing, and no data is returned, it means that in the meantime, the browsable dataset
                // has changed. Maybe data was saved during browsing, causing the datatable criteria to pickup less 
                // data when paging, maybe another user changed the data, does not matter.
                // Since the data is not available, we simply go back to page 1 of the data.
                // As, at this point, we are in an update action, changing the page here does not work.
                // We need to escape the current execution context, and easy way is using a timer. 
                if (!self.items?.length) {                    
                    if (this.options.sync.page > 1) {
                        this.isBrowsingNext = true;
                        // we already defined self.
                        setTimeout( ()=>{self.options.sync.page = 1;}, 100);
                    }
                }
            }
        })
        .catch(e => {
            console.error("paging error", e);
            self.loading = false;
        })
        .finally(() => {
            self.loading = false;
        });        
    } 

    /**
     * Convert json to excel
     */
    jsonToExcel(json, name) {

        // We want to export with all headers. 
        // That is because we also want to export hidden headers.
        // By using allHeaders, the column order is preserved. 
        var initialHeaders = this.allHeaders;
        // Convert the items to desired format.
        // {
        //     rel_nr: "Relatienummer",
        //     rel_name: "Bebbet betuiningen"
        // }
        var fieldNameMap = [];
        for (var n = 0; n < initialHeaders.length; n++) {
            var header = initialHeaders[n]
            if (header.type == 'note') {
                continue;
            }
            if (header.type == 'flag') {
                continue;
            }
            if (header.type == 'cmd') {
                continue;
            }
            if ( !(header.excel || undefined === header.excel)) {
                continue;
            }
            fieldNameMap[header.value] = { title: header.text, fmtExcel: header.fmtExcel};
        }
        // convert all sheet items to excel items
        let convertedItems = [];
        for (var n = 0; n < json.length; n++) {
            var item = json[n];

            var convertedItem = {};
            for (var prop in fieldNameMap) { 
                var title = fieldNameMap[prop].title;
                var fmt = fieldNameMap[prop].fmtExcel;
                convertedItem[title] = (fmt ? fmt(item[prop], item) : item[prop]) || null;
            }
            convertedItems.push(convertedItem);
        }
        // console.dir('jsontoexcel, initialHeaders', initialHeaders)
        // console.dir('jsontoexcel fieldNameMap', fieldNameMap)
        // console.dir('jsontoexcel converteditems', convertedItems)
        var basename = string.coalesce(name, "lijst");
        var filename = basename + ".xlsx";
        const data = XLSX.utils.json_to_sheet(convertedItems);
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, data, basename)
        XLSX.writeFile(wb, filename)
    }
    
    toExcel(name) {
        this.loading = true;
        var self = this;
        // Copy the sync as we want to set the excel flag once.
        var sync = {...this.options.sync};
        sync.excel = 1;
        this.api.page(sync, this.appliedFilter)
            .then(({ data }) => {
                self.jsonToExcel(data.rows, name);
            })
            .catch(e => {
                console.error(e);
            })
            .finally(() => {
                self.loading = false;
            });        
    }

    /**
     * When a single select callback handler is set, call it.  
     * @param {} item 
     */
    onSingleSelect(item) {
        if (this.callbacks && this.callbacks.onSingleSelect) {
            this.callbacks.onSingleSelect(item);
        }
    }
    /**
     * A shortcut to start the checkboxes for removal.
     */
    onStartRemove(item) {    
        return this.onStartCheckBox(item, 'remove');
    };
    /**
     * A shortcut to start the checkboxes for unremove.
     */
    onStartUnRemove(item) {    
        return this.onStartCheckBox(item, 'unremove');
    };
    /**
     * A shortcut to start the checkboxes for archive.
     */
    onStartArchive(item) {    
        return this.onStartCheckBox(item, 'archive');
    };
    /**
     * A shortcut to start the checkboxes for archive.
     */
    onStartUnArchive(item) {    
        return this.onStartCheckBox(item, 'unarchive');
    };
    /**
     * Show the checkboxes
     */
    onStartCheckBox(item, bulkAction) {
        this.stateCheckAll = false;
        this.checkAll(false);
        if (item) {
            item.checked = true;
        }
        this.checkboxed = true;
        this.bulkAction = bulkAction;
    };
    setLoading(bLoading) {
        this.loading = !!bLoading;
    };

    /**
     * Hide the checkboxes
     */
    onStopCheckbox() {
        this.checkboxed = false;
    };
    getRemoveConfirmationBody(ids) {
        
        // When the caller formats the confirmation message, fine.
        if (typeof(this.removeConfirmationMessage) == 'function') {
            var msg = this.removeConfirmationMessage(ids, this);
            if (msg) {
                return msg; // Otherwise, construct a message below.
            }
        }
        if (typeof(this.removeConfirmationMessage) == 'string') {
            return this.removeConfirmationMessage;
        }

        // Otherwise, construct a general message.
        var single = (ids.length == 1)
        if (this.canArchive) {
            return single ? `Wanneer ${this.itemName.prefix} ${this.itemName.single} nergens in gebruik is wordt deze definitief verwijderd.<br>Anders wordt ${this.itemName.prefix} ${this.itemName.single} gearchiveerd en kunt u deze altijd weer actueel maken.`
            : `Geselecteerde ${this.itemName.plural} die nergens in gebruik zijn worden definitief verwijderd.<br>${string.capitalize(this.itemName.plural)} die in gebruik zijn worden gearchiveerd en kunt u altijd weer actueel maken.`
        }
        return single ? `${string.capitalize(this.itemName.prefix)} geselecteerde ${this.itemName.single} wordt definitief verwijderd. U kunt dit niet ongedaan maken.`
                                    : `De geselecteerde ${this.itemName.plural} worden definitief verwijderd. U kunt dit niet ongedaan maken.`
    }
    async onBulkRemove() {
        var ids = this.selectedIDs();     
        if (!ids||!ids.length) {
            return;
        }
        if (this.callbacks.onBulkRemove) {
            this.callbacks.onBulkRemove(ids, this);
            return;
        }
        if (!this.api || !this.api.remove) {
            console.error("api.remove is not implemented");
            return;
        }

        var body = this.getRemoveConfirmationBody(ids);
        var confirmation = ids.length ==1 ? `${string.capitalize(this.itemName.prefix)} ${this.itemName.single} is verwijderd.` : `De ${this.itemName.plural} zijn verwijderd.`;
        var self = this;

        try {
            await noty.confirm(body, {title: "Weet u het zeker?"});
        } catch (e) {
            return; // When canceled, the promise is rejected, which results in an exception we must catch.
        }
        
        var data = await self.api.remove(ids, this.parent);
//        console.log('data: ', data)
        eventbus.model.removed(self.modelName, ids);
        self.onStopCheckbox();
        // Don't need to call search here as we are registered to the removed event.
        // self.search();
        eventbus.snackbar.info({ text: confirmation });
        if (this.callbacks.onAfterRemove) {
            this.callbacks.onAfterRemove(ids, self.parent, data);
        }
    }
    onBulkArchive() {
        var ids = this.selectedIDs();     
        if (!ids||!ids.length) {
            return;
        }
        if (this.callbacks.onBulkArchive) {
            this.callbacks.onBulkArchive(ids, this);
            return;
        }
        if (!this.api || !this.api.archive) {
            console.error("api.archive is not implemented");
            return;
        }

        var body = this.getRemoveConfirmationBody(ids);
        var confirmation = ids.length ==1 ? `${string.capitalize(this.itemName.prefix)} ${this.itemName.single} is gearchiveerd.` : `De ${this.itemName.plural} zijn gearchiveerd.`;
        var self = this;

        var body = (ids.length == 1) ? `${string.capitalize(this.itemName.prefix)} ${this.itemName.single} wordt gearchiveerd en u kunt deze altijd weer actueel maken.`
                : `De ${this.itemName.plural} worden gearchiveerd en u kuntdeze altijd weer actueel maken.`;
    

        eventbus.dialog.confirm.promise({
            title: "Weet u het zeker?",
            body: body
        }).then( (x) => {
            self.api.archive(ids, this.parent).then( (data) => {
                eventbus.model.removed(self.modelName, ids);
                self.onStopCheckbox();
                // Don't need to call search here as we are registered to the removed event.
                // self.search();
                eventbus.snackbar.info({ text: confirmation });
                if (this.callbacks.onAfterArchive) {
                    this.callbacks.onAfterArchive(ids, self.parent, data);
                }
            });
        });
    }
    onBulkUnArchive() {
        var ids = this.selectedIDs();     
        if (!ids||!ids.length) {
            return;
        }
        if (this.callbacks.onBulkUnArchive) {
            this.callbacks.onBulkUnArchive(ids, this);
            return;
        }
        if (!this.api || !this.api.unArchive) {
            console.error("api.unArchive is not implemented");
            return;
        }

        var body = ids.length ==1 ? `${string.capitalize(this.itemName.prefix)} ${this.itemName.single} zal weer actueel worden gemaakt.` 
                                    : `De ${this.itemName.plural} zullen weer actueel worden gemaakt.`;
        var confirmation = ids.length == 1 ? `${string.capitalize(this.itemName.prefix)} ${this.itemName.single} is weer actueel gemaakt.`
                                    : `De ${this.itemName.plural} zijn weer actueel gemaakt.`
        var self = this;

        eventbus.dialog.confirm.promise({
            title: "Weet u het zeker?",
            body: body
        }).then( (x) => {
            self.api.unArchive(ids, this.parent).then( (data) => {
                eventbus.model.unarchived(self.modelName, ids);
                self.onStopCheckbox();
                self.search();
                eventbus.snackbar.info({ text: confirmation });
            });
        });

    }

    /**
     * Get the row with the given id
     */
    getRowById = function(id) {
        if (!this.items) {
            return [];
        }
        if (!id) {
            console.error("getRowById - empty parameter.");
            return [];
        }
        return this.items.find( (item) => item.id == id);
    };
    /**
     * Get the index of the item with the given id.
     * @param {} id
     */
    getIndexByID = function(id) {
        for (var n = 0; n < this.items.length; n++) {
            if (this.items[n].id == id) {
                return n;
            }
        }
        return -1;
    };
    /**
     * Get information about the current browse status. 
     * The data parameter must contain a tableName which will be matched
     * to the current table name. 
     * The data.ix and data.totalItems will be filled with the current 
     * values.
     * 
     * @param {*} data 
     * @returns 
     */
    browseInfo(data) {
        if (!data.tableName || data.tableName != this.name) {
            return;
        }
        var id = data.id;
        if (!id) {
            console.error("browseInfo: No browsing id specified");
            return;
        }
        var ix = this.getIndexByID(id);  // [0 - totalItems -1]
        // Use the page number in the index calculation.
        if (ix >= 0) {
            if (this.options.sync.page > 1) {
                ix = ix + ( (this.options.sync.page-1) * this.options.sync.itemsPerPage);
            }
        }
        data.ix = ix;
        data.totalItems = this.totalItems;
    }
    /**
     * Browse to the next item in the table. 
     * The parameter tableName is matched with our tabename.
     * The row with the given id is located and the next row is opened.
     * Note that when the last item in the current page is currently active, 
     * the page number is increased.
     *   
     * @param {*} data 
     * @returns 
     */
    browseNext(data) {

        if (!data.tableName || data.tableName != this.name) {
            return;
        }
        var id = data.id;
        if (!id) {
            console.error("No browsing id specified");
            return;
        }
        if (!this.modelName) {
            console.error("No browsing modelName specified ");
            return;
        }
        let ix = this.getIndexByID(id);  // [0 - totalItems -1]
        if (ix < 0) { // 
            console.error("Current item not found. Can not browse.");
            return;
        }
        ix += 1;
        if (ix >= this.items.length) {
            if ( (this.options.sync.page * this.options.sync.itemsPerPage) < this.totalItems) {
                // a page action will be triggered which we can not handle here. 
                // Therefore, set the browse flag so that the browse action can be handled after
                // paging. 
                this.isBrowsingNext = true;
                this.options.sync.page++;
            } else {
                if (this.options.sync.page != 1) {
                    this.isBrowsingNext = true;
                    this.options.sync.page = 1;
                }
            }
            data.isBrowsing = this.isBrowsingNext; // return argument.
            return;
        } else {
            let row = this.items[ix];
            this.openDialog(row);    
        }
    }
    /*
    * Browse to the previous item in the table. 
    * The parameter tableName is matched with our tabename.
    * The row with the given id is located and the previous row is opened.
    * Note that when the first item in the current page is currently active, 
    * the page number is decreased unless we are already on the first page.
    */
    browsePrevious(data) {
        if (!data.tableName || data.tableName != this.name) {
            return;
        }
        var id = data.id;
        if (!id) {
            console.error("No browsing id specified");
            return;
        }
        if (!this.modelName) {
            console.error("No browsing modelName specified ");
            return;
        }
        let ix = this.getIndexByID(id);  // [0 - totalItems -1]
        if (ix < 0) { // Item is not found
            console.error("Current item not found. Can not browse.");
            return;   // We can not handle it.
        } 
        if (!ix) { // first index
            if ( this.options.sync.page > 1) {
                // a page action will be triggered which we can not handle here. 
                // Therefore, set the browse flag so that the browse action can be handled after
                // paging. 
                this.isBrowsingPrevious = true;
                this.options.sync.page--;
            }
            data.isBrowsing = this.isBrowsingPrevious; // return argument.
            return; // We are done.
        }
        ix--;
        let row = this.items[ix];
        this.openDialog(row);
    }

    /**
     * Open a dialog for the given row.
     * The tablename is provided to the dialogdata so that the dialog can reference it for sending
     * browse messages.
     * @param {} item 
     */
    openDialog(item) {
        var id = item?.id;
        if (id) {
            dlg.open(this.modelName, item, {tableName: this.name})
        }
    }

    /**
     * sync the data in the datatable with the given data
     * @param {} ids 
     */
    syncRow(data) {
        data = data ||{};        
        var row = this.getRowById(data.id);
        if (!row) {
            return null;
        }
        // Get all attributes of the data except the id
        var keys = Object.keys(data).filter( (key) => key != 'id' );
        
        // Update them all
        (keys||[]).forEach ( (key) => row[key] = data[key] )           
    };

    /**
     * Refresh one or more specific rows by calling the configured api.
     * Note that id can either be a single id or an array of parameters.
     *  params: 
     *      - array:     [1,2,3,4,5]
     *      - id:        1234
     *      - object:    {id: 123}
     * 
     * @param id 
     */
    refreshRows = function(params) {
        if (!this.api||!this.api.pageSingle) {
            console.error("pageSingle is not configured");
            return;
        }
        if (!params) {
            return;
        }
        var ids = [];

        // For direct handling a params parameter which is sent in via most eventbus invocations. 
        if (!Array.isArray(params) && params.id) {
            ids = [params.id];
        }
        else if (Array.isArray(params)) {
            ids = params;
        } else {
            ids = [params];
        }

        var found = false;
        for (var n = 0; n < ids.length; n++) {
            var id = ids[n];
            var item = this.getRowById(id);
            if (item) {            
                found = true; 
                break;
            }
        }
        if (!found) {
            return;
        }
        this.loading = true;
        var self = this;
        // pageSingle gives the same result as paging. We need just the data.        
        this.api.pageSingle(ids, this.parent)
            .then( (result) => {
                let data = result?.data || {};
                let rows = data.rows;
                if (!rows) {
                    return;
                }
                if (!Array.isArray(rows)) {
                    rows = [rows];
                }
                (rows||[]).forEach( (row) => {
                    this.syncRow(row);
                }) 
        })
        .finally(() => {
            self.loading = false;
        });        

    }

    /**
     * Refresh one or more rows based on input IDs. 
     * When ids are not found, a new search is started.
     */
    refreshLine(params, reload) {    
        // console.log('refreshline', params, reload)

        let id = params && params.id;
        let ids = params && params.ids || [];
        if (!id && params && params.ids && params.ids.length == 1) {
            id = params.ids[0];
        }

        if (id) {
            if (reload || this.getIndexByID(id) < 0) {
                this.search();
            } else {
                this.refreshRows(id);
            }
        } else if (ids && ids.length) {
            this.search();
        }
    }

    /**
     * Refresh one or more lines when the modelName matches our modelName
     */
    refreshLineWhenModel(modelName, params) {
        // console.log('refreshLinewHENmodel', modelName, params)
        if (this.modelName && this.modelName == modelName) {
            this.refreshLine(params);
        }
        else if (this.modelNames && this.modelNames.find( (name) => name == modelName)) {
            this.refreshLine(params);
        
        // Now that we are here, lets handle notes and flags so that it can be done once. 
        } else if (modelName == 'note') {
            if (params?.noteType && this.headers.find( (h) => h.noteType == params.noteType)) {
                var row = this.getRowById(params.id_entity);
                if (row) {
                    row.noteCount = (!!params.note) ? 1 : 0;
                }
            }
        } else if (modelName == 'flag') {
            if (params?.flagType && this.headers.find( (h) => h.flagType == params.flagType)) {
                var row = this.getRowById(params.id_entity);
                if (row) {
                    row.flag = params.flag;
                }
            }
        }
    }

    /**
     * Remove the items with the given ids from the table (client only action) 
     */
    clientRemoveItems(ids) {
        if (!ids) {
            return;
        }
        if (!Array.isArray(ids)) {
            ids = [ids];
        }
        this.items = this.items.filter( (item) => !ids.find( (id) => item.id == id) );
    }

    evtRegistrations = []
    registerEvents() {
        var self = this;
        this.evtRegistrations.push(
            eventbus.model.saved.on( 
                (modelName, params) => {
                    if (self.preventSaveHandler && (typeof self.preventSaveHandler == 'function')) {
                        if (self.preventSaveHandler(self, modelName, params)) {
                            return;
                        }
                    }
                    if (self.customSaveHandler) {
                        return self.customSaveHandler(this, modelName, params)
                    }
                    self.refreshLineWhenModel(modelName, params)
                }
            )
        );         
        this.evtRegistrations.push(eventbus.model.removed.on(
            (modelName, ids) => {
                if (self.preventRemoveHandler && (typeof self.preventRemoveHandler == 'function')) {
                    if (self.preventRemoveHandler(self, modelName, ids)) {
                        return;
                    }
                }

                if (self.customRemoveHandler) {
                    return self.customRemoveHandler(self, modelName, ids)
                }
                if (self.modelName == modelName) {
                    self.search()
                }
            }
        ));         
        this.evtRegistrations.push(eventbus.datatable.browse.next.on( (data) => self.browseNext(data)));
        this.evtRegistrations.push(eventbus.datatable.browse.previous.on( (data) => self.browsePrevious(data)));
        this.evtRegistrations.push(eventbus.datatable.browse.info.on( (data) => self.browseInfo(data)));

        (this.saveEvents||[]).forEach( (event) => {
            this.evtRegistrations.push(event.on((params) => self.refreshLine(params)));                         
        })


    }
    cleanUp() {
        (this.evtRegistrations||[]).forEach( (fnUnRegister) => fnUnRegister() ) 
    }

    test() {
        var arr = [];
        for (var n = this.headers.length-1; n>=0; n--) {
            arr.push(this.headers[n]);
        }
        this.headers = arr;
    }

    /**
     * Handle the dbl click event on a row.
     * When actionDblClick is specified, it is executed.
     * 
     * @param {*} evt 
     * @param {*} obj 
     * @returns 
     */
    onDblClick(evt, obj) {
        if (!this.actionDblClick) {
            return;
        }
        if(!obj || !obj.item) {
            return;
        }
        if (this.actionDblClick instanceof Function) {
            return this.actionDblClick(evt, obj.item);
        }
        this.openDialog(obj.item)
    }

    /**
     * When configured, the row is highlighted on a click.
     * Note that we use the native event to accomplish this. There is simply not an easy way
     * except for manipulating the row data and formatting the row accordingly, which is harder 
     * to do genericly. Note that except from highlighting, an actionClick handler is also called. 
     * @param {} itemData 
     * @param {*} slotData 
     * @param {*} evt 
     */
    onHighlightClick(itemData, slotData, evt) {
        if (!this.hightlightOnClick) {
            return;
        }
        var evtTarget = evt.target;
        var tr = evtTarget.closest('tr'); // It exists, otherwise we had not found any lines.
        var body = evtTarget.closest('tbody'); // It exists, otherwise we had not found any lines.
        if (!tr||!body) {
            return;
        }
        var wasSelected = tr.classList && tr.classList.contains('select-list-selected');    
        var lines = body.getElementsByClassName('select-list-selected');
        for (var n = 0; n < lines.length; n++) {
            lines[n].classList.remove('select-list-selected')            
        }
        if (tr && wasSelected) {
            tr.classList.remove('select-list-selected')
        } else if (tr && !wasSelected) {
            tr.classList.add('select-list-selected')
        }    
    }
    /**
     * Handle the click event on a row.
     * When actionClick is specified, it is executed.
     * 
     * @param {*} itemData - the item data (the data row) 
     * @param {*} obj - the slot data. It contains the item data as well as other information like the headers and other row information.
     * @param {*} evt - the native event 
     * @returns 
     */
    onClick(itemData, slotData, evt) {
        this.onHighlightClick(itemData, slotData, evt);
        
//        console.log('onClick', itemData, slotData, evt)
        if (!this.actionClick) {
            return;
        }
        if(!itemData) {
            return;
        }
        if (!this.actionClick instanceof Function) {
            return;
        }
        var ix = this.getIndexByID(itemData.id);
        return this.actionClick(itemData, ix, evt);
    }
    
    constructor(name, modelName, api, headers, options) {
        options = options || {};
        this.api = api;
        this.name = name;
        this.modelName = modelName;
        this.allHeaders = this.preprocessHeaders(headers) || [];
        this.storeOriginalColumnConfig();
        this.actionDblClick = (undefined === options.actionDblClick) ? true : options.actionDblClick;
        this.actionClick = options.actionClick;
        if (options.modelNames && Array.isArray(options.modelNames)) {
            this.modelNames = options.modelNames;
        }
        // By default: sort.
        this.noSort = !!options.noSort;
        
        this.onAfterSearch       = options.onAfterSearch;         // callback
        this.customRemoveHandler = options.customRemoveHandler;   // callback(dt, modelname, ids)
        this.customSaveHandler   = options.customSaveHandler;     // callback(dt, modelname, params)
        // When not implementing a custom handler but refresh is not wanted (e.g. dialog is not active), use the prevent callback.
        this.preventRemoveHandler = options.preventRemoveHandler;   // callback(dt, modelname, ids)
        this.preventSaveHandler   = options.preventSaveHandler;     // callback(dt, modelname, params)

        if (undefined !== options.removeConfirmationMessage) {
            this.removeConfirmationMessage = options.removeConfirmationMessage;
        }
        if (undefined !== options.canArchive) {
            this.canArchive = options.canArchive;
        }
        if (options.callbacks) {
            this.callbacks=options.callbacks;
        }
        if (options.dense === false) {
            this.dense = false;
        }
        if (options.noAutoSearch) {
            this.autoSearch = false;
        }
        if (options.noConfigureColumns) {
            this.canConfigureColumns = false;
        }
        this.saveEvents = options.saveEvents||[];
        if (options.itemName && options.itemName.plural) {
            this.itemName.plural = options.itemName.plural;
        }
        if (options.itemName && options.itemName.single) {
            this.itemName.single = options.itemName.single;
        }
        if (options.itemName && options.itemName.prefix) {
            this.itemName.prefix = options.itemName.prefix;
        }
        if (options.footer) {
            this.footer = options.footer;
        }
        if (options.isChildList) {
            this.isChildList = options.isChildList;
        }
        if (options.hideDefaultFooter) {
            this.hideDefaultFooter = options.hideDefaultFooter;
        }
        this.useCustomMenu = !!options.useCustomMenu;

        if (options.noPager) {
            this.noPager = true;
        }
        this.fnParseItem = options.fnParseItem;        

        this.hightlightOnClick = !!options.hightlightOnClick;
        if (options.mode == 'singleselect') {
            this.setModeSingleSelect();
        } else if (options.mode == 'multiselect') {
            this.setModeMultiSelect();
        } else {
            this.setModeDefault();
        }
        this.setParent(options.parent);
        this.initFilter(options.filter);
        this.setHeaders();

        this.registerEvents();
        var self = this;

        onUnmounted(() => {
            self.cleanUp();
        })

    }
}

export default clsDataTable;