<template>

    <Picker 
        :disabled="disabled"
        :skeleton="skeleton"
        :rules="rules"
        :noClearable="noClearable"
        :placeholder="placeholder"
        :model="model"         
        :valueModel="valueModel"
        :filter="filter"
        :idField="idField" 
        :repField="repField"
        selectIdField="id" 
        selectRepField="tend_number" 
        :fieldMap="fieldMap"
        dialogName="select_tender"
        :noBorder="noBorder"
        :propagateClear="propagateClear"
        @select="onSelect"

    ></Picker>

</template>

<script setup>

    const emit = defineEmits(['select'])
    function onSelect(v) { emit('select', v); }

    // import TenderPicker from '@controls/picker/Tender'
    // <TenderPicker :model="model" :rules="model.rules.id_tender" v-model="model.id_tender"></TenderPicker>

    const props = defineProps({

        disabled: {
            type: [Boolean]
        },
        rules: {
            type: [Array]
        },
        noClearable: {
            type: [Boolean],
            default: false
        },
        noBorder: {
            type: [Boolean]
        },
        skeleton: {
            type: [Boolean]
        },
        placeholder: {
            type: [String],
            default: 'Offerte'
        },
        model: {
            type: [Object]
        },
        valueModel: {
            type: [Object]
        },
        // Mandatory. The name of the field in the valueModel or model which is the id of this picker field
        idField: {
            type: [String],
            default: 'id_tender'            
        },
        // Mandatory. The name of the field in the valueModel or model which is the representation of this picker field
        repField: {
            type: [String],
            default: 'tend_number'            
        },
        // Optional. The id in the select list which we take over on select
        selectIdField: {
            type: [String],
            default: "id"
        },
        // Optional. The repField in the select list which we take over on select. Defaults to the property 'repField'
        selectRepField: {
            type: [String],
        },
        // When provided, the fieldmap contains fields which must be taken over from a selected item.
        // Those are sibling fields. For example, for a project picker, the reference may be displayed in a separate field or column.
        fieldMap: {
            type: [Object]
        },
        // When the field is filled, and then cleared, should the new values (empty value) be propagated to
        // any depending field? (defined in the fieldmap)
        propagateClear: {                    
            type: [Boolean]
        },
        // The default filter used for browsing. 
        // Example, for selecting a project in a purchase invoice, only projects which are in phase where it is allowed to 
        // book purchase invoices should be found. The same for sales invoices. 
        filter: {
            type: [Object]
        },
    })
    
    import Picker from '@controls/picker/Picker'

</script>
