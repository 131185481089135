import { clsModel, fnCreate } from '@cls/clsModel'
import { relation_contacts as api } from '@/app/api'
import eventbus from '@app/eventbus'
import string from '@lib/string'

var modelName = "relation_contact";

class clsRelationContact extends clsModel {

    id = null; // id_contactperson
    id_relation = null;
    prs_prefix = null;
    prs_firstname = null;
    prs_infix = null;
    prs_lastname = null;
    prs_title = null;
    prs_email = null;
    prs_phone = null;
    prs_mobile = null;


    constructor() {
        super({
          api: api,   
          modelName: modelName, 
          mandatoryFields: ['prs_lastname'] // TODO
        })
    } 

    get modelRep() {
        return string.concat(" ", this.prs_firstname, this.prs_infix, this.prs_lastname);  
    }


    /**
     * Override load to retrieve the entity via 2 keys - a relation id and an contact id.
     * @param {} id 
     * @param {*} params 
     * @returns 
     */
    load(id, params) {
        var self = this;
        this.fill();
        let id_relation = params.id_relation || params.id_parent;
        return this.withDataLoading( () => {
            return self.api.load2(id_relation, params.id)
                .then( ({data}) => {
                self.fill(data);
            })
        })    
    }
    async doCreate(defaults) {
        if (defaults) {
            defaults.id_relation = defaults.id_relation || defaults.id_parent;
        }
        return super.doCreate(defaults);
    }

    /**
     * Fill the data 
     * 
     * @param {*} data 
     */
    fill(data) {
        data = data || {};
        super.fill(data);
        this.prs_prefix = data.prs_prefix;
        this.id_relation = data.id_relation;
        this.prs_firstname = data.prs_firstname;
        this.prs_infix = data.prs_infix;
        this.prs_lastname = data.prs_lastname;
        this.prs_title = data.prs_title;
        this.prs_email = data.prs_email;
        this.prs_phone = data.prs_phone;
        this.prs_mobile = data.prs_mobile;
    
    }

    /**
     * Get the JSON representation for saving the data
     * 
     * @param {*} data 
     */
    toJSON() {
        return this.propsToJSON([
            "id", 
            "id_relation",
            "prs_prefix",
            "prs_firstname",
            "prs_infix",
            "prs_lastname",
            "prs_title",
            "prs_email",
            "prs_phone",
            "prs_mobile",
    
        ]); // TODO
    }        
 }
 export default fnCreate(clsRelationContact , 'clsRelationContact');
