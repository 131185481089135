<template>
    <span>
        {{ fmt() }}
    </span>
</template>

<script setup>

/**
 * import DateTime from from '@controls/text/DateTime'
 * <DateTime v-model="item.created_at" time />
 * <DateTime :value="item.created_at" time />
 * 
 */
import { date } from "@lib/date";

const props = defineProps({
    value: {
        type: [String, Date]
    },
    defaultText: {
        type: [String],
        default: null
    },
    // Is the date stored in UTC?
    // If so, we calculate it back to local date.
    isUtc: {
        type: Boolean,
        default: false,
    },
    time: {
        type: Boolean,
        default: false,
    },
})
function fmt() {
    if (!props.value) {
        if (props.defaultText) {
            return props.defaultText;
        }
        return "";
    }            
    return date.fmt.local(props.value, 0, props.time, props.isUtc);
}

</script>
