import { clsModel, fnCreate } from '@cls/clsModel'
import { creditrestrictions as api } from '@/app/api'
import eventbus from '@app/eventbus'

var modelName = "creditrestriction";

class clsCreditrestriction extends clsModel {

    cr_description = null;
    cr_shortname = null;
    cr_type = null;
    cr_buyingselling = null;
    cr_days = null;
    cr_percentage = null;

    constructor() {
        super({
          api: api,   
          modelName: modelName, 
          mandatoryFields: ['cr_description', 'cr_shortname', 'cr_days', 'cr_percentage'] // TODO
        })
    } 

    /**
     * Fill the data 
     * 
     * @param {*} data 
     */
    fill(data) {
        data = data || {};
        super.fill(data);

        this.cr_description = data.cr_description || "";
        this.cr_shortname = data.cr_shortname || "";
        this.cr_type = data.cr_type || 'P';
        this.cr_days = data.cr_days || 21;
        this.cr_percentage = data.cr_percentage || 1;
    
    }

    /**
     * Get the JSON representation for saving the data
     * 
     * @param {*} data 
     */
    toJSON() {        
        return this.propsToJSON(["id", "cr_description", "cr_shortname", "cr_type", "cr_days", "cr_percentage" ]); // TODO
    }
        
 }
 export default fnCreate(clsCreditrestriction , 'clsCreditrestriction');
