<template>    
    <v-chip small xlabel outlined class="status" :class="cls">
        <span class="prefix" v-if="$slots.prefix"><slot name="prefix"></slot></span>
        <slot>a</slot>
    </v-chip>  
</template>

<script setup>

// A Palette chip is a chip which as colors according to a palette id.  
// Note that this control is typically used from within another control. E.g. a Project status chip.
// Usage: 
// import PaletteChip from "@controls/widgets/PaletteChip.vue";
//   <PaletteChip id_palette="3">Nieuw</PaletteChip>
// 

import {computed, ref} from 'vue'

const props = defineProps({
    id_palette: {
        type: [String, Number],
        default: "0"
    },
})

const cls = computed({
    get() {
        return `palette-${props.id_palette}` 
    },
})

</script>