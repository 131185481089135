import { clsModel, fnCreate } from '@cls/clsModel'
import { costtypes as api } from '@/app/api'

var modelName = "costtype";
const fields = ['id', 'name'];

class clsCosttype extends clsModel {

    name = null;
    
    get modelRep() {
        return this.name;
    }
    
    
    constructor() {
        super({
          api: api,   
          modelName: modelName, 
          mandatoryFields: ['name'],

          fillable: fields
        })
    } 
        
 }
 export default fnCreate(clsCosttype , 'clsCosttype');
