<template>

    <span>
        <span v-if="bvalue">
            <span v-if="bcheck">
                <Icon small color="success" type="check"></Icon>
            </span>
            <span :class="cls" v-else>
                Ja
            </span>
        </span>
        <span v-else>
            <span v-if="bcheck">
                <Icon color="error" small type="minus"></Icon>
            </span>
            <span :class="cls" v-else>
                Nee
            </span>
        </span>
            
    </span>

</template>

<script setup>
import bool from '@lib/bool'
import Icon from '@controls/icons/Icon'
import {computed} from 'vue'


/**
 * import BoolYesNo from from '@datatable/columns/BoolYesNo'
 * 
 *     <template v-slot:item.isSolved="{ item }">
 *       <BoolYesNo check v-model="item.isSolved""></BoolYesNo>
 *     </template>
 * 
 */

const props = defineProps({
    value: {
        type: [String, Boolean, Number]
    },
    check: {
        type: [String, Boolean, Number],
        default: true
    },
    colored: {
        type: [Boolean],
        default: false
    }
})

function isTrue(v) {
    return bool.isTrue(v);
}
const bvalue = computed({
    get() {
        return isTrue(props.value);
    },
})
const bcheck = computed({
    get() {
        return isTrue(props.check);
    },
})

const color = computed({
    get() {
        if (!props.colored) {
            return "";
        }
        return isTrue(props.value) ? "success" : "error"; 
    },
})
const cls = computed({
    get() {
        if (!props.colored) {
            return "";
        }
        return isTrue(props.value) ? "text-success" : "text-error"; 
    },
})
</script>
