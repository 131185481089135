//
// 
//

import clsIcon from '@cls/clsIcon'

class clsIconExt extends clsIcon {

    constructor() {
        super();
    }
} 

export default clsIconExt;
