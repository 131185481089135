<template>
        <v-card class="bg-card mb-2">
            <v-card-text>
                <v-row dense>
                    <v-col sm="12" lg="6" xl="4" class="pr-8">
                                <FormRow col-label="4" label="Type">    
                                    <ProjectGroupCombo no-clearable :model="model" :rules="model.rules.mandatory" v-model="model.id_group"></ProjectGroupCombo>
                                </FormRow>
                                <FormRow col-label="4" label="Titel">    
                                    <TextField max-length="255" placeholder="Projectnaam" :rules="model.rules.pro_name" :model="model"  v-model="model.pro_name"></TextField>
                                </FormRow>
                                <FormRow col-label="4" label="Referentie">    
                                    <TextField max-length="255" placeholder="Referentie" :rules="model.rules.pro_reference" :model="model"  v-model="model.pro_reference"></TextField>
                                </FormRow>
                                <FormRow col-label="4" label="Opdrachtgever">    
                                    <RelationCombo sales :model="model" :rules="model.rules.id_relation" v-model="model.id_relation"></RelationCombo>
                                </FormRow>
                                <FormRow col-label="4" label="Contactpersoon">    
                                    <RelationContactCombo placeholder="Contactpersoon" :model="model" :rules="model.rules.id_person" :id_parent="model.id_relation" v-model="model.id_person"></RelationContactCombo>
                                </FormRow>
                                <FormRow col-label="4" label="Factuur relatie">            
                                    <Checkbox class="" switcher :skeleton="model.isDataLoading" v-model="model.pro_use_relation_in_invoice" label="Opdrachtgever ontvangt ook de facturen"></Checkbox>
                                </FormRow>
                                <FormRow v-if="!model.pro_use_relation_in_invoice" col-label="4" label="Factuur relatie">            
                                    <RelationCombo sales :model="model" :rules="model.rules.id_relation_invoice" v-model="model.id_relation_invoice"></RelationCombo>
                                </FormRow>
                                <FormRow label='Start / Einddatum'>
                                    <v-row dense>
                                        <v-col><DatePicker :rules="model.rules.pro_start" :model="model" v-model='model.pro_start'></DatePicker> </v-col>
                                        <v-col><DatePicker :rules="model.rules.pro_end" :model="model" v-model='model.pro_end'></DatePicker> </v-col>
                                    </v-row>
                                </FormRow>

                    </v-col>
                    <v-col sm="12" lg="6" xl="4">

                            <FormRow label="Adres">
                                <v-row dense >
                                    <v-col class="col-9">
                                        <TextField max-length="255" placeholder="Straatnaam" :rules="model.rules.adr_street" :model="model" v-model="model.adr_street"></TextField>
                                    </v-col>            
                                    <v-col class="col-3">
                                        <TextField max-length="45" placeholder="Nr" :rules="model.rules.adr_street_number" :model="model" v-model="model.adr_street_number"></TextField>
                                    </v-col>            
                                </v-row>
                            </FormRow>
                            <FormRow>
                                <v-row dense>
                                    <v-col class="col-4">
                                        <TextField max-length="45" placeholder="Postcode" :rules="model.rules.adr_zipcode" :model="model" v-model="model.adr_zipcode"></TextField>
                                    </v-col>            
                                    <v-col class="col-8">
                                        <TextField max-length="255" placeholder="Plaats" :rules="model.rules.adr_city" :model="model" v-model="model.adr_city"></TextField>
                                    </v-col>            
                                </v-row>
                            </FormRow>
                            <FormRow>            
                                <CountryCombo :model="model" :rules="model.rules.country_code" v-model="model.country_code"></CountryCombo>
                            </FormRow>

                            <FormRow col-label="4" label="Projectleider">    
                                <EmployeeCombo placeholder="Projectleider" :model='model' :rules='model.rules.id_employee_manager' v-model='model.id_employee_manager'></EmployeeCombo>
                            </FormRow>
                            <FormRow col-label="4" label="Werkvoorbereider">    
                                <EmployeeCombo placeholder="Werkvoorbereider" :model='model' :rules='model.rules.id_employee_planner' v-model='model.id_employee_planner'></EmployeeCombo>
                            </FormRow>
                            <FormRow col-label="4" label="Uitvoerder">    
                                <EmployeeCombo placeholder="Uitvoerder" :model='model' :rules='model.rules.id_employee_executor' v-model='model.id_employee_executor'></EmployeeCombo>
                            </FormRow>
                            <FormRow label='Project fase'>
                                <ProjectPhaseCombo :model='model' :rules='model.rules.mandatory' v-model='model.id_project_phase'></ProjectPhaseCombo>
                            </FormRow>


                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
</template>



<script setup>
    
//    <FormRow col-label="4" label="Contactpersoon">    
//        <RelationContactCombo placeholder="Contactpersoon (Optioneel)" :disabled="!model.id_relation" :model="model" :id_parent="model.id_relation" v-model="model.id_person"></RelationContactCombo>
//    </FormRow>

    import FormRow from '@controls/forms/FormRow'
    import TextField from '@controls/input/TextField'
    import RelationCombo from '@controls/combo/Relation'
    import DatePicker from '@controls/input/DatePicker'
    import NumberField from '@controls/input/NumberField'
    import CountryCombo from '@controls/combo/Country'
    import ProjectPhaseCombo from '@controls/combo/ProjectPhase'
    import Checkbox from '@controls/check/Checkbox.vue'
    import ProjectGroupCombo from '@controls/combo/ProjectGroup'
    import RelationContactCombo from '@controls/combo/RelationContact'
    import EmployeeCombo from '@controls/combo/Employee'

    const props = defineProps({
        model: {
            type: [Object]
        },
    });

</script>
