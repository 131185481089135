<template>
        <v-card class="bg-card mb-2">
            <v-card-text class="px-0 pt-0 pb-0">

                <LocalTable :model="model" modelLinesProp='lines' :disabled="model.isOpenOrLater" editable can-drag v-if="!false" no-hover dense :dt="tblLines">

                    <template v-slot:row="{ index, item, dt, columnHeaders }">
                        <SalesInvoiceLine v-if="item.ihp_type!='text'" :model="model" :key="index" :item="item" :dt="dt" :columnHeaders="columnHeaders"></SalesInvoiceLine>
                        <SalesInvoiceTextLine v-else :model="model" :key="index" :item="item" :dt="dt" :columnHeaders="columnHeaders"></SalesInvoiceTextLine>
                    </template>


                    <template v-slot:tfoot="{/*dt, hasCmd, */columnHeaders}">
                        <tfoot v-if="!model.disabled && !model.isOpenOrLater">
                        <tr>
                            
                            <td class="pt-4 pb-6 pl-4" :colspan="columnHeaders.length">
                                <span class="position-absolute" >
                                    <ActionButton action="salesinvoice.modify" sub dusk="btn-add-line" :model="model" :disabled="model.isOpenOrLater" icon="add" xmain xprimary @click='(evt)=>addLine(evt)'>Nieuwe regel</ActionButton>
                                    <ActionButton sub dusk="btn-add-text-line" :model="model" dropdown :disabled="model.isOpenOrLater"  ml icon='text'>
                                        Tekstregel
                                        <template v-slot:dropdown>
                                            <ActionMenuItem  no-icon dusk="btn-add-line-empty-text" :model="model" action='salesinvoice.modify' no-execute :disabled='model.isOpenOrLater' @click='(evt)=>addEmptyText(evt)'>Lege tekstregel</ActionMenuItem>
                                            <ActionMenuItem  no-icon dusk="btn-add-line-standard-text" :model="model" action='salesinvoice.modify' no-execute :disabled='model.isOpenOrLater' @click='(evt)=>addStandardText(evt)'>Standaard tekstregel</ActionMenuItem>
                                        </template>
                                    </ActionButton>
                                    <ActionButton sub action="salesinvoice.modify" dusk="btn-add-product" :model="model" ml :disabled="model.isOpenOrLater" icon="product" @click='()=>addProductLine()'>Artikel</ActionButton>
                                    <ActionButton v-if="feature.canManday||feature.canProject" sub dusk="btn-add-text-line" :model="model" dropdown :disabled="model.isOpenOrLater"  ml="8" icon='text'>
                                        Koppel met
                                        <template v-slot:dropdown>
                                            <ActionMenuItem  v-if="feature.canManday" no-icon dusk="btn-link-manday" :model="model" action='salesinvoice.modify' :disabled='model.isOpenOrLater' @click='(evt)=>addManday(evt)'>Mandagenstaat</ActionMenuItem>
                                            <ActionMenuItem  v-if="feature.canProject" no-icon dusk="btn-link-project" :model="model" action='salesinvoice.modify' :disabled='model.isOpenOrLater' @click='(evt)=>addProject(evt)'>Project</ActionMenuItem>
                                        </template>
                                    </ActionButton>
                                </span>
                                <span class="min-h-16"></span>
                                </td>
                            <td class="pt-4 text-right text-error text-small pr-3" colspan="3">                                
                            </td>
                        </tr>
                        </tfoot>
                    </template>

                    <template v-slot:header.amount_excl="{datatable}">
                        <span>
                            <span v-if="model.useAmountsExclVat">Totaal Excl. BTW</span>
                            <span v-else class="text-error">Totaal Incl. BTW</span>
                        </span>
                        <span v-if="1==100">
                            <span class="text-primary" @click="model.useAmountsExclVat=!model.useAmountsExclVat">
                                <v-menu offset-y bottom>
                                    <template v-slot:activator="{ on }">
                                        <span v-on="on" class="cursor-pointer">
                                            <span class="mr-1"><Icon small color="primary" type='edit'></Icon></span>
                                            <span v-if="model.useAmountsExclVat">Totaal Excl. BTW</span>
                                            <span v-else>Totaal Incl. BTW</span>
                                        </span>
                                        </template>
                                        <v-list dense>
                                            <ActionMenuItem :model="model" no-icon action="salesinvoice.modify" no-execute  @click="()=>model.useAmountsExclVat=true">Exclusief BTW</ActionMenuItem> 
                                            <ActionMenuItem :model="model" no-icon action="salesinvoice.modify" no-execute  @click="()=>model.useAmountsExclVat=false">Inclusief BTW</ActionMenuItem> 
                                        </v-list>
                                </v-menu>
                            </span>
                        </span>
                        
                    </template>

                </LocalTable>
            </v-card-text>
        </v-card>
</template>


<script setup>
    import ActionButton from '@shared/ui/controls/buttons/ActionButton'
    import LocalTable from '@datatable/LocalTable'  
    import clsLocalTable from '@cls/clsLocalTable'
    import ActionMenuItem from "@controls/menu/ActionMenuItem.vue";
    import Icon from '@controls/icons/Icon'
    import {computed, ref, vue, nextTick} from 'vue'

    import SalesInvoiceLine from "./SalesInvoiceLine"
    import SalesInvoiceTextLine from "./SalesInvoiceTextLine"
    import dlg from '@app/dlg'
    import feature from "@app/features";
    import DrillDown from '@controls/buttons/DrillDown'

    const props = defineProps({
        model: {
            type: [Object]
        },
    });
    const emit = defineEmits(['addline', 'addemptytextline'])

    let headers = [

        { canhide: false, canmove: false, type: 'cmd'}, 
        { canhide: false, canmove: false, type: 'drag'}, 
        { text: 'Aantal',                                   value: 'ihp_amount',                     class:'w-min-2 w-2 text-right pr-3'},
        { text: 'Eenheid', configText: 'Eenheid',           value: 'id_unity',                       class:'w-min-3 w-3 pl-3'},
        { text: 'Omschrijving',                              value: 'ihp_pd_name',                   class: 'w-max-10 w-min-4 pl-3'   },
        { visible: false, text: 'Artikel',                   value: 'id_product',                    class:'w-min-4 w-4 pl-3'},
        { visible: false, text: 'Fabrikant',                 value: 'sup_name',                      class: 'w-3 pl-3', cellClass: 'pl-3'},
        { visible: false, text: 'Fabrikantcode',             value: 'pd_supplier_code',              class: 'w-3 pl-3', cellClass: 'pl-3'},
        { visible: false, text: 'Fabrikantnaam',             value: 'pd_supplier_name',              class: 'w-3 pl-3', cellClass: 'pl-3'},
        { visible: false, text: 'Ean',                       value: 'ean',                           class: 'w-3 pl-3', cellClass: 'pl-3'},
        { visible: false, text: 'Manurenstaat',              value: 'id_mandays_registration',       class:'w-min-4 w-4 pl-3'},
//        { visible: false, text: 'Order',                     value: 'id_order_line',                 class:'w-min-3 w-3 pl-3'},
        { visible: false, text: 'Project',                   value: 'id_project',                    class:'w-min-4 w-4 pl-3'},
        { visible: false, text: 'Order',                     value: 'id_order',                      cellClass: "pl-2 pa-2 ", class:'w-min-3 w-3 pl-2'},
        { visible: false, text: 'Korting',                   value: 'ihp_discount',                  class:'w-min-2 w-2 text-right pr-3'},
        { canhide: false, text: 'Stukprijs',                 value: 'ihp_sales_price',               class:'w-min-3 w-3 text-right pr-3'},
        { canhide: false,  text: "BTW",                      value: "id_vat",                        class:"w-min-3 w-3 pl-3"}, 
        { canhide: false,  text: "Totaal Excl. BTW",         value: "amount_excl",                   cellClass: "text-right pr-3", class:"w-3 text-right pr-3"}, 
    ];
    if (!feature.canProject) { headers = headers.filter( (line) => line.value != 'id_project'); }
    if (!feature.canManday)  { headers = headers.filter( (line) => line.value != 'id_mandays_registration'); }
    if (!feature.canSalesOrder) { headers = headers.filter( (line) => line.value != 'id_order'); }
    
    let tblLines = ref(new clsLocalTable("salesinvoice.lines", headers, {
        noDataText: "Er zijn nog geen regels toegevoegd",
        noSort: true,
        model: props.model, 
        modelLinesProp: 'lines'        
    }));

    window.invl = tblLines
    
    /**
     * Defer adding a line to the parent.
     * Since we add a line both via the button and via a keyboard shortcut, we can more easy share code. 
     */
    function addLine(evt) {
        emit('addline', evt);
    }
    /**
     * Defer adding a line to the parent.
     * Since we add a line both via the button and via a keyboard shortcut, we can more easy share code. 
     */
    function addEmptyText(evt) {    
        emit('addemptytextline', evt);
    }
    async function addStandardText() {
        try {
            let selected = await dlg.promised.open("select_standardtext", {multiselect:false})
            if (!selected || !selected.length) {
                return;
            }
            props.model.addTextLine(selected[0]);
        } catch (e) {} // canceled
    }
    async function addProductLine() {
        try {
            var fnMultiSelectCallback = function(selected) {
                if (!selected || !selected.length) {
                    return;
                }
                props.model.addProductLine(selected[0]);                
            }

            let selected = await dlg.promised.open("select_product", {multiselect:false, fnMultiSelectCallback: fnMultiSelectCallback, filter: {type: 'rp'}}) // recipes and products
            if (!selected || !selected.length) {
                return;
            }
            props.model.addProductLine(selected[0]);
        } catch (e) {} // canceled
    }
    async function addManday() {
        try {
            let selected = await dlg.promised.open("select_manday", {multiselect:false})
            if (!selected || !selected.length) {
                return;
            }
            props.model.addMandayLine(selected[0]);
        } catch (e) {} // canceled
    }
    async function addProject() {
        try {
            let selected = await dlg.promised.open("select_project", {multiselect:false})
            if (!selected || !selected.length) {
                return;
            }
            props.model.addProjectLine(selected[0]);
        } catch (e) {} // canceled
    }
    
</script>
