<template>

    <span>
        <FlagMenu :disabled="compDisabled" :model="model" v-model="model.flag" @input="(v)=>setValue(v)"></FlagMenu>
    </span>

</template>

<script setup>

//   import FlagColumn  from '@datatable/columns/Flag'

import string from "@lib/string";
import FlagMenu from "@controls/menu/FlagMenu.vue";      
import {computed} from 'vue'
import {flags as api} from '@app/api'
import action from '@app/action'

const props = defineProps({
        disabled: {
            type: [Boolean]
        },
        value: {
            type: [String, Number]
        },
        action: {
            type: [String]
        },
        id_entity: {
            type: [String, Number]
        },
        id_optimit_type: {
            type: [String, Number]
        },
        model: {
            type: [Object]
        },
        flagType: {
            type: [String]
        },

    })

    const emit = defineEmits(['input'])    
    const dvalue = computed({
        get() {
            return props.value
        },
        set: function(v) {
            emit('input', v)
        }	
    })

    // A flag can always be seen, however modify can be restricted.
    const compDisabled = computed({
        get() {
            if (!props.flagType) {
                return false;
            }
            return !action.can(`${props.flagType}.modify`)
        }
    })

    async function setValue (value) {
        if (compDisabled.value) {
            return; // Should not be here anyways as the control is disabled.
        }
        dvalue.value = value;
        let result = await api.save(value, props.id_optimit_type, props.id_entity);
        props.model.flag = result.data.value;
    }

</script>
