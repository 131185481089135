import clsFeature from '@/app/clsFeature'
import fnExpose from '@shared/lib/expose'

// Usage: 
// import feature from '@app/features';
// if (feature.canProject) {
//     console.log('Feature enabled')   
// }
// 
let feature = new clsFeature();

fnExpose(feature, 'feature')

export default feature