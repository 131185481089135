import clsRouting from '@cls/clsRouting'
import user from '@app/user'
import action from '@app/action'

/**
 * This component handles the routes
 * The purpose of this class is to manage the routes, and, when applicable to the application, 
 * handle route specific authorisation. 
 * 
 * To implement user specific authorisation decisions, override method isAuthorized.  
 *
 * Note that the routes are defined in the application @/app/routes.2.js file. 
 * Note also that the actual VUE router calls the isAuthorized method.
 * 
 */
 class clsRoutingExt extends clsRouting{
 
    /**
     * Return the 'to' clause which can be used in routing components. 
     * For example: 
     *  <v-list-item :to="compTo" exact :class="{'opacity-50': compDisabled}" :title="compTitle" @click="onClick">  
     * 
     * @param {*} name 
     */
    to(name, params) {
        let to = super.to(name, params);
        if (user && user.id_administration) {
            to.params = to.params || {}
            to.params.id_administration = user.id_administration 
        }
        return to;
    }

    /**
     * Does the current company user have access to the modules required to access this route?
     * E.g. for navugatung to a workorder, the module workorder is required.
     * 
     * Note that this is only part of the authorization check. 
     * When the company has access to the module workorders, it might still be that the current user has no rights on any workorder action.
     *  
     * @param {*} action 
     */
    hasRequiredModule(route) {
        var routeAction = this.actionFor(route);
        if (!routeAction) {
            return true; // route is not connected to action. Fine.
        }
        return action.hasRequiredModule(routeAction);
    }
    
    /**
     * Does the current user have rights to access this route?
     * When the involved action has no rights: true
     *  
     * @param {*} action 
     */
    hasRequiredRight(route) {
        var routeAction = this.actionFor(route);
        if (!routeAction) {
            return true; // route is not connected to action. Fine.
        }
        return action.hasRequiredRight(routeAction);
    }
        
    /**
     * Check whether a user has access to the route. The authorization is granted when:
     *      - no action is coupled to the route. 
     *      - the user company has access to the module required for the coupled action 
     *        and 
     *        the user has any of the rights required to access the route. 
     * 
     * @param {*} route. Can be a route object or a route name (string) 
     * @returns 
     */
    isUserAuthorizedFor(route) {
//        if (route == 'tender.search' || route.name == 'tender.search') {
//            return false;
//        }
        var routeAction = this.actionFor(route);
        if (!routeAction) {
            return true; // route is not connected to action. Fine.
        }
        return action.isUserAuthorizedFor(routeAction);
    }

 }
 
 export default clsRoutingExt;