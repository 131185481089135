/**
 * 
 *   Usage: See clsList for examples, API and implementation notes. 
 * 
 */
import clsList from '@cls/clsList'

 
class clsVatList extends clsList {
 
    beforeAddingItem(item) {
        if (item.is_shifted) {
            item.name = 'BTW Verlegd';
            item.shortname = 'Verlegd';
        } else if (item.is_abroad) {
            item.name = 'BTW Buitenland';
            item.shortname = 'Buitenland';
        } else {
            item.name = `${item.pct}% BTW`
            item.shortname = `${item.pct}%`
        }
    }

     /**
     * Get the shifte vat type
     * @param {} id 
     */
    shifted() {
        let result = this.list.filter( (v) => v.is_shifted);
        if (!result || !result.length) {
            return null;
        }
        return result[0];
    }

    /**
     * Get one foreign vat type
     * @param {} id 
     */
    foreign() {
        let result = this.list.filter( (v) => v.is_abroad);
        if (!result || !result.length) {
            return null;
        }
        return result[0];
    }
    /**
     * Get one none vat type
     * @param {} id 
     */
    none() {
        let result = this.list.filter( (v) => v.is_none);
        if (!result || !result.length) {
            return null;
        }
        return result[0];
    }

    /**
     * Get 'standard' vat types. That is, no vat shifted and no vat foreign.
     * @param {} id 
     */
    standardTypes() {
        return this.list.filter( (v) => !v.is_shifted && !v.is_abroad);
    }
    
    /**
     * Get vat types applicable for purchase invoices (and tenders et all)
     * @param {} id 
     */
    purchase() {
        return this.list.filter( (v) => v.is_purchase);
    }

    /**
     * Get vat types applicable for sales invoices (and tenders et all)
     * @param {} id 
     */
    sales() {
        return this.list.filter( (v) => v.is_sales);
    }
    
 }
 
export default clsVatList
