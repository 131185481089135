<template>
    <Dialog :dialog="dlg" no-content-padding>

        <Datatable :dt="dt" class="full-height attachments">
            <template v-slot:menu ="{ item }">
                <ActionMenuItem :action="dlg.downloadAction" :payload="item" @click="onDownload(item)"></ActionMenuItem>
                <v-divider></v-divider>
                <ActionMenuItem :action="dlg.removeAction" icon="remove" @click="dt.onStartRemove(item)">Verwijder bijlage</ActionMenuItem>
            </template>

            <template v-slot:item.name ="{ item }">
                <span class="cursor-pointer primary--text" :color="'primary'" @click="() => onDownload(item)">
                   {{ item.name }}
                </span>
            </template>

        </Datatable>
    
        <template v-slot:footer-left>
            <ActionButton sub :action='dlg.uploadAction' @click="()=>dlg.onStartUpload()"></ActionButton>
        </template>

    </Dialog>
</template>



<script setup>
    import useDataTable from '@app/useDataTable'
    import Datatable from '@datatable/Datatable'
    import ActionMenuItem from "@controls/menu/ActionMenuItem.vue";
    import { attachments as api } from '@app/api'
    import string from '@lib/string'
    import bool from '@lib/bool'
    import ActionButton from '@controls/buttons/ActionButton'
    import Dialog from '@shared/ui/dialogs/ActionDialog'
    import action from '@app/action'
    import { ref, computed,onMounted, onUnmounted } from 'vue'
    import clsDialog from '@cls/clsDialog'    
    import eventbus from '@app/eventbus'    
    import appDlg from '@app/dlg'
    import noty from '@shared/lib/noty'

    var headers = [
        { type: 'cmd' },
        { text: "Bijlage",    value: "name",  xtype: 'open', xaction: 'download' },
        { text: "Type",       value: "type" },
        { text: "Grootte",    value: "size" },
        { text: "Aangemaakt", value: "created_at"      },
    ]


    var dt = useDataTable("dt_attachments", "attachments", api, headers, {
        actionDblClick:null,
        noConfigureColumns: true,
        itemName: {
            prefix: 'de',
            single: 'bijlage',
            plural: 'bijlagen',
        },
        canArchive: false,
        autoSearch: false,
//        hideDefaultFooter: true,
        footer: {
            itemsPerPageOptions: [20, 50],
            itemsPerPageText: 'Resultaten per pagina:',
            // disableItemsPerPage: true
        },
    })


    class clsAttachmentsDialog extends clsDialog {
        
        rightsModelName = null;
        id_optimit_type = null; 
        id_entity = null;
        getActionName(action) {
            if (this.rightsModelName) {
                return `${this.rightsModelName}.${action}`
            }
            return `attachment.${action}`;

        }
        // This model is 
        get downloadAction() {
            return this.getActionName('open');
        }
        // This model is 
        get removeAction() {
            return this.getActionName('remove');
        }
        // This model is 
        get uploadAction() {
            return this.getActionName('create');
        }

        // Instead of close with a bang, always resolve the promise if we were opened with a promise. 
        // This way we can return the number of attachments in the datatable. 
        // Note that in theory, we could return a wrong number as a user might close the dialog directly after
        // executing a delete or insert while the refreshed rows are not yet retrieved.
        // However, the chance is low and there is also no impact as it is only used for visual hints.
        async closeDialog(force) {
            var count = dt.rowCount;
            if (this.fnResolve) {
                this.fnResolve({count: count});
            }
            this.open = false;
        }

        onOpen(params, extraData) {
            // For rights, we might need to check a different model as attachments is generic functionallity
            this.rightsModelName = extraData.rightsModelName||"attachments";
            this.id_optimit_type = params?.id_optimit_type;
            this.id_entity  = params?.id;

            dt.setParent({id_entity: this.id_entity, id_optimit_type: this.id_optimit_type}, true);
            dt.onStopCheckbox();
            return super.onOpen(params, extraData);            
        }

        async onStartUpload() {
            try {
                var result = await appDlg.promised.open("attachment_upload", {id_optimit_type: this.id_optimit_type, id_entity:this.id_entity}, {rightsModelName: this.rightsModelName});
                if (result.count) { // Files were uploaded.
                    dt.search();
                }
            } catch (e) {} // the upload dialog was canceled.
        }
    }

    let dlg = ref(
        new clsAttachmentsDialog("attachments", "Bijlagen", null, null, {
            noSubTitle: true,
            maxWidth:'800px',
            icon: 'attachments',
            save: null, 
            cancel: 'Sluit'
        } )).value;


    async function onDownload(item) {
        var downloadAction = dlg.downloadAction;
        if (!action.can(downloadAction)) {
            noty.alert("U heeft geen rechten bijlagen te downloaden");
            return;
        }
        dt.loading = true;
        try {
            var url = `download/${item.id}?id_optimit_type=${dlg.id_optimit_type}`;
            await api.download(url);
        } finally {
            dt.loading = false;
        }
    }

    window.dlga = dlg
    window.dta = dt
</script>
