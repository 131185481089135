import { clsModel, fnCreate } from '@cls/clsModel'
import { salesinvoices as api } from '@/app/api'
import string from '@lib/string'
import Constants from '@app/consts'

// We operate on a sales invoice, no special model name for sending an invoice reminder. 
var modelName = "salesinvoice_reminder";

const fields = ['id', 'subject', 'body', 'to', 'cc', 'type', 'recipients', 'address', 'attachments', 'allattachments', 'mergeattachments'];

class clsSalesInvoiceReminder extends clsModel {

    subject = null;
    body = null;
    to = null;
    cc = null;
    _type = null;           // reminder type: 1, 2, 3
    recipients = [];    
    attachments = [];
    allattachments = [];

    digital = true;
    address = [];

    // Different templates gives different subjects and bodies
    // We don't send it back to the server, thereofre not in the fields list above.
    bodies = [];
    subjects = [];

    get type() {
        return this._type;
    }
    get typeName() {
        switch (this.type) {
            case Constants.invoice.reminder.REMINDER1: return '1e herinnering';
            case Constants.invoice.reminder.REMINDER2: return '2e herinnering';
            case Constants.invoice.reminder.DEFAULTNOTICE: return 'Ingebrekestelling';
        }
        return '1e herinnering';
    }
    // The available types. 
    types = [
        {id: Constants.invoice.reminder.REMINDER1, name: '1e herinnering'},
        {id: Constants.invoice.reminder.REMINDER2, name: '2e herinnering'},
        {id: Constants.invoice.reminder.DEFAULTNOTICE, name: 'Ingebrekestelling'},
    ];

    // Depending on the type of reminder we sent, default the subject and the body.
    set type(value) {
        // When emptying, do not do anything else.
        this._type = value;
        if (!value || !this.subjects || !this.bodies || (this.subjects.length < 3) || (this.bodies.length < 3)) {
            return; // change nothing else.
        }
        let ixReminder = 0; // 0: reminder 1, 1: reminder 2, 2: defaultnotice
        switch (value) {
            case Constants.invoice.reminder.REMINDER1: ixReminder = 0; break;
            case Constants.invoice.reminder.REMINDER2: ixReminder = 1; break;
            case Constants.invoice.reminder.DEFAULTNOTICE: ixReminder = 2; break;
        }
        this.subject = this.subjects[ixReminder];
        this.body = this.bodies[ixReminder];        
    }

    attach(attachment) {
        this.attachments.push(attachment);
    }
    unattach(attachment) {
        this.attachments = this.attachments.filter( (item) => item != attachment);
    }

    apiLoad(id) {
        return this.api.loadReminderData(id);
    }
    apiSave(model) {
        return this.api.emailReminder(model);
    }
    onBeforeOpen() {
        this.digital = true;
    }

    async download() {
        let result = null;
        this.isDataLoading = true;
        try {
            result = await api.downloadReminder(this.id, this.type);        
        } finally {
            this.isDataLoading = false;
        }
        this.sendSavedEvent();
        return result;
    }

    /**
     * Download or send the invoice 
     */
    async save() {
        if (!this.digital) {
            return await this.download();
        }
        return super.save();
    }

    fill(data) {
        data = data ||{};
        data.digital = true;
        // Ok, for reminders, allthough we get all available attachments, we just want to use the default attachments. 
        data.attachments = data.attachments ||[];
        // data.allattachments = data.allattachments ||[];
        data.allattachments = [...data.attachments] || [];
        data.address = data.address || [];
        data.type = data.type || Constants.invoice.reminder.REMINDER1;
        
        this.digital = true;        

        this.subjects = data.subjects;
        this.bodies = data.bodies;

        return super.fill(data);
    }
    
    toJSON() {        

        var result = this.propsToJSON(['id', 'type', 'subject', 'body', 'to', 'cc', 'attachments', 'mergeattachments']);    
        result.attachments = this.attachments.map( (x) => x.id);
        return result;
    }

    constructor() {
        super({
          api: api,   
          modelName: modelName, 
          mandatoryFields: ['subject', 'body'],

          fillable: fields
        })
    } 
        
 }
 export default fnCreate(clsSalesInvoiceReminder, 'clsSalesInvoiceReminder');
