import clsApi from '@cls/clsApi'

class clsNoteApi extends clsApi {

    async loadNote(id_optimit_type, id_entity) {
        return await this.post(`load`, {id_optimit_type: id_optimit_type, id_entity: id_entity}); 
    }

}

export default clsNoteApi;
