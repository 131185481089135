<template>
    <div>        
        <Datatable :dt="dt" class="full-height">
            <template v-slot:menu ="{ item }">
                <ActionMenuItem action="project_additionalwork.open" @click="()=>onOpen(item)"></ActionMenuItem>
                <v-divider></v-divider>
                <ActionMenuItem action="project_additionalwork.invoice" @click="()=>startCreateInvoice(item)">Maak factuur</ActionMenuItem>
                <ActionMenuItem action="project_additionalwork.open" @click="()=>startPrint(item)">Print</ActionMenuItem>
                <v-divider></v-divider>
                <ActionMenuItem action="project_additionalwork.remove" @click="dt.onStartRemove(item)"></ActionMenuItem>
            </template>

            <template v-slot:item.pew_type="{item}">
                <strong>
                    <span v-if="item.pew_price<0">Minderwerk</span>
                    <span v-else>Meerwerk</span>
                </strong>
            </template>
            <template v-slot:item.inv_number="{item}">
                <span v-if="!item.id_invoice">Niet gefactureerd</span>
                <span v-else class="text-primary cursor-pointer" @click="onOpenInvoice(item)">{{ item.inv_number }}</span>
            </template>

            <template v-slot:footer.prepend>
                <v-row dense>
                    <v-col>
                        <v-card flat>
                            <v-card-text>
                                <ActionButton action="project_additionalwork.create" sub dusk="btn-add-extrawork" :model="model" icon="add" xmain xprimary @click='addExtraWorkLine'>Nieuw meerwerk</ActionButton>                        
                                <ActionButton ml action="project_additionalwork.create" sub dusk="btn-add-lesswork"  :model="model" icon="add" xmain xprimary @click='addLessWorkLine'>Nieuw minderwerk</ActionButton>                        
                            </v-card-text>
                        </v-card>

                    </v-col>
                    <v-col class="text-right pr-0">
                        <v-card flat>
                            <v-card-text class="pr-0">
                                <ActionButton ml action="project_additionalwork.invoice" sub dusk="btn-add-create-extrawork-invoice" :model="model" icon="invoice" xmain xprimary @click='startCreateInvoice'>Maak factuur</ActionButton>                        
                                <ActionButton ml action="project_additionalwork.open" sub dusk="btn-add-print-extrawork" :model="model" icon="print" xmain xprimary @click='startPrint'>Print meer / minderwerk</ActionButton>                        
                            </v-card-text>
                        </v-card>

                    </v-col>
                </v-row>
            </template>

            <template v-slot:customcommands="{dt,bulkAction, selectedCount}">
                <ActionButton main primary icon="invoice" v-if="bulkAction=='createInvoice'"  @click="()=>onCreateInvoice(dt)" :disabled="!selectedCount" >Maak factuur</ActionButton>
                <ActionButton main primary icon="print" v-if="bulkAction=='print'"  @click="()=>onPrint(dt)" :disabled="!selectedCount" >Print</ActionButton>
            </template>

        </Datatable>

    </div>
</template>



<script setup>
    import useDataTable from '@app/useDataTable'
    import Datatable from '@datatable/Datatable'
    import ActionMenuItem from "@controls/menu/ActionMenuItem.vue";
    import { project_extrawork as api } from '@app/api'
    import dlg from '@app/dlg'
    import ActionButton from '@controls/buttons/ActionButton'
    import noty from '@shared/lib/noty'
    
    const props = defineProps({
        model: {
            type: [Object]
        },
    });

    var headers = [
        { type: 'cmd' },

        { text: "Type",     value: "pew_type" , },
        { visible:false, text: "Hoofdstuk",  value: "chapter", },
        { text: "Omschrijving",  value: "pew_description", type:'open', open: (item,header)=>onOpen(item)},
        { text: "Prijs",    value: "pew_price"    ,type: 'amount' },
        { text: "Factuur",  value: "inv_number" },
        { text: "Status",   value: "status"    },
        { visible: false, text: "Opmerking",   value: "pew_remark"    },
        { isUtc: true, text: "Gewijzigd",                value: "updated_at",   type: 'datetime', visible: false},
        { text: "Gewijzigd door",           value: "updated_by",    visible: false},
        { isUtc: true, text: "Aangemaakt",               value: "created_at",    type: 'datetime', visible: false, sort: 'desc'},
        { text: "Aangemaakt door",          value: "created_by",    visible: false},
    ];

    // Refresh when additional work is the invoice is saved or sent.
    var customSaveHandler = (_dt, modelName, params) => { 
        if (modelName == "project_additionalwork") {
            _dt.search(); // refresh
        }
        if (modelName == "salesinvoice_send") {
            if (!_dt.items.find( (item) => item.id_invoice == params.id)) {
                return;
            }
            _dt.search(); // refresh
        } 
    }
    var customRemoveHandler = (_dt, modelName, _ids) => {
        if (modelName == "project_additionalwork") {
            return _dt.search();
        }
        if (modelName == "salesinvoice") {
            return _dt.search();
        }
    }
    
    var dt = useDataTable("dt_project_extra_work", "project_additionalwork", api, headers, {
        // hideDefaultFooter: true,
        noPager: true,
        itemName: {
            prefix: 'De',
            single: 'meer/minderwerk',
            plural: 'meer/minderwerken',
        }, parent: {
            id_project: props.model.id
        },
        actionDblClick: (evt, item) =>onOpen(item),
        preventRemoveHandler: ()=>!props.model.isDialogOpen, // No refresh when dialog is not open
        preventSaveHandler:   ()=>!props.model.isDialogOpen, // No refresh when dialog is not open
        customSaveHandler:    customSaveHandler,
        customRemoveHandler:  customRemoveHandler,
        canArchive: false
    })

    function onOpenInvoice(item) {
        if (item.id_invoice) {
            dlg.open('salesinvoice', {id: item.id_invoice})
        }
    }
    function startCreateInvoice(line) {
        line = line ||{}
        dt.onStartCheckBox(line, 'createInvoice')
    }
    async function onCreateInvoice() {
        var ids = dt.selectedIDs();     
        if (!ids||!ids.length) {
            return;
        }
        try {
            await noty.confirm("Voor de geselecteerde regels wordt een conceptfactuur aangemaakt.<br>Deze factuur wordt dus <strong>niet</strong> automatisch verzonden.<br><br>Voordat u de factuur verstuurt kunt u deze nog aanpassen.", {title: "Maak conceptfactuur"});
            var id_invoice = await props.model.createExtraWorkInvoice(ids);
            dt.search();
            dt.onStopCheckbox();
            dlg.open('salesinvoice', {id: id_invoice})
        } catch (e) {
            if (!e.canceled) { throw e;}
        }
    }

    function startPrint(line) {
        line = line ||{}
        dt.onStartCheckBox(line, 'print')
    }
    async function onPrint(dataTable) {
        var ids = dt.selectedIDs(); 
        if (!ids||!ids.length) {
            return;
        }
        try {
            dlg.pdf(async () => props.model.downloadExtraWorkPdf(ids), "Meerwerk specificatie.pdf");
            dt.onStopCheckbox();
        } catch (e) {
            if (!e.canceled) { throw e;}
        }
    }
    function addExtraWorkLine() {
        dlg.create('project_additionalwork', {id_project: props.model.id, chapters: props.model.chaptersv2, isExtra: true})
    }
    function addLessWorkLine() {
        dlg.create('project_additionalwork', {id_project: props.model.id, chapters: props.model.chaptersv2, isExtra: false})
    }

    async function onOpen(item) {
        var xxx = await dlg.promised.open('project_additionalwork', item, {id_project: props.model.id, chapters: props.model.chaptersv2})        
    }
</script>