import clsApi from '@cls/clsApi'

class clsCompany extends clsApi {

    async load() {
        return this.get(`load`); 
    }

}

export default clsCompany;
