<template>
    <Dialog :dialog="dlg" no-content-padding>
            <template v-slot:tabs-left>
                <v-tabs dense v-model="dlg.tab">        
                    <v-tab>Relatie</v-tab>
                    <v-tab :disabled="model.isLoading||!action.can('relation_settings.open')">Instellingen</v-tab>
                    <v-tab :disabled="model.isLoading||!action.can('relation_bankaccount.open')">Bankrekeningen</v-tab>
                    <v-tab :disabled="model.isLoading||!action.can('relation_address.open')">Adressen</v-tab>
                    <v-tab :disabled="model.isLoading||!action.can('relation_contact.open')">Contactpersonen</v-tab>
                    <v-tab v-if="feature.canSalesInvoice||feature.canPurchase" :disabled="model.isNew||model.isLoading||(!action.can('salesinvoice.open')&&!!action.can('salesinvoice.open'))">Dossier</v-tab>
                </v-tabs>

            </template>
            <v-form ref="xform" lazy-validation>
                <v-tabs-items class="relation-tabs" v-model="dlg.tab">                
                    <v-tab-item class="restore-content-padding" transition="fade-transition" reverse-transition="fade-transition" >                        
                        <v-form ref="formHeader">
                            <RelHeader :model="model"/>
                        </v-form>
                    </v-tab-item>
                    <v-tab-item class="restore-content-padding" transition="fade-transition" reverse-transition="fade-transition">
                        <v-form ref="formSettings">
                                <RelSettings :model="model" :dlg="dlg"/>
                        </v-form>
                    </v-tab-item>
                    <v-tab-item transition="fade-transition" reverse-transition="fade-transition">
                        <v-form ref="formBankAccounts">
                            <RelBankAccounts :model="model"/>
                        </v-form>
                    </v-tab-item>
                    <v-tab-item transition="fade-transition" reverse-transition="fade-transition">
                            <RelAddresses :model="model" :dlg="dlg"/>
                    </v-tab-item>
                    <v-tab-item transition="fade-transition" reverse-transition="fade-transition">
                            <RelContacts :model="model" :dlg="dlg"/>
                    </v-tab-item>
                    <v-tab-item v-if="feature.canSalesInvoice||feature.canPurchase" transition="fade-transition" reverse-transition="fade-transition">
                        <v-tabs dense v-model="dossiertab">        
                            <v-tab v-if="feature.canPurchase" :disabled="model.isNew||model.isLoading||!action.can('purchaseinvoice.open')">Inkoopfacturen</v-tab>
                            <v-tab v-if="feature.canSalesInvoice" :disabled="model.isNew||model.isLoading||!action.can('salesinvoice.open')">Verkoopfacturen</v-tab>
                        </v-tabs>
                        <v-tabs-items class="relation-tabs" v-model="dossiertab">
                            <v-tab-item v-if="feature.canPurchase" transition="fade-transition" reverse-transition="fade-transition">
                                <RelPurchaseInvoices :model="model" :dlg="dlg"/>
                            </v-tab-item>
                            <v-tab-item v-if="feature.canSalesInvoice" transition="fade-transition" reverse-transition="fade-transition">
                                    <RelSalesInvoices :model="model" :dlg="dlg"/>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-tab-item>
                </v-tabs-items>                

        </v-form>

        <template v-slot:footer-left>
            <ActionButton sub dropdown v-if="dlg.tab==2">
                <template v-slot:dropdown >
                    <ActionMenuItem  action="relation_bankaccount.create"  @click="()=>onAddBankAccount()">Bankrekening</ActionMenuItem> 
                    <ActionMenuItem  action="relation_bankaccount.create"  @click="()=>onAddGBankAccount()">G-rekening</ActionMenuItem> 
                </template>
                Nieuwe bankrekening
            </ActionButton>

            <ActionButton v-else-if="dlg.tab==3"  sub action='relation_address.create'  @click="()=>onCreateAddress()"></ActionButton>
            <ActionButton v-else-if="dlg.tab==4"  sub action='relation_contact.create'  @click="()=>onCreateContact()"></ActionButton>


        </template>

        <DialogRelationBankAccount/>

    </Dialog>
</template>



<script setup>
    import useModel from '@/models/relation'
    import Dialog from '@shared/ui/dialogs/ActionDialog'

    import RelHeader from './TabRelationHeader';
    import RelSettings from './TabRelationSettings';
    import RelBankAccounts from './TabRelationBankAccounts';
    import RelAddresses from './TabRelationAddresses';
    import RelContacts from './TabRelationContacts';
    import RelSalesInvoices from './TabRelationSalesInvoices';
    import RelPurchaseInvoices from './TabRelationPurchaseInvoices';
    import ActionMenuItem from "@controls/menu/ActionMenuItem.vue";
    import ActionButton from '@controls/buttons/ActionButton'
    import { ref } from 'vue'
    import clsDialog from '@cls/clsDialog'
    import Constants from '@app/consts'
    import DialogRelationBankAccount from '@/ui/dialogs/relation/DialogRelationBankAccount'    
    import action from '@app/action'
    import appDlg from '@app/dlg'
    import feature from "@app/features";

    const id_optimit_type = Constants.optimit_types.relation;

    const formHeader = ref(null);     
    const formSettings = ref(null);
    const dossiertab = ref(0);
    
    let model = useModel();
    let dlg = ref(
        new clsDialog("relation", "Relatie", model, [formHeader,formSettings], {            
            setKeyOnOpen: true,
            icon: 'relations',
            maxWidth:'1200px', 

            showNote: true,            
            noteType: "relation_note",
            showAttachment: true,
            attachmentType: "relation_attachment",

            id_optimit_type: id_optimit_type, // required when notes and / or attachments are used.
        } )).value;  //TODO

    async function onAddBankAccount() {
        if (model.isNew) {
            if (await dlg.onSave(true)) { // Keep open
                appDlg.create('relation_bankaccount', {id_relation: model.id})
            }
        } else {
            appDlg.create('relation_bankaccount', {id_relation: model.id})
        }

    }
    async function onAddGBankAccount() {
        if (model.isNew) {
            if (await dlg.onSave(true)) { // Keep open
                appDlg.create('relation_bankaccount', {id_relation: model.id, is_g_account: 1});
            }
        } else {
            appDlg.create('relation_bankaccount', {id_relation: model.id, is_g_account: 1});
        }
    }
    async function onCreateAddress() {
        if (model.isNew) {
            if (await dlg.onSave(true)) {
                appDlg.create('relation_address', {id_relation: model.id})
            }
        } else {
            appDlg.create('relation_address', {id_relation: model.id})
        }
    }
    async function onCreateContact() {
        if (model.isNew) {
            if (await dlg.onSave(true)) {
                appDlg.create('relation_contact', {id_relation: model.id})
            }
        } else {
            appDlg.create('relation_contact', {id_relation: model.id})
        }
    }

    window.mo = model;
    window.modlg = dlg;
</script>
