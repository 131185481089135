import { clsModel, fnCreate } from '@cls/clsModel'
import { paymentorders as api, purchaseinvoices as piApi } from '@/app/api'
import Constants from '@app/consts'
import {company} from '@app/settings';
import string from '@lib/string'
import {date} from '@lib/date'
import bool from '@shared/lib/bool'
import http from '@app/http'

var modelName = "paymentorder";
const id_optimit_type = Constants.optimit_types.paymentorder;
const fields = [
    'id'         , 
    'po_number'  , 
    'description', 
    'split_g'    , 
    'execution'  , 
    "iban"       , 
    "ibang"      , 
    "status"     , 
    "lines"      , 
];


class clsPaymentLine {

    hasChildren          = false
    cntChildren          = 0
    expanded             = false;
    noMenu               = false;
    noCheckbox           = false;
    isChild              = false;
    checked              = false

    id                   = null; 
    id_purchase_invoice  = null; 
    rel_name             = null; 
    invoicenr            = null; 
    total_payable        = null; 
    total_excl           = null; 
    total_vat            = null; 
    discount             = null; 
    apply_discount       = null; 
    cr_days              = null; 
    invoicedate          = null; 
    invoiceduedate       = null; 
    iban                 = null; 
    ibang                = null; 
    g_amount             = null; 

    constructor(line, isChild) {
        line = line ||{}
        this.hasChildren          = line.lines && line.lines.length>0
        this.cntChildren          = line.lines && line.lines.length
        this.expanded             = false;
        this.noMenu               = isChild;
        this.noCheckbox           = isChild;
        this.isChild              = isChild;
        this.checked              = false

        this.id                   = line.id; 
        this.id_purchase_invoice  = line.id_purchase_invoice; 
        this.rel_name             = line.rel_name; 
        this.invoicenr            = line.invoicenr; 
        this.total_payable        = line.total_payable; 
        this.total_excl           = line.total_excl; 
        this.total_vat            = line.total_vat; 
        this.discount             = line.discount; 
        this.apply_discount       = line.apply_discount; 
        this.cr_days              = line.cr_days; 
        this.invoicedate          = line.invoicedate; 
        this.invoiceduedate       = line.invoiceduedate; 
        this.iban                 = line.iban; 
        this.ibang                = line.ibang; 
        this.g_amount             = line.g_amount; 
    }

    get applied_discount() {
        let discount = this.discount;
        if (this.isPurchaseInvoiceLine) {
            if (!this.apply_discount) {
                discount = 0;
            }
        }
        return Number(discount);
    }
    get total_minus_discount() {
        return Number(this.total_payable) - Number(this.applied_discount);
    }
    get isPurchaseInvoiceLine() {
        return !!this.id_purchase_invoice;
    }
    get hasDiscount() {
        return this.applied_discount !=0
    }
    get isDiscountLate() {
        if (!this.cr_days) {
            return true;
        }
        // diff invoicedate with the current date.
        // E.g. date.dayDiff('2023-10-10', '2023-10-15') => -5
        let days = date.dayDiff(this.invoicedate);
        return days < (-1 *this.cr_days);
    }

    /**
     * Download the current invoice in PDF format.
     * @returns 
     */
    async downloadAsPdf() {
        if (!this.id_purchase_invoice) {
            throw new "Deze regel is geen inkoopfactuur";
        }
        return piApi.downloadData(`download/${this.id_purchase_invoice}`, true);  
    }
    
}

class clsPaymentOrder extends clsModel {

    description  = null;
    _split_g     = null;
    execution    = null;
    iban         = null;
    ibang        = null;
    status       = null;
    po_number    = null;
    total_amount = 0;
    _lines       = [];

    // boolean fields
    get split_g()      { return this.bool(this._split_g);  }
    set split_g(value) { this._split_g = this.bool(value); }
    
    get disabled() {
        return super.disabled || this.isDownloaded;
    }
    get modelRep() {
        return string.concat(" / ", this.po_number, this.description);         
    }

    get statusRep() {
        switch (string.lower(this.status)) {
            case "new"      : return "Nieuw";
            case "pending"  : return "Onderweg";
            case "paid"     : return "Betaald";
        }
        return "-";
    }
    get isPaid() {
        return string.compare(this.status, 'paid')
    }
    get isOpen() {
        return !this.status || string.compare(this.status, 'new')
    }
    get isPending() {
        return !this.status || string.compare(this.status, 'pending')
    }
    get isDownloaded() {
        return !this.isOpen;
    }
    // The lines must be checkable. Properties must be present on the object to be reactive.
    // Therefore, before assignign the lines, add the checked property. 
    get lines() {return this._lines}
    set lines(arr) { 
        let arrTmp = [];
        (arr||[]).forEach( (line) => {
            arrTmp.push(new clsPaymentLine(line, false));
            (line.lines||[]).forEach( (subline) => {
                arrTmp.push(new clsPaymentLine(subline, true));
            })
        })
        this._lines = arrTmp;
    }

    get purchaseInvoiceIDs() {
        let arr = [];
        (this.lines ||[]).forEach( (line) => {
            if (line.id_purchase_invoice) {
                arr.push(line.id_purchase_invoice);
            }
        })
        return arr;
    }
    get total() {
        return (this._lines||[]).filter( (l)=>!l.hasChildren).reduce( (accumulator, line) => accumulator + (Number(line.total_payable)||0),0)
    }
    get total_discount() {
        let t = 0;
        (this._lines||[]).forEach( (l) => {
            if (!l.hasChildren) {
                if(bool.isTrue(l.apply_discount) && Number(l.discount)) {
                    t = t + Number(l.discount)
                }                
            }
        });
        return t;
    }
        
    get total_minus_discount() {
        return this.total - this.total_discount;
    }

    get hasDiscount() {
        return !!this.total_discount;
    }
    get total_g() {
        return (this._lines||[]).filter( (l)=>!l.hasChildren).reduce( (accumulator, line) => accumulator + (Number(line.g_amount)||0),0)
    }
    get total_vat() {
        return (this._lines||[]).filter( (l)=>!l.hasChildren).reduce( (accumulator, line) => accumulator + (Number(line.total_vat)||0),0)
    }
    get total_excl() {
        return (this._lines||[]).filter( (l)=>!l.hasChildren).reduce( (accumulator, line) => accumulator + (Number(line.total_excl)||0),0)        
    }

    toggleLine(parentLine) {
        if (!parentLine || !parentLine.id) {
            return;
        }
        this._lines.forEach( (line) => {
            if (line.id == parentLine.id) {
                line.expanded = !line.expanded;
            }
        })
    }
    async toggleDiscount(line) {        
        if (this.disabled) {
            return; // No.
        }
        var self = this;
        function updateLine(updatedData) {

            let foundLine = null; 
            // line is a purchase invoice line, which may be a child of the combined payment line
            if (updatedData.id_purchase_invoice) {
                foundLine = self.lines.find( (x) => x.id_purchase_invoice == updatedData.id_purchase_invoice);
            // else, the line is a parent of a set of combined purchase invoices. It has no purchase invoice id.
            } else {
                foundLine = self.lines.find( (x) => x.id == updatedData.id && !x.id_purchase_invoice);
            }
            if (!foundLine) {
                return;
            }
            foundLine.apply_discount = updatedData.apply_discount;
            foundLine.discount = updatedData.discount;
        }

        try {
            this.isLoading = true;
            let result = null;
            if (line.apply_discount) {
                result = await api.removeDiscount(this.id, line.id_purchase_invoice);

            } else {
                result = await api.applyDiscount(this.id, line.id_purchase_invoice);
            }

            // We only need to refresh the affected fields in the affected sublines (when it is a combined payment line).            
            let affectedLine = (result.data.lines||[]).find( (resultLine) => resultLine.id == line.id);
            if (!affectedLine) {
                return; // Something failed already before, we should already encounter an exceptio.
            }
            // Update the parent line plus sublines.
            let affectedLines = [affectedLine, ...(affectedLine.lines||[])];
            affectedLines.forEach( (subLine) => {
                updateLine(subLine);
            })
        } 
        finally {
            this.isLoading = false;
        }

    }

    /**
     * Add purchase invoice lines to this payment order 
     * @param {} lines - selected purchase invoices. We'll extract the ids. 
     */
    async addLines(lines) {

        const arrIds = (lines||[]).map(x => x.id);
        try {
            this.isLoading = true;
            let result = await api.addLines(this.id, arrIds);
            this.fill(result.data);
        } 
        finally {
            this.isLoading = false;
        }
    }

    /**
     * Remove specific lines from the payment order
     * @param {} lines - array with selected payment ids. 
     */
    async removeLines(arrIds) {

        try {
            this.isLoading = true;
            let result = await api.removeLines(this.id, arrIds);
            this.fill(result.data);
        } 
        finally {
            this.isLoading = false;
        }
    }

    /**
     * Combine specific lines in a payment order - e.g. to cancel a credit amount against a debit invoice
     * @param {} lines - array with selected payment ids. 
     */
    async combineLines(arrIds) {

        try {
            this.isLoading = true;
            let result = await api.combineLines(this.id, arrIds);
            this.fill(result.data);
        } 
        finally {
            this.isLoading = false;
        }
    }

    /**
     * Split a combined line back in individual lines
     * @param {} lines - array with selected payment ids. 
     */
    async splitLine(id) {

        try {
            this.isLoading = true;
            let result = await api.splitLine(this.id, id);
            this.fill(result.data);
        } 
        finally {
            this.isLoading = false;
        }
    }

    /**
     * Make sure that the vendors array is always present, frontend probably depends on array type. 
     * @param {} data 
     * @returns 
     */
    fill(data) {
        data = data ||{};
        let arrIbans = company.getIbanArray();
        if (!data.iban) {
            if (arrIbans && arrIbans.length == 1) {
                data.iban = arrIbans[0]; // set the default iban.
            }    
        }
        if (!data.ibang) {
            data.ibang = company.ibang; // set the g iban (can be empty).
        }
        if (!data.execution) {
            data.execution = date.iso.today();            
        }
        data.status = data.status ||'new';
        return super.fill(data);
    }

    constructor() {
        super({
          api: api,   
          modelName: modelName, 
          mandatoryFields: ['description', 'execution', 'iban'],
          id_optimit_type: id_optimit_type, 

          fillable: fields
        })
    } 

    async download() {
        this.isDataLoading = true;
        try {
            let {data} = await api.download(this.id);  
            http.downloadRawDataToClient(data.xml, "text/xml", `${this.description}.xml`);
            let changed = this.status != data.status;
            this.status = data.status;
            if (changed) {
                this.sendSavedEvent();
            }
        } finally {
            this.isDataLoading = false;
        }
    }
    async downloadG() {
        this.isDataLoading = true;
        try {
            let {data} = await api.downloadG(this.id);  
            http.downloadRawDataToClient(data.xml, "text/xml", `G-${this.description}.xml`);
            let changed = this.status != data.status;
            this.status = data.status;
            if (changed) {
                this.sendSavedEvent();
            }
        } finally {
            this.isDataLoading = false;
        }
    }
    async setPaid() {
        this.isDataLoading = true;
        try {
            let {data} = await api.setPaid(this.id);  
            let changed = this.status != data.status;
            this.status = data.status;
            if (changed) {
                this.sendSavedEvent();
            }
        } finally {
            this.isDataLoading = false;
        }
    }
    async setConcept() {
        this.isDataLoading = true;
        try {
            let {data} = await api.setOpen(this.id);  
            let changed = this.status != data.status;
            this.status = data.status;
            if (changed) {
                this.sendSavedEvent();
            }
        } finally {
            this.isDataLoading = false;
        }
    }
    async downloadWarnings() {
        await api.downloadWarnings(this.id);
    }
    // For exporting to Excel, we only want to export 'real' lines, that is no combined lines.
    // Otherwise the details are not clear.
    getLinesForExcel() {
        return this.lines.filter( (line) => !line.hasChildren)
    }
 }
 export default fnCreate(clsPaymentOrder , 'clsPaymentOrder');
