<template>

    <Dialog :dialog="dlg">
        <template v-slot:title>{{dlg.title}}</template>

        <v-form ref="form" lazy-validation>
            <div v-html="dlg.body"></div>
            <Form>
                <FormRow :label="dlg.label">
                    <TextField v-model='value' :placeholder="dlg.label"></TextField>
                </FormRow>
            </Form>
        </v-form>

    </Dialog>

</template>

<script setup>

    import Dialog from '@shared/ui/dialogs/ActionDialog'
    import clsDialog from '@cls/clsDialog'
    import eventbus from '@app/eventbus'
    import Form from '@controls/forms/Form'
    import FormRow from '@controls/forms/FormRow'
    import TextField from '@controls/input/TextField'
    
    import { ref, onMounted, onUnmounted } from 'vue'

    let value = ref('');

    /**
     * For an alert dialog, we do not need a model. Just use the dialog.
     */
    class clsPromptDialog extends clsDialog {
        label = "";
        title="";
        bodyHtml="";

        onOpen(params) {
            this.onCreate(params);
        }
        onCreate(params) {
            params = params || {};
            if (undefined == params.value) {
                value.value = "";
            } else {
                value.value = params.value;
            }
            
            this.label = params.label || "Waarde";
            this.title = params.title || "Voer waarde in";
            this.body  = params.body || "";
            this.open  = true;
        }
        /**
         * Instead of open on the eventbus.dialog.open event, use the dialog event. It is for backward compatibillity.
         */
        registerEvents() {
            var self = this;
            this.evtRegistrations.push(
                eventbus.dialog.prompt.onPromised( (fnResolve, fnReject, modelName, params) => {    

                    this.fnReject      = fnReject;
                    this.fnResolve     = fnResolve;
                    self.onOpen(params);
                })
            )
        }     
        // on save, we just return the value.
        async onSave(bKeepOpen) {
            if (this.fnResolve) {
                this.fnResolve(value.value);
            } else {
                console.error("Unpromised call to prompt is not resolved.");
            }
            this.closeDialog();
        }
    };

    let dlg = ref (new clsPromptDialog("prompt", "Voer waarde in", {}, [], {
        icon: 'edit', save: "Ga door", noSubTitle: true
    }));

</script>