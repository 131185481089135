import clsSettings from '@cls/clsSettings'
import string from '@lib/string'

var modelName="company"
const fields = [ 'cpy_name', 'cpy_name_short', 'cpy_code', 'cpy_kvk', 'cpy_email', 
                 'cpy_website', 'cpy_phone', 'cpy_fax', 'cpy_vatnumber', 'cpy_email_frombox', 
                 'cpy_email_frombox_name', 'cpy_email_replyto', 'cpy_note', 'cpy_email_alias', 'iban1', 'iban2', 'ibang',
                'hasMollie'];

class clsCompanySettings extends clsSettings {

    cpy_name               = null
    cpy_name_short         = null
    cpy_code               = null
    cpy_kvk                = null
    cpy_email              = null
    cpy_website            = null
    cpy_phone              = null
    cpy_fax                = null
    cpy_vatnumber          = null
    cpy_email_frombox      = null
    cpy_email_frombox_name = null
    cpy_email_replyto      = null
    cpy_note               = null
    cpy_email_alias        = null
    iban1                  = null
    iban2                  = null
    ibang                  = null
    hasMollie              = false

    constructor() {
        super({modelName: modelName});
    }
    
    fill(data) {
        data = data ||{};
        data.hasMollie = !!data.hasMollie;
        fields.forEach( (field) => {
            this[field]=data[field];
        })
    }

    /**
     * return the ibans in array format (not the g account)
     * @returns 
     */
    getIbanArray() {
        let arr = [];

        if (string.isNotEmpty(this.iban1)) {
            arr.push(this.iban1);
        }
        if (string.isNotEmpty(this.iban2)) {
            arr.push(this.iban2);
        }
        return arr;
    }
    toJSON() {
        return this.propsToJSON(fields);
    }

}

export default clsCompanySettings;
