<script setup>
    import { datetime } from "@lib/date";

    const props = defineProps({
        value: {type: [String, Number], default: null},
    })
    var stamp = "";
    if (Number(props.value)) {
        var st = props.value;
        if (`${st}`.length < 12) {
            st = st * 1000;
        }
        stamp = datetime.fmt.local(Number(st));    
    }
</script>

<template>

    <span>{{stamp}}</span>

</template>
