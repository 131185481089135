<template>

    <v-card flat class="rich-text-editort rich-text-editor-line wysiwyg-editor"  :class="{'has-focus': isFocused, 'no-border': noBorder, 'is-disabled': compDisabled}">

    <v-card-text class="wysiwyg-content pa-0">            
    
    <bubble-menu v-if="editor && !compDisabled"
      class="bubble-menu"
      sctippy-options="{ duration: 100 }"
      :editor="editor"
      :shouldShow="()=>isFocused"
    >    
            <slot name="toolbar-left" v-bind:editor="editor"></slot>

            <v-btn tabindex="-1" small icon v-for="(cmd, index) in commands" :disabled="compDisabled" :key="index" @click="() => cmd.fn(editor)">
                <Icon small color="#000" class="custom-color" :class="{ 'text-primary': editor && editor.isActive(cmd.name) }"  :type="cmd.icon"></Icon>
            </v-btn>
            <v-spacer v-if="variables.length"></v-spacer>
            <v-menu offset-y v-if="variables.length">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn tabindex="-1" class="mr-4" depressed icon v-bind="attrs" v-on="on"  :disabled="compDisabled" title="Voeg een variabele in">
                        <Icon type="brew"></Icon>
                    </v-btn>
                </template>
                <v-list dense>
                    <v-list-item
                        v-for="(item, index) in variables"
                        :key="index"
                        link
                    >
                    <v-list-item-title @click="() => editor.chain().focus().insertContent(` ${item.value} `).run()">{{ item.label }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu> 
            <slot name="toolbar-right"  v-bind:editor="editor"></slot>


    </bubble-menu>

    <editor-content class="edit-pane" :class="{ 'is-focused': isFocused, 'is-disabled': compDisabled}" :editor="editor">    
    </editor-content>    
</v-card-text>
    </v-card>
    
</template>

<script setup>
    // NOTE: 
    // If the menu must be visible, when positioned in a table, the overflow-y style attribute must be set to 'visible'.
    // E.g. 
    //     <td style="overflow-y:visible"><RichTextLine></RichTextLine></td>
    // or
    //     <td class="overflow-y"><RichTextLine></RichTextLine></td>
    // 

    import Icon from '@controls/icons/Icon'

    const commands = [
        {name: 'bold', icon: 'bold', fn: (editor) => {editor.chain().focus().toggleBold().run()}},
        {name: 'italic', icon: 'italic', fn: (editor) => {editor.chain().focus().toggleItalic().run()}},
        {name: 'bulletList', icon: 'bullet-list', fn: (editor) => {editor.chain().focus().toggleBulletList().run()}},
    ];
    
</script>

<script>
//   import RichTextLine from '@controls/input/RichTextLine'
//   <RichTextLine :model="model" placeholder="Omschrijving" v-model="model.note"></RichTextLine>

import { Editor, EditorContent, FloatingMenu, BubbleMenu } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'

export default {
    components: {
        EditorContent,
        FloatingMenu,
        BubbleMenu
    },

    props: {
        value: {
        type: String,
        default: '',
        },
        disabled: {
            type: [Boolean]
        },
        skeleton: {
            type: [Boolean]
        },
        xl: {
            type: [Boolean]
        },
        lg: {
            type: [Boolean]
        },
        md: {
            type: [Boolean]
        },
        sm: {
            type: [Boolean]
        },
        variables: {
            type: [Object, Array],
            default: () => []
        },
        noBorder: {
            type: [Boolean]
        },
        model: {
            type: [Object]
        }
    },

    data() {
        return {
            editor: null,
        }
    },
    computed: {
        classHeight: function() {
            var cls = "sm";
            if (this.xl) { cls = "xl"; }
            else if (this.lg) { cls = "lg"; }
            else if (this.md) { cls = "md"; }
            else if (this.sm) { cls = "sm"; }
            return `wysiwyg-${cls}`;
        },
        isFocused() {
            if (!this.editor) {
                return false; 
            }
            return this.editor.isFocused
        },
        compDisabled: function() {
            if (this.disabled) {
                return true;
            }
            if (this.model && this.model.disabled) {
                return true;
            }
            if (this.model && this.model.isDataLoading) {
                return true;
            }
            return false;
        }

    },
    watch: {
        value(value) {
            if (!this.editor) {
                return; // Skip in init phase.
            }
            // HTML
            const isSame = this.editor.getHTML() === value

            // JSON
            // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)

            if (isSame) {
                return
            }

            this.editor.commands.setContent(value, false)
        },
        'compDisabled': function(newVal, oldVal) {
            if (this.editor) {
                this.editor.setOptions({editable: !newVal});
            }
        },
    },

    mounted() {
        this.editor = new Editor({
            content: this.value,
            extensions: [
                StarterKit,

    //            FloatingMenu.configure({
    //                shouldShow: ({ editor, view, state, oldState }) => {
    //                    // show the floating within any paragraph
    //                    return true; // editor.isActive('paragraph')
    //                },
    //            })
            ],
            // editable: false,
            onUpdate: () => {
                // HTML

                this.$emit('input', this.editor.getHTML())

                // JSON
                // this.$emit('input', this.editor.getJSON())
            },
        })
//        var self = this;
//        this.editor.on('focus', ({ editor, event }) => {
//            self.hasFocus = true;
//            // setTimeout( () => {self.hasFocus = true;}, 10)
//        })
//
//        this.editor.on('blur', ({ editor, event }) => {
//            setTimeout( () => {self.hasFocus = false;}, 150)
//        })        
        window.leddie = this.editor;
    },

    beforeDestroy() {
        this.editor.destroy()
    },
}
</script>
