/**
 * 
 *   Usage: See clsList for examples, API and implementation notes. 
 * 
 */
import clsList from '@cls/clsList'

 
class clsProjectPhase extends clsList {
 
     /**
     * Get the final project phase
     * @param {} id 
     */
    endPhase() {
        return this.list.find( (v) => !!v.pp_is_endphase);
    }

     /**
     * Get the start project phase
     * @param {} id 
     */
    startPhase() {
        return this.list.find( (v) => !!v.pp_is_startphase);
    }
 }
 
export default clsProjectPhase
