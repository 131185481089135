<template>    

  <v-alert  class="mb-0"
        :icon="compIcon"
        :outlined="outlined"
        text
        :color="compColor">

        <div v-if="title" class="text-larger text-bold mb-2">
            {{ title }}
        </div>
        <div class="text-regular">
            <slot></slot>
        </div>
    </v-alert>
            
</template>

<script setup>
// import AlertArea from '@shared/ui/markup/AlertArea'
//
// Usage:
//  <AlertArea info icon>
//        No data found
//  </AlertArea>
//  <AlertArea error icon>
//        Something is really wrong
//  </AlertArea>
//  <AlertArea warning icon>
//        All data will be imported
//  </AlertArea>
//  <AlertArea success icon>
//        All data is imported
//  </AlertArea>
//  <AlertArea error icon title="Attention!">
//        If you proceed ... 
//  </AlertArea>
//  <AlertArea error icon>
//    <template v-slot:title>
//        <h1>Alert</h1>
//        <h2>Really</h2>
//    </template>        
//        If you proceed ... 
//  </AlertArea>
//
import {computed, ref} from 'vue'
const props = defineProps({
    title: {            // either prop or slot
        type: [String]
    },
    error: {
        type: [Boolean]
    },
    warning: {
        type: [Boolean]
    },
    success: {
        type: [Boolean]
    },    
    default: {
        type: [Boolean]
    },    
    primary: {
        type: [Boolean]
    },    
    info: { // info is same as primary
        type: [Boolean]
    },    
    iconError: {
        type: [Boolean]
    },
    iconInfo: {
        type: [Boolean]
    },
    iconWarning: {
        type: [Boolean]
    },
    iconSuccess: {
        type: [Boolean]
    },    
    default: {
        type: [Boolean]
    },    
    primary: {
        type: [Boolean]
    },    
    icon: {
        type: [Boolean],
        default: false
    },
    customIcon: {
        type: [String]
    },
    outlined: {
        type: [Boolean]
    },

})

const compColor = computed({
    get() {
        if (props.error) {            
            return 'error';
        } 
        if (props.success) {
            return 'success';
        } 
        if (props.primary) {
            return 'primary';
        } 
        if (props.warning) {
            return 'warning';
        } 
        if (props.default) {
            return 'default';
        } 
        if (props.info) {
            return 'primary';
        } 
        return 'primary'
    }
})

const compIcon = computed({
    get() {
        if (props.customIcon) {
            return props.customIcon;
        }
        // Most used values
        if (props.iconInfo) {
            return "fa-light fa-circle-info";
        }
        if (props.iconError) {
            return 'fa-light fa-triangle-exclamation';
        } 
        if (props.iconWarning) {
            return 'fa-light fa-triangle-exclamation';
        } 
        if (props.iconSuccess) {
            return 'fa-light fa-thumbs-up';
        } 
        // When icon not desired, null.
        if (!props.icon) {
            return null;
        }
        // Otherwise use defualt for type when applicable
        if (props.error) {            
            return 'fa-light fa-triangle-exclamation';
        } 
        if (props.success) {
            return 'fa-light fa-thumbs-up';
        } 
        if (props.warning) {
            return 'fa-light fa-triangle-exclamation';
        } 
        if (props.info) {
            return "fa-light fa-circle-info";
        } 
        if (props.primary) {
            return null; // no default icon
        } 
        if (props.default) {
            return null; // no default icon
        } 
        return null;
    }
})

</script>
