<template>
    <Dialog :dialog="dlg">
        
        <FileDrop :isLoading="dlg.isUploading" @filesSelected="(a,b,c,d) => dlg.onFilesSelected(a,b,c,d)">

        </FileDrop>


    </Dialog>
</template>



<script setup>
//    import useModel from '@/models/note'
    import FileDrop from '@controls/file/FileDrop'
    import Dialog from '@shared/ui/dialogs/ActionDialog'
    import Constants from '@app/consts'
    import eventbus from '@app/eventbus'
    import { attachments as api } from '@app/api'

    import { ref } from 'vue'
    import clsDialog from '@cls/clsDialog'

    class clsAttachmentsUploadDialog extends clsDialog {
        msg = ""
        isUploading = false;
        id_optimit_type = null; 
        id_entity = null;

        async closeDialog(force) {
            super.closeDialog(true);
        }

        onOpen(params) {
            this.id_optimit_type = params?.id_optimit_type;
            this.id_entity       = params?.id_entity;
            return super.onOpen(params);            
        }

        async onFilesSelected(fileList) {

            if (!fileList || !fileList.length) {
                return; // Just ignore.
            }

            let cnt = fileList.length;
            if (cnt > 4) {
                eventbus.dialog.alert({
                    title: "Te veel bestanden",
                    body:  "U kunt maximaal 4 bestanden tegelijk uploaden."});
                return;
            }

            let formData = new FormData();
            for (var n = 0; n < fileList.length; n++) {
                let file = fileList[n];

                if (file.size > Constants.limits.file_upload.bytes) {
                    eventbus.dialog.alert({
                        title: "Te groot bestand",
                        body:  `De maximale bestandsgrootte is ${Constants.limits.file_upload.text}.`});
                    return;
                }
                formData.append(`file${n}`, file);
            }
            formData.append("id_entity", this.id_entity);
            formData.append("id_optimit_type", this.id_optimit_type);      


            this.msg = `Bezig ${cnt} bestand${cnt==1?"":"en"} te uploaden`;
            this.isUploading = true;

            let self = this;
            
            try {
                var result = await api.upload(formData);
                let count = result && Number(result?.data?.count) || 0;
                
                if (this.fnResolve) {
                    this.fnResolve({count: count});
                }
                self.open = false;
            }
            catch (e) {
                self.closeDialog();
            }
            finally {
                self.isUploading = false;
            }
        }

    }

    // let model = useModel();
    let dlg = ref(
        new clsAttachmentsUploadDialog("attachment_upload", "Upload bestanden", null, null, {
            save: null,
            icon: 'upload'
        })).value;


    window.dlgau = dlg;


</script>
