import { clsModel, fnCreate } from '@cls/clsModel'
import { tenders as api } from '@/app/api'
import dlg from '@app/dlg'

// We operate on a sales invoice, no special model name for sending an invoice. 
var modelName = "tender_replace";
const fields = ['id', 'actualize', 'replace_original', 'tend_number'];

class clsTenderReplace extends clsModel {

    // Replace: create a new version of a tender
    // Not replace: copy the tender (and parhaps, replace the original based on field value.)
    bReplace = false;

    id    = null;
    actualize    = true;
    replace_original = true;
    tend_number  = null;

    get subTitle() {
        return this.tend_number;
    }

    // We don't load. Just fill default values.
    async load(id, params) {
        return this.fill(params);
    }
    /**
     * The default api save implementation just calls save on the api.
     * Override this method for example to call another save method.
     */
    apiSave(data) {
        return this.api.copy(data);
    }    

    /**
     * Do not fill after save as we just want to pass the id of the cloned item to the caller 
     */
    fillAfterSave() {
        return false;
    }
    
    fill(data) {
        data = data || {};
        data.actualize        = true;
        data.replace_original  = true;
        return super.fill(data);
    }
    
    constructor() {
        super({
          api: api,   
          modelName: modelName, 

          fillable: fields
        })
    } 
        
 }
 export default fnCreate(clsTenderReplace, 'clsTenderReplace');
