import clsApi from '@cls/clsApi'

class clsWorkorderApi extends clsApi {

    loadStats() {
        return this.get(`stats`); 
    }
    async loadEmployees(date) {
        return this.post(`loademployees`, {date: date}); 
    }
    async loadUnassignedWorkorders(year, week, filter) {
        return this.post(`loadunassignedworkorders`, {year: year, week: week, filter: filter}); 
    }
    async loadWeekPlanning(year, week) {
        return this.post(`loadweekplanning`, {year: year, week: week}); 
    }
    
    async getPdf(id) {
        return this.getRaw(`downloadpdf/{id}`, {id: id}, {hideErrors: true});  
    }
    /**
     * save an Appointment
     * This can be either a new appointment or a move.
     */
    saveAppointment(model) {
        return this.post(`appointment/save`, model); 
    }
    /**
     * cancel an Appointment
     */
    cancelAppointment(id) {
        return this.post(`appointment/cancel`, {id: id}); 
    }

}

export default clsWorkorderApi;
