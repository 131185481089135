<template>
    <Dialog :dialog="dlg">
        <v-form ref="form" lazy-validation>
            <Form>
                <FormRow label="Adres" >
                    <v-row dense >
                        <v-col class="col-9">
                            <TextField :model="model" max-length="255" placeholder="Straatnaam" :rules="model.rules.adr_street" v-model="model.adr_street"></TextField>
                        </v-col>            
                        <v-col class="col-3">
                            <TextField :model="model" max-length="45" placeholder="Nr" :rules="model.rules.adr_street_number" v-model="model.adr_street_number"></TextField>
                        </v-col>            
                    </v-row>
                </FormRow>
                <FormRow>
                    <v-row dense>
                        <v-col class="col-4">
                            <TextField :model="model" max-length="45" placeholder="Postcode" :rules="model.rules.adr_zipcode" v-model="model.adr_zipcode"></TextField>
                        </v-col>            
                        <v-col class="col-8">
                            <TextField :model="model" max-length="255" placeholder="Plaats" :rules="model.rules.adr_city" v-model="model.adr_city"></TextField>
                        </v-col>            
                    </v-row>
                </FormRow>
                <FormRow>            
                    <CountryCombo :model="model" :rules="model.rules.country_code" v-model="model.country_code"></CountryCombo>
                </FormRow>

                <FormRow col-label="9" col-value-right label="Aflever adres">            
                    <Checkbox :model="model" class="d-inline-block width-auto" switcher v-model="model.is_delivery_address"></Checkbox>
                </FormRow>

                <FormRow col-label="9" col-value-right label="Ophaal adres">      
                    <Checkbox :model="model" class="d-inline-block width-auto" switcher  v-model="model.is_pickup_address"></Checkbox>
                </FormRow>
            </Form>
        </v-form>

    </Dialog>
</template>



<script setup>
    import useModel from '@/models/relationaddress'
    import Form from '@controls/forms/Form'
    import FormColumn from '@controls/forms/FormColumn'
    import FormRow from '@controls/forms/FormRow'
    import TextField from '@controls/input/TextField'
    import Dialog from '@shared/ui/dialogs/ActionDialog'
    import CountryCombo from '@controls/combo/Country'
    import Checkbox from "@controls/check/Checkbox.vue";

    import { ref } from 'vue'
    import clsDialog from '@cls/clsDialog'

    const form = ref(null); 

    let model = useModel();
    let dlg = ref(
        new clsDialog("relation_address", "Adres", model, [form], {
            icon: 'location'
        })).value;

    window.dra = dlg;
</script>
