<template>
    <Dialog :dialog="dlg">
        <v-form ref="form" lazy-validation>
            <Form>
                <FormRow label="Naam">
                    
                    <TextField dusk="name" :model="model" :rules="model.rules.mandatory" placeholder="Naam" v-model="model.ht_name"></TextField>

                </FormRow>
                <FormRow label="Projectgebonden">            
                    <Checkbox switcher :model="model" v-model="model.ht_project"></Checkbox>
                </FormRow>
            </Form>
        </v-form>

    </Dialog>
</template>



<script setup>
    import useModel from '@/models/hours/hourtype'
    import Form from '@controls/forms/Form'
    import FormRow from '@controls/forms/FormRow'
    import TextField from '@controls/input/TextField'
    import Dialog from '@shared/ui/dialogs/ActionDialog'
    import Checkbox from '@controls/check/Checkbox.vue'

    import { ref } from 'vue'
    import clsDialog from '@cls/clsDialog'

    const form = ref(null); 

    let model = useModel();
    let dlg = ref(
        new clsDialog("hourtype", "Urentypering", model, [form], {
            icon: 'hours'
        })).value;


</script>
