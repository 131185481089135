<template>
    <div>
    <v-simple-table :dense="dt.dense" fixed-header :height="dt.height" class="table-local" :class="{'no-hover': noHover, 'editable': editable, 'checkboxed': dt.checkboxed, 'disabled': dt.model.disabled||disabled}">
        <template v-slot:default>
        <thead v-if="!dt.hideHeader">
            <tr>
                <th v-if="hasCmd" class="hdr-cmd">
                    <slot name="header.cmd" :datatable="dt">                        
                        <Checkbox class="mt-0 ml-0" v-if="dt.checkboxed" v-model="dt.stateCheckAll" @click="dt.onFollowCheckAll()"></Checkbox>
                        <span v-else-if="!dt.checkboxed ">

                            <span @click="()=>dt.configure()" class="hdr-cmd cursor-pointer ml-2" >
                                <Icon class="" small type="setting"></Icon>
                            </span>

                        </span>

                    </slot>
                </th>

                <th v-for="(header, i) in columnHeaders" :key="i" :class="header.class">
                    <slot :name="columnHeaderName(header)" :datatable="dt">
                        {{header.text}}
                    </slot>
                </th>
            </tr>
        </thead>
        <tbody v-if="!compItems?.length">
            <tr >
                <td :colspan="dt.headers.length" class="pt-1 no-data-text text-italic text-center text-small">{{dt.noDataText}}</td>
            </tr>
        </tbody>

        <draggable :disabled="!canDrag" v-model="compItems" handle=".draghandle" ghost-class="ghost" animation="200" tag="tbody">            
        <!--
          Can not add a transition-group here as it adds a span element which invalidates the dom table.
        -->
            <template v-for="(item,i) in compItems" >    
                                
                <!--
                Template the row slot if you want to implement the complete row yourselves.
                -->
                <slot name="row" :hasCmd="hasCmd" :index="i" :item="item" :dt="dt" :columnHeaders="columnHeaders">

                    <tr :key="i" v-if="!(item.isChild && (item.expanded === false))">        
                    <td v-if="hasCmd" class="col-cmd" >       
                                
                        <Checkbox v-if="!item.noCheckbox && dt.checkboxed" v-model="item.checked"></Checkbox>
                        <IconMenu v-else-if="!item.noMenu && !dt.noMenu"> 
                            <slot name="menu" :item="item" :datatable="dt"></slot>
                        </IconMenu>
                        <slot v-else name="menucmd" :item="item" :datatable="dt"></slot>
                    </td>

                    <td v-for="(header, index) in columnHeaders" :key="index" :class="header.cellClass">
                        <span :class="header.fnClass?(header.fnClass(item[header.value], `${item[header.value]}`.toUpperCase(), item)):header.innerCellClass" :key="index">
                        <span v-if="header.fmt">
                            {{ header.fmt(item[header.value], `${item[header.value]}`.toUpperCase(), item)}}
                        </span>
                        <span v-else-if="header.type=='drag'">                            
                            <Icon class="cursor-move-y" type="drag"></Icon>
                        </span>
                        <span v-else-if="header.type=='amount'">
                            <AmountColumn :value="item[header.value]"></AmountColumn>
                        </span>
                        <span v-else-if="header.type=='date'">
                            <DateTimeText :value="item[header.value]"></DateTimeText>
                        </span>
                        <span v-else-if="header.type=='datetime'">
                            <DateTimeText time :value="item[header.value]"></DateTimeText>
                        </span>
                        <span v-else-if="header.open" >
                            <span class="cursor-pointer primary--text" :color="'primary'" @click="onOpen(header, item)">
                                {{ coalesce(item, header.value, header.alternateText) }}
                            </span>
                        </span>

                        <slot v-else :name="columnItemName(header)" :item="item" :datatable="dt">
                            {{item[header.value]}}
                        </slot>
                        </span>
                    </td>
                </tr>
                </slot>
            </template>
        </draggable>  
        <slot name="tfoot" :hasCmd="hasCmd" :dt="dt" :columnHeaders="columnHeaders"></slot>
        <tfoot v-if="dt.showFooter && (!dt.minimalFooterRows || (dt.items && dt.items.length >= dt.minimalFooterRows))">
            <tr>
                <td v-if="hasCmd" class="cmd pl-0 pr-4">
                    <slot name="footer.cmd" :datatable="dt">                        
                    </slot>
                </td>

                <td v-for="(header, i) in columnHeaders" :key="i" :class="header.clsFooter" :style="headerStyle(header)">
                    <slot :name="columnFooterName(header)" :datatable="dt">
                        
                    </slot>
                </td>
            </tr>
        </tfoot>


        </template>
        </v-simple-table>
        
        <slot name="commands" :hasCmd="hasCmd" :dt="dt" :columnHeaders="columnHeaders"></slot>

        <CommandPane v-if="dt.useCommandPane && dt.checkboxed" :selectedcount="dt.selectedCount" @close="dt.onStopCheckbox()">
            <template v-slot:commands>
                <slot name="commands" :dt="dt" :bulkAction="dt.bulkAction" :selectedCount="dt.selectedCount">
                    <ActionButton main primary icon="remove" v-if="dt.bulkAction=='remove'"  @click="dt.onBulkRemove()" :disabled="!dt.selectedCount" >Verwijder</ActionButton>
                    <ActionButton main primary icon="archive" v-if="dt.bulkAction=='archive'"  @click="dt.onBulkArchive()" :disabled="!dt.selectedCount" >Archiveer</ActionButton>
                    <ActionButton main primary icon="unarchive" v-if="dt.bulkAction=='unarchive'"  @click="dt.onBulkUnArchive()" :disabled="!dt.selectedCount" >Maak actueel</ActionButton>
                    <slot name="customcommands" :bulkAction="dt.bulkAction" :selectedCount="dt.selectedCount">
                    </slot>
                </slot>
            </template>
        </CommandPane> 

    </div>
</template>

<script setup>
    
    import string from '@lib/string'
    import {computed} from 'vue'
    import IconMenu from "@controls/menu/IconMenu.vue";
    import AmountColumn from '@datatable/columns/Amount'
    import Icon from '@controls/icons/Icon'
    import DateTimeText from '@controls/text/DateTime'
    import Checkbox from '@controls/check/Checkbox.vue'
    import CommandPane from '@datatable/components/CommandPane'
    import ActionButton from '@controls/buttons/ActionButton'
    import draggable from 'vuedraggable'
    import dlg from '@app/dlg'

    const props = defineProps({
        dt: {
            type: [Object]
        },
        items: {
            type: [Array]
        },
        noHover: {
            type: [Boolean]
        },
        // When specified, the table is draggable. 
        canDrag: {
            type: [Boolean]
        },
        editable: {
            type: [Boolean],
            default: false
        },
        disabled: {
            type: [Boolean],
            default: false
        },
        model: {
            type: [Object]
        },
        modelLinesProp: {
            type: [String]
        }
    })

    var compItems = computed({
        get() {
            if (props.model && props.modelLinesProp) {
                return props.model[props.modelLinesProp];
            }

            if (props.dt.items && props.dt.items.length) {
                return props.dt.items;
            }
            return [];
        },
        set(value) {
            if (props.model && props.modelLinesProp) {
                props.model[props.modelLinesProp] = value
            }
        }

    })
    var columnHeaders = computed({
        get() {
            return (props.dt.headers||[]).filter( (header) => header.type != "cmd")
        }
    })
    var hasCmd = computed({
        get() {
            return !!(props.dt.headers||[]).find( (header) => header.type == "cmd")
        }
    })  

    function columnHeaderName(header) {
        return `header.${header.value}`
    }
    function columnFooterName(header) {
        return `footer.${header.value}`
    }
    function columnItemName(header) {
        return `item.${header.value}`
    }
    function coalesce(item, field, alternateText) {
        return string.coalesce(item[field], alternateText);
    }
    function headerStyle(header) {
        let style = header.styleHeader || {};
        if (header.minw) {
            style.width=header.minw;
            style["min-width"]=header.minw;
        }
        if (header.w) { // width can be overridden
            style.width=header.w;
        }
        if (header.maxw) {
            style["max-width"]=header.maxw;
        }
        return style;
    }

    // When an open action is specified, either: 
    // - open the dialog with the item data as parameters
    // - open the dialog and get the parameters from a specified function
    // - call a custom function to open the dialog
    function onOpen(header, item) {
        if (!header.open) {
            return;
        }
        if (typeof(header.open) == 'function') {
            header.open(item, header);
            return;
        }
        var params = item;
        if (header.fnParamsOpen && typeof(header.fnParamsOpen) == 'function') {
            params = header.fnParamsOpen(item);
        }
        dlg.open(header.open, params);
    }

</script>