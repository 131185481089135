<template> 

    <v-list-item :value="value" class="cursor-pointer" inactive active-class="none" @click="onClick" :class="{'opacity-50': compDisabled}">
        <v-list-item-action v-if="!noIcon&&!iconRight&&compIcon" class="my-1 mr-1">
            <span class="icon"><Icon small class="btn-icon mr-2" :type="compIcon"></Icon></span>
        </v-list-item-action>
        <v-list-item-content >
            <v-list-item-title>
                <slot>{{compText}}</slot>
            </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action v-if="useCount">
            <NumberChip :count="count"></NumberChip>
        </v-list-item-action>
    </v-list-item>

</template>

<script setup>

/*

    Usage: 
    
        import ActionMenuItem from "@controls/menu/ActionMenuItem.vue";        
        <ActionMenuItem action="log_error.open" :payload="item"></ActionMenuItem>
        <ActionMenuItem icon="pdf" @click="onOpenPDF(item)">Open PDF</ActionMenuItem>

        
*/
import action from '@app/action'
import Icon from '@controls/icons/Icon'
import {computed} from 'vue'
import NumberChip from "@controls/widgets/NumberChip";

const props = defineProps({
    action: {
        type: [String],
        default: null
    },     
    disabled: {
        type: [Boolean],
        default: false
    },
    useCount:{
        type: Boolean,
        default: false,
    },
    count: {
        type: [String, Number],
        default: ""
    },
    payload: {
        type: [Object, Array, String, Number],
    },
    icon: {
        type: [String]
    },
    type: {
        type: [String],
        default: ""
    },
    noIcon: {
        type: [Boolean],
        default: false
    },
    iconRight: {
        type: [Boolean],
        default: false
    },
    noExecute: {
        type: [Boolean],
        default: false
    },
    model: {
        type: [Object]
    },
    value: {
        type: [String, Number],
        default: undefined
    }
})

const emit = defineEmits(['click', 'promise'])

const compText = computed({
    get() {
        if (props.action) {
            return action.textFor(props.action);
        }
        return "";
    }
});

const compIcon = computed({
    get() {
        if (props.noIcon) {
            return null;
        }
        if (props.icon) {
            return props.icon;
        }
        if (props.action) {
            return action.iconFor(props.action);
        }
        return null;
    }
});

const compDisabled = computed({
    get() {
        if (props.disabled) {
            return true;
        }
        if (props.model && props.model.disabled) {
            return true;
        }
        if (props.model && props.model.isDataLoading) {
            return true;
        }
        if (props.action) {
            return !action.can(props.action);
        }
        return false;
    }
});

async function onClick(evt) {    
    // When disabled, do nothing
    if (compDisabled.value) {
        return; // Do nothing.
    }
    emit('click', evt)
}
 
 
 
</script>