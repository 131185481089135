import { clsModel, fnCreate } from '@cls/clsModel'
import { project_extrawork as api } from '@/app/api'

var modelName = "project_additionalwork";
const fields = [
    'id', 
    'id_project',
    'pew_description',
    'pew_price',
    'pew_remark',
    'id_status',
    'id_project_chapter',    
];

class clsProjectExtraWork extends clsModel {

    id_project = null;
    pew_description = null;
    _pew_price = null;
    pew_remark = null;
    id_status = null;
    chapters = null;
    isExtra = true; // true: meerwerk, false: minderwerk 
    id_project_chapter = null;

    get title() {
        return this.isExtra ? "Meerwerk" : "Minderwerk";
    }
    get pew_price() {
        return Math.abs(this._pew_price);
    }
    set pew_price(v) {
        this._pew_price = v;
    }
    get useChapters() {
        return this.chapters && this.chapters.length;
    }

    get modelRep() {
        return this.pew_description;
    }    
    async load(id, params, extraData) {
        console.log('load', id, params, extraData)
        if (extraData && extraData.chapters) {
            this.chapters = extraData.chapters;
        } else {
            this.chapters = [];
        }
        return super.load(id, params, extraData);
    }

    async doCreate(defaults) {
        defaults = defaults ||{};
        super.doCreate(defaults);
        this.isExtra = (undefined === defaults.isExtra) ? true : !!defaults.isExtra;
    }

    fill(data, ignoreInitialState) {
        console.log('fill1', data.pew_price, data)     
        data.pew_price = data.pew_price ||0;
        super.fill(data, ignoreInitialState);   
        this.isExtra = this._pew_price >=0;
    }
    toJSON() {
        var json = super.toJSON();
        if (this.isExtra) {
            json.pew_price = Math.abs(json.pew_price);
        } else {
            json.pew_price = -Math.abs(json.pew_price);    
        }
        return json;
    }

    constructor() {
        super({
          api: api,   
          modelName: modelName, 
          fillable: fields
        })
    } 
        
 }
 export default fnCreate(clsProjectExtraWork , 'clsProjectExtraWork');
