import user from '@app/user'
import { clsModel, fnCreate } from '@cls/clsModel'
// import useModel from '@shared/models/clsConfirmPassword'; 
import noty from '@shared/lib/noty'

var modelName = "confirmpassword";
const fields = ['password'];

class clsConfirmPassword extends clsModel {

    password = null;
    
    async save() {
        var result = await user.confirmPassword(this.password);
        if (!result.success) {
            noty.snack.error(result.message);
            return false;
        }
    }
    async load() {
        this.password = null;
    }    
    constructor() {
        super({          
          modelName: modelName, 
          fillable: fields
        })
    } 
}

export default fnCreate(clsConfirmPassword , 'clsConfirmPassword');
