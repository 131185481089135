<template>
    <Dialog :dialog="dlg" :dt="dt">

        <template v-slot:filter="{onFocus}">

            <TextField @focus="onFocus" ref="q" dusk="select-search" block max-length="255" placeholder="Zoek..." v-model="dt.filter.q"></TextField>

        </template>
    </Dialog>
</template>



<script setup>

    import useDataTable from '@app/useDataTable'
    import TextField from '@controls/input/TextField'
    import Dialog from '@shared/ui/dialogs/SelectListDialog'
    import { tenders as api } from '@app/api'

    import { ref } from 'vue'
    import clsDialog from '@cls/clsSelectListDialog'

    const q = ref(null)

    var headers = [
    { type: 'cmd'},
      { type: 'flag', flagType: 'tender_flag'},
      { type: 'note', noteType: 'tender_note'},
      { visible: false, text: "Groep",                    value: "group",      },
      { text: "Status",                   value: "status",      },
      { text: "Offertenummer",            value: "tend_number",  fmt: (v, item) => ((v)? v : 'Concept')} ,
      { text: "Verkoper",                 value: "company_user_contact"} ,
      { text: "Klant",                    value: "rel_name"} ,
      { visible: false, text: "Telefoon",                 value: "rel_phone", type: 'phone' },
      { text: "Offertedatum",             value: "tend_date",    type: 'date'   },
      { visible: false, text: "Vervaldatum", value: "tend_exp_date", type: 'date'},
      { text: "Referentie",               value: "tend_reference", emptyText: '-'} ,
      { text: "Offerte titel",            value: "tend_name",        },
      { text: "Project",                  value: "pro_name", emptyText:'-' } ,

      { text: "Incl BTW",                 value: "tend_total",     type: 'amount'     },
      { text: "Excl BTW",                 value: "tend_sub_total",     type: 'amount'     },
      { text: "BTW",                      value: "tend_vat_total",      type: 'amount'     },

      { isUtc: true, text: "Gewijzigd",                value: "updated_at",    type: 'datetime',   visible: false},
      { text: "Gewijzigd door",           value: "updated_by",                        visible: false},
      { isUtc: true, text: "Aangemaakt",               value: "created_at",    type: 'datetime',   visible: false},
      { text: "Aangemaakt door",          value: "created_by",                        visible: false},

    ]

    var dt = useDataTable("dt_selecttender", "tender", api, headers, {
        actionDblClick:null,
        itemName: {
            prefix: 'de',
            single: 'offerte',
            plural: 'offertes',
        }, 
        useCommandPane: false,
        canArchive: false,
        filter: {
            type: '',
            selectfor: '',
        }
    })

    let dlg = ref(
        new clsDialog("select_tender", "Selecteer offerte", dt, {
            multiselect: false, 
            maxWidth:"1200px",
            defaultFocus: ()=>q.value
        })).value;

    window.stdlg = dlg;
</script>
