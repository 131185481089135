<template>
        <DrawerContent :drawer="model.tend_use_paragraphs">
            <template v-slot:drawer>
                
                <v-list dense class="y-draggable bg-transparent navigation-menu">
                    <v-list-item-group dense v-model="model.activeParagraph">

                        <draggable v-model="model.paragraphs" handle=".draghandle" ghost-class="ghost" animation="200">
                        <transition-group type="transition" :name="!drag ? 'flip-list' : null">
                            <v-list-item dense :class="{'draghandle':!model.disabled}" class=" cursor-move-y cursor-pointer menu-paragraph"  v-for="(paragraph, index) in model.paragraphs" :key="`p${index}`"  @click="onSelectParagraph()">
                                <v-list-item-icon class="mr-2">
                                    <span class="icon"><Icon type="drag"></Icon></span>
                                </v-list-item-icon>
                                <v-list-item-content >
                                    <v-list-item-title>
                                        {{paragraph.thp_name}}
                                    </v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action class="my-0" >
                                    <ActionButton action="tender.modify" :disabled="model.isOpenOrLater" :model="model" dusk="btn-remove-paragraph" 
                                        icon-button 
                                        icon="remove" 
                                        @click="()=>removeParagraph(paragraph)">

                                    </ActionButton>
                                </v-list-item-action>
                            </v-list-item>
                            </transition-group>
                        </draggable>  
                    </v-list-item-group>
                </v-list>
                <v-row dense>
                    <v-col>
                        <ActionButton :disabled="model.isOpenOrLater" class="mt-2 ml-5" link-button icon="add" action='tender.modify' @click="()=>model.addParagraph()">Nieuw hoofdstuk</ActionButton>
                    </v-col>
                    <v-col class="text-right col-auto mr-2 mt-2">
                        <span v-if="model.activeParagraph>=0&&model.paragraphs.length>1" @click="model.activeParagraph=-1" class="text-primary text-small cursor-pointer"> Toon alle</span>
                    </v-col>
                </v-row>

            </template>

        <Paragraph v-if="!model.tend_use_paragraphs" :model="model" :paragraph="model.paragraphs[0]"></Paragraph>
        <template v-else v-for="(paragraph, index) in model.paragraphs">
            <Paragraph v-if="model.activeParagraph<0||(model.activeParagraph===index)" :model="model" :key="index" :paragraph="paragraph"></Paragraph>
        </template>
        <Totals :model="model"/>
    </DrawerContent>
</template>


<script setup>
    import Checkbox from '@controls/check/Checkbox.vue'
    import Paragraph from './Paragraph'
    import ActionMenuItem from '@controls/menu/ActionMenuItem.vue'
    import ActionButton from '@controls/buttons/ActionButton'
    import Icon from '@controls/icons/Icon'
    import draggable from 'vuedraggable'
    import { ref } from 'vue'
    import Totals from './Totals'
    import noty from '@shared/lib/noty'
    import DrawerContent from '@shared/ui/dialogs/DrawerContent'

    const props = defineProps({
        model: {
            type: [Object]
        },
    })
    var drag = ref(false); 

    async function removeParagraph(paragraph) {
        try {
            await noty.confirm("Weet u zeker dat u dit hoofdstuk wilt verwijderen?");
        }
        catch (e) { return; } // Canceled
        props.model.removeParagraph(paragraph)
    }
    function onSelectParagraph(p) {

    }
</script>
