<template>
  <div>

  <v-dialog content-class="pdf-viewer-dialog" v-model="open" max-width="1100px" :scrollable="!false" persistent @keydown="onKeyDown">
        <v-card dense>
          <v-card-text dense class="mxt-4 pa-0">
            
            <slot>
            <v-card class="card-blxock container-viewer-outer pdf-viewer-component" outlined>        
            
            <div class="viewer-commands">
                <v-toolbar flat dense>
                    <slot name="cmd-left">
                        <span class="title">{{title}}</span>
                    </slot>
                    <v-spacer></v-spacer>

                    <v-btn icon class="mt-1x d-inline-block" @click="() => viewer.zoomIn()">
                        <Icon small type="zoom-in"></Icon>
                    </v-btn>
                    <v-btn icon class="mt-1x d-inline-block" @click="() => viewer.zoomOut()">
                        <Icon small type="zoom-out"></Icon>
                    </v-btn>
                    <v-btn icon class="mt-1x d-inline-block" @click="() => viewer.maximize()">
                        <Icon small type="enlarge"></Icon>
                    </v-btn>
                    <v-btn icon class="mt-1x d-inline-block" @click="() => viewer.crop()">
                        <Icon small type="shrink"></Icon>
                    </v-btn>
                    <v-btn icon class="mt-1x d-inline-block" @click="() => viewer.rotateLeft()">
                        <Icon small type="rotate-left"></Icon>
                    </v-btn>
                    <v-btn icon class="mt-1x d-inline-block" @click="() => viewer.rotateRight()">
                        <Icon small type="rotate-right"></Icon>
                    </v-btn>
                    <v-btn v-if="canDownload" icon class="mt-1x d-inline-block" @click="() => viewer.download()">
                        <Icon small type="download"></Icon>
                    </v-btn>
                    <v-btn icon class="ml-4 text-bold mt-1x d-inline-block" @click="() => open=false">
                        <Icon class="text-bold" color="#000"  type="close"></Icon>
                    </v-btn>

                </v-toolbar>                        

            <v-divider/>
            </div>            
            <div><v-progress-linear class="position-absolute" style="z-index:12;" v-if="isLoading" indeterminate color="primary"></v-progress-linear></div>
            <div id="viewerContainer" class="position-absolute container-viewer container-viewer-pdf pt-2" ref="container">

                <div id="viewer" class="pdfViewer" align="center"></div>

            </div>
                

        </v-card>

            </slot>

          </v-card-text>
        </v-card>

  </v-dialog>

  </div>
</template>

<script setup>

    //
    // import PDFViewer from '@shared/ui/dialogs/DialogPDF'
    //
    // eventbus.dialog.pdfviewer();
    //
    import string from '@lib/string'
    import clsPdfjs from '@cls/clsPdfjs'
    import Icon from '@controls/icons/Icon'


    import eventbus from '@app/eventbus'
    import ActionButton from '@shared/ui/controls/buttons/ActionButton'
    import {ref, onMounted, computed} from 'vue'

    var open = ref(false);
    var title = ref("PDF Weergave");
    var canDownload = ref(true);
    let container = ref();  // automagically loads the element with the container reference

    const isLoading = ref(false);
    let viewer = new clsPdfjs({callbackOnLoading: (value) => { isLoading.value = value;}});

    eventbus.dialog.pdfviewer.on( (params, fileName, options) => {
        open.value = true;
        title.value = fileName || 'PDF Weergave';
        options = options || {};

        canDownload.value = !options.noDownload;

        // The dialog is made visible, which makes the container visible. 
        // This also assignes the container reference. 
        // When the viewer is not created yet, use a timeout to give the vue engine
        // time to set the container element reference value.
        setTimeout( () => {
            viewer.create(container.value);
            viewer.loadData(params, fileName, options);
        }, 10)
    })

    function onKeyDown(evt, b, c) {
        if (evt && evt.key == "Escape") {
            open.value = false;
        }
    }

</script>
