import user from '@app/user'
import { clsModel, fnCreate } from '@cls/clsModel'
// import useModel from '@shared/models/clsChangePassword'; 
import noty from '@shared/lib/noty'

var modelName = "changepassword";
const fields = ['password', 'current_password', 'password_confirm'];

class clsChangePassword extends clsModel {

    current_password= '';
    password= '';
    password_confirm= '';

    async save() {
        var result = await user.updatePassword(this.current_password, this.password, this.password_confirm);
        if (!result.success) {
            noty.snack.error(result.message);
        } else {
            noty.snack.info(result.message);
        }
        return result.success;
    }
    async load() {
        this.current_password= '';
        this.password= '';
        this.password_confirm= '';
    }    
    constructor() {
        super({          
          modelName: modelName, 
          fillable: fields
        })
    } 
}

export default fnCreate(clsChangePassword , 'clsChangePassword');
