import user from '@app/user'
// @shared/models/clsSetup2FA.js
import { clsModel, fnCreate } from '@cls/clsModel'
import noty from '@shared/lib/noty'

var modelName = "setup2fa";
const fields = ['qrCode', 'authcode'];

class clsSetup2FA extends clsModel {

    qrCode =  null;
    authcode = null;
    
    async save() {
        var result = await user.confirmTwoFactor(this.authcode);
        if (!result.success) {
            noty.snack.error(result.message);
        } else {
            noty.snack.info(result.message);
        }
        return result.success;
    }

    async load() {
        this.authcode = '';
        this.qrCode = null;
        var result = await user.enableTwoFactor();
        if (result.success) {
            result = await user.getTwoFactorQR();
        }
        if (!result.success) 
        {
            console.error(result.message);
            noty.alert('Het is op dit moment niet mogelijk twee factor authenticatie in te stellen.\nProbeer het aub op een later moment opnieuw.');
            throw result.message;
        }
        this.qrCode = result.data.svg;
    }    
    constructor() {
        super({          
          modelName: modelName, 
          fillable: fields
        })
    } 
}
export default fnCreate(clsSetup2FA , 'clsSetup2FA');
