<template>
    <Dialog :dialog="dlg">
        <v-form ref="form" lazy-validation>
            
                <Form>
                    <FormRow label="Offerte">                    
                        <TenderPicker ref="focus" :fieldMap="tenderFieldMap" :filter="{'selectfor':'tender'}" :rules="model.rules.mandatory" :model="model" v-model="model.id_tender"></TenderPicker>
                    </FormRow>
                    <FormRow v-if="model.cnt_chapters>1" label="Hoofdstukken">
                        <v-menu xv-model="trigger.active" xoffset-Y bottom nudge-bottom="36" :close-on-content-click="false">
                        <template v-slot:activator="{ on }">
                            <span v-on="on">
                                <TextField :rules='model.rules.mandatory' placeholder="Selecteer hoodstukken" :model="model" v-model='model.repChapters'>
                                    <template v-slot:append>
                                        <Icon class="mt-1" type="dropdown"></Icon>
                                    </template>            
                                </TextField>
                            </span>
                        </template>
                        <v-card style="max-height:240px;overflow-y:auto;">
                            <v-card-text>
                                <v-simple-table style="width:100%'">
                                <tr v-for='(item, index) in model.chapters' :key='index'>
                                    <td style="width:40px;" class="py-2 pr-4">
                                        <Checkbox class=""  v-model="item.checked"></Checkbox>
                                    </td>
                                    <td class="cursor-pointer">
                                        <span class="py-2 pr-4" @click="()=>item.checked=!item.checked">{{ item.name }}</span>
                                    </td>
                                    <td class="text-right">
                                        <AmountText v-model='item.subtotal'/>    
                                    </td>
                                </tr>
                                </v-simple-table>
                            </v-card-text>
                        </v-card>
                        </v-menu>
                    </FormRow>
                    <FormRow label="Actualiseer prijzen" col-label="8" col-value-right subLabel="Neem meest actuele prijzen over van artikelen">                    
                        <Checkbox :model="model" class="ml-4" switcher v-model="model.actualize"></Checkbox>
                    </FormRow>
                </Form>
        </v-form>

    </Dialog>
</template>



<script setup>
    import useModel from '@/models/tender/importtender'
    import Form from '@controls/forms/Form'
    import FormRow from '@controls/forms/FormRow'
    import TextField from '@controls/input/TextField'
    import Dialog from '@shared/ui/dialogs/ActionDialog'
    import TenderPicker from '@controls/picker/Tender'
    import Checkbox from '@controls/check/Checkbox.vue'
    import AmountText from '@controls/text/Amount.vue'
    import Icon from '@controls/icons/Icon'    
    import { ref } from 'vue'
    import clsDialog from '@cls/clsDialog'

    // Our fields which come from projects. We want them overridden when a project is selected.
    let tenderFieldMap = {tend_number: 'tend_number', cnt_chapters: 'cnt_chapters'};

    const focus = ref(null)

    const form = ref(null); 
    let model = useModel();

    let dlg = ref(
        new clsDialog("tender_import", "Importeer offerte", model, [form], {
            icon: 'import',
            noSubTitle: true,
            defaultFocus: ()=>focus.value,
            save: 'Importeer'
        } )).value;

    window.itdlg = dlg;
</script>
