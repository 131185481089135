<template>
    <Dialog :dialog="dlg" no-content-padding>
            <template v-slot:tabs-left>
                <v-tabs dusk="tabs-project-chapter" dense v-model="dlg.tab">        
                    <v-tab dusk="tab-project-chapter-header">Gegevens</v-tab>
                    <v-tab dusk="tab-project-chapter-costs" :disabled="model.isLoading">Kostenbegroting
                        <TipMenu class="ml-2">
                            <p><h4>De kostenbegroting.</h4></p>
                            <p>In de kostenbegroting kunt u de de verwachte kosten specificeren. Dit is optioneel.</p> 
                            <p>Wanneer u verwachte kosten invult voor een project of een project hoofdstuk kunt u deze kosten in het project-dossier vergelijken met de werkelijk gemaakte kosten.</p> 
                            <p>Op deze manier kunt u signaleren of de werkelijke kosten overeenkomen met de begrote kosten</p> 
                        </TipMenu>
                    </v-tab>
                    <v-tab dusk="tab-project-chapter-hours" :disabled="model.isLoading">Urenbegroting
                        <TipMenu class="ml-2">
                            <p><h4>Urenbegroting.</h4></p>
                            <p>In de urenbegroting kunt u de de verwachte uren-kosten specificeren. Dit is optioneel.</p> 
                            <p>Wanneer u verwachte kosten invult voor een project of een project hoofdstuk kunt u deze kosten in het project-dossier vergelijken met de werkelijk gemaakte kosten.</p> 
                            <p>Op deze manier kunt u signaleren of de werkelijke kosten overeenkomen met de begrote kosten</p> 
                        </TipMenu>


                    </v-tab>
                </v-tabs>
            </template>

            <v-form xref="form" lazy-validation>
                <v-tabs-items class="project-chapter-tabs xmt-2" v-model="dlg.tab">                
                    <v-tab-item class="restore-content-padding" transition="none" reverse-transition="none">                        
                        <v-form ref="formHeader">
                            <Form>
                                <FormRow label="Naam">            
                                    <TextField :model="model" dusk="pro-ch-name" max-length="48" placeholder="Naam" :rules="model.rules.mandatory" v-model="model.name"></TextField>
                                </FormRow>
                                <FormRow label="Aanneemsom excl. BTW">
                                    <NumberField dusk="pro-ch-contract_sum" amount :model="model" v-model="model.contract_sum"></NumberField>
                                </FormRow>
                            </Form>
                        </v-form>
                    </v-tab-item>
                    <v-tab-item class="" transition="none" reverse-transition="none">
                        <v-form ref="formCosts">
                            <CostBudget :model="model"></CostBudget>
                        </v-form>
                    </v-tab-item>
                    <v-tab-item  class="" transition="none" reverse-transition="none">
                        <v-form ref="formHours">
                            <HourBudget :model="model"></HourBudget>
                        </v-form>
                    </v-tab-item>
                </v-tabs-items>
            </v-form>
    </Dialog>
</template>



<script setup>
    import useModel from '@/models/project/chapter'
    import Form from '@controls/forms/Form'
    import FormRow from '@controls/forms/FormRow'
    import TextField from '@controls/input/TextField'
    import Dialog from '@shared/ui/dialogs/ActionDialog'
    import NumberField from '@controls/input/NumberField'
    import TipMenu from "@controls/menu/TipMenu.vue";
    import CostBudget from './chapters/CostBudget'
    import HourBudget from './chapters/HourBudget'

    import { computed, ref } from 'vue'
    import clsDialog from '@cls/clsDialog'    

    const formHeader = ref(null); 
    const formHours = ref(null); 
    const formCosts = ref(null); 

    let model = useModel();
    let dlg = ref(
        new clsDialog("project_chapter", "Hoofdstuk", model, [formHeader, formHours, formCosts], {
            icon: 'chapter'
        } )).value;

    window.dlgpc = dlg;
</script>
