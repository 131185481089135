import { clsModel, fnCreate } from '@cls/clsModel'
import { salesorderterms as api } from '@/app/api'

var modelName = "salesorderterm";
const fields = [
    'id', 
    'ot_name',
    'lines',
];

class clsSalesOrderTerm extends clsModel {

    ot_name = null;
    lines = [];

    get modelRep() {
        return this.ot_name;
    }    

    get totalPct() {
        return this.lines.reduce( (acc, line) => acc+(line.otl_pct||0),0);
    }
    addLine() {
        this.lines.push( {
            otl_name : null,
            otl_pct  : 0
        });
    }
    removeLine(lineRemove) {
        this.lines = this.lines.filter( (line) => line != lineRemove);
    }
    fill(data) {
        data = data ||{};
        data.digital = true;
        data.lines = data.lines || [];
        return super.fill(data);
    }

    constructor() {
        super({
          api: api,   
          modelName: modelName, 

          fillable: fields
        })
    } 
        
 }
 export default fnCreate(clsSalesOrderTerm , 'clsSalesOrderTerm');
