import clsApi from '@cls/clsApi'

class clsSupportRequest extends clsApi {

    // Ignore the id.
    load() { 
        return this.get(`load`); 
    }

}

export default clsSupportRequest;
