import clsApi from '@cls/clsApi'

class clsUserApi extends clsApi {

    saveInvitation(model) { 
        return this.post(`invitation/save`, model); 
    }
    loadInvitation(id) { 
        return this.get(`invitation/load/${id}`); 
    }
    sendInvitation(id) { 
        return this.post(`invitation/send/${id}`); 
    }

    removeUser(ids) {
        return this.remove(ids);
    }
        
    removeInvitation(ids) {
        var payload = {ids: this.ids(ids)}; 

        return this.post(`invitation/remove`, payload); 
    }
}

export default clsUserApi;
