import { clsModel, fnCreate } from '@cls/clsModel'
import { relations as api } from '@/app/api'
import bool from '@shared/lib/bool'
var modelName = "relation_bankaccount";
const fields = ['id', 'id_relation', 'iban', 'bic', 'is_g_account', 'archived_at'];

class clsRelationBankAccount extends clsModel {

    id_relation = null; // The relation is filled by the load/create event, not by filling the bank account.
    is_g_account = false;
    bic = null;
    iban = null;
    archived_at = null;

    get modelRep() {
        var rep = this.iban;
        if (this.inActive) {
            rep = `${rep} (niet actief)`;
        }
        return rep;
    }

    /**
     * When the bank account is archived it is inactive.
     */
        get inActive() {
            return !!this.archived_at;
        }
    
    /**
     * The model is disabled when the bank account is archived.
     */
    get disabled() {
        if (super.disabled) {
            return true;
        }
        return this.inActive;
    }

//    // - The 
//    //
//    load(id, params) {
//        if (!params || !params.id_relation) {
//            throw new "The relation id is not specified.";
//        }
//        return super.load(id, params);
//    }

    /**
     * send the saved event.
     * In addition to the bank accounts, add the current relation.
     */
    sendSavedEvent(data) {
        data.id_relation = this.id_relation;
        return super.sendSavedEvent(data);
    }

    /**
     * Check if there are server side warnings.
     * @param {*} data 
     */
    async checkBeforeSave(data) {
        await api.getSaveBankAccountWarnings(data);     
    }

    /**
     * The default api save implementation just calls save on the api.
     * Override this method for example to call another save method.
     */
    apiSave(data) {
        return api.saveBankAccount(data);
    }

    /**
     * The default api save implementation just calls save on the api.
     * Override this method for example to call another save method.
     */
    apiLoad(id, params) {
        return api.loadBankAccount(id);
    }

    /**
     * 
     */
    fill(data, ignoreInitialState) {
        data.is_g_account = bool.isTrue(data.is_g_account);

        return super.fill(data, ignoreInitialState);
    }

    /**
     * Get the main title. This is basicly the item type. E.g. 'Nieuwe relatie' or 'Relatie'
     */
    get title() {
        return this.is_g_account ? "G-rekening" : "Bankrekening"
    }
    
    constructor() {
        super({
          api: api,   
          modelName: modelName, 
          mandatoryFields: ['iban'],

          fillable: fields
        })
    } 
        
 }
 export default fnCreate(clsRelationBankAccount , 'clsRelationBankAccount');
