<template>

<div>    
    <ActionCombo v-if="items.length > 0" :noClearable="noClearable" :placeholder="placeholder" :skeleton="compSkeleton" :disabled="compDisabled" :rules="rules" v-model="dvalue" :items="items"
        item-text="iban"
        item-value="iban"
        >
    </ActionCombo>
    <TextField v-else disabled :skeleton="compSkeleton" :value="singleItem"></TextField>
</div>
</template>

<script setup>

    // import RelationIBAN from '@/ui/controls/RelationIBAN'
    // <RelationIBAN :id_relation="model.id_relation" :model="model" :rules="model.rules.iban" v-model="model.iban"></RelationIBAN>
    // or 
    // <RelationIBAN :id_relation="model.id_relation" :disabled="model.disabled" :rules="model.rules.iban" v-model="model.iban"></RelationIBAN>

    const props = defineProps({
        disabled: {
            type: [Boolean]
        },
        rules: {
            type: [Array]
        },
        noClearable: {
            type: [Boolean],
            default: false
        },        
        skeleton: {
            type: [Boolean]
        },
        value: {
            type: [String, Number]
        },
        id_relation: {
            type: [String, Number]
        },
        placeholder: {
            type: [String],
            default: 'IBAN'
        },
        model: {
            type: [Object]
        },
        g: {
            type: [Boolean]
        }

    })
    
    import ActionCombo from '@controls/combo/ActionCombo'
    import {relation} from '@app/list';
    import {computed} from 'vue'
    import string from '@lib/string'
    import TextField from '@controls/input/TextField'
    import bool from '@shared/lib/bool'

    const emit = defineEmits(['input'])

    const dvalue = computed({
        get() {
            return props.value
        },
        set: function(v) {
            emit('input', v)
        }	
    })

    const compSkeleton = computed({
        get() {
            if (props.skeleton) {
                return true;
            }
            if (!props.model) {
                return false;
            }
            return props.model.isDataLoading;
        }
    });
    const compDisabled = computed({
        get() {
            if (props.disabled) {
                return true;
            }
            if (!props.model) {
                return false;
            }
            return props.model.disabled;
        }
    });
    
    const items = computed({
        get() {
            let items = [];
            if (!props.id_relation) {
                return items;
            }
            let rel = relation.one(props.id_relation);
            if (!rel) {
                return items;
            }            
            if (props.g) {
                return (rel.bankaccounts||[]).filter( (ba) => ba.is_g_account && (!ba.archived_at || string.compare(ba.iban, props.value)) );
            } 
            // else
            return (rel.bankaccounts||[]).filter( (ba) => !ba.is_g_account && (!ba.archived_at || string.compare(ba.iban, props.value)) );

            //  Note, do not use an intermediate array to store the bank accounts. 
            //  This is because the array won't be triggered by reactivity when e.g. a bank account is added.            
        },
    })

    const singleItem = computed({
        get() {
            if (!items.value || !items.value.length) {
                return "";
            }
            return items.value[0].name;
        },
    })

</script>
