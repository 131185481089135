import clsHttp from '@/app/clsHttp'
import fnExpose from '@lib/expose';

//
// Usage: 
//  import http from '@app/http'
//  var result = http.get(myurl)
//

let obj = new clsHttp();

fnExpose(obj, 'system', 'http')

export default obj;