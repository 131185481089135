<template>
    <div>        
        <Datatable :dt="dt" class="full-height">
            <template v-slot:menu ="{ item }">
                <ActionMenuItem action="salesorder.open" @click="()=>onOpen(item)"></ActionMenuItem>
            </template>

            <template v-slot:footer.prepend>
                <v-row dense>
                    <v-col>
                        <v-card flat>
                            <v-card-text>
                                <ActionButton action="salesorder.create" sub dusk="btn-add-order" :model="model" icon="add" xmain xprimary @click='createOrder'>Nieuwe order</ActionButton>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </template>

        <template v-slot:item.archived_at ="{ item }">
            <span class="cursor-pointer" @click="()=>dlg.open('salesorder', item)">            
                <PaletteChip :id_palette="!!item.archived_at?1:2">{{ !!item.archived_at?"Gereed":"Open" }}</PaletteChip>
            </span>
        </template>
        <template v-slot:item.first_expiry ="{ item }">
            <span v-if="!item.first_expiry">-</span>
            <DateColumn v-else :value="item.first_expiry" warn-past/>
        </template>

        </Datatable>

    </div>
</template>



<script setup>
    import useDataTable from '@app/useDataTable'
    import Datatable from '@datatable/Datatable'
    import ActionMenuItem from "@controls/menu/ActionMenuItem.vue";
    import { salesorder as api } from '@app/api'
    import dlg from '@app/dlg'
    import ActionButton from '@controls/buttons/ActionButton'
    import PaletteChip from "@controls/widgets/PaletteChip.vue";
    import DateColumn from '@datatable/columns/Date'

    const props = defineProps({
        model: {
            type: [Object]
        },
    });

    var headers = [
        { canmove: false, canhide: false, type: 'cmd'},
        { type: 'flag', flagType: 'salesorder_flag'},
        { type: 'note', noteType: 'salesorder_note'},
		{ value: "archived_at", text: "Status", fmtExcel: (v,item)=>(!v?'Open':'Gereed')},
		{ value: "ord_number",  text: "Nummer",               type: 'open'},
		{ value: "ord_name",                  text: "Naam",                 },
		{ value: "rel_name",                  text: "Klant",              },
		{ visible: false, value: "ord_reference",             text: "Referentie",           },
		{ value: "first_expiry",              text: "1e verloop",           },
		{ value: "total_amount",              text: "Totaal",       type: 'amount'},
		{ value: "total_amount_invoiced",     text: "Gefactureerd", type: 'amount'},
		{ value: "total_amount_open",         text: "Open",         type: 'amount'},

        { isUtc: true, visible: false, text: "Gewijzigd",                value: "updated_at",    type: 'datetime'},
		{ visible: false, text: "Gewijzigd door",           value: "updated_by",                    },
		{ isUtc: true, visible: false, text: "Aangemaakt",               value: "created_at",    type: 'datetime'},
    ];

    // Refresh when additional work is the invoice is saved or sent.
    var customSaveHandler = (_dt, modelName, params) => { 
        if (modelName == "salesorder") {
            _dt.search(); // refresh
        }
    }
    var customRemoveHandler = (_dt, modelName, _ids) => {
        if (modelName == "salesorder") {
            return _dt.search();
        }
        if (modelName == "salesinvoice") {
            return _dt.search();
        }
    }

    var dt = useDataTable("dt_project_orders", "salesorder", api, headers, {
        // hideDefaultFooter: true,
        noPager: true,
        itemName: {
            prefix: 'De',
            single: 'verkooporder',
            plural: 'verkooporders',
        }, parent: {
            id_project: props.model.id
        },
        preventRemoveHandler: ()=>!props.model.isDialogOpen, // No refresh when dialog is not open
        preventSaveHandler:   ()=>!props.model.isDialogOpen, // No refresh when dialog is not open
        customSaveHandler:    customSaveHandler,
        customRemoveHandler:  customRemoveHandler,
        canArchive: false,

    })

    function createOrder() {
        dlg.create('salesorder', {id_relation: props.model.id_relation, id_project: props.model.id, pro_number: props.model.pro_number, pro_name: props.model.pro_name})
    }
    async function onOpen(item) {
        var xxx = await dlg.open('salesorder', item)        
    }

    async function onRemoveOrder(item) {
        alert('Remove order')
    } 
</script>