<template>
    <PaletteChip :id_palette="compIdPalette">{{ compName }}</PaletteChip>
</template>
<script setup>
// Usage: 
//   import ProjectPhaseChip from '@/ui/controls/ProjectPhaseChip'
//
//   <ProjectPhaseChip v-model="model.id_project_phase"></ProjectPhaseChip>

import PaletteChip from "@controls/widgets/PaletteChip.vue";
import {computed, ref} from 'vue'
import {projectphase} from '@app/list'

const props = defineProps({
    value: {
        type: [String, Number],
        default: 0
    },
})

const compIdPalette = computed({
    get() {
        if (!Number(props.value)) {
            return 0;
        }
        var phase = projectphase.one(props.value);
        return phase.id_palette || 0
    }
})
const compName = computed({
    get() {
        if (!Number(props.value)) {
            return 0;
        }
        var phase = projectphase.one(props.value);
        if (!phase) {
            return "";
        }
        return phase.name || ""
    }
})
</script>
