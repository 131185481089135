//
// Usage: 
//  import url from '@shared/lib/url'
//  token = url.getParam('token');
//

var url = {};
url.redirect = (link, reload) => {
    window.location.assign(link);
    if (reload) {
        window.location.reload();
    }
}
/**
 * Use this to open a link in a new tab.
 */
url.open = (link, target) => {
    target = target |"_blank";
    window.open(link, target);
}

url.getParam = (name) => {
    const queryString = window.location.search;    
    const urlParams = new URLSearchParams(queryString);    
    const value = urlParams.get(name);
    
    if (!value) {
        return null;
    }
    return value;
}
url.back = () => {
    history.back();
}

/**
 * Does the current url contain the given phrase?
 * Note that as we use hashes to navigate, we search the hash.
 */
url.contains = (str) => {
    return (window.location.hash     ||"").indexOf(str) >=0; 
}

/**
 * Example: 
 *  url: 
 *      https://kantoor.optimit.nl/c/1234/#/relaties
 * 
 *  getPathPart("c") => 1234
 *  getPathPart("x") => null
 * 
 *  Note: this method only returns numeric values.
 * 
 * @param {*} name 
 */
url.getPathPart = (name) => {
    var q = `\/${name}\/([0-9]*)\/`
    var c = RegExp(q).exec(window.location.pathname ||"")     
    if (c && Array.isArray(c) && c.length == 2) {
        return c[1];
    }
    return null;
}
export default url;