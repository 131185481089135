<template>
    <div>
        <v-row dense>
            <v-col sm="12" lg="8" xl="auto" style="min-width:50%;">
                <v-card class="">
                    <v-card-title>
                        Hoofdstukken
                    </v-card-title>
                    <v-card-text class="pa-0">
                        <v-simple-table dense class="header-contrast no-hover border-last-row border-header-row">
                            <thead>
                                <th class="w-1"></th>
                                <th class="pl-4 pa-2 pl-2 text-left border-right"></th>
                                <th class="pr-4 pa-2 w-2 text-right border-right">Aanneemsom</th>
                                <th class="pr-4 pa-2 w-5 text-right border-right">Verwachte kosten
                                    <TipMenu class="ml-2">
                                        <p><h4>De verwachte kosten.</h4></p>
                                        <p>Het gebruik van verwachte kosten is optioneel.</p> 
                                        <p>Wanneer u verwachte kosten invult voor een project of een project hoofdstuk kunt u deze kosten in het project-dossier vergelijken met de werkelijk gemaakte kosten.</p> 
                                        <p>Op deze manier kunt u signaleren of de werkelijke kosten overeenkomen met de begrote kosten</p> 
                                    </TipMenu>
                                </th>
                                <th class="pr-4 pa-2 w-5 text-right border-right">Verwachte uren
                                    <TipMenu class="ml-2">
                                        <p><h4>De verwachte uren kosten.</h4></p>
                                        <p>Het gebruik van verwachte uren kosten is optioneel.</p> 
                                        <p>Wanneer u verwachte uren kosten invult voor een project of een project hoofdstuk kunt u deze kosten in het project-dossier vergelijken met de werkelijk gemaakte kosten.</p> 
                                        <p>Op deze manier kunt u signaleren of de werkelijke kosten overeenkomen met de begrote kosten</p> 
                                    </TipMenu>
                                </th>
                                <th class="w-2"></th>
                            </thead>
                            <tbody> 
                                <tr v-for='(line, index) in model.chaptersv2' :key='`gtl-${index}`'>
                                    <td class="border-right">
                                        <span v-if="line.is_default_chapter"  class="ml-2"><Icon type='project'></Icon></span>
                                        <ActionButton v-else action="project.modify" :model="model" ml icon-button icon="remove"  
                                            @click="() => removeChapter(line)">
                                        </ActionButton>
                                    </td>
                                    <td class="pl-4 pa-1 border-right">
                                        {{ line.name }}
                                    </td>
                                    <td class="pr-4 pa-1 text-right border-right">
                                        <AmountText v-model='line.contract_sum'/>    
                                    </td>
                                    <td class="pr-4 pa-1 text-right border-right">
                                        <AmountText :value='-line.budget_costs' leading-min/>    
                                    </td>
                                    <td class="pr-4 pa-1 text-right border-right">
                                        <AmountText :value='-line.budget_hours' leading-min/>    
                                    </td>
                                    <td class="text-bold border-right">
                                        <ActionButton no-icon link-button action="project.open" @click='()=>openChapter(line)'>Specificeer</ActionButton>
                                    </td>
                                </tr>
                                <tr v-if="(model.chaptersv2||[]).length > 1">
                                    <td class="border-right"></td>
                                    <td class="text-bold border-right">Totaal</td>
                                    <td class="pr-4 pa-1 text-right border-right text-bold">
                                        <AmountText :value='model.chaptersv2_total_contract_sum' leading-min/>    
                                    </td>
                                    <td class="pr-4 pa-1 text-right border-right text-bold">
                                        <AmountText :value='-model.chaptersv2_total_costs' leading-min/>    
                                    </td>
                                    <td class="pr-4 pa-1 text-right border-right text-bold">
                                        <AmountText :value='-model.chaptersv2_total_hours' leading-min/>    
                                    </td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                        <div class="pa-4">
                            <ActionButton class="" :model="model" action="project.modify" sub dusk="btn-add-chapter" icon="add" @click='(evt)=>addChapter(evt)'>Nieuw hoofdstuk</ActionButton>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <DialogChapter/>
    </div>
</template>



<script setup>
    
    import ActionButton from '@controls/buttons/ActionButton'
    import AmountText from '@controls/text/Amount.vue'
    import TipMenu from "@controls/menu/TipMenu.vue";
    import DialogChapter from '@/ui/dialogs/project/DialogChapter.vue'    
    import dlg from '@app/dlg'
    import noty from '@shared/lib/noty'
    import Icon from '@controls/icons/Icon'
    const props = defineProps({
        model: {
            type: [Object]
        },
    });

    async function openChapter(line) {
        try {
            var result = await dlg.promised.open('project_chapter', line);
            if (result && result.chaptersv2) {
                props.model.chaptersv2 = result.chaptersv2;
            }
        } catch (e) { if (!e.canceled) { throw e;} }
    }
    async function removeChapter(line) {
        if (await noty.tryConfirm("Wanneer u een hoofdstuk verwijderd worden alle hieraan gekoppelde omzet en kosten aan het hoofdproject gekoppeld. Weet u het zeker?")) {
            props.model.removeChapterv2(line);
        }
    }
    async function addChapter(evt) {
        try {
            var result = await dlg.promised.create('project_chapter', {id_project: props.model.id});
            if (result && result.chaptersv2) {
                props.model.chaptersv2 = result.chaptersv2;
            }
        } catch (e) { if (!e.canceled) { throw e;} }
    }

    
</script>
