import clsSettings2 from '@cls/clsSettings2'

var modelName="salesorder"
var arrFields = ['add_summary_in_invoice'];
class clsSalesOrderSettings extends clsSettings2 {

    add_summary_in_invoice  = false

    constructor() {
        super(modelName, arrFields);
    }

    fill(data) {
        this.add_summary_in_invoice  = !!data.add_summary_in_invoice;
    }
}

export default clsSalesOrderSettings;
