import clsApi from '@cls/clsApi'

class clsSupport extends clsApi {

    async requestKvkChange(value) {
        return await this.post(`requestkvkchange`, {value: value}); 
    }
    async requestEmailAliasChange(value) {
        return await this.post(`requestemailaliaschange`, {value: value}); 
    }

}

export default clsSupport;
