<template>
    <v-skeleton-loader type="opti-input" v-if="compSkeleton"></v-skeleton-loader>

    <v-menu v-else
        v-model="openMenu"
        :close-on-content-click="true"        
        transition="scale-transition"
        offset-y
        min-width="290px"
    >
    <template v-slot:activator="{ on }">
        <v-text-field 
            @keydown.enter="onSearch"
            @keydown.down="onSearch"
            @click:clear="onClear"
            @click.right="onRightClick"
            @blur="onBlur"
            @focus="onFocus"
            v-model="prompt"
            :disabled="compDisabled"
            :placeholder="compPlaceholder"
            outlined
            dense small hide-details
            class="opti-picker"
            :class="compClass"
            :rules="rules"
            >
            <template v-slot:append>
                <slot name="append"></slot>
                <span v-if="useMenu" class="px-2 cursor-pointer picker-trigger-search" @click="openMenu=!openMenu"><Icon small type="menu-vertical"></Icon></span>
                <span v-if="!useMenu && !!id&&!compDisabled" class="cursor-pointer mr-1 picker-trigger-clear" @click="onClear"><Icon type="close"></Icon></span>
                <span v-if="!useMenu" class="cursor-pointer picker-trigger-search" @click="onSearch"><Icon small type="search"></Icon></span>
            </template>
        </v-text-field>
    </template>
    <v-list dense>
        <slot name="menu" :id="id" :clear="()=>onClear()" :disabled="compDisabled", :search="()=>onSearch()">
        </slot>
    </v-list>

    </v-menu>

</template>

<script setup>

    // import Picker from '@controls/picker/Picker'
    // <Picker :model="model" :valueModel="model" :rules="model.rules.id_project" idField="" repField=""></ProjectPicker>

    const props = defineProps({
        disabled: {
            type: [Boolean]
        },
        rules: {
            type: [Array]
        },
        noClearable: {
            type: [Boolean],
            default: false
        },
        noBorder: {
            type: [Boolean]
        },
        skeleton: {
            type: [Boolean]
        },
        placeholder: {
            type: [String],
            default: ''
        },
        model: {
            type: [Object]
        },
        valueModel: {
            type: [Object]
        },
        // Mandatory. The name of the field in the valueModel or model which is the id of this picker field
        idField: {
            type: [String],            
        },
        // Mandatory. The name of the field in the valueModel or model which is the representation of this picker field
        repField: {
            type: [String],
        },
        // Optional. The id in the select list which we take over on select
        selectIdField: {
            type: [String],
            default: "id"
        },
        // Optional. The name of a field which is shown as an appendix to the representation
        // It can be used to show extra information. E.g. "Project 12345.4" where .4 is the chapter which is shown as an 
        // appendix.
        childRepField: {
            type: [String],
        },
        // Optional. The id of a child field
        // E.g. instead of a project, a project with an associated chapter can be chosen. The child is a chapter in this case
        // appendix.
        childIdField: {
            type: [String],
        },
        childSeparator: {
            type: [String],
            default: "."
        },
        hideChildOnFocus: {
            type: [Boolean],
            default: true
        },

        // The default filter used for browsing. 
        // Example, for selecting a project in a purchase invoice, only projects which are in phase where it is allowed to 
        // book purchase invoices should be found. The same for sales invoices. 
        filter: {
            type: [Object]
        },
        // Optional. The repField in the select list which we take over on select. Defaults to the property 'repField'
        selectRepField: {
            type: [String],
        },
        // When provided, the fieldmap contains fields which must be taken over from a selected item.
        // Those are sibling fields. For example, for a project picker, the reference may be displayed in a separate field or column.
        fieldMap: {
            type: [Object]
        },
        // When the field is filled, and then cleared, should the new values (empty value) be propagated to
        // any depending field? (defined in the fieldmap)
        propagateClear: {                    
            type: [Boolean]
        },
        // Mandatory. The name of the selectlist to open.
        dialogName: {
            type: [String]
        },
        // When specified, the parent id is sent along with the query.
        idParent: {
            type: [Number, String],
            default: null
        },
        useMenu: {
            type: [Boolean],
            default: false
        },
        dialogOptions: {
            type: [Object],            
        }

    })
    const emit = defineEmits(['select'])

    import ActionCombo from '@controls/combo/ActionCombo'
    import {computed, ref, watch} from 'vue'
    import Icon from '@controls/icons/Icon'
    import eventbus from '@app/eventbus';

    const openMenu = ref(false);

    const compSkeleton = computed({
        get() {
            if (props.skeleton) {
                return true;
            }
            if (!props.model) {
                return false;
            }
            return props.model.isDataLoading;
        }
    });
    const compDisabled = computed({
        get() {
            if (props.disabled) {
                return true;
            }
            if (!props.model) {
                return false;
            }
            return props.model.disabled;
        }
    });
    const compPlaceholder = computed({
        get() {
            if (compDisabled.value) {
                return null;
            }
            return props.placeholder;
        }
    });

    const compClass = computed({
        get() {
            var clsDisabled = compDisabled.value ?"disabled" : "";
            var border = this.noBorder ? "no-border" : ""; 
            return `${border} ${clsDisabled}`;
        }
    });

    if (!props.idField) {
        throw "idField must be specified."
    }
    if (!props.repField) {
        throw "repField must be specified."
    }
    if (!props.dialogName) {
        throw "dialogName must be specified."
    }


    // -- operational flow:
    //
    // 1) We bind to the model for the disabled and skeleton properties. 
    // 2) We retrieve the idField and repField values from the specifid (value)Model
    // 3) Changes in the (value)Model are taken over
    // 4) A selected value is propagated to the (value)Model.
    //

    // The id we retrieve from and propagate to the (valueModel)
    const id = computed({
        get() {
            if (props.valueModel) { 
                return props.valueModel[props.idField]
            }
            if (props.model) { 
                return props.model[props.idField]
            }
            return null;
        },
        set(value) {
            if (props.valueModel) { 
                props.valueModel[props.idField] = value;
            }
            else if (props.model) { 
                props.model[props.idField] = value;
            }
        }
    })
    // The rep we retrieve from and propagate to the (valueModel)
    const repValue = computed({
        get() {
            var value = "";
            if (props.valueModel) { 
                value = props.valueModel[props.repField]
            } else if(props.model) { 
                value = props.model[props.repField]
            }            
            return value;
        },
        set(value) {
            if (props.valueModel) { 
                props.valueModel[props.repField] = value;
            }
            else if (props.model) { 
                props.model[props.repField] = value;
            }
        }

    })
    const repChild = computed({
        get() {            
            var value = "";
            if (!props.childRepField) {
                return value;
            }
            if (props.valueModel) { 
                value = props.valueModel[props.childRepField]
            } else if(props.model) { 
                value = props.model[props.childRepField]
            }            
            if (!value) {
                return value;
            }
            return `${props.childSeparator}${value}`;
        },
        set(value) {
            if (!props.childRepField) {
                return;
            }
            if (props.valueModel) { 
                props.valueModel[props.childRepField] = value;
            }
            else if (props.model) { 
                props.model[props.childRepField] = value;
            }
        }
    })
    const idChild = computed({
        get() {            
            var value = "";
            if (!props.childIdField) {
                return value;
            }
            if (props.valueModel) { 
                value = props.valueModel[props.childIdField]
            } else if(props.model) { 
                value = props.model[props.childIdField]
            }            
            if (!value) {
                return value;
            }
            return `${props.childSeparator}${value}`;
        },
        set(value) {
            if (!props.childIdField) {
                return;
            }
            if (props.valueModel) { 
                props.valueModel[props.childIdField] = value;
            }
            else if (props.model) { 
                props.model[props.childIdField] = value;
            }
        }
    })
    const rep = computed({
        get() {
            var child = repChild.value||"";
            var value    = repValue.value||"";
            return `${value}${child}`;
        },
        set(value) {
            if (props.valueModel) { 
                props.valueModel[props.repField] = value;
            }
            else if (props.model) { 
                props.model[props.repField] = value;
            }
        }
    })
    // A private observable is the 'working text field'
    let prompt = ref("");
    // Initially taken from the model representation
    prompt.value = rep.value;
    // Keep in sync when the model rep changes
    watch(rep, (newValue, oldValue) => {
        prompt.value = newValue;
    })

    /**
     * When a fieldmap is specified, fill the values from the parameter in the dedicated modelvalue.
     * This is called after an item is selected from a picker.
     * For example: fieldMap = {"pro_number" : "projectNumber", "phase" : "projectPhase"}
     * In this example, when an item is selected: 
     *      - valueModel["projectNumber"] = selectedItem["pro_number"] and
     *      - valueModel["projectPhase"] = selectedItem["phase"] and
     */
    function setFieldmapValues(values) {
        if (!props.fieldMap) {
            return;
        }
        let model = props.valueModel || props.model;
        for (var key in (props.fieldMap)) {
            let targetField = props.fieldMap[key]; 
            model[targetField] = values[key] || null;
        }
    }

    async function onSearchEnter(a,b,c) {
        console.log('onSearchEnter',a,b,c)
    }

    // Start a search action
    async function onSearch() {
        if (props.disabled) {
            return;
        }

        let filter = props.filter || {};
        filter.q = prompt.value;
        // When searching, remove the appendix. Otherwise, nothing will be found ever.
        var appendix = repChild.value;        
        if (!!appendix) {
            filter.q = (`${prompt.value}`.replace(appendix, ""));
        }

        // When an item is selected, inlcude  it in the search results. 
        // Scenario: 
        //   - manday X is added to an invoice. 
        //   - the user searches mandays via the picker. In the results, X is not shown as it is already coupled to an invoice (this invoice).
        //   - now the user selects manday Y.
        //   - It was a mistake, he want to select back the manday. This is not possible as the previous manday is also 
        //     invoiced - to the current invoice.
        // So, solution: 
        //   - add the parent id to the filter so that the manday of the current invoice can be re-selected.
        // 
        filter.id_parent = props.idParent; // might be null, fine. Implementer can decide.

        var options = {multiselect:false, filter: filter};
        for(var key in (props.dialogOptions||{})) {
            if (props.dialogOptions[key]) {
                options[key] = props.dialogOptions[key];
            }
        }
        let selected = await eventbus.dialog.open.promise(props.dialogName, options)

        if (!selected || !selected.length) {
            repValue.value = null;
            repChild.value = null;
            id.value = null;
            setFieldmapValues({});
            return;
        }
        let idField = props.selectIdField;
        let repField = props.selectRepField || props.repField;
        let selRepValue = selected[0][repField];
        let selIdValue = selected[0][idField];

        let childIdField = props.childIdField;
        let childRepField = props.childRepField;
        let selChildRep = selected[0][childRepField];
        let selChildId = selected[0][childIdField];

        repValue.value = selRepValue;
        repChild.value = selChildRep;
        idChild.value = selChildId;
        // important: assign the id as last. It is being watched on in models.
        id.value = selIdValue;

//         console.log("Selected: ", selected);
        setFieldmapValues(selected[0]);

        emit('select', selected[0]);
    }

    function onClear() {
        repChild.value = null;
        repValue.value = null;
        id.value = null;
        prompt.value = null;
        if (props.propagateClear) {
            setFieldmapValues({});
        }
    }
    function onBlur() {
        if (!prompt.value) {
            onClear();
        } else {
            prompt.value = rep.value
        }
    }
    // When the appendum must be hidden, hide it on focus.
    function onFocus() {
//        if (props.hideChildOnFocus) {
//            prompt.value=repValue.value;
//        }
    }

    function onRightClick() {
        console.log('Yep. Clicked right.');
    }
</script>
