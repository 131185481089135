import eventbus from '@app/eventbus'
import {data as api} from '@app/api'

/**
 * Use this class as a concrete- or a base class for lists of items.
 * Takes care of maintaining list of items, handling archived / actual items, etcetera.
 *
 * Example: this is a full implementation of a company combo using the companies list. 
 *
 *     <template>
 *         <ActionCombo :items="list.company.list" :noClearable="noClearable" :placeholder="placeholder" :skeleton="skeleton" :disabled="disabled" :rules="rules" v-model="dvalue"></ActionCombo>
 *     </template>
 *     
 *     <script setup>
 *     
 *         const props = defineProps({
 *             disabled:    { type: [Boolean] },
 *             rules:       { type: [Array] },
 *             noClearable: { type: [Boolean], default: false },
 *             skeleton:    { type: [Boolean] },
 *             value:       { type: [String, Number] },
 *             placeholder: { type: [String], default: 'Company' },
 *         })
 *         
 *         import ActionCombo from '@controls/combo/ActionCombo'
 *         import list from '@app/list';
 *         import {computed} from 'vue'
 *     
 *         const value = props.value;
 *         const emit = defineEmits(['input'])
 *     
 *         const dvalue = computed({
 *             get() {
 *                 return value
 *             },
 *             set: function(v) {
 *                 emit('input', v)
 *             }	
 *         })
 *     
 *     </script>
 * 
 * 
 * The exposed API's: 
 * 
 * - list:                            all actual items
 * - listActual(id):                  method which returns the actual items and the one with the given id, even if it is archived
 * - one(id):                         get the one with the given id
 * - oneProp(id, name, defaultValue): get the one with the given id
 * - count                            The number of actual items.
 *  
 * Just implements a couple of shared items.
 * 
 ************************************************************************************************************* 
 *
 * Note. 
 * The initial version of this method did update the list by synchronizing the dirty list item with the saved item.
 * For example, the list contains units: 
 *    units: [ { id: 1, name: 'm2'}, id: 2, name: 'm3' ]
 * 
 * Now, a unit is saved via the units dialog. The saved data is distributed via an event: 
 *    saved: { id_unity: 1, un_name: 'Vierkante meter}; 
 * 
 * Now we maintained a mapping for which field to update on which input field.
 * 
 * Although this seemed efficient, in fact it was way too complex. All kind of exceptional cases popped up.
 * Therefore, this is replace by a simple approach. 
 * 
 ****
 * This has been replaced by a simple approach: 
 * - on every save event, the whole list is refreshed. 
 *   This is a super simple approach, and it is also efficient as lists can be retrieved very fast. 
 *   
 * - In cases where this is not appropriate, the onEventSaved method can be overridden to implement a 
 *   more efficient approach. 
 *   For example, relations. There can be thousands of relations, we don't want to refresh them all. 
 *   In this case, we can just reload one item from the backend.   
 * 
 *************************************************************************************************************
 * Usage: 
 * import clsList from '@cls/clsList'
 * 
 * class clsMyList extends clsList {
 * 
 *      constructor() {
 *         super( {
 *            archivedFlag:    "archived_at",
 *            keyField:        "id",
 *            modelName: "company"
 *         })
 *      }
 * 
 *      // return { 
 *      //      local_field1: received_fieldname1, 
 *      //      local_field2: received_fieldname2, 
 *      //      ...
 *      // }
 *      updateableFields() {
 *         return {
 *             rel_name: 'rel_name',
 *             rel_refr: 'reference',
 *         };
 *      }
 * }
 *
 * export default clsList;
 * 
 */

class clsList {

    list = [];
    archivedList = [];
    fieldMap = null;
    modelName = null;
    fnConvert = null;

    archivedFlag = "archived_at";
    keyField = "id";

    constructor(config) {
        config = config || {};
        this.modelName = config.modelName || null;
        this.fnConvert = config.fnConvert;
        this.archivedFlag = config.archivedFlag || "archived_at";
        this.keyField = config.keyField || "id";

        // fieldmap must be null when not used. NOT a {}. 
        // That is because reactivity adds properties to empty object so that it appears not empty 
        // further on in the code.
        this.fieldMap = config.fieldMap || null;

        this.registerEvents();
    }

    /**
     * Refresh the whole list from the server.
     */
    async reload() {        
        var result = await api.loadList(this.modelName);

        var data = result?.data?.list?.[this.modelName];
        if (data) {
            this.fill(data);
        }            
    }

    /**
     * Override to manipulate the item before initially adding the item to the list.
     * return false when the item must not be added.
     * 
     * @param {} item 
     */
    beforeAddingItem(item) {
        // By default, no action
    }

    /**
     * Fill the list with values.
     * @param {} list 
     */
    fill(list) {
        list = list || [];
        this.list = [];
        this.archivedList = [];
        var self = this;
        list.forEach( (item) => {

            if (self.beforeAddingItem(item) !== false) {
                if (item[this.archivedFlag]) {
                    self.archivedList.push(item);
                } else {
                    self.list.push(item);
                }
            }
        })
    }

    /**
     * list actual items unless the 
     * 
     * @param {} id 
     */
    listActual(id) {
        // When no id is given, or when no archive is present, simply return the list.
        if (!id || !this.archivedList.length) {
            return this.list
        }
        // When the archived list doesnot contain the id, just return the list.
        if (!this.archivedList.find( (item) => item[this.keyField] == id)) {
            return this.list
        }
        // Return the archived item
        var self = this;
        var result = this.archivedList.filter( (item) => item[self.keyField] == id);
        // and the rest of the list.
        this.list.forEach( (item) => {
            result.push(item);
        })
        return result;
    }

    /**
     * Remove one or more items with the given ids from the lists.
     * ids can either be a singele value or an array with values
     * Example: 
     *  - list.remove(100)
     *  - list.remove([100])
     *  - list.remove([100, 101, 102])
     */
    remove(ids) {
        if (!ids) {
            return;
        }
        if (!ids.pop) {
            ids = [ids];
        }
        var self = this;
        this.list = this.list.filter( (item) => !ids.find( (id) => id == item[self.keyField])); 
        this.archivedList = this.archivedList.filter( (item) => !ids.find( (id) => id == item[self.keyField])); 
    };

    /**
     * Archive one or more items with the given ids
     * ids can either be a singele value or an array with values
     * Example: 
     *  - list.archive(100)
     *  - list.archive([100])
     *  - list.archive([100, 101, 102])
     */
    archive(ids) {
        if (!ids) {
            return;
        }
        if (!Array.isArray(ids)) {
            ids = [ids];
        }
        // For all IDs, remove them from the actual list and move them to the archived list.
        var self = this;
        ids.forEach( (id) => {
            var index = self.list.findIndex( (item) => item[self.keyField] == id);
            if (index >=0) {
                var listItem = self.list.splice(index,1);
                self.archivedList.push(listItem[0]);
            }     
        })
    };
    
    /**
     * unArchive one or more items with the given ids
     * ids can either be a singele value or an array with values
     * Example: 
     *  - list.unArchive(100)
     *  - list.unArchive([100])
     *  - list.unArchive([100, 101, 102])
     */
     unArchive(ids) {
        if (!ids) {
            return;
        }
        if (!Array.isArray(ids)) {
            ids = [ids];
        }
        // For all IDs, remove them from the actual list and move them to the archived list.
        var self = this;
        ids.forEach( (id) => {
            var index = self.archivedList.findIndex( (item) => item[self.keyField] == id);
            if (index >= 0) {
                var listItem = self.archivedList.splice(index,1);
                self.list.push(listItem[0]);
            }     
        })
    };

    /**
     * Get one item from the list
     * @param {} id 
     */
    one(id){
        if (!id) {
            return null;
        }
        var self = this;
        var item = this.list.find( (item) => item[self.keyField] == id);
        if (!item) {
            var item = this.archivedList.find( (item) => item[self.keyField] == id);
        }
        return item;
    }

    /**
     * Get one prop from one item from the list
     * @param {} id 
     */
    oneProp(id, prop, defaultValue){
        if (!id) {
            return defaultValue;
        }
        var self = this;
        var item = this.list.find( (item) => item[self.keyField] == id);
        if (!item) {
            var item = this.archivedList.find( (item) => item[self.keyField] == id);
        }
        if (!item || !item[prop]) {
            return defaultValue;
        }
        return item[prop];
    }

    /**
     * The number of items in the list
     **/    
    get count() {
        return (this.list||[]).length;
    } 

    /**
     * Compare the entity with the modelName. 
     * Ignore underscores
     */    
    isSameEntity(entityName, modelName) {
        var left = (entityName||"").replaceAll('_', ''); 
        var right = (modelName||"").replaceAll('_', ''); 
        return left == right;
    }
    
    // Handle the model saved event
    onModelSavedEvent(entityName, data) {
        if (this.isSameEntity(entityName, this.modelName)) {
            this.reload();
        }
    };

    /**
     * Register for events.
     */
    registerEvents() {
        var self = this;
        var modelName = self.modelName;
        if (!modelName) {
            return;
        }

        eventbus.appdata.loaded.on(  (data) => {
            if (data && data.list && data.list[modelName]) {
                self.fill(data.list[modelName]);            
            }
        });
        // We migrate removing and archiving to one action.
        // As we can foresee, when a user removes an item on the client, it is fine to archive it in the lists.
        // As for user point of view, the item is not available anymore. 
        eventbus.model.removed.on(  (entityName, ids) => {
            if (this.isSameEntity(entityName, modelName)) {
                self.archive(ids);            
            }
        });
        eventbus.model.archived.on(  (entityName, ids) => {
            if (this.isSameEntity(entityName, modelName)) {
                self.archive(ids);            
            }
        });
        eventbus.model.unarchived.on(  (entityName, ids) => {
            if (this.isSameEntity(entityName, modelName)) {
                self.unArchive(ids);            
            }
        });
        eventbus.model.saved.on(  (entityName, data) => this.onModelSavedEvent(entityName, data) );
    }


    /**
     * Update a data row in our list. 
     * When: 
     *      - a row with the id of the data is not found n our list: 
     *        * push the row at the bottom.
     * 
     *      - a row with the id of the data is found: 
     *        * replace the row. 
     * 
     * Note that this method is not used in this class. It is created for convenience for 
     * derived classes which do not refresh all rows on save.
     * 
     * @param {*} data 
     * @returns 
     */
    updateRow(data) {
        if (!data || !data.id) {
            console.error("Received refresh / save event without data or id");
            return;
        }
        let item = this.one(data[this.keyField]);        
        // When new item,  
        if (!item) {
            this.list.push(data);
        } else {
            var ix = this.list.indexOf(item);
            if (ix < 0) {
                console.error("updateRow - updatable row not found.");
                return;    
            }
            this.list[ix] = data;
        }
    }
    
}

export default clsList