<template>    

      <v-menu :close-on-content-click="true" offset-y :disabled="compDisabled">

        <template v-slot:activator="{ on }">
            <span v-on="on" v-if="headerButton" :class="`flag-${activeId}`">
                <v-btn elevation="0" class="header-action-button px-0">
                    <Icon :small="small" :type="(Number(value)>0)?'flag':'flag-empty'"></Icon>                                    
                </v-btn>
            </span>
            <span v-on="on" v-else-if="button" :class="`flag-${activeId}`">
                <ActionButton dusk="btn-dialog-menu-toolbar" icon-button-outlined  :class="borderThin?'border-w-1':''"
                icon-small
                :icon="(Number(value)>0)?'flag':'flag-empty'"
                sciconButtonColor="activeColor">
                </ActionButton>
            </span>
            <span v-else v-on="on" :class="iconFlagClass">
                <Icon small class="" :type="(Number(value)>0)?'flag':'flag-empty'" sccolor="activeColor"></Icon>
            </span>
        </template>
        <v-card>
        <v-list>            
            <v-list-item @click="setValue(flag.id)" dense v-for="(flag) in flags" :key="flag.id">
                <v-list-item-content class="cursor-pointer">
                    <v-list-item-title >
                        <span :class="`flag-${flag.id}`">
                            <Icon small  :type="(Number(flag.id)>0)?'flag':'flag-empty'" sccolor="flag.color"></Icon>
                        </span>
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
        </v-card>

      </v-menu>       

</template>

<script setup>
import ActionButton from '@controls/buttons/ActionButton'
import Icon from '@controls/icons/Icon'
import ActionCombo from '@controls/combo/ActionCombo'
 import {computed} from 'vue'

//
// Usage: 
/// import FlagMenu from "@controls/menu/FlagMenu.vue";        
//  <FlagMenu :model="model" v-model="model.flag" :id_optimit_type="id_optimit_type" :id_entity="id_entity"></FlagMenu>

    const props = defineProps({
        disabled: {
            type: [Boolean]
        },
        skeleton: {
            type: [Boolean]
        },
        button: {
            type: [Boolean],
            default: false
        },
        headerButton: {
            type: [Boolean],
            default: false
        },
        borderThin: {
            type: [Boolean],
            default: false
        },
        value: {
            type: [String, Number]
        },
        action: {
            type: [String]
        },
        id_entity: {
            type: [String, Number]
        },
        id_optimit_type: {
            type: [String, Number]
        },
        model: {
            type: [Object]
        },

    })

    const emit = defineEmits(['input'])    
    const dvalue = computed({
        get() {
            return props.value
        },
        set: function(v) {
            emit('input', v)
        }	
    })
    function setValue (value) {
        dvalue.value = value;
    }

    const compSkeleton = computed({
        get() {
            if (props.skeleton) {
                return true;
            }
            if (!props.model) {
                return false;
            }
            return props.model.isDataLoading;
        }
    });
    const compDisabled = computed({
        get() {
            if (props.disabled) {
                return true;
            }
            if (!props.model) {
                return false;
            }
            return props.model.disabled;
        }
    });
    const icon = computed({
        get() {
            return dvalue > 0 ? "flag":"flag-empty";
        }
    });
    let flags = [
        {id: 0, name: 'empty'   , color:"default"},
        {id: 1, name: '1'       , color:"error"},
        {id: 2, name: '2'       , color:"primary"},
        {id: 3, name: '3'       , color:"success"},
        {id: 4, name: '4'       , color:"warning"},
    ];

    const activeColor = computed({
        get() {
            let flag = flags.find ( (f) => Number(f.id) == Number(dvalue.value));
            if (!flag) {
                flag = flags[0];
            }
            return flag.color;   
        }
    });

    const activeId = computed({
        get() {
            let flag = flags.find ( (f) => Number(f.id) == Number(dvalue.value));
            if (!flag) {
                flag = flags[0];
            }
            return flag.id;   
        }
    });

    const iconFlagClass = computed({
        get() {
            var cls = {
                'cursor-pointer': !compDisabled.value,
            }
            var flagId = `flag-${activeId.value}`;
            cls[flagId] = true;
            return cls;
        }
    });

</script>
