<template>
    <v-card class="" flat>
        <v-card-text class="pa-0">
            <v-simple-table dense class="header-contrast no-hover no-bordered no-border-bottom border-header-row-bottom">
                <thead>
                    <th class="pl-4 pa-2 text-left">Aanneemsom</th>
                    <th class="px-2 w-4 text-right">
                        <span class="">
                            <AmountText leading-min :value='model.chapterResult.contract_sum'/>    
                        </span>
                    </th>
                    <th class="col-contrast w-0_5 px-2"></th>
                </thead>
                <tbody>              
                    <tr>
                        <td>Gefactureerd</td>
                        <td class="text-bold pa-2 text-right col-contrast">
                            <AmountText leading-min :value='model.chapterResult.revenue.line_total_excl'/>    
                        </td>
                        <td :class="clsColIcon">

                            <v-menu v-if="model.chapterResult.revenue.showInfo" :close-on-content-click="false" :nudge-width="200" offset-x >
                                <template v-slot:activator="{ on, attrs }">
                                    <span class="cursor-pointer " v-on="on"><Icon type='tip' color="error"></Icon></span>
                                </template>
                                <v-card>
                                    <v-card-title>
                                        Gefactureerd 
                                    </v-card-title>
                                    <v-divider></v-divider>
                                    <v-card-text>
                                            <v-row dense>
                                            <v-col class="no-wrap">Aanneemsom:</v-col>
                                            <v-col class="text-right text-bold pl-8">
                                                <AmountText leading-min :value='model.chapterResult.contract_sum'/>      
                                            </v-col>
                                        </v-row>
                                        <v-row dense>
                                            <v-col class="no-wrap">Gefactureerd:</v-col>
                                            <v-col class="text-right text-bold pl-8">
                                                <AmountText leading-min :value='model.chapterResult.revenue.line_total_excl'/>     
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                    <v-divider v-if="model.chapterResult.revenue.warnings.length"></v-divider>

                                        <v-card-text>
                                                <UlCustom v-if="model.chapterResult.revenue.warnings.length">
                                                <LiCustom warning v-for='(warning, index) in model.chapterResult.revenue.warnings' :key="`invoiced_warnings_${index}`">{{ warning }}</LiCustom>    
                                                </UlCustom>
                                    </v-card-text>
                                </v-card>
                            </v-menu>
                        </td>
                    </tr>          
                </tbody>
                <thead>
                    <th class="pl-4 pa-2 text-left">Kosten</th>
                    <th class="pa-2 text-right"></th>
                    <th class="col-contrastpx-2"></th>
                </thead>
                <tbody>              
                    <tr>
                        <td>
                            <span @click="expandCost=!expandCost" class="cursor-pointer">
                                <span class="top--1 mr-2"><Icon :type='expandCost?"collapse":"expand"'></Icon></span>
                                <span>Inkoopkosten</span>
                            </span>
                        </td>
                        <td class="pa-2 text-right col-contrast">
                            <AmountText leading-min :value='-model.chapterResult.purchaseCosts.cost_amount'/>    
                        </td>
                        <td :class="clsColIcon">
                            <v-menu  :close-on-content-click="false" :nxudge-width="200" offset-x >
                                <template v-slot:activator="{ on, attrs }">
                                    <span class="cursor-pointer " v-on="on"><Icon type='tip' :color="model.chapterResult.purchaseCosts.warnings.length?'error':'primary'"></Icon></span>
                                </template>
                                <v-card>
                                    <v-card-title>
                                        Inkoopkosten
                                    </v-card-title>
                                    <v-divider></v-divider>
                                        <v-card-text>
                                            <v-row dense>
                                            <v-col class="no-wrap">Verwachte kosten:</v-col>
                                            <v-col class="text-right text-bold pl-8">
                                                <AmountText leading-min v-model='model.chapterResult.purchaseCosts.budget_amount'/>    
                                            </v-col>
                                        </v-row>
                                        <v-row dense>
                                            <v-col class="no-wrap">Geboekte kosten:</v-col>
                                            <v-col class="text-right text-bold pl-8">
                                                <AmountText leading-min v-model='model.chapterResult.purchaseCosts.cost_amount'/>    
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                    <v-divider v-if="model.chapterResult.purchaseCosts.warnings.length">
                                    </v-divider>

                                        <v-card-text v-if="model.chapterResult.purchaseCosts.warnings.length">
                                        <UlCustom v-if="model.chapterResult.purchaseCosts.warnings.length">
                                            <LiCustom warning v-for='(warning, index) in model.chapterResult.purchaseCosts.warnings' :key="`purchase_costs_${index}`">{{ warning }}</LiCustom>    
                                        </UlCustom>
                                    </v-card-text>
                                </v-card>
                            </v-menu>
                        </td>
                    </tr>          
                    <tr v-if="expandCost&&!model.chapterResult.purchaseCosts.specification.length" class="opacity-50">
                        <td colspan="3">Geen inkoopkosten geboekt</td>
                    </tr>
                    <tr v-if="expandCost" class="opacity-50" v-for='(line, index) in model.chapterResult.purchaseCosts.specification' :key="`hour_costs_${index}`">
                        <td>
                            <span class="ml-2 d-inline-block"><span class="d-inline-block"><Icon type="child-expanded" class=""></Icon></span></span>                                        
                            {{ line.name }}
                        </td>
                        <td class="pa-2 text-right col-contrast">
                            <AmountText leading-min :value='-line.cost_amount'/>    
                        </td>
                        <td :class="clsColIcon">
                            <v-menu  :close-on-content-click="false" :nxudge-width="200" offset-x >
                                <template v-slot:activator="{ on, attrs }">
                                    <span class="cursor-pointer " v-on="on"><Icon type='tip' :color="line.warnings.length?'error':'primary'"></Icon></span>
                                </template>
                                <v-card>
                                    <v-card-title>
                                        {{ line.name }}
                                    </v-card-title>
                                    <v-divider></v-divider>
                                        <v-card-text>
                                            <v-row dense>
                                            <v-col class="no-wrap">Verwachte kosten:</v-col>
                                            <v-col class="text-right text-bold pl-8">
                                                <AmountText leading-min v-model='line.budget_amount'/>    
                                            </v-col>
                                        </v-row>
                                        <v-row dense>
                                            <v-col class="no-wrap">Geboekte kosten:</v-col>
                                            <v-col class="text-right text-bold pl-8">
                                                <AmountText leading-min v-model='line.cost_amount'/>    
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                    <v-divider v-if="line.warnings.length">
                                    </v-divider>

                                        <v-card-text v-if="line.warnings.length">
                                        <UlCustom v-if="line.warnings.length">
                                            <LiCustom warning v-for='(warning, index) in line.warnings' :key="`purchase_costs_warning${index}`">{{ warning }}</LiCustom>    
                                        </UlCustom>
                                    </v-card-text>
                                </v-card>
                            </v-menu>
                        
                        </td>
                    </tr>          

                    <tr>
                        <td>
                            <span @click="expandHours=!expandHours" class="cursor-pointer">
                                <span class="top--1 mr-2"><Icon :type='expandHours?"collapse":"expand"'></Icon></span>
                                <span>Urenkosten</span>
                            </span>
                        </td>
                        <td class="pa-2 text-right col-contrast">
                            <AmountText leading-min :value='-model.chapterResult.hourCosts.cost_amount'/>    
                        </td>
                        <td :class="clsColIcon">
                            <v-menu  :close-on-content-click="false" :nxudge-width="200" offset-x >
                                <template v-slot:activator="{ on, attrs }">
                                    <span class="cursor-pointer " v-on="on"><Icon type='tip' :color="model.chapterResult.hourCosts.warnings.length?'error':'primary'"></Icon></span>
                                </template>
                                <v-card>
                                    <v-card-title>
                                        Urenkosten 
                                    </v-card-title>
                                    <v-divider></v-divider>
                                        <v-card-text>
                                            <v-row dense>
                                                <v-col class="no-wrap">Geboekte uren:</v-col>
                                                <v-col class="text-right text-bold pl-8">
                                                    <AmountText no-symbol leading-min v-model='model.chapterResult.hourCosts.hours_completed'/>    
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                        <v-divider></v-divider>
                                        <v-card-text>
                                            <v-row dense>
                                            <v-col class="no-wrap">Verwachte urenkosten:</v-col>
                                            <v-col class="text-right text-bold pl-8">
                                                <AmountText leading-min v-model='model.chapterResult.hourCosts.budget_amount'/>    
                                            </v-col>
                                        </v-row>
                                        <v-row dense>
                                            <v-col class="no-wrap">Geboekte urenkosten:</v-col>
                                            <v-col class="text-right text-bold pl-8">
                                                <AmountText leading-min v-model='model.chapterResult.hourCosts.cost_amount'/>    
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                    <v-divider v-if="model.chapterResult.hourCosts.warnings.length">
                                    </v-divider>
                                        <v-card-text v-if="model.chapterResult.hourCosts.warnings.length">
                                        <UlCustom v-if="model.chapterResult.hourCosts.warnings.length">
                                            <LiCustom warning v-for='(warning, index) in model.chapterResult.hourCosts.warnings' :key="`hourcost_warning_${index}`">{{ warning }}</LiCustom>    
                                        </UlCustom>
                                    </v-card-text>
                                </v-card>
                            </v-menu>
                        </td>
                    </tr>          
                    <tr v-if="expandHours&&!model.chapterResult.hourCosts.specification.length" class="opacity-50">
                        <td colspan="3">Geen uren gespecificeerd</td>
                    </tr>
                    <tr v-if="expandHours" class="opacity-50" v-for='(line, index) in model.chapterResult.hourCosts.specification' :key="`hourcost_specification_${index}`">
                        <td>
                            <span class="ml-2 d-inline-block"><span class="d-inline-block"><Icon type="child-expanded" class=""></Icon></span></span>                                        
                            {{ line.name }}
                        </td>
                        <td class="pa-2 text-right col-contrast">
                            <AmountText leading-min :value='-line.cost_amount'/>    
                        </td>
                        <td :class="clsColIcon">
                            <v-menu  :close-on-content-click="false" :nxudge-width="200" offset-x >
                                <template v-slot:activator="{ on, attrs }">
                                    <span class="cursor-pointer " v-on="on"><Icon type='tip' :color="line.warnings.length?'error':'primary'"></Icon></span>
                                </template>
                                <v-card>
                                    <v-card-title>
                                        {{ line.name }}
                                    </v-card-title>
                                    <v-divider></v-divider>
                                        <v-card-text>
                                            <v-row dense>
                                                <v-col class="no-wrap">Geboekte uren:</v-col>
                                                <v-col class="text-right text-bold pl-8">
                                                    <AmountText leading-min v-model='line.hours_completed'/>    
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                        <v-divider></v-divider>
                                        <v-card-text>
                                            <v-row dense>
                                            <v-col class="no-wrap">Verwachte urenkosten:</v-col>
                                            <v-col class="text-right text-bold pl-8">
                                                <AmountText leading-min v-model='line.budget_amount'/>    
                                            </v-col>
                                        </v-row>
                                        <v-row dense>
                                            <v-col class="no-wrap">Geboekte urenkosten:</v-col>
                                            <v-col class="text-right text-bold pl-8">
                                                <AmountText leading-min v-model='line.cost_amount'/>    
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                    <v-divider v-if="line.warnings.length">
                                    </v-divider>

                                        <v-card-text v-if="line.warnings.length">
                                        <UlCustom v-if="line.warnings.length">
                                            <LiCustom warning v-for='(warning, index) in line.warnings' :key="`hourcost_specification_warning_${index}`">{{ warning }}</LiCustom>    
                                        </UlCustom>
                                    </v-card-text>
                                </v-card>
                            </v-menu>
                        
                        </td>
                    </tr>          
                    <tr>
                        <td class="">Overige kosten</td>
                        <td class="pa-2 text-right col-contrast">
                            <AmountText leading-min :value='-model.chapterResult.otherCosts'/>    
                        </td>
                        <td class="col-contrast"></td>
                    </tr>          
                    <tr>
                        <td class="text-bold">Totaal kosten</td>
                        <td class="pa-2 text-right col-contrast text-bold">
                            <AmountText leading-min :value='model.chapterResult.totalCosts'/>    
                        </td>
                        <td class="col-contrast"></td>
                    </tr>          
                    <tr>
                        <td></td>
                        <td class="col-contrast">
                        </td>
                        <td class="col-contrast"></td>
                    </tr>
                </tbody>
                <thead>
                    <th class="pl-4 pa-2 text-left">Resultaat</th>
                    <th class="pa-2 text-right" :class="model.chapterResult.result<0?'text-error':'text-success'">
                        <AmountText leading-min :value='model.chapterResult.result'/>    
                    </th>
                    <th class=""></th>
                </thead>
                
            </v-simple-table>

        </v-card-text>
    </v-card>
</template>

<script setup>

    import AmountText from '@controls/text/Amount.vue'
    import { ref } from 'vue';
    import Icon from '@controls/icons/Icon'

    import UlCustom from "@controls/list/UlCustom.vue";
    import LiCustom from "@controls/list/LiCustom.vue";

    const clsColIcon="col-contrast px-2";

    const expandHours = ref(false);
    const expandCost = ref(false);
    const props = defineProps({
        model: {
            type: [Object]
        },
    });

</script>