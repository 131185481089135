import clsApi from '@cls/clsApi'

class clsSalesInvoicePaymentApi extends clsApi {

    /**
     * load defaults for a new payment
     */
    async loadNew(id_invoice) {
        return this.post(`loadnew`, {id: id_invoice}); 
    }
    /**
     * load payment history info - same as laodNew
     */
    async loadInfo(id_invoice) {
        return this.post(`loadinfo`, {id: id_invoice}); 
    }

    /**
     * remove a payment for an invoice
     */
    remove(id_invoice, id) {
        return this.post(`remove`, {id_invoice, id}); 
    }

}

export default clsSalesInvoicePaymentApi;
