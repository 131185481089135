<template>

<div>
    <ActionCombo item-text="name" :noClearable="noClearable" :placeholder="placeholder" :skeleton="compSkeleton" :id_parent="id_parent" :disabled="compDisabled" :rules="rules" v-model="dvalue" :items="items"
                 noData="Relatie heeft geen contactpersonen" :open="!!dvalue" :create="!!id_parent" baseType="relation_contact" :createPayload="{id_relation: id_parent}" @itemcreated="onItemCreated">

    </ActionCombo>
</div>
</template>

<script setup>

    // import RelationContactCombo from '@controls/combo/RelationContact'
    //            <RelationContactCombo :model="model" :rules="model.rules.id_address" :id_parent="model.id_relation" v-model="model.id_address"></RelationContactCombo>

    const props = defineProps({
        disabled: {
            type: [Boolean]
        },
        rules: {
            type: [Array]
        },
        noClearable: {
            type: [Boolean],
            default: false
        },
        bookNumber: {
            type: [Boolean],
            default: false
        },
        skeleton: {
            type: [Boolean]
        },
        value: {
            type: [String, Number]
        },
        id_parent: {
            type: [String, Number]
        },
        placeholder: {
            type: [String],
            default: 'Contactpersoon'
        },
        model: {
            type: [Object]
        }

    })
    
    import ActionCombo from '@controls/combo/ActionCombo'
    import {relation as list} from '@app/list';
    import {computed, watch, ref} from 'vue'
    import string from '@lib/string'

    const emit = defineEmits(['input'])

    const dvalue = computed({
        get() {
            return props.value
        },
        set: function(v) {
            emit('input', v)
        }	
    })

    const compSkeleton = computed({
        get() {
            if (props.skeleton) {
                return true;
            }
            if (!props.model) {
                return false;
            }
            return props.model.isDataLoading;
        }
    });
    const compDisabled = computed({
        get() {
            if (!props.id_parent) {
                return true;
            }
            if (props.disabled) {
                return true;
            }
            if (!props.model) {
                return false;
            }
            return props.model.disabled;
        }
    });
    const refreshCount = ref(0);

    const items = computed({
        get() {
            if (-1 == refreshCount.value) {
                return []; // This will never happen as the count is 0 or higher. It is just for triggering the list --> reactivity triggering.
                           // Do not make a non function statement ( refreshCount.value; ) since it might be optimized out. 
            } 
            if (!props.id_parent) {
                return [];
            }
            let item = list.one(props.id_parent);
            if (!item) {
                return [];                
            }
            return item.contactpersons ||[];
        },
    })

    async function refreshParent() {
        if (props.id_parent) {
            await list.refresh(props.id_parent)
            refreshCount.value ++;
        }
    }
    async function onItemCreated(id) {
        await refreshParent();
    }

    const id_relation = computed({
        get() { return props.id_parent }
    })

    watch(id_relation, async (newValue, oldValue) => {
        await refreshParent();
    })

</script>
