//
// Usage: 
//  import routing from '@app/routing'
//

import clsRouting from '@/app/clsRouting'
import fnExpose from '@shared/lib/expose'
import {ref} from 'vue'

let obj =  ref (new clsRouting());
fnExpose(obj.value, 'system', 'routing')

export default obj.value;