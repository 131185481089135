//
// Usage:
// 
//  var headers = [
//      { type: 'cmd'                         },
//      { excel: false, text: "id",     value: "id",        },
//      { text: "Naam",   value: "un_name",   },
//      { text: "accept", value: "accept",    type: 'bool'},
//  ]
//
//  import useDataTable from '@app/useDataTable'
//
//  import { units as api } from '@app/api'
//  const  dt = useDataTable("dt_units", "unit", api, headers, {
//    actionDblClick:"masterdata.unit.open",
//      noAutoSearch: true, 
//      filter: {
//          q: ''
//      }, itemName: {
//          prefix: 'de',
//          single: 'eenheid',
//          plural: 'eenheden',
//      }
//      
//  })


//

import clsDataTable from '@/app/clsDataTable'
import { ref, watch} from 'vue'


function useDataTable(name, modelName, api, headers,  options) {
    var c = new clsDataTable(name, modelName, api, headers, options);
    var dt = ref(c).value;

    watch(dt.options, (newValue, oldValue) => {
        dt.search(true /* initiated 'internally' */);
    })

    return dt;
}

export default useDataTable;
