//
//  Scaffolding a component which uses events which must be registered and de-registered.
//  De-registration is done automatically on destroy. This is important to prevent duplicated actions.
// 
//  import component from '@shared/lib/ui/component'
//
//  export default component.scaffold(
//     /* Your normal component declaratons*/
//     ... 
//  
//    /* Register eventbus events. Registrations are automatically cleaned up */ 
//    registerEvents(self) {
//        return [
//            eventbus.purchase.saved.note.on( self.refreshRows),
//            eventbus.purchase.saved.on( self.refreshRows),
//            eventbus.purchase.approved.on( self.refreshRows),
//            eventbus.purchase.rejected.on( self.refreshRows)
//        ] 
//    },
//   
//  )
//


var component = {};
component.scaffold = function(base) {

    var fnMounted = base.mounted || function(/* self */) {};
    var fnBeforeDestroy = base.beforeDestroy || function(/* self */) {};

    //////////////////////////////////////////////////////////////////////////////////////////////////////
    // Mounted
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    base.mounted = function() {
        var self = this;
        // Register an array with event registrations which we can automatically cleanup.
        // Usage:
        // Register an array with event registrations which we can automatically cleanup.
        // registerEvents(self) {
        //      return [
        //          eventbus.purchase.saved.note.on( self.refreshRows),
        //          eventbus.purchase.saved.on( self.refreshRows);
        //      ] 
        // }
        //         
        if (base.registerEvents) {
            self.registeredEvents = base.registerEvents(self);
        }

        fnMounted(self);
    };
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    // Before destroy -- unsubscribe the bus
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    base.beforeDestroy = function() {
        var self = this;

        // Register an array with event registrations which we can automatically cleanup.
        if (self.registeredEvents) {
            (self.registeredEvents||[]).forEach( (fnUnRegister) => fnUnRegister() ) 
        }

        fnBeforeDestroy(self);
    };

    return base;
}



export default component;