<template>
    <Dialog :dialog="dlg">
        <v-form ref="form" lazy-validation>
            <Form>

                <FormRow label="Bedrag">
                    <NumberField min="0" dusk="pew_price" amount :model="model" v-model="model.pew_price"></NumberField>
                </FormRow>
                <FormRow v-if="model.useChapters" label="Hoofdstuk" label-v-top>
                    <ActionCombo :model="model" v-model="model.id_project_chapter" :items="model.chapters"></ActionCombo>
                </FormRow>
                <FormRow label="Status" label-v-top>
                    <ExtraWorkStatus :model="model" v-model="model.id_status"></ExtraWorkStatus>
                </FormRow>
                <FormRow v-if="1==2" label="Hoofdstuk" label-v-top>
                    <TextField :model="model" max-length="255" v-model="model.id_project_chapter"></TextField>
                </FormRow>
                <FormRow label="Omschrijving" label-v-top>
                    <TextField :model="model" max-length="255" placeholder="Omschrijving" :rules="model.rules.mandatory" v-model="model.pew_description"></TextField>
                </FormRow>
                <FormRow label="Opmerking" label-v-top>
                    <TextArea :model='model' max-length="255" placeholder='Opmerking' v-model='model.pew_remark'></TextArea>
                </FormRow>
            </Form>
        </v-form>
        <template v-slot:footer-left>
            <v-menu :close-on-content-click="false" :nudge-width="200" offset-x >
                    <template v-slot:activator="{ on, attrs }">
                        <span class="cursor-pointer  px-2 py-2" v-on="on"><Icon type='tip' color="info"></Icon></span>
                    </template>
                    <v-card>
                        <v-card-title>
                            {{ model.isExtra ?"Meerwerk":"Minderwerk"}}
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text style="max-width:600px;">
                            In dit scherm kunt u {{ model.isExtra ?"Meerwerk":"Minderwerk"}} aanmaken.<br>
                            <br>
                            <strong>Status veld</strong><br>
                            Het status veld is optioneel en kan voor uw eigen overzicht worden gebruikt.<br>
                            <br>
                            <strong>Omschrijving</strong><br>
                            De omschrijving wordt overgenomen in de factuur-regel wanneer u een factuur aanmaakt.<br>
                            <br>
                            <strong>Opmerking</strong><br>
                            Het opmerking veld is voor eigen gebruik en wordt nergens op afgedrukt.
                        </v-card-text>
                    </v-card>
                </v-menu>

        </template>
    </Dialog>
</template>



<script setup>
    import useModel from '@/models/project/extrawork'
    import Form from '@controls/forms/Form'
    import FormRow from '@controls/forms/FormRow'
    import TextField from '@controls/input/TextField'
    import Dialog from '@shared/ui/dialogs/ActionDialog'
    import NumberField from '@controls/input/NumberField'
    import ToggleButtonGroup from '@controls/buttons/ToggleButtonGroup'
    import TextArea from '@controls/input/TextArea'
    import ExtraWorkStatus from '@controls/combo/ExtraWorkStatus'
    import ActionCombo from '@controls/combo/ActionCombo'
    import Icon from '@controls/icons/Icon'
    import { ref } from 'vue'
    import clsDialog from '@cls/clsDialog'

    const form = ref(null); 

    let model = useModel();
    let dlg = ref(
        new clsDialog("project_additionalwork", "Meer / Minderwerk", model, [form], {
            icon: 'work'
        } )).value;

    window.dtpext = dlg;
</script>
