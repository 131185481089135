import { clsModel as clsModelBase, fnCreate } from '@cls/clsModel'
import {relation} from '@app/list';

////////////////////////////////////////////////////////////////////////////////////////////////////
//
// clsModel with a some optimit specific addons.
// Usage: 
//    See salesorder/salesorder.js
//
////////////////////////////////////////////////////////////////////////////////////////////////////

class clsModel extends clsModelBase {

    /**
     * this method is only applicable when id_relation is in this model.
     * 
     */
    get rel_name() {
        if (!this.id_relation) {
            return '';
        }
        let rel = relation.one(this.id_relation);
        if (!rel) {
            return '-';            
        }         
        return rel.rel_name;
    }
}
export { clsModel, fnCreate }
