import { clsModel, fnCreate } from '@cls/clsModel'
import { recipes as api } from '@/app/api'
import vat from '@lib/vat'
import Constants from '@app/consts'
import noty from '@shared/lib/noty'

var modelName = "recipe";
const id_optimit_type = Constants.optimit_types.product;

const fields = [
        'pd_name', 
        'pd_code', 
        "use_recipe_price",
        'pd_description', 
        'pd_sales_price_excl_vat', 
        'pd_sales_price_incl_vat', 
        'id_vat', 
        'id_unity', 
        'id_productgroup',
        'lines' 
    ];

class clsRecipe extends clsModel {

    pd_name = null;
    pd_code = null;
    use_recipe_price = null;
    pd_description = null;
    pd_sales_price_excl_vat = null;
    pd_sales_price_incl_vat = null;
    id_vat = null;
    id_unity = null;
    id_productgroup = null;
    lines = [];


    removeLine(line) {
        this.lines = this.lines.filter( (l) => l != line);
        if (!this.lines.length) {
            this.id_vat = null;
        }
    }

    addProduct(item) {
        let line = {
            amount: 1,
            id_product: item.id,
            pd_code: item.pd_code,
            pd_name: item.pd_name,
            un_name: item.un_name,
            pd_sales_price_excl_vat: item.pd_sales_price_excl_vat,
            pd_sales_price_incl_vat: item.pd_sales_price_incl_vat,            
        }
        if (!this.id_vat) {
            this.id_vat = item.id_vat;
        }
        this.lines.push(line);
    }

    // The calculated price from recipe lines.
    get calc_purchase_price() { 
        return (this.lines||[]).reduce( 
            (accumulator, line) => accumulator + (Number(line.amount)||0)*(Number(line.pd_purchase_price)||0),
            0
        )
    }
    set calc_price_excl_vat(v) {} // do nothing 
    get calc_price_excl_vat() { 
        return (this.lines||[]).reduce( 
            (accumulator, line) => accumulator + (Number(line.amount)||0)*(Number(line.pd_sales_price_excl_vat)||0),
            0
        )
    }
    set calc_price_incl_vat(v) {} // do nothing 
    get calc_price_incl_vat() { 
        return (this.lines||[]).reduce( 
            (accumulator, line) => accumulator + (Number(line.amount)||0)*(Number(line.pd_sales_price_incl_vat)||0),
            0
        )
    }

    /**
     * Verify whether the user really meant not to fille a price when specifying a fixed price.
     */
    async checkBeforeSave(data) {
        if (this.use_recipe_price && (!this.pd_sales_price_excl_vat)) {
            await noty.confirm("U wilt een vaste prijs gebruiken maar heeft geen verkoopprijs ingevuld.<br>Wilt u door gaan?");
        }
    }


    /**
     * Make sure that the vendors array is always present, frontend probably depends on array type. 
     * @param {} data 
     * @returns 
     */
    fill(data) {
        data = data ||{};
        if (!data.lines) {
            data.lines = [];
        }
        data.use_recipe_price = !!data.use_recipe_price;
    
        data.pd_sales_price_excl_vat = data.pd_sales_price_excl_vat ||0;  
        data.pd_sales_price_incl_vat = data.pd_sales_price_incl_vat ||0; 

        return super.fill(data);
    }

    get modelRep() {
        return this.pd_code;
    }
    get id_pd_vat() {
        return this.id_vat;
    }
    get price_excl_vat() {
        return this.pd_sales_price_excl_vat;
    }
    get price_incl_vat() {
        return this.pd_sales_price_incl_vat;
    }
    
    set price_incl_vat(value) {
        this.pd_sales_price_incl_vat = value;
        if (!this.isFilling) {
            this.incl2excl();        
        }
    }
    set price_excl_vat(value) {
        this.pd_sales_price_excl_vat = value;
        if (!this.isFilling) {
            this.excl2incl();        
        }
    }
    set id_pd_vat(value) {
        this.id_vat = value;
    }
    // Calculate the excl price from the incl.
    incl2excl() {
        this.pd_sales_price_excl_vat = vat.incl2excl(this.id_vat, this.price_incl_vat);
    }
    // Calculate the incl price from the excl.
    excl2incl() {
        this.pd_sales_price_incl_vat = vat.excl2incl(this.id_vat, this.price_excl_vat);
    }

    
    /**
     * Override to create specific implementations. 
     */
    async doCreate(defaults) {
        defaults = defaults || {};
        var result = await api.loadNew();
        return await super.doCreate(result.data);
    }


    constructor() {
        super({
          api: api,   
          modelName: modelName, 
          id_optimit_type: id_optimit_type, 

          fillable: fields
        })
    } 


 }
 export default fnCreate(clsRecipe , 'clsRecipe');
