<template>

    <Dialog :dialog="dlg">
        <template v-slot:title>{{dlg.title}}</template>

        <div v-html="dlg.fmtBody"></div>
        <div v-if="dlg.warnings.length">
            <UlCustom>
                <LiCustom warning
                    v-for='(item, index) in dlg.warnings' :key='index'>
                    {{ item }}
                    </LiCustom>
            </UlCustom>
        </div>
    </Dialog>

</template>

<script setup>

    import Dialog from '@shared/ui/dialogs/ActionDialog'
    import clsDialog from '@cls/clsDialog'
    import eventbus from '@app/eventbus'
    import UlCustom from "@controls/list/UlCustom"
    import LiCustom from "@controls/list/LiCustom"

    import { ref,computed } from 'vue'

    /**
     * For an alert dialog, we do not need a model. Just use the dialog.
     */
    class clsAlertDialog extends clsDialog {
        body = "";
        title="";
        warnings=[];

        get fmtBody() {
                let b = this.body || "";
                b = b.replace("[TIP]", "<div class='tip primary--text pa-2'>");
                b = b.replace("[/TIP]", "</div>");
                b = b.replace(/\n/g, "<br>");
                return b;
        }
        
        onOpen(params) {
            this.onCreate(params);
        }
        onCreate(params) {
            params = params || {};
            this.body = params.body || "";                        
            this.title = params.title || "Let op.";
            this.open = true;
            this.warnings = params.warnings ||[];
        }
        /**
         * Instead of open on the eventbus.dialog.open event, use the dialog event. It is for backward compatibillity.
         */
        registerEvents() {
            var self = this;
            this.evtRegistrations.push(
                eventbus.dialog.alert.on((params) => {
                    self.onOpen(params);
                })
            )
        }
    }

    let dlg = ref (new clsAlertDialog("alert", "Alert", {}, [], {
        icon: 'alert', save: null, cancel: 'Sluit', noSubTitle: true
    }));

</script>