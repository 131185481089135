import authApi from '@lib/authApi'
import string from '@lib/string'
import eventbus from '@app/eventbus'
import {session} from '@/app/api'

class clsCurrentuser {

    name = null;
    email = null;
    two_factor = false;
    isLoading = false;
    // The logintoken used for support purposes. 
    // It identifies the current support request. 
    // Note that when a normal enduser would add the token, there is no harm as it is ignored.
    // Even if the user would guess a valid one, it would be ignored.
    loginToken = null;

    fill(data) {
        data = data || {};
        this.name = data.name || null;
        this.email = data.email || null;
        this.two_factor = data.two_factor || false;   
        // The logintoken is used once when requesting the user data.
        this.loginToken = null;      
    }

    /**
     * Clear our data
     */
    clear() {
        this.fill();
        // The logintoken is used once when requesting the user data.
        this.loginToken = null;      
    }
    /**
     * Force logout is for handling an explicit logout as a user clicks: 'logout'.
     */
    async forceLogout() {
        try {
            await authApi.logout();                    
        } finally {
            this.fill();
            eventbus.auth.loggedOut({scenario: 'forcelogout'});
        }
    }
    /**
     * Use logout to logout for the user only.
     * This is used e.g. when accepting an invitation. In this case, the user is logged out
     * but the routing should not forward to the login page.
     * @param {} bSilent 
     */
    async logout() {
        try {
            await authApi.logout();
        }
        finally {
            this.fill();
            eventbus.auth.loggedOut({scenario: 'silentlogout'});
        }
    }

    /**
     * When any headers should be provided to the server for any purpose for the current user, override this method.
     * @returns 
     */
    getHeaders() {
        return {};
    }

    /**
     * Return the Initials of the name.
     */
    get paraph() {
        var firstname = this.email; // Just a default
        var lastname = null;
        var arr = (this.name||"").split(" ");
        if (!arr.length) {
            // Do nothing
        } else if (arr.length == 1 ) {
            firstname = arr[0];
        } else {
            firstname = arr[0];
            lastname = arr[arr.length-1];
        }        
        return string.paraph(firstname, lastname);
    }

    /**
     * Get the constructed full name
     */
    get fullName() {
        return this.name;
    }

    /**
     * Update the username. The password is used for safety. 
     *
     * @param {} email 
     * @param {*} password 
     * @returns 
     */
    async updateUsername(email, password) {
        this.isLoading = true;
        var result = await authApi.updateUsername({email: email, password: password});
        if (result.success) {
            this.email = email;
        }
        this.isLoading = false;

        return result;                
    }

    /**
     * For the given email adres, send a password reset link.
     * 
     * @param {}} email 
     * @returns 
     */
    async forgotPassword(email) {
        this.isLoading = true;
        var result = await authApi.forgotPassword({email: email});
        this.isLoading = false;

        return result;
    }

    /**
     * With the given data, reset the user password.
     * 
     * @param {}} email 
     * @returns 
     */
    async resetPassword(email, password, password_confirmation, token) {
        this.isLoading = true;
        var result = await authApi.resetPassword({email: email, password: password, password_confirmation: password_confirmation, token: token});
        this.isLoading = false;
    
        return result;
    }

    /**
     * With the given data, update the current user password.
     * 
     * @param {*} current_password 
     * @param {*} password 
     * @param {*} password_confirmation 
     * @returns 
     */
    async updatePassword(current_password, password, password_confirmation) {
        this.isLoading = true;
        var result = await authApi.updatePassword({current_password: current_password, password: password, password_confirmation: password_confirmation});
        this.isLoading = false;
    
        return result;
    }

    /**
     * For a logged in user, confirm the password.
     * 
     * @param {}} email 
     * @returns 
     */
     async confirmPassword(password) {
        this.isLoading = true;
        var result = await authApi.confirmPassword({password: password});
        this.isLoading = false;

        return result;
    }

    /**
     * Enable 2-factor authentication by generating the necesary backend data
     * 
     * @param {}} email 
     * @returns 
     */
    async enableTwoFactor() {
        this.isLoading = true;
        var result = await authApi.enableTwoFactor();
        this.isLoading = false;

        return result;
    }
    /**
     * Confirm 2-factor authentication by entering a valid authentication code.
     * 
     * @param {}} email 
     * @returns 
     */
     async confirmTwoFactor(authcode) {
        this.isLoading = true;
        var result = await authApi.confirmTwoFactor(authcode);
        if (result.success) {
            this.two_factor = true;
        }
        this.isLoading = false;

        return result;
    }

    /**
     * Disable 2-factor authentication
     * 
     * @param {}} email 
     * @returns 
     */
     async removeTwoFactor() {
        this.isLoading = true;
        var result = await authApi.removeTwoFactor();
        if (result.success) {
            this.two_factor = false;
        }
        this.isLoading = false;

        return result;
    }

    /**
     * Get a svg of the QR code for enabling 2 factor authentication via TOTP - Time based One Time Password 
     * 
     * @param {}} email 
     * @returns 
     */
    async getTwoFactorQR() {
        this.isLoading = true;
        var result = await authApi.getTwoFactorQR();
        this.isLoading = false;

        return result;
    }

    /**
     * Get the recovery codes for allowing access to the sytem when no phone available.
     * 
     * @param {}} email 
     * @returns 
     */
     async getTwoFactorRecoveryCodes() {
        this.isLoading = true;
        var result = await authApi.getTwoFactorRecoveryCodes();
        this.isLoading = false;

        return result;
    }

    /**
     * Reset the recovery codes for allowing access to the sytem when no phone available.
     * 
     * @param {}} email 
     * @returns 
     */
    async resetTwoFactorRecoveryCodes() {
        this.isLoading = true;
        var result = await authApi.resetTwoFactorRecoveryCodes();
        this.isLoading = false;

        return result;
    }

    /**
     * Send the Two factor challenge for validation.
     * 
     * @param {}} email 
     * @returns 
     */
    async twoFactorChallenge(code, recovery_code) {
        this.isLoading = true;
        var result = await authApi.twoFactorChallenge(code, recovery_code);
        this.isLoading = false;

        return result;
    }

    /**
     * Implement this method for application specific handling of the loaded data.
     * @param {*} data 
     */
    onAppDataLoaded(data) {
        // -- optional -- 
        //    override and implement
    }

    /**
     * Load application data.
     * Typically loads data which is required for normal application run. 
     * For example, loading relations, settings etcetera.
     * 
     * Override this method to alter the behavior.
     *  
     * @param {} a 
     */
    async loadAppData() {
        this.isLoading = true;
        var result = await authApi.loadAppData();
        if (result && result.success) {
            this.onAppDataLoaded(result.data);
            eventbus.appdata.loaded(result.data);
        } else {
            this.logout(); // silently logout.
        }

        this.isLoading = false;
        return result;
    }

    async getAuthUser(p /* extra parameter which overridden implementations could use*/) {
        var result = await authApi.getAuthUser();
        if (result && result.success) {
            this.fill(result.data);
            
            await this.loadAppData();
        }
        else {
            this.logout(); // silently logout
        }
        return result;
    }

    /**
     * Login
     * Note that the token is only uses for support requests. 
     * There is no harm in providing it by a 'normal' end user as it is ignored.
     */
    async login(email, password, token) {
        this.isLoading = true;
        this.loginToken = token;
        var result = await authApi.login({email: email, password: password});
        if (result && result.success && !result.action) {
            result = await this.getAuthUser(token);
        }
        this.isLoading = false;

        return result;
    }

    isLoggedIn() {
        return !!this.name;
    }    

    /**
     * Check whether the user is logged in.
     * 
     * @returns 
     */
    async checkIsLoggedIn(p /* extra parameter which overridden implementations could use*/) {
        return this.getAuthUser(p); // Caller can deside to await or not to await.
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    //
    // 
    //
    ////////////////////////////////////////////////////////////////////////////////////////////////////

    /**
     * Update the user person Name.
     * 
     * @param {*} name 
     * @returns 
     */
    async updateUserPersonName(name, password) {

        this.isLoading = true;
        try {
            // var result = await authApi.setUserPersonName(name, password);            
            var result = await session.setUserPersonName(name, password);            
            this.name = name; // Only when successfull.
            return result;
        } finally {
            this.isLoading = false;            
        }
    }

}

export default clsCurrentuser;
