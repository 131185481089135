import eventbus from '@app/eventbus'
import {settings as api} from '@app/api'
import {onUnmounted} from 'vue'

/**
 * Use this class as a base class for settings. V2.
 * 
 * Important, if you clone this model, make sure to destroy on onMount. 
 * This is important as we must unregister the events, so that the new 
 * model can be cleaned up by memory mgt.
 * 
 */

class clsSettings2 {
    
    isDataLoading = false;
    arrFields = [];

    rules = {};

    // We store the registered events so that we can unregister on destroy.
    eventRegistrations = [];

    modelName = null;
    constructor(modelName, arrFields) {
        if (!modelName) {
            throw new "modelName is mandatory";
        }
        this.arrFields = arrFields;
        this.modelName = modelName || ""; 
        this.registerEvents();
    }

    clone(noAutoLoad) {
        var obj = new this.constructor(this.modelName, this.arrFields);
        
        // When this object is cloned, we want to cleanup resources on unmount. 
        // A cloned object is a shortlived object which is re-created when necesary.
        onUnmounted( async () => {
            obj.destroy();
        });       
        if (!noAutoLoad) {
            obj.load();
        }     
        return obj;
    }
    
    /**
     * Destroy. Call this in onUnmounted when this model is created in a component. 
     * It unregisterers events, which releases the object for memory cleanup.
     */
    destroy() {
//        var me = this.modelName;
        this.eventRegistrations.forEach( (fnUnregister) => {
//            console.log('Cleaning up ' + me)
            fnUnregister()
        });
    }

    /**
     * Fill the data. Overwrite when appropriate.
     * 
     * @param {} data 
     */
    fill(data) {
        data = data || {}
        for (var key in data) {
            if (undefined !== this[key]) {
                this[key] = data[key];
            }
        }
    }

    toJSON() {
        return this.propsToJSON(this.arrFields);
    }

    /**
     * Helper to add one or more properties to a json structure.
     * Example: 
     *  var json = this.propsToJSON(["id","name","rights"])
     * @param {*} arrProps 
     * @returns 
     */
    propsToJSON(arrProps) {
        let result = {};
        var self = this;
        (arrProps || []).forEach(  ( item ) => result[item] = self[item] ) 
       
        return result;
    }

    /*
     * Load data from the server. As a side effect, we refresh our own settings by distributing the loaded event. 
     */
    async load() {
        this.isDataLoading = true;
        this.isDirty = false;

        try {
            let result = await api.load(this.modelName);
            // call the loaded event so that we refresh our own and also other instance data.
            eventbus.appdata.loaded({settings: result.data}); 
        }
        finally {
            this.isDataLoading = false;
        }
    }

    /*
     * save settings data to the server. As a side effect, we refresh our own settings. 
     */
    async save(data) {
        this.isDataLoading = true;
        var json = this.toJSON();
        try {
            let result = await api.save(this.modelName, json);        
            eventbus.appdata.loaded({settings: result.data}); // Will call the fill method via register events. 
        }
        finally {
            this.isDataLoading = false;
        }
    }
    /**
     * Register for events.
     */
    registerEvents() {
        var self = this;
        var modelName = self.modelName;
        if (!modelName) {
            return;
        }
        this.eventRegistrations.push( 
            eventbus.appdata.loaded.on(  (data) => {
                if (data && data.settings && data.settings[modelName]) {
                    self.fill(data.settings[modelName]);    
                }
            })
        )        
    }
}

export default clsSettings2