<template>

        <v-btn elevation="0" class="header-action-button px-0" :disabled="compDisabled" @click="onClick">
            <Icon :small="small" :color="iconColor" :type="type"></Icon>                                    
        </v-btn>

</template>

<script setup>
import Icon from '@controls/icons/Icon'
import {computed, ref} from 'vue'
import action from '@app/action'

/**
 * A Title Header for dialogs 
 * 
 * Usage: 
 *   import HeaderButton from '@/components/ui/dialog/HeaderActionButton'
 * 
 *   <HeaderTitle :model="model">
 *      <template v-slot:header-icon></template>
 *   </HeaderTitle
 */
const props = defineProps({
    type: {},
    iconColor: {
        type: [String],
        default: null
    },
    small: {
        type: [Boolean],
        default: false
    },
    action: {
        type: [String],
        default: null
    },
    disabled: {
        type: [Boolean],
        default: false
    },
})

const emit = defineEmits(['click'])
function onClick(evt) {
    emit('click', evt);
}

const compDisabled = computed({
    get() {
        if (props.disabled) {
            return true;
        }
        if (props.action) {
            if (!action.can(props.action)) {
                return true;
            }
        }
        return false
    }
})

</script>