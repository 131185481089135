<template>
    <tr>
        <td class="col-cmd">                 
            <Checkbox v-if="!item.noCheckbox && dt.checkboxed" v-model="item.checked"></Checkbox>
            <span v-else-if="!item.noMenu && !dt.noMenu">
                <slot name="menu" :item="item" :dt="dt"></slot>
            </span>
            <slot v-else name="menucmd" :item="item" :dt="dt"></slot>
        </td>
        <td class="draghandle col-cmd">
            <span>
                <Icon class="cursor-move-y" type="drag"></Icon>
            </span>
        </td>
        <td :colspan="leadingColumns"></td>
        <td :colspan="columnSpan" class="overflow-y">
            <RichTextLine dusk="name" :disabled="model.isOpenOrLater" ref="rfocus" no-border :model="model" placeholder="Omschrijving" v-model="item.tphp_pd_name"></RichTextLine>
        </td>
        <td :colspan="trailingColumns">
        </td>
    </tr>
</template>


<script setup>
    import RichTextLine from '@controls/input/RichTextLine'

    import IconMenu from "@controls/menu/IconMenu.vue";
    import Icon from '@controls/icons/Icon'
    import {computed} from 'vue'

    const props = defineProps({
        model: {
            type: [Object]
        },
        item: {
            type: [Object]
        }, 
        dt: {
            type: [Object]
        },
        columnHeaders: {
            type: [Object, Array]
        },        
    });

    let textColumnValue = 'tphp_pd_name';
    // We want the text content to start at the same column as the 
    // text content of 'normal' product fields.
    // 
    var leadingColumns = computed({
        get() {            
            // Note that columnHeaders also has the 'drag' field. 
            // However, we do draw the drag field, wo we need to subtract it from the leading columns.
            let indexTextField = (props.columnHeaders||[]).findIndex( (x) => x.value == textColumnValue);
            if (indexTextField < 0) {
                return 0;
            }
            // Remove one column for the drag column
            return indexTextField -1;
        }
    })
    // The trailing columns after the text field
    var trailingColumns = computed({
        get() {

            // Note that the total columns are all columns minus the cmd column, which is not considered a 'normal' column.
            let totalColumns = (props.columnHeaders||[]).length;
            // Note as well that we draw the 'drag' column ourselves. It should be subtracted from the index of the text field.
            let indexTextField = (props.columnHeaders||[]).findIndex( (x) => x.value == textColumnValue)  -1 /* subtract the drag column */;
            if (indexTextField < 0) {
                return 0;
            }            
            let trailCols = totalColumns - indexTextField;
            // Exclude the cmd column and the text column itself from the totals. 
            trailCols = trailCols -2;
            return trailCols;
        }
    })
    // The text column is as wide as the description of other lines.
    //
    var columnSpan = computed({
        get() {
            return 1;
        }
    })
</script>
