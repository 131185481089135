<template>
    <div>        
        <Datatable :dt="dt" class="full-height">
            <template v-slot:menu ="{ item }">
                <ActionMenuItem action="project_othercosts.open" @click="()=>onOpen(item)"></ActionMenuItem>
                <v-divider></v-divider>
                <ActionMenuItem action="project_othercosts.remove" @click="dt.onStartRemove(item)"></ActionMenuItem>
            </template>

            <template v-slot:footer.prepend>
                <v-row dense>
                    <v-col>
                        <v-card flat>
                            <v-card-text>
                                <ActionButton action="project_othercosts.create" sub dusk="btn-add-othercost" :model="model" icon="add" xmain xprimary @click='addLine'>Nieuwe kosten</ActionButton>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </template>

        </Datatable>

    </div>
</template>



<script setup>
    import useDataTable from '@app/useDataTable'
    import Datatable from '@datatable/Datatable'
    import ActionMenuItem from "@controls/menu/ActionMenuItem.vue";
    import { project_othercost as api } from '@app/api'
    import dlg from '@app/dlg'
    import ActionButton from '@controls/buttons/ActionButton'

    const props = defineProps({
        model: {
            type: [Object]
        },
    });

    var headers = [
        { type: 'cmd' },

        { text: "Bedrag",        value: "poc_amount", type:'amount'},
        { visible:false, text: "Hoofdstuk",  value: "chapter", },
        { text: "Kosten",        value: "poc_name", type:'open', open:(item)=>onOpen(item)},
        { text: "Omschrijving",  value: "poc_description", },
        { isUtc: true, text: "Gewijzigd",                value: "updated_at",   type: 'datetime', visible: false},
        { text: "Gewijzigd door",           value: "updated_by",    visible: false},
        { isUtc: true, text: "Aangemaakt",               value: "created_at",    type: 'datetime', visible: false, sort: 'desc'},
        { text: "Aangemaakt door",          value: "created_by",    visible: false},
    ]

    var dt = useDataTable("dt_project_other_cost", "project_othercosts", api, headers, {
        // hideDefaultFooter: true,
        noPager: true,
        itemName: {
            prefix: 'De',
            single: 'overige kosten',
            plural: 'overige kosten',
        }, parent: {
            id_project: props.model.id
        },
        actionDblClick: (evt, item) =>onOpen(item),
        canArchive: false
    })

    function addLine() {
        dlg.create('project_othercosts', {id_project: props.model.id, chapters: props.model.chaptersv2})
    }
    async function onOpen(item) {
        var xxx = await dlg.promised.open('project_othercosts', item, {chapters: props.model.chaptersv2})        
    }
</script>