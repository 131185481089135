<template>

<div>
    <ActionCombo item-text="name" no-clearable :placeholder="placeholder" :skeleton="compSkeleton" :id_parent="id_parent" :disabled="compDisabled" :rules="rules" v-model="dvalue" :items="items"
                 actionOpen="relation.address.open" actionCreate="relation.address.create">
    </ActionCombo>
</div>
</template>

<script setup>

    // import RelationAddressCombo from '@controls/combo/RelationAddress'
    //            <RelationAddressCombo :model="model" :rules="model.rules.id_address" :id_parent="model.id_relation" v-model="model.id_address"></RelationAddressCombo>

    const props = defineProps({
        disabled: {
            type: [Boolean]
        },
        rules: {
            type: [Array]
        },
        noClearable: {
            type: [Boolean],
            default: false
        },
        bookNumber: {
            type: [Boolean],
            default: false
        },
        skeleton: {
            type: [Boolean]
        },
        value: {
            type: [String, Number]
        },
        id_parent: {
            type: [String, Number]
        },
        placeholder: {
            type: [String],
            default: 'Factuuradres'
        },
        model: {
            type: [Object]
        }

    })
    
    import ActionCombo from '@controls/combo/ActionCombo'
    import {relation as list} from '@app/list';
    import {computed, watch} from 'vue'
    import string from '@lib/string'

    const emit = defineEmits(['input'])

    function repAddress(item) {
        var value = string.concat(",", item.adr_city, item.adr_street);
        if (string.isNotEmpty(item.adr_street)) {
            value = string.concat(" ", value, item.adr_street_number);
        }
        return value;
    }

    function repBooknumber(item) {

        if (string.isNotEmpty(item.booknumber)) {
            return `Boekhoudnummer: ${item.booknumber}`;
        }
        return '';
    }

    const dvalue = computed({
        get() {
            return props.value
        },
        set: function(v) {
            emit('input', v)
        }	
    })

    const compSkeleton = computed({
        get() {
            if (props.skeleton) {
                return true;
            }
            if (!props.model) {
                return false;
            }
            return props.model.isDataLoading;
        }
    });
    const compDisabled = computed({
        get() {
            if (props.disabled) {
                return true;
            }
            if (!props.model) {
                return false;
            }
            return props.model.disabled;
        }
    });

    const items = computed({
        get() {
            if (!props.id_parent) {
                return [];
            }
            let item = list.one(props.id_parent);
            if (!item) {
                return [];                
            }
            return item.addresses ||[];
        },
    })
    const id_relation = computed({
        get() { return props.id_parent }
    })

    watch(id_relation, (newValue, oldValue) => {
        if (props.id_parent) {
            list.refresh(props.id_parent)
        }
    })

</script>
