import { clsModel, fnCreate } from '@/app/clsModel'
import { salesorder as api } from '@/app/api'
import { numeric } from '@lib/numeric'
import { date } from '@lib/date'
import Constants from '@app/consts'
import {salesorderterm} from '@app/list';
import noty from '@shared/lib/noty'
import eventbus from '@app/eventbus'

var modelName = "salesorder";
const id_optimit_type = Constants.optimit_types.salesorder;

const fields = [
    'id', 
    'vat_shifted', 
    'ord_number',
    'ord_name',
    'ord_reference',
    'ord_lines_incl_excl_vat',
    'id_relation',
    'id_project',
    'pro_number',
    'archived_at',
    'lines',
];

class clsSalesOrderLine {

    id                     = null;
    id_product             = null;
    id_vat                 = null;
    ol_date                = null;
    ol_pd_name             = null;
    ol_amount              = null;
    ol_sales_price         = null;
    ol_sub_total           = null;
    id_unity               = null;
    id_invoice             = null;
    inv_number             = null;
    sup_name               = null;
    pd_name                = null;
    pd_code                = null;
    pd_supplier_code       = null;
    pd_supplier_name       = null;
    checked                = false;     // Order lines are selectable. The table component uses the checked property for this. 
                                        // In order for this to be reactive, the property must be there already from creation time. 

    constructor(data) {        
        data = data || {};

        this.checked            = false;

        this.id                 = data.id || null;
        this.id_product         = data.id_product;
        this.id_vat             = data.id_vat;
        this.ol_date            = data.ol_date;
        this.ol_pd_name         = data.ol_pd_name;
        this.ol_amount          = data.ol_amount;
        this.ol_sales_price     = data.ol_sales_price;
        this.ol_sub_total       = data.ol_sub_total;
        this.id_unity           = data.id_unity;
        this.id_invoice         = data.id_invoice;
        this.inv_number         = data.inv_number;
        this.sup_name           = data.sup_name;
        this.pd_name            = data.pd_name;
        this.pd_code            = data.pd_code;
        this.pd_supplier_code   = data.pd_supplier_code;
        this.pd_supplier_name   = data.pd_supplier_name;        
    }

    get isInvoiced() {
        return !!this.id_invoice;
    }
    get isExpired() {
        if (!this.ol_date) {
            return false;
        }
        return date.isInPast(this.ol_date);
    }
    get canRemove() {
        return !this.isInvoiced;
    }
    get line_total() {
        return numeric.round(this.ol_amount || 1) * numeric.round(this.ol_sales_price || 0);
    }
}

class clsSalesOrder extends clsModel {

    lines = [];
    ord_number = null;
    ord_name = null;
    ord_reference = null;
    ord_lines_incl_excl_vat = null;
    id_relation = null;
    id_project = null;
    pro_number = null;
    archived_at = null;
    vat_shifted = false;

    get modelRep() {
        return this.ord_number;
    }    

    get isArchived() {
        return !!this.archived_at;
    }
    set isArchived(b) {
        this.archived_at = b ? date.iso.today() : null;
    }

    get ord_amount() {
        return this.lines.reduce( (acc, line) => acc+(line.line_total||0),0);
    }
    get open_amount() {
        var total    = this.ord_amount; 
        var invoiced = this.lines.filter( (line)=>line.isInvoiced).reduce( (acc, line) => acc+(line.line_total||0),0);
        return numeric.round(total -invoiced)
    }
    
    get useAmountsExclVat() {
        return this.ord_lines_incl_excl_vat == "excl"
    }
    set useAmountsExclVat(value) {
        this.ord_lines_incl_excl_vat = value ? "excl" : "incl";
    }

    addTerms(termData) {
        termData = termData || {};
        var amount = termData.amount ||0;
        var id_order_term = termData.id_order_term;
        if (!id_order_term) {
            console.error("unknown term. Canceled.");
            return;
        }
        var orderTerm = salesorderterm.one(id_order_term);
        if (!orderTerm) {
            console.error("Term not found. Canceled.");
            return;
        }
        window.selorderterm = orderTerm;
        if (!orderTerm.lines ||!orderTerm.lines.length) {
            console.error("Term has no lines. Canceled.");
            return;
        }
        orderTerm.lines.forEach( (line) => {
            var pct = line.otl_pct || 0;
            var data = {
                ol_pd_name : line.otl_name || "",
                ol_amount  : 1,
                ol_date    :  date.iso.today(), 
                ol_sales_price: numeric.round(amount * (pct/100)),
                id_vat: Constants.defaults.id_vat, // high. Ignored when shifted. 
            };        
            this.lines.push( new clsSalesOrderLine(data));
        });        

        return;
    }

    addLine() {
        var data = {
            ol_pd_name : null,
            ol_amount  : 1,
            ol_sales_price: 0,
            id_vat: Constants.defaults.id_vat, // high. Ignored when shifted. 
            ol_date:  date.iso.today(), 
        };        
        this.lines.push( new clsSalesOrderLine(data));
    }
    addProductLine(product) {
        if (!product) {
            return;
        }
        var data = {
            ol_amount: 1,
            ol_sales_price:     this.useAmountsExclVat ? product.pd_sales_price_excl_vat : product.pd_sales_price_incl_vat,
            id_product:         product.id,
            ol_pd_name:         product.pd_name,
            pd_code:            product.pd_code,
            pd_supplier_code:   product.pd_supplier_code,
            pd_supplier_name:   product.pd_supplier_name,
            id_vat:             product.id_vat,
            id_unity:           product.id_unity,
            sup_name:           product.sup_name,
            ol_date:  date.iso.today(), 
        }
        if (product.amount) {
            data.ol_amount = product.amount;
        }
        this.lines.push( new clsSalesOrderLine(data));
    }
    removeLine(lineRemove) {
        if (lineRemove.isInvoiced) {
            noty.alert('Deze regel is al gefactureerd en kan daarom niet verwijderd worden.');
            return false;
        }
        this.lines = this.lines.filter( (line) => line != lineRemove);
    }

    _createLine(data) {
        data = data || {}
        return new clsSalesOrderLine(data);
    }

    /**
     * Create an invoice for one or more lines. 
     * Creation of the line returns the new line data as well as the id of the created invoice: 
     * {
     *    id_invoice: 1234565,
     *    lines: []
     * }
     * 
     * 
     * @param {*} lines 
     */
    ourInvokedSalesInvoiceEvent = false;
    async createInvoice(lines) {
        this.isLoading = true;
        try {
            var {data} = await api.createInvoice(this.id, lines);
            this.fill(data.salesorder);
            // The invoice is created 'under water'. We need to send a save notification so that 
            // screens which need to be refreshed can do so. 
            this.ourInvokedSalesInvoiceEvent = true; // We should not react on this one.
            eventbus.model.saved("salesinvoice", {id: data.invoice.id_invoice});
            // Also, in essence, we are saved as e.g. the exiry date is adjusted.
            eventbus.model.saved(this.modelName, this);

            return data.invoice.id_invoice;
        }
        finally {
            this.ourInvokedSalesInvoiceEvent = false;
            this.isLoading = false;
        }
    }

    async archive() {
        this.isLoading = true;
        try {
            await api.archive([this.id]);
            this.isArchived = true; // We don't get the data back
            this.sendSavedEvent(this);
        }
        finally {
            this.isLoading = false;
        }
    }
    async unArchive() {
        this.isLoading = true;
        try {
            await api.unArchive([this.id]);
            this.isArchived = false; // We don't get the data back
            this.sendSavedEvent(this);
        }
        finally {
            this.isLoading = false;
        }
    }

    fill(data) {
        this._disabled = false;        
        data = data ||{};
        data.ord_lines_incl_excl_vat = data.ord_lines_incl_excl_vat == "incl" ? "incl" : "excl";
        data.lines = data.lines || [];
        data.lines = data.lines.map( (line) => this._createLine(line));

//         // Important, we are still filling the data from the backend. 
//         // This means we still do not want to trigger setting defaults based on field changes.
//         // Therefore, we - like the parent implementation - must set the isFilling property.
//         try {
//             this.isFilling = true;
//         }
//         finally {
//             this.isFilling = false;
//         }


        return super.fill(data);
    }

    constructor() {
        super({
          api: api,   
          modelName: modelName, 
          id_optimit_type: id_optimit_type, 

          fillable: fields
        })

        // We need to refresh when an invoice is created / changed or removed.
        // When we are open in a dialog, we can safely assume that the invoice is opened by us.
        // So the check is easy: on an event for a salesinvoice, when the dialog is open, reload the data.
        var self = this;
        eventbus.model.saved.on( (type, para) => {
            // Do not respond to events we send ourselves.
            if (self.ourInvokedSalesInvoiceEvent) {
                return;
            }
            if (!self.isDialogOpen) {
                return; // No need for action if we are not open.
            }
            if (type == 'salesinvoice' || type == 'salesinvoice_send') {
                self.reload();
            }
            if (type == 'salesinvoice_send') {
                eventbus.model.saved(this.modelName, this);
            }
        })
        eventbus.model.removed.on( (type, para) => {
            if (!self.isDialogOpen) {
                return; // No need for action if we are not open.
            }
            if (type == 'salesinvoice') {
                self.reload();
            }
        })
    } 
        
 }
 export default fnCreate(clsSalesOrder , 'clsSalesOrder');
