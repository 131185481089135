import { clsModel, fnCreate } from '@cls/clsModel'
import { customfields as api } from '@/app/api'
import string from '@lib/string'

var modelName = "customfield";
const fields = ["id", "id_entity", "id_optimit_type", "type", "name", "description", "default", "values", "mandatory"];

class clsCustomField extends clsModel {

    id_optimit_type  = null;
    id_entity        = null;

    _type            = null;
    name            = null;
    description     = null;
    default        = null;
    _values          = null;
    mandatory       = null;

    get values() {
        return this._values;
    }    
    set values(value) {
        this._values = value;
        if (this.isFilling) {
            return;
        }
        this.default = null;
    }

    get type() {
        return this._type;
    }    
    set type(value) {
        this._type = value;
        if (this.isFilling) {
            return;
        }

        switch (value) {
            case "checkbox":   this.default = false; break;
            case "text":       this.default = ""; break;
            case "combo":      this.default = ""; break;
            case "amount":     this.default = 0; break;
            case "percentage": this.default = 0; break;
            case "number":     this.default = 0; break;
            case "date":       this.default = ""; break;
            case "time":       this.default = 0; break;    
        }

    }    
    get showDefaultValue() {
        if (this.type =="combo" && !this.values) {
            return false;
        }
        return true;
    }
    get modelRep() {
        return this.name;
    }    

    /**
     * Convert the comma separated values to combobox values
     */
    get comboItems() {
        var values = string.split(this.values, ",");
        return values.map( (x) => { return {id: x, name: x} });
    }

    fill(data) {
        data.type = data.type || "text";
        data.mandatory = !!data.mandatory;
        super.fill(data);
    }

    /**
     * override the implementation when required 
     */
    async onAfterLoad(data, params) {

        params = params || {};
        this.id_optimit_type  = params.id_optimit_type;
        this.id_entity        = params.id_entity;

        if (empty(this.id_optimit_type)) {
            throw "id_optimit_type is mandatory for custom fields.";
        }
    }


    /**
     * Also include the id_entity and id_optimit_type in the data
     */
    toJSON() {
        let json = super.toJSON();
        json.id_entity = this.id_entity;
        json.id_optimit_type = this.id_optimit_type;
        json.default = `${json.default}`; // must be a string.
        return json;
    }
    

    constructor() {
        super({
          api: api,   
          modelName: modelName, 

          fillable: fields
        })
    } 
        
 }
 export default fnCreate(clsCustomField , 'clsCustomField');
