<template>
    <Dialog :dialog="dlg">

        <v-form ref="form" @submit.prevent="()=>false" lazy-validation>

            <Form>
                <FormRow label="Huidige wachtwoord">
                    <PasswordField inner-icon placeholder="Huidige wachtwoord" dense
                        :rules="model.rules.mandatory" 
                        v-model="model.current_password">
                    </PasswordField>
                </FormRow>
                <FormRow label="Nieuw wachtwoord">
                    <PasswordField inner-icon placeholder="Nieuwe wachtwoord" dense
                        :rules="model.rules.mandatory" 
                        v-model="model.password">
                    </PasswordField>
                </FormRow>
                <FormRow label="Bevestig wachtwoord">
                    <PasswordField inner-icon placeholder="Bevestig wachtwoord" dense
                        :rules="model.rules.mandatory" 
                        v-model="model.password_confirm">
                    </PasswordField>
                </FormRow>
            </Form>

        </v-form>


    </Dialog>

</template>

<script setup>

import PasswordField from '@controls/input/PasswordField'
import Dialog from '@shared/ui/dialogs/ActionDialog'
import clsDialog from '@cls/clsDialog'
import Form from '@controls/forms/Form'
import FormColumn from '@controls/forms/FormColumn'
import FormRow from '@controls/forms/FormRow'
import {ref} from 'vue'

import useModel from '@shared/models/clsChangePassword'; 
const form = ref(null); 

let model = useModel();
let dlg = ref(
    new clsDialog("changepassword", "Verander uw wachtwoord", model, [form], {
        noSubTitle: true,
        save: 'Wijzig',
        icon: 'security',
    } )).value;

</script>