import clsApi from '@cls/clsApi'

class clsRecipe extends clsApi {

    expand(id, amount) {
        return this.post(`expand`, {id: id, amount: amount}); 
    }
}

export default clsRecipe;
