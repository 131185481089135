import eventbus from '@app/eventbus'
import {dashboard as api} from '@app/api'

/**
 * Dashboard settings. Note that, as dynamic configuration is not implemented yet, 
 * saving the widgets is not thorougly tested.
 * 
 * Note that we do not derive from dashboard settings as we do not need the 'fancy' stuff.
 */
class clsDashboardSettings {
    
    widgets = [];

    constructor() {
        this.registerEvents();        
    }

    /**
     * Get a copy of the widget array.
     * @returns 
     */    
    getWidgets() {
        return [...this.widgets];
    }

    /**
     * Is the widget with the given id in our configuration?
     */
    hasWidget(id_widget) {
        return !!(this.widgets||[]).find( (id) => id == id_widget);
    }

    /**
     * Save the array of widgets in the given order.
     */
    async save(id_widgets) {
        let result = await api.save({ids: id_widgets});
        this.widgets = result?.data?.settings?.dashboard?.widgets || [];
    }

    /**
     * Register for events.
     */
    registerEvents() {
        eventbus.appdata.loaded.on(  (data) => {
            if (undefined != data?.settings?.dashboard?.widgets) {
                this.widgets = data?.settings?.dashboard?.widgets || [];
            }
        })
    }

}

export default clsDashboardSettings;
