<template>
    <Dialog :dialog="dlg">

        <div class="">
            Let op. Na het veranderen van de gebruikersnaam kunt u alleen nog met deze nieuwe gebruikersnaam inloggen. 
            Zorg er voor dat u zeker weet dat u toegang tot dit e-mail adres heeft.
        </div>
        <div class="my-4">
            Voor de veiligheid moet u uw huidige wachtwoord invoeren.
        </div>
        <v-divider class="mb-4" ></v-divider>

        <v-form ref="form" @submit.prevent="()=>false" lazy-validation>

            <Form>
                <FormRow label="Gebruikersnaam (email)">

                    <TextField placeholder="Gebruikersnaam" 
                        :rules="model.rules.mandatory" 
                        v-model="model.email">
                    </TextField>

                </FormRow>
                <FormRow label="Uw wachtwoord">

                    <PasswordField inner-icon placeholder="Huidige wachtwoord" scdense="!true"
                        :rules="model.rules.mandatory" 
                        v-model="model.password">
                    </PasswordField>

                </FormRow>
            </Form>

        </v-form>


    </Dialog>

</template>

<script setup>

import PasswordField from '@controls/input/PasswordField'
import TextField from '@controls/input/TextField'
import Dialog from '@shared/ui/dialogs/ActionDialog'
import clsDialog from '@cls/clsDialog'
import Form from '@controls/forms/Form'
import FormColumn from '@controls/forms/FormColumn'
import FormRow from '@controls/forms/FormRow'
import {ref} from 'vue'

import useModel from '@shared/models/clsChangeUsername'; 
const form = ref(null); 

let model = useModel();
let dlg = ref(
    new clsDialog("changeusername", "Verander uw gebruikersnaam", model, [form], {
        noSubTitle: true,
        save:'Wijzig',
        icon: 'security',
    } )).value;

window.dcun = dlg;
</script>

