<template>
    <v-card class="mx-auto rich-text-editor wysiwyg-editor" outlined>
        <v-toolbar flat dense>
            <slot name="toolbar-left" v-bind:editor="editor"></slot>
            <v-btn :disabled="disabled" class="ml-2" small icon v-for="(cmd, index) in commands" :key="index" @click="() => cmd.fn(editor)">
                <Icon :class="{ 'text-bold': editor && editor.isActive(cmd.name) }"  small :type="cmd.icon"></Icon>
            </v-btn>
            <slot name="toolbar-cmd-right"  v-bind:editor="editor"></slot>
            <v-spacer></v-spacer>
            <v-menu offset-y v-if="variables&&variables.length">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn class="mr-6" depressed icon v-bind="attrs" v-on="on" title="Voeg een variabele in">
                        <slot name="variable-trigger">
                            <span class="">variabele<Icon class="ml-1" small type="dropdown"></Icon></span>
                        </slot>
                    </v-btn>
                </template>
                <v-list dense>
                    <v-list-item @click="() => editor.chain().focus().insertContent(` ${item.value} `).run()"
                        v-for="(item, index) in variables"
                        :key="index"
                        link
                    >
                    <v-list-item-title >{{ item.label }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu> 
            <slot name="toolbar-right"  v-bind:editor="editor"></slot>
        </v-toolbar>

        <v-divider/>
        <v-card-text class="wysiwyg-content pa-2" :class="classHeight">            
            <editor-content class="rich-text-area" :editor="editor" :editable="!disabled&&!skeleton"/>
        </v-card-text>
  </v-card>

</template>

<script setup>
    import Icon from '@controls/icons/Icon'

    const commands = [
        {name: 'bold', icon: 'bold', fn: (editor) => {editor.chain().focus().toggleBold().run()}},
        {name: 'italic', icon: 'italic', fn: (editor) => {editor.chain().focus().toggleItalic().run()}},
        {name: 'bulletList', icon: 'bullet-list', fn: (editor) => {editor.chain().focus().toggleBulletList().run()}},
    ];
</script>

<script>
//   import RichTextArea from '@controls/input/TextArea'
//   <TextArea :skeleton="model.isDataLoading" placeholder="Notitie" v-model="model.note"></TextArea>

import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'

// We need text style, width and display for being able to display pre-formatted 'table'-like data. 
// For example, invoice reminder data. 
import TextStyle from "@tiptap/extension-text-style";
import TextWidth from "@lib/ui/tiptap-extension-text-width";
import TextDisplay from "@lib/ui/tiptap-extension-text-display";

export default {
    components: {
        EditorContent,
    },

    props: {
        value: {
        type: String,
        default: '',
        },
        disabled: {
            type: [Boolean]
        },
        skeleton: {
            type: [Boolean]
        },
        xl: {
            type: [Boolean]
        },
        lg: {
            type: [Boolean]
        },
        md: {
            type: [Boolean]
        },
        sm: {
            type: [Boolean]
        },
        variables: {
            type: [Object, Array],
            default: () => []
        }

    },

    data() {
        return {
        editor: null,
        }
    },
    computed: {
        classHeight: function() {
            var cls = "sm";
            if (this.xl) { cls = "xl"; }
            else if (this.lg) { cls = "lg"; }
            else if (this.md) { cls = "md"; }
            else if (this.sm) { cls = "sm"; }
            return `wysiwyg-${cls}`;
        },
    },
    watch: {
        value(value) {
            if (!this.editor) {
                return; // Skip in init phase.
            }
            // HTML
            const isSame = this.editor.getHTML() === value

            // JSON
            // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)

            if (isSame) {
                return
            }

            this.editor.commands.setContent(value, false)
        },
        disabled(value) {
            this.editor.setOptions({editable: !value})            
        }
    },

    mounted() {
        this.editor = new Editor({
        content: this.value,
        extensions: [
            StarterKit.configure({
                heading: false,
                orderedList: false,
                horizontalRule: false,
                blockQuote: false,
            }),
            TextStyle,
            TextWidth,
            TextDisplay,
        ],
        onUpdate: () => {
            // HTML
            this.$emit('input', this.editor.getHTML())

            // JSON
            // this.$emit('input', this.editor.getJSON())
        },
        })
        this.editor.setOptions({editable: !this.disabled})            
        window.eddie = this.editor;
    },

    beforeDestroy() {
        this.editor.destroy()
    },
}
</script>
