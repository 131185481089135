import { clsModel, fnCreate } from '@cls/clsModel'

////////////////////////////////////////////////////////////////////////////////////////////////////
//
// This model is a helper model which is not used for data manipulation but to
// help create a number of orderlines which can be invoiced at different dates. 
// 
// For example, for an amount of 1000 euro, create 3 terms of 50 / 25 / 25 percent.
//
////////////////////////////////////////////////////////////////////////////////////////////////////

var modelName = "salesorder_createterm";
const fields = [
    'amount', 
    'id_order_term',
    'date',
];

class clsSalesOrderCreateTerm extends clsModel {

    amount = null;
    id_order_term = null;
    date = null;

    async save() {
        return this.toJSON();
    }        

    fill(data) {
        data = {amount: 0, id_order_term: null, date: null};
        return super.fill(data);
    }

    constructor() {
        super({
          api: {},   
          modelName: modelName, 

          fillable: fields
        })
    } 
        
 }
 export default fnCreate(clsSalesOrderCreateTerm , 'clsSalesOrderCreateTerm');
