import eventbus from '@app/eventbus'
import {settings as api} from '@app/api'

/**
 * Datatable settings are actually a simple version of other settings. 
 * Thefore, a simple class. 
 * 
 * The datatable configuration is saved in the database as a stringified json object. 
 * Of course, in here, we add the json objects in the store.
 * 
 */

class clsDatatableSettings {
    store = {};

    constructor() {
        this.registerEvents();        
    }

    /**
     * When parsing to and from json, the names of fields are escaped when containing e.g. a .
     * Just filter them out. 
     * @param {*} table 
     * @returns 
     */
    sanitizeTablename(table) {
        return `${table}`.replaceAll(".", "_");
    }
    
    /**
     * Register for events.
     */
    registerEvents() {
        var self = this;
        eventbus.appdata.loaded.on(  (data) => {
            if (data && data.settings && Array.isArray(data.settings.datatable)) {
                for(var n = 0; n < data.settings.datatable.length; n++) {
                    let tableSetting = data.settings.datatable[n];
                    tableSetting.datatable = this.sanitizeTablename(tableSetting.datatable);
                    if (tableSetting && tableSetting.datatable) {
                        try {
                            let config = JSON.parse(tableSetting.config);
                            if (config) {
                                self.store[tableSetting.datatable] = config; // array
                            }
                        }
                        catch(e) {
                            console.error(`Parsing dt setting config ${tableSetting.datatable}`, e);
                        }
                    }
                }
            }
        })
    }
    
    /**
     * Save a table configuration
     * @param {*} table 
     * @param {*} columns 
     */
    async save(table, columns, extraconfig) {

        table = this.sanitizeTablename(table);

        var saveColumns = [];
        columns.forEach( (col) => {
            var sCol = {value: col.value, visible: col.visible};
            if (col.sort) {
                // sort may be a referece object. Flat it down to a string.
                if (col.sort.value && typeof(col.sort.value) == "string") {
                    sCol.sort = col.sort.value;
                } else if (typeof(col.sort) == "string"){
                    sCol.sort = col.sort
                }
            }
            saveColumns.push(sCol);
        })
        var config = {columns: saveColumns};
        for (var key in (extraconfig||{})) {
            config[key] = extraconfig[key];
        }
//        console.log('config', config)
        let str = JSON.stringify(config);
//        let parsed = JSON.parse(str);
//        console.log('save dt settings original, str, parsed: ', {datatable: table, config: str})
        await api.save("datatable", {datatable: table, config: str});
        this.store[table] = config;
    }

    /**
     * Get the columns for a table.
     * @param {} table 
     * @returns 
     */
    getColumns(table) {

        table = this.sanitizeTablename(table);

        let config = this.store[table] || {};
        if (!config || !config.columns ) {
            return [];
        }
        return config.columns;
    }

}

export default clsDatatableSettings