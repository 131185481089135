import { clsModel, fnCreate } from '@cls/clsModel'
import { workorders as api } from '@/app/api'
import { workordertypes as typeApi } from '@/app/api'
import Constants from '@app/consts'
import string from '@lib/string'

var modelName = "workorder";
const id_optimit_type = Constants.optimit_types.workorder;
const ACTION_COPY   = 'workorder.copy';

const fields = [
    "id",
    "id_workordertype",
    "title",
    "repeat",
    "number",
    "status",
    "description",
    "targetdate",
    "estimate",
    "invoicable",
    "sendemail",
    "priority",
    "recurrence",
    "recurrencetype",
    "id_relation",
    "id_project",
    "pro_number",
    "id_address",
    "id_person",
    "customfields",
];

class clsCustomFieldValues {
    list = [];

    fillData(customfields) {
        this.list = (customfields || []).map( (cf) => {
            cf.id_customfield = cf.id_customfield || cf.id;
            cf.id = null;
            // If default is provided, set its value
            if (!cf.value && undefined !== cf.default) {
                cf.value = cf.default;
            }
            var values = string.split(cf.values, ",");
            cf.values = values.map( (x) => { return {id: x, name: x} });
            
            return cf;
        });        
    }
    toJSON() {
        return this.list.map( (cf) => {
            return {id_customfield: cf.id_customfield, value: cf.value}
        })
    }
}
class clsWorkorder extends clsModel {

    _id_workordertype = null;
    title = null;
    number = null;
    status = null;
    description = null;
    targetdate = null;
    estimate = null;
    sendemail = false;
    invoicable = null;
    priority = null;
    repeat = false;
    recurrence = null;
    recurrencetype = null;
    id_relation = null;
    id_project = null;
    pro_number = null;
    id_address = null;
    id_person = null;

    customfields = new clsCustomFieldValues();

    get id_workordertype() {
        return this._id_workordertype;
    }

    apiLoad(id, params) {
        params = params ||{};
        if (params.action == ACTION_COPY) {
            return this.api.copy(id, params);
        }
        return super.apiLoad(id, params);
    }

    /**
     * Takeover the defaults from the chosen workorder type. 
     * Also, convert the custom fields to custom field values.
     */
    async applyWorkorderType() {
        this.isDataLoading = true;
        try {
            let {data} = await typeApi.load(this.id_workordertype);
            console.log("data: ", data)
            this.estimate   = data.estimate;
            this.priority   = data.priority;
            this.invoicable = data.invoicable;
            this.sendemail  = data.sendemail;
            
            this.customfields.fillData(data.customfields);
//            this.customfields = (data.customfields || []).map( (cf) => {
//                cf.id_customfield = cf.id;
//                cf.id = null;
//                cf.value = "";
//                return cf;
//            });        
        } finally {
            this.isDataLoading = false;
        }
    }

    set id_workordertype(value) {
        this._id_workordertype = value;
        if (this.isFilling) {
            return;
        }
        this.applyWorkorderType();
    }

    /**
     * Make sure that the vendors array is always present, frontend probably depends on array type. 
     * @param {} data 
     * @returns 
     */
    fill(data) {
        data = data ||{};
        if (!data.vendors) {
            data.vendors = [];
        }
        if (undefined == data.invoicable) {
            data.invoicable = true;
        }
        if (undefined == data.status) {
            data.status = Constants.workorder.status.CONCEPT
        }
        data.recurrencetype = data.recurrencetype || 'n';
        data.recurrence = data.recurrence || 1;
        data.repeat = data.recurrencetype != 'n'
        data.priority = data.priority || Constants.priority.NORMAL
        data.invoicable = !! data.invoicable;  
        data.sendemail = !! data.sendemail;  
        data.estimate = data.estimate || 0;
        
        return super.fill(data);
    }
    get recurrenceRep() {
        switch (this.recurrencetype) {
            case 'd': return "dagen";
            case 'w': return "weken";
            case 'm': return "maanden";
            case 'y': return "jaar";
        }
        return "";
    }

    get modelRep() {
        return this.number;
    }

    toJSON() {
        var data = super.toJSON();
        if (!this.repeat) {
            data.recurrence = 0;
            data.recurrencetype = 'n';
        }
        return data;
    }

    /**
     * Override to create specific implementations. 
     */
//    async doCreate(defaults) {
//        defaults = defaults || {};
//        var result = await api.loadNew();
//        return await super.doCreate(result.data);
//    }


    constructor() {
        super({
          api: api,   
          modelName: modelName, 
          id_optimit_type: id_optimit_type, 

          fillable: fields
        })
    } 


 }
 export default fnCreate(clsWorkorder , 'clsWorkorder');
