<template>
    <v-dialog class="action-dialog action-dialog-small"
        :key="dialog.dlgKey"
        :content-class="`${dialog.cls} action-dialog-small`" 
        persistent 
        scrollable
        :fullscreen="$vuetify.breakpoint.mobile"
        v-model="dialog.open" 
        :maxWidth="dialog.maxWidth" 
        @keydown="(evt) => dialog.onKeyDown(evt)">
            <v-card flat :loading="dialog.isLoading">

                <v-card-title flat class="px-4 pt-0 pb-0">
                    <slot name="header-title">
                        <v-list class="py-0 dialog-header-title">
                            <v-list-item class="px-0 py-2">
                                <v-list-item-action class="icon-container ma-0 pa-2 mr-4" v-if="dialog.icon" >
                                    <slot name="header-icon">
                                        <Icon color="primary" :type="dialog.icon"></Icon>
                                    </slot>
                                </v-list-item-action>                                            
                                <v-list-item-content class="py-0">
                                    <v-list-item-title class="mb-0">
                                        <slot name="header-maintitle">
                                            <h1 class="capitalize"><slot name="title">{{dialog.title}}</slot></h1>
                                        </slot>
                                    </v-list-item-title>
                                    <v-list-item-subtitle  v-if="!dialog.noSubTitle">
                                        <slot name="header-subtitle">
                                            <caption class="capitalize">{{dialog.subTitle}}</caption>
                                        </slot>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>                                    
                        </v-list>
                    </slot>
                        <v-spacer></v-spacer>                            
                        <div class="commands">
                            <slot name="header-pre-actions"></slot>
                                <slot name="header-action-note">
                                    <HeaderButton v-if="dialog.noteType"  :action="`${dialog.noteType}.open`" dusk="btn-dialog-note" title="Open notitie" @click="() => dialog.onNote()" class="ml-2" small :class="dialog.noteCount?'icon-note-filled':'icon-note-empty'" :type="dialog.noteCount?'notefilled':'note'"/>
                                </slot>
                                <slot name="header-action-attachment">
                                    <HeaderButton v-if="dialog.attachmentType" :action="`${dialog.attachmentType}.open`" dusk="btn-dialog-attachments" title="open bijlagen" @click="() => dialog.onAttachment()" class="ml-2" small :class="dialog.attachmentCount?'icon-attachment-filled':'icon-attachment-empty'" :type="dialog.attachmentCount?'attachmentsfilled':'attachments'"/>
                                </slot>
                            <slot name="header-post-actions">

                            </slot>
                            <slot name="header-action-menu">                                        
                                <v-menu v-if="$slots.topmenu" bottom offset-y>
                                    <template v-slot:activator="{ on }">
                                        <span v-on="on">
                                            <HeaderButton dusk="btn-dialog-menu-top" no-count class="ml-2" type="menu-horizontal"/>
                                        </span>
                                    </template>
                                    <v-list dense>
                                        <slot name="topmenu"></slot>
                                    </v-list>
                                </v-menu>

                            </slot>
                            <slot name="header-close">
                                <HeaderButton  dusk="btn-dialog-close" no-count class="ml-2" type="close" :disabled="dialog.isLoading" @click="dialog.onClose()"/>
                            </slot>
                            <slot name="header-post-actions"></slot>

                        </div>
                </v-card-title>                           

                <v-card-text flat class="pa-0">
                    <v-divider class="mb-0"></v-divider>
                </v-card-text>
                
                <v-card-text v-if="$slots['toolbar-left']||$slots['toolbar-right']"  class="overflow-y-visible pb-0">
                    <v-toolbar dense flat rclass="mx-0 px-0 mb-0">
                        <slot name="toolbar-left"></slot>
                        <v-spacer></v-spacer>
                        <slot name="toolbar-right"></slot>
                    </v-toolbar>
                </v-card-text>
                <v-divider v-if="$slots['toolbar-left']||$slots['toolbar-right']">
                </v-divider>

                <v-card-text v-if="$slots['pre-tabs']" class="overflow-y-visible pb-0">
                    <slot name="pre-tabs"></slot>
                </v-card-text>

                <v-card-text v-if="$slots['tabs-left']||$slots['tabs-right']" class="overflow-y-visible pa-0">
                    <v-toolbar dense flat class="mx-0 pl-4 mb-0">
                        <slot name="tabs-left"></slot>
                        <v-spacer></v-spacer>
                        <slot name="tabs-right"></slot>
                    </v-toolbar>
                    <v-divider></v-divider>
                </v-card-text>
                
                <slot name="content">
                    <v-card-text class="content" :class="clsContentPadding">
                        <slot></slot>
                    </v-card-text>
                </slot>
                <v-divider v-if="!dialog.noActions"></v-divider>
                <v-card-actions class="py-4" v-if="!dialog.noActions">
                    <slot name="footer-left">
                    </slot>
                    <v-spacer></v-spacer>                            
                    <slot name="footer-right">
                    </slot>
                    <div class="commands">
                        <slot name="footer-pre-actions"></slot>

                        <slot v-if="dialog.cancel" name="footer-cancel">
                            <ActionButton dusk="btn-cancel" :disabled="dialog.isLoading" @click="dialog.onClose()">
                                <slot name="footer-cancel-text">{{dialog.cancel}}</slot>
                            </ActionButton>
                        </slot>

                        <slot v-if="dialog.save" name="footer-save">
                            <ActionButton dusk="btn-save" :disabled="dialog.isLoading||dialog.disabled" main primary ml @click="dialog.onSave()">
                                <slot name="footer-save-text">{{dialog.save}}</slot>
                            </ActionButton>
                        </slot>

                        <slot name="footer-post-actions"></slot>

                    </div>
            </v-card-actions>           
        </v-card>

    </v-dialog>
</template>

<script setup>

    import Icon from '@controls/icons/Icon'
    import ActionControl from '@controls/ActionControl'
    import HeaderTitle from '@shared/ui/dialogs/HeaderTitle'
    import HeaderButton from '@shared/ui/dialogs/HeaderActionButton'
    import ActionButton from '@controls/buttons/ActionButton'
    import {computed, ref} from 'vue'

    const props = defineProps({
        dialog: {
            type: [Object]
        },
        noContentPadding: {
            type: [Boolean],
            default: false
        },
    })

    const clsContentPadding = computed({
        get() {
            return props.noContentPadding ? "pa-0" : "pa-4";
        }
    })

</script>
