import bool from '@shared/lib/bool'
import action from '@app/action'

/**
 * Base class for models
 * Just implements a couple of shared items.
 * 
 * Usage: 
 * import clsModelBase from '@cls/clsModelBase'
 *    class clsEntityBase extends clsModelBase {
 *       ... implementation ...
 *    }
 * 
 * The model base does not check any rights.
 *
 */
class clsModelBase {

    _disabled = false;
    isLoading = false;
    cntDataLoading = 0;

    modelName  = null;     // The name of the entity. Used in event handling - e.g. sending save event
    modelTitle = null;     // The title. Shown in dialogs. For example: 'Factuur'.

    // a modelname which might be set to check rights on. 
    // This is onl applicable for generic models.
    // For example, we have generic note functionallity for which the rights can be set per entity. 
    // In this case, the note model must set the rightsModelName after load.
    rightsModelName = null; // 

    mandatoryFields = [];
    rules = {};
    
    /**
     * Set the mandatory rules based on the mandatory Field values. 
     * Override to implement specific validation rules.
     */
    setRules() {
        var mandatoryFields = this.mandatoryFields||[] ;
        (mandatoryFields||[]).forEach( (f) => {
            this.rules[f] = [ (v) => !!this.isLoading || !!this.isDataLoading || !!v || "Veld is verplicht" ];
        })
        // 2 freely usable fields
        this.rules["mandatory"] = [ (v) => !!this.isLoading || !!this.isDataLoading || !!v || "Veld is verplicht" ];
        this.rules["mandatoryOr0"] = [ (v) => !!this.isLoading || !!this.isDataLoading || (v===0||!!v) || "Veld is verplicht" ];
    }

    constructor(config) {
        config = config || {};

        this.modelName  = config.modelName;
        this.modelTitle = config.modelTitle;
        this.mandatoryFields = config.mandatoryFields||[];

        this.setRules();
    }

    /**
     * Called befor the model is opened in a dialog. We set the name of the rights model.
     * This is used for generic dialogs which are used in multiple authorizatio contexts. 
     * For example, a note dialog can be opened for a relation, invoice, etcetera.
     * In this case the rights to check are different in each case. 
     */
    onBeforeOpen(params, extraData) {
        this.rightsModelName = extraData?.rightsModelName || null;
    }

    

    /**
     * Is Data Loading uses a counter as loaders can be started withing loading actions.
     */
    get isDataLoading() {
        return this.cntDataLoading > 0;
    }
    set isDataLoading(v) {
        var self = this;
        if (v)  {
            self.isLoading = true;
            self.cntDataLoading = self.cntDataLoading + 1;
            if (self.cntDataLoading <= 0) {
                self.cntDataLoading = 1;
            }

        } else {
            setTimeout( () => {
                self.isLoading = false;
                self.cntDataLoading = self.cntDataLoading - 1;
                if (self.cntDataLoading < 0) {
                    self.cntDataLoading = 0;
                }
            }, 100);
        }
    }

    /*
     * The name of the model. When applicable, overrule. Example: modelType: 'Factuur', modelRep: '20220123'  
     */
    get modelRep() {
        return '';
    } 
    
    /**
     * Get the main title. This is basicly the item type. E.g. 'Nieuwe relatie' or 'Relatie'
     */
    get title() {
        return this.modelTitle || "";
    }

    /**
     * Get the title. Overload the titleRep getter to implement the title.
     */
     get subTitle() {
        if (this.isLoading) {
            return 'Laden...';
        }
        if (this.isNew) {
            return 'Nieuw';
        }
        if (this.modelRep) {
            return this.modelRep;
        } 
        if (this.disabled) {
            return '';
        }
        return 'Wijzig';
    }

    /**
     * return default rights (by default, all rights)
     */
    get canAccess() {
        return true;
    }
    get canModify() {

        if (this.isLoading||this.isDataLoading) {
            return false;
        }
        // The modelName can be different than the name for which to check rights. 
        // E.g. A note dialog might need rights on relation_note or invoice_note.
        var modelName = this.rightsModelName || this.modelName;
        if (!modelName) {
            return true; // No restrictions.
        }
        // When the model is new --> no id is set, check the create right.
        // When no create right exists, use the modify anyway. For example, a note dialog has only open/modify rights.
        if (this.isNew) {
            if (action.exists(`${modelName}.create`)) {
                return action.can(`${modelName}.create`)
            }
        }
        // When no modify rights are available, no modify rights.
        if (!action.exists(`${modelName}.modify`)) {
            return false;
        }
         
        return action.can(`${modelName}.modify`)
    }

    get canRemove() {
        return true;
    }
    get canCreate() {
        return true;
    }

    /**
     * Are we disabled?
     * The default disabled rule just checks the modify rights. 
     * When required, overwrite this getter.
     */
    get disabled() {
        return this._disabled || !this.canModify;
    }
    set disabled(val) {
        this._disabled = val;  
    }

    /**
     * Tiny helper to convert a value to bool.
     * @param {*} v 
     * @returns 
     */
    bool(v) {
        return bool.isTrue(v);
    }

    // Execute an async action and while executing, set the isLoading flag to true.
    // Usage: 
    //
    //      save() {
    //          let data = this.toJson();
    //      
    //          return this.withLoading( () => { 
    //              return api.purchase.save(data)
    //                    .then( (result) => {
    //                          var para = result && result.data && result.data.model;
    //                          eventbus.purchase.saved(para);
    //                          return result;    
    //                    })
    //          })
    //      }
    // 
    withLoading( fnPromise ) {
        var self = this;
        this.isLoading = true;
        return fnPromise()
        .finally( () => {
            self.isLoading = false;
        })
    }

    /**
     * Some actions and states are specificly affected when we are data loading, and not when loading in general. 
     * For example, we can display a skeleton when data is loaded.
     * However, when we are saving, we don't want to show a skeleton.
     * 
     * Note that although the original idea is to have a promised parameter function, however, it supports unpromised functions as well.
     * 
     * The withDataLoading method does the same as withLoading plus it switches the isDataLoading member. 
     */
    withDataLoading( fnPromise ) {
        var self = this;
        self.isDataLoading = true;
        let result = fnPromise();
        if (result && result.finally) {
            return result.finally( () => self.isDataLoading  = false)
        }
        self.isDataLoading = false;
        return result;
    }

    /**
     * Add one or more properties to a json structure.
     * Example: 
     *  var json = this.propsToJSON(["id","name","rights"])
     * @param {*} arrProps 
     * @returns 
     */
    propsToJSON(arrProps) {
        let result = {};
        var self = this;
        (arrProps || []).forEach(  ( item ) => { 
            var value = self[item];
            if (value && value.toJSON) {
                result[item] = value.toJSON();
            } else {
                result[item] = self[item] 
            }
        }) 
       
        return result;
    }
    
}

export default clsModelBase
 
