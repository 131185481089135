import clsApi from '@cls/clsApi'

class clsAccountantApi extends clsApi {

    load() {
        return this.get(`load`); 
    }

    remove() {
        return this.post(`remove`); 
    }

    invite(email) {
        return this.post(`invite`, {email: email}); 
    }

}

export default clsAccountantApi;
