import { clsModel, fnCreate } from '@cls/clsModel'

// We operate on a sales invoice, no special model name for sending an invoice. 
var modelName = "tender_finish";
const fields = ['id', 'tend_number'];

class clsTenderFinish extends clsModel {

    id = null;
    tend_number  = null;

    get subTitle() {
        return this.tend_number;
    }

    // We don't load. Just fill default values.
    async load(id, params) {
        return this.fill(params);
    }

    /**
     * Instead of saving, we just return the data
     */
    async save() {
        let data = this.toJSON();
        return data;
    }

    fill(data) {
        data = data || {};
        return super.fill(data);
    }
    
    constructor() {
        super({
          modelName: modelName, 
          fillable: fields
        })
    } 
        
 }
 export default fnCreate(clsTenderFinish, 'clsTenderFinish');
