//
// Usage: 
//  import user from '@app/user'
//

import clsCurrentuser from '@/app/clsCurrentuser'
import fnExpose from '@shared/lib/expose'
import {ref} from 'vue'

let obj = ref(new clsCurrentuser());
fnExpose(obj.value, 'user')

export default obj.value;
