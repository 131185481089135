import clsApi from '@cls/clsApi'

class clsPlanningApi extends clsApi {

    /**
     * Load employees available for planning
     */
    async loadEmployees(date) {
        return this.post(`employees/load`, {date: date}); 
    }
    /**
     * Load employees available for planning
     */
    async loadUnassignedWorkorders(year, week, filter) {
        return this.post(`workorders/load/unassigned`, {year: year, week: week, filter: filter}); 
    }
    /**
     * Load planning for a week 
     */
    async loadWeekPlanning(year, week) {
        return this.post(`weekplanning/load`, {year: year, week: week}); 
    }

    /**
     * save an Appointment
     * This can be either a new appointment or a move.
     */
    saveAppointment(model) {
        return this.post(`appointment/save`, model); 
    }
    /**
     * cancel an Appointment
     */
    cancelAppointment(id) {
        return this.post(`appointment/cancel`, {id: id}); 
    }

}

export default clsPlanningApi;
