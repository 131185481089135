////////////////////////////////////////////////////////////////////////////////////////////////////
//
// Base class to be used for a simple custom prompt dialog.
// A custom prompt dialog serves as a simple way to let the user enter some values to be used 
// by other logic. 
// 
// For a complete example, see frontend.shared\ui\dialogs\DialogCustomPrompt.vue
// 
//
////////////////////////////////////////////////////////////////////////////////////////////////////
class clsDialogCustomPrompt {
    dlgKey = undefined
    open = false
    isLoading = false
    disabled = false
    fnCallback = null;
    fields = [];
    // a helper to define a mandatory field.
    mandatory = [(v) => !!this.isLoading || !!this.isDataLoading || !!v || "Veld is verplicht"];

    // await new Promise((resolve, reject) => emit('save', resolve, reject));

    constructor(fields) {
        for (var l = 0; l < (fields||[]).length; l++) {
                this[fields[l]] = null;
        }
        this.fields = fields;
        if (!window._kdlg) {
            window._kdlg = 1;
        }
        this.dlgKey = ++window._kdlg;
    }
    close() {
        this.open = false;
    }

    async save() {
        this.isLoading = true;
        try {
            var result = {}; 
            for (var l = 0; l < (this.fields||[]).length; l++) {
                var key = this.fields[l];
                result[key] = this[key];
            }
            await this.fnCallback(result);
            this.open = false;
        } finally {
            this.isLoading = false;
        }
    }
    clear() {
        return this.setData();
    }
    setData(data) {
        data = data ||{}
        for (var l = 0; l < (this.fields||[]).length; l++) {
            var key = this.fields[l];
            this[key] = data[key]||null;
        }
        return this;
    }
    prompt(fnCallback) {
        if (!fnCallback) {
            throw "Pls. specify async callback";
        }
        this.fnCallback = fnCallback;
        this.open = true;
    }
}


export default clsDialogCustomPrompt;