<template>    

    <v-icon :class="{'custom-color':color,'icon-medium':compMedium,'icon-small':compSmall,'icon-xsmall':compXSmall}" :small="compSmall" :large="compLarge" :xLarge="compXLarge" :color="color">{{compIcon}}</v-icon>

</template>

<script setup> 
import string from '@lib/string'
import icon from '@app/icon'
import {computed, ref} from 'vue'

//
// Usage: 
// import Icon from '@controls/icons/Icon'
// <Icon type="invoice"></Icon>
// 

const props = defineProps({
            icon: {
                type: [String],
                default: null
            },
            size: {
                type: [String],
                default: "s"
            },
            type: {
                type: [String],
                default: ""
            },
            color: {
                type: [String],
                default: null
            },
})

// sizes. 
// Note that 'medium' is the default annotation on an icon in case 
// no small, large, or xlarge attribute is set on the icon is specified.
const compSmall = computed({
    get() {
        return props.size == "s"
    }
})
const compXSmall = computed({
    get() {
        return props.size == "xs"
    }
})
const compMedium = computed({
    get() {
        return props.size == "m"
    }
})
const compLarge = computed({
    get() {
        return props.size == "l"
    }
})
const compXLarge = computed({
    get() {
        return props.size == "xl"
    }
})

const compIcon = computed({
    get() {
        if (string.isNotEmpty(props.icon)) {
            return props.icon;
        }
        
        return icon.forType(props.type)
    }
})
</script>
