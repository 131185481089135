<template>    


    <v-radio-group :disabled="compDisabled||compSkeleton" hide-details class="mt-0" v-model="dvalue">
        <slot></slot>
    </v-radio-group>
    

</template>

<script setup>

// import Radiogroup from "@controls/check/Radiogroup.vue";
// import Radio from "@controls/check/Radio.vue";
// <Radiogroup :model="model" v-model="model.color">
//      <v-row>
//          <v-col>
//              <Radio :model="model" value="percentage" label="Percentage"/>
//          </v-col>
//          v-col>
//              <Radio :model="model" value="amount" label="Vast bedrag"/>
//          </v-col>
//      </v-row>                            
// </Radiogroup>

    const props = defineProps({
        disabled: {
            type: [Boolean]
        },
        rules: {
            type: [Array]
        },
        noClearable: {
            type: [Boolean],
            default: false
        },
        skeleton: {
            type: [Boolean]
        },
        value: {
            type: [String, Number, Boolean]
        },
        model: {
            type: [Object]
        }

    })
    
    import {computed} from 'vue'

    const emit = defineEmits(['input'])

    const dvalue = computed({
        get() {
            return props.value
        },
        set: function(v) {
            emit('input', v)
        }	
    })

    const compSkeleton = computed({
        get() {
            if (props.skeleton) {
                return true;
            }
            if (!props.model) {
                return false;
            }
            return props.model.isDataLoading;
        }
    });
    const compDisabled = computed({
        get() {
            if (props.disabled) {
                return true;
            }
            if (!props.model) {
                return false;
            }
            return props.model.disabled;
        }
    });

</script>
