import { clsModel, fnCreate } from '@cls/clsModel'
import { notes as api } from '@/app/api'
import eventbus from '@app/eventbus'

var modelName = "note";

class clsNote extends clsModel {

    note     = "";
    id_optimit_type = null;
    id_entity = null;
    
    constructor() {
        super({
          api: api,   
          modelName: modelName, 
        })
    } 

    /**
     * Override load to retrieve the address via 2 keys - a relation id and an address id.
     * @param {} id 
     * @param {*} params 
     * @returns 
     */
    load(id, params, extraData) {
        // When an extra rights model is provided, use that to check for modify acces.
        this.rightsModelName = extraData?.rightsModelName || null;

        var self = this;
        this.fill();
        return this.withDataLoading( () => {
            return self.api.loadNote(params.id_optimit_type, params.id)
                .then( ({data}) => {
                self.fill(data);

                // After load, set the initial state so that we can track the modified flag.
                self.setInitialState();
            })
        })    
    }

    /**
     * Add the note type to the saved event.
     */
    sendSavedEvent(data) {
        if (data) {
            data.noteType = this.rightsModelName;
        }
        return super.sendSavedEvent(data);
    }

    /**
     * Fill the data 
     * 
     * @param {*} data 
     */
    fill(data) {
        data = data || {};
        super.fill(data);
        this.id_optimit_type = data.id_optimit_type;
        this.id_entity = data.id_entity;
    
        this.note     = data.note || ""
    }

    /**
     * Get the JSON representation for saving the data
     * 
     * @param {*} data 
     */
    toJSON() {
        return this.propsToJSON(["id_optimit_type", "id_entity", "note"]);
    }
        
 }
 export default fnCreate(clsNote , 'clsNote');
