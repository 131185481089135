<template>
    <Dialog :dialog="dlg" no-content-padding>
            <template v-slot:tabs-left>
                <v-tabs dusk="tabs-product" dense v-model="dlg.tab">        
                    <v-tab dusk="tab-product-header">Artikel</v-tab>
                    <v-tab dusk="tab-product-producer" :disabled="model.isLoading">Fabrikant</v-tab>
                    <v-tab dusk="tab-product-supplier" :disabled="model.isLoading">Leveranciers</v-tab>
                </v-tabs>
            </template>

            <v-form xref="form" lazy-validation>
                <v-tabs-items class="product-tabs xmt-2" v-model="dlg.tab">                
                    <v-tab-item class="restore-content-padding" transition="fade-transition" reverse-transition="fade-transition">                        
                        <v-form ref="formHeader">
                            <Form>
                                <FormRow label="Artikelcode">            
                                    <TextField :model="model" dusk="pd_code" max-length="128" placeholder="Uw artikelcode" :rules="model.rules.mandatory" v-model="model.pd_code"></TextField>
                                </FormRow>
                                <FormRow label="Naam">            
                                    <TextField dusk="pd_name" :model="model" max-length="255" placeholder="Uw artikelnaam" :rules="model.rules.mandatory" v-model="model.pd_name"></TextField>
                                </FormRow>
                                <FormRow label="Omschrijving" label-v-top>            
                                    <TextArea dusk="pd_description" rows=2 :model="model" max-length="2048" placeholder="" v-model="model.pd_description"></TextArea>                                    
                                </FormRow>
                                <v-divider class="my-4"></v-divider>

                                <FormRow label="Artikelgroep">            
                                    <ProductGroupCombo dusk="id_productgroup" :model="model" v-model="model.id_productgroup"></ProductGroupCombo>
                                </FormRow>

                                <v-divider class="my-4"></v-divider>
                                <FormRow label="BTW Type">            
                                    <VatCombo dusk="id_pd_vat" :model="model"  v-model="model.id_pd_vat"></VatCombo>
                                </FormRow>
                                <FormRow label="Eenheid">            
                                    <UnitCombo dusk="id_unity" :model="model"  v-model="model.id_unity"></UnitCombo>
                                </FormRow>

                                <FormRow label="Inkoopprijs excl. BTW">
                                    <NumberField dusk="pd_purchase_price" amount :model="model"   v-model="model.pd_purchase_price"></NumberField>
                                </FormRow>
                                <FormRow label="Verkoopprijs excl. BTW">
                                    <NumberField dusk="price_excl_vat" amount :model="model"   v-model="model.price_excl_vat"></NumberField>
                                </FormRow>
                                <FormRow label="Grootboek">            
                                    <LedgerCombo dusk="id_ledger" :model="model"  v-model="model.id_ledger"></LedgerCombo>
                                </FormRow>
                                

                                <v-divider v-if="feature.canConsumerPrice" class="my-4"></v-divider>
                                <FormRow  v-if="feature.canConsumerPrice" label="Consumentenprijs Incl. BTW">
                                    <NumberField dusk="price_incl_vat" amount :model="model"  v-model="model.price_incl_vat"></NumberField>
                                </FormRow>

                            </Form>

                        </v-form>
                    </v-tab-item>
                    <v-tab-item class="restore-content-padding" transition="fade-transition" reverse-transition="fade-transition">                        
                        <v-form ref="formSupplier">
                            <Form>
                                <FormRow label="Fabrikant">            
                                    <SupplierCombo dusk="id_supplier" :model="model"  v-model="model.id_supplier"></SupplierCombo>
                                </FormRow>
                                <FormRow label="EAN">            
                                    <TextField dusk="ean" :model="model" max-length="18" placeholder="Europees Artikel Nummer"  v-model="model.ean"></TextField>
                                </FormRow>
                                <FormRow label="Code">            
                                    <TextField dusk="pd_supplier_code" :model="model" max-length="128" placeholder="Artikelcode"  v-model="model.pd_supplier_code"></TextField>
                                </FormRow>
                                <FormRow label="Naam">            
                                    <TextField dusk="pd_supplier_name" :model="model" max-length="255" placeholder="Artikelnaam"  v-model="model.pd_supplier_name"></TextField>
                                </FormRow>
                            </Form>
                        </v-form>
                    </v-tab-item>
                    <v-tab-item>
                        <LocalTable :model="model" modelLinesProp='vendors' :dt="tblLocal">
                            <template v-slot:menu="{ item }">
                                <ActionMenuItem action="relation.open" :payload="item">Open leverancier</ActionMenuItem>
                                <v-divider></v-divider>
                                <ActionMenuItem action="product.modify" no-execute icon="remove" @click="model.removeVendor(item)">Verwijder leverancier</ActionMenuItem>
                            </template>
                            <template v-slot:item.purchase_price_excl_vat="{ item }">
                                <NumberField dusk="purchase_price_excl_vat" dense amount :model="model"   v-model="item.purchase_price_excl_vat"></NumberField>
                            </template>
                            <template v-slot:item.archived_at="{ item }">
                                <span v-if="!item.archived_at">Actief</span>
                                <span v-else class="text-error">Gearchiveerd</span>
                            </template>

                        </LocalTable>
                    </v-tab-item>
                </v-tabs-items>
            </v-form>

            <template v-slot:footer-left>
                <ActionButton main sub dusk="btn-addsupplier" v-if="dlg.tab>=2" action="product.modify" :model="model" @click="addSupplier()">Voeg leverancier toe</ActionButton>
            </template>
    </Dialog>
</template>



<script setup>
    import useModel from '@/models/product/product'
    import Form from '@controls/forms/Form'
    import FormColumn from '@controls/forms/FormColumn'
    import FormRow from '@controls/forms/FormRow'
    import TextField from '@controls/input/TextField'
    import TextArea from '@controls/input/TextArea'
    import Dialog from '@shared/ui/dialogs/ActionDialog'
    import CountryCombo from '@controls/combo/Country'
    import Checkbox from "@controls/check/Checkbox.vue";
    import VatCombo from '@controls/combo/Vat'
    import UnitCombo from '@controls/combo/Unit'
    import LedgerCombo from '@controls/combo/Ledger'
    import SupplierCombo from '@controls/combo/Supplier'
    import NumberField from '@controls/input/NumberField'
    import ProductGroupCombo from '@controls/combo/ProductGroup'
    import ActionButton from '@controls/buttons/ActionButton'
    import ActionMenuItem from "@controls/menu/ActionMenuItem.vue";
    import eventbus from '@app/eventbus'

    import { computed, ref } from 'vue'
    import clsDialog from '@cls/clsDialog'
    import LocalTable from '@datatable/LocalTable'  
    import clsLocalTable from '@cls/clsLocalTable'
    import feature from '@app/features';

    const formHeader = ref(null); 
    const formSupplier = ref(null); 

    let model = useModel();
    let dlg = ref(
        new clsDialog("product", "Artikel", model, [formHeader, formSupplier], {
            noteType: "product_note",
        } )).value;

    // -- add a supplier to the list of suppliers.
    async function addSupplier() {

        let exclude = model.vendorIds; // already there
        let selected = await eventbus.dialog.open.promise('selectvendor', {multiselect: false, exclude: exclude})
        if (!selected || !selected.length) {
            return;
        }
        model.addVendor(selected[0]);
    }
    

    let headers = [ 
        { "type": "cmd" }, 
        { "text": "Leverancier", "value": "rel_name" }, 
        { "text": "InkoopPrijs", "value": "purchase_price_excl_vat" }, 
        { "text": "Status", "value": "archived_at", xfmt: (v,i) => !v ? 'Actief' : 'Archief' }, 
    ];

    let tblLocal = new clsLocalTable("productsupliers", headers, {
        noDataText: "Er zijn geen leveranciers gekoppeld",
        model: model, 
        modelLinesProp: "vendors"    

    });

    window.dlgp = dlg;
</script>
