<template>

<div>
    <ActionCombo :noContextMenu="noContextMenu" item-text="rel_name" :noClearable="noClearable" :placeholder="placeholder" :skeleton="compSkeleton" :disabled="compDisabled" :rules="rules" v-model="dvalue" :items="items"
                 :customFilter="filter" open :create="!noCreate&&create" route="relations.overview" baseType="relation">
        <template v-slot:item="{ index, item }">
                <v-list-item-content>
                    <v-list-item-title>{{ item.rel_name}}</v-list-item-title>
                    <v-list-item-subtitle>{{ repAddress(item) }}</v-list-item-subtitle>
                    <v-list-item-subtitle v-if="bookNumber">{{ repBooknumber(item) }}</v-list-item-subtitle>
                </v-list-item-content>
        </template>

    </ActionCombo>
</div>
</template>

<script setup>

    // import RelationCombo from '@controls/combo/Relation'
    //            <RelationCombo :disabled="model.disabled" :rules="model.rules.id_relation" v-model="model.id_relation"></RelationCombo>

    const props = defineProps({
        disabled: {
            type: [Boolean]
        },
        rules: {
            type: [Array]
        },
        noClearable: {
            type: [Boolean],
            default: false
        },
        bookNumber: {
            type: [Boolean],
            default: false
        },
        skeleton: {
            type: [Boolean]
        },
        value: {
            type: [String, Number]
        },
        placeholder: {
            type: [String],
            default: 'Relatie'
        },
        model: {
            type: [Object]
        },
        noContextMenu: {
            type: [Boolean],
            default: false
        },
        skeleton: {
            type: [Boolean]
        },
        purchase: {
            type: [Boolean],
            default: false
        },
        sales: {
            type: [Boolean],
            default: false
        },
        create: {
            type: [Boolean],
            default: true
        },
        noCreate: {
            type: [Boolean],
            default: false
        },

    })
    
    import ActionCombo from '@controls/combo/ActionCombo'
    import {relation as list} from '@app/list';
    import {computed} from 'vue'
    import string from '@lib/string'

    const emit = defineEmits(['input'])

    function repAddress(item) {
        var value = string.concat(",", item.adr_city, item.adr_street);
        if (string.isNotEmpty(item.adr_street)) {
            value = string.concat(" ", value, item.adr_street_number);
        }
        return value;
    }

    function repBooknumber(item) {

        if (string.isNotEmpty(item.booknumber)) {
            return `Boekhoudnummer: ${item.booknumber}`;
        }
        return '';
    }

    const dvalue = computed({
        get() {
            return props.value
        },
        set: function(v) {
            emit('input', v)
        }	
    })

    const compSkeleton = computed({
        get() {
            if (props.skeleton) {
                return true;
            }
            if (!props.model) {
                return false;
            }
            return props.model.isDataLoading;
        }
    });
    const compDisabled = computed({
        get() {
            if (props.disabled) {
                return true;
            }
            if (!props.model) {
                return false;
            }
            return props.model.disabled;
        }
    });

    const items = computed({
        get() {
            
            // Sales only 
            if (props.sales && !props.purchase) {
                return list.listSales(dvalue.value) || []
            }

            // Purchase only 
            else if (props.purchase && !props.sales) {
                return list.listPurchase(dvalue.value) || []
            }
            // Else, return all relations
            return list.listActual(dvalue.value) || []
        },
    })

    /**
     * Dedicated filtering on relations
     *  Search for: 
     *  - name, 
     *  - city
     *  - street 
     *  -- or combinations - e.g. bebber volendam
     */
    function filter(item, filter, c, d) {
        if (!filter) {
            return true;
        }
        // Split search on space so that searching on e.g. 'schilder volendam' also works.
        var fnSearch = (v) => {
            if (string.contains(item.rel_name, v)) {
                return true;
            }
            if (string.contains(item.adr_street, v)) {
                return true;
            }
            if (string.contains(item.adr_city, v)) {
                return true;
            }
            return false;
        }
        var arr = string.split(filter, " ");
        for ( var n = 0; n < (arr||[]).length; n++) {
            if (!fnSearch(arr[n])) {
                return false;
            }            
        }
        return true; 
    } 


</script>
