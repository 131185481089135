import clsApi from '@cls/clsApi'

class clsEmployeeApi extends clsApi {


    loadTariff(id_employee, id_tariff) { 
        return this.post(`tariff/load`, {id_employee:id_employee, id_tariff: id_tariff}); 
    }
    
    saveTariff(model) { 
        return this.post(`tariff/save`, model); 
    }

}

export default clsEmployeeApi;
