<template>
    <Dialog :dialog="dlg">
        <v-form ref="form" lazy-validation>
            <Form>

                <FormRow label="Relatie">
                    <RelationCombo :model='model' :rules='model.rules.mandatory' v-model='model.id_relation'></RelationCombo>
                </FormRow>
                <FormRow label="Limiet">
                    <NumberField dusk="pl_amount" :rules="model.rules.mandatory" amount :model="model" v-model="model.pl_amount">
                    </NumberField>
                </FormRow>
            </Form>
        </v-form>

    </Dialog>
</template>



<script setup>
    import useModel from '@/models/project/costlimit'
    import Form from '@controls/forms/Form'
    import FormRow from '@controls/forms/FormRow'
    import Dialog from '@shared/ui/dialogs/ActionDialog'
    import NumberField from '@controls/input/NumberField'
    import RelationCombo from '@controls/combo/Relation'

    import { ref } from 'vue'
    import clsDialog from '@cls/clsDialog'

    const form = ref(null); 

    let model = useModel();
    let dlg = ref(
        new clsDialog("project_limit", "Kostenlimiet", model, [form], {
            icon: 'money'
        } )).value;

</script>
