<template>
    <Dialog :dialog="dlg">
        <v-form ref="form" lazy-validation>
            <Form>
                <FormRow label="IBAN" >            
                    <TextField :disabled="!model.isNew" max-length="34" :model="model" placeholder="IBAN" :rules="model.rules.iban" v-model="model.iban"></TextField>
                </FormRow>
                <FormRow label="BIC">
                    <TextField max-length="11" :model="model" placeholder="Alleen voor betalingen buiten de EU" :rules="model.rules.bic" v-model="model.bic"></TextField>
                </FormRow>
            </Form>
        </v-form>

    </Dialog>
</template>



<script setup>
    import useModel from '@/models/relationbankaccount'
    import Form from '@controls/forms/Form'
    import FormColumn from '@controls/forms/FormColumn'
    import FormRow from '@controls/forms/FormRow'
    import TextField from '@controls/input/TextField'
    import Dialog from '@shared/ui/dialogs/ActionDialog'

    import { ref } from 'vue'
    import clsDialog from '@cls/clsDialog'

    const form = ref(null); 

    let model = useModel();

    class clsMyDialog extends clsDialog {

    }

    let dlg = ref(
        new clsMyDialog("relation_bankaccount", "Bankrekening", model, [form], {
            icon: 'bank'
        })).value;

    window.drbnk = dlg;
</script>
